import React from 'react';

import styled, { keyframes } from 'styled-components';

import theme from 'config/theme';

const { colors } = theme;

const Spinner = styled.div`
	width: 70px;
	text-align: center;
`;

const animationKeyFrames = keyframes`
  0%, 80%, 100% { 
    transform: scale(0);
  } 
  40% { 
    transform: scale(1.0);
  }
`;

const dotSizePx = 12;

const Dot = styled.div<{ color: string }>`
	width: ${dotSizePx}px;
	height: ${dotSizePx}px;
	background-color: ${({ color }) => color};
	border-radius: 100%;
	display: inline-block;
	animation: ${animationKeyFrames} 1.4s infinite ease-in-out both;
`;

const Dot1 = styled(Dot)`
	animation-delay: -0.32s;
`;

const Dot2 = styled(Dot)`
	animation-delay: -0.16s;
`;

interface ThreeDotsLoader {
	color?: string;
}

const ThreeDotsLoader = ({ color = colors.darkBlue }: ThreeDotsLoader) => (
	<Spinner>
		<Dot1 color={color} />
		<Dot2 color={color} />
		<Dot color={color} />
	</Spinner>
);

export default ThreeDotsLoader;
