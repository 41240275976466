import React from 'react';
import styled from 'styled-components';
import theme from 'config/theme';
import CheckedIcon from 'assets/images/icons/filter-checkbox-checked.svg';
import { InfoText } from 'lib/InfoText';

const { colors } = theme;

interface CheckboxRowProps {
	text: string;
	checked: boolean;
	onClick(): void;
}

const CheckboxRow = ({ text, checked, onClick }: CheckboxRowProps) => {
	return (
		<Container onClick={() => onClick()}>
			<TextContainer>
				<InfoText noMargin>{text}</InfoText>
			</TextContainer>
			<CheckIcon checked={checked} />
		</Container>
	);
};

export default CheckboxRow;

const Container = styled.div`
	margin-bottom: 16px;
	display: flex;
	justify-content: space-between;
	cursor: pointer;
	align-items: center;
	user-select: none;
`;

const TextContainer = styled.div`
	display: flex;
	align-items: center;
	margin-right: 8px;
	flex-direction: column;
`;

const CheckIcon = styled.div<{ checked: boolean }>`
	${({ checked }) =>
		checked ? `background-image: url(${CheckedIcon})` : `border: 2px solid ${colors.darkGray}`};
	width: 20px;
	height: 20px;
	min-width: 20px;
	min-height: 20px;
	background-size: contain;
	background-position: center;
	border-radius: 4px;
`;
