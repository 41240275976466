import React from 'react';

import styled from 'styled-components';

import { Cross } from 'lib/Cross';
import theme from 'config/theme';
import Title from 'lib/Title';
import SubTitle from 'lib/SubTitle';

const { colors, topBar } = theme;

const HeaderContainer = styled.div<{ closeOnRight?: boolean }>`
	height: ${topBar.height};
	min-height: ${topBar.height};
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const EllipsedTitle = styled(Title)<{ smallTitle?: boolean }>`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	padding: 0 16px;
	font-size: ${({ smallTitle }) => (smallTitle ? '20px' : undefined)};
`;

const SubTitleContainer = styled.div`
	display: flex;
	justify-content: center;
	padding-bottom: 16px;
`;

const Container = styled.div`
	padding: 0 24px;
	border-bottom: 1px solid ${colors.grey10};
`;

const HidableCross = styled(Cross)<{ hidden?: boolean }>`
	${({ hidden }) => (hidden ? 'visibility: hidden;' : '')};
`;

interface TopBarProps {
	onClose(): void;
	title?: string;
	subTitle?: string;
	closeOnRight?: boolean;
	smallTitle?: boolean;
}

export const TopBar = ({
	closeOnRight = true,
	title,
	subTitle,
	smallTitle,
	onClose,
}: TopBarProps) => (
	<Container>
		<HeaderContainer>
			<HidableCross hidden={closeOnRight} onClick={onClose} />
			{Boolean(title) && (
				<EllipsedTitle mobiCentered noMargin smallTitle={smallTitle}>
					{title}
				</EllipsedTitle>
			)}
			<HidableCross hidden={!closeOnRight} onClick={onClose} />
		</HeaderContainer>
		{Boolean(subTitle) && (
			<SubTitleContainer>
				<SubTitle noMargin>{subTitle}</SubTitle>
			</SubTitleContainer>
		)}
	</Container>
);
