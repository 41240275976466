export const EventType = {
	Landing: 'landing',
	FindADigs: 'find_a_digs',
	DigsListing: 'digs_listing',
	BookRoomClick: 'book_a_room_click',
	SelectRoomClick: 'select_room_click',
	ViewRoomsClick: 'view_rooms_click',
	BookClick: 'book_click',
	BookingUserProfileCheck: 'booking_user_profile_check',
	AddADigs: 'add_a_digs_click',
	SearchEmpty: 'search_empty',
	Enquire: 'enquire',
	UserProfileIncomplete: 'user_profile_incomplete',
	PartnersSearch: 'partners_search',

	//booking events
	BookingProfilePersonalInfo: 'booking_profile_personal_info',
	BookingProfileFunding: 'booking_profile_funding',
	BookingMobileVerify: 'booking_mobile_verify',
	BookingEnquiryAdditionalInfo: 'booking_enquiry_additional_info',
	BookingEnquirySubmitSuccess: 'booking_enquiry_submit_success',
	BookingEnquirySubmitFail: 'booking_enquiry_submit_fail',

	//profile setup events
	ProfileStart: 'profile_start',
	ProfileBasic: 'profile_basic',
	ProfileVerifyEmail: 'profile_verify_email',
	ProfilePicture: 'profile_picture',
	ProfileCellNumber: 'profile_cell_number',
	ProfileIdentity: 'profile_identity',
	ProfileGenderBirth: 'profile_gender_birth_date',
	ProfileComplete: 'profile_complete',

	//removed
	ProfileUploadId: 'profile_upload_id',
	ProfileUploadDrivers: 'profile_upload_drivers',
	ProfileUploadPassport: 'profile_upload_passport',
	ProfileIdentityUpload: 'profile_identity_upload',
	ProfileAdditionalInfo: 'profile_additional_info',
};
