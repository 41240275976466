import React from 'react';

const Youtube = ({ color }) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		{color ? (
			<>
				<path
					d="M21.5835 7.18627C21.353 6.32526 20.6763 5.64864 19.8153 5.41816C18.2545 5 12 5 12 5C12 5 5.74547 5 4.18637 5.41816C3.32532 5.64864 2.64867 6.32526 2.41818 7.18627C2 8.7453 2 12 2 12C2 12 2 15.2547 2.41818 16.8137C2.64867 17.6747 3.32532 18.3514 4.18637 18.5818C5.74547 19 12 19 12 19C12 19 18.2545 19 19.8136 18.5818C20.6747 18.3514 21.3513 17.6747 21.5818 16.8137C22 15.2547 22 12 22 12C22 12 22 8.7453 21.5835 7.18627Z"
					fill={color}
				/>
				<path d="M10 15L15 12L10 9V15Z" fill="#041F47" />
			</>
		) : (
			<>
				<path
					d="M21.5835 7.18627C21.353 6.32526 20.6763 5.64864 19.8153 5.41816C18.2545 5 12 5 12 5C12 5 5.74547 5 4.18637 5.41816C3.32532 5.64864 2.64867 6.32526 2.41818 7.18627C2 8.7453 2 12 2 12C2 12 2 15.2547 2.41818 16.8137C2.64867 17.6747 3.32532 18.3514 4.18637 18.5818C5.74547 19 12 19 12 19C12 19 18.2545 19 19.8136 18.5818C20.6747 18.3514 21.3513 17.6747 21.5818 16.8137C22 15.2547 22 12 22 12C22 12 22 8.7453 21.5835 7.18627Z"
					fill="#041F47"
				/>
				<path d="M10 15L15 12L10 9V15Z" fill="white" />
			</>
		)}
	</svg>
);

export default Youtube;
