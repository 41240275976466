import theme from 'config/theme';
import styled from 'styled-components';

const { colors } = theme;

export const SocialLink = styled.a.attrs({
	target: '_blank',
	rel: 'noopener noreferrer',
})`
	text-decoration: none;
	color: ${({ color }) => color || colors.darkBlue};
	transition: color 200ms ease-in-out;
	margin: ${({ margin }) => margin || 'auto'};

	@media (max-width: 650px) {
		margin-right: 10px;
		margin-left: 0px;
	}

	&:hover {
		color: ${({ color }) => color || colors.blue};
	}
`;
export const SocialContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;

	@media (max-width: 650px) {
		align-items: flex-start;
	}
`;
