import client from '../utils/fetch-client';

const path = '/payments/';

export default {
	getCheckoutId(ids = []) {
		return client.post(`${path}checkout-id`, {
			ids: ids,
		});
	},

	getPropertyPaymentItems(id = '') {
		return client.get(`${path}items?property=${id}`);
	},

	getSnapscanCheckout(id = '') {
		return client.post(`${path}snapscan`, {
			body: { property: id },
		});
	},

	getSnapscanOrderStatus(id = '') {
		return client.get(`${path}snapscan?order=${id}`);
	},
};
