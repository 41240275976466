import { createGlobalStyle } from 'styled-components';
import reset from 'styled-nanoreset';

export const GlobalStyle = createGlobalStyle`
  ${reset}

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
  
  .errorList {
    font-size: 1em;
  }
`;
