import { SearchItem, Viewport } from 'models/search';

const getShortTitle = (item: SearchItem): string => item.label.split(',')[0];

const getBoundingViewport = (items: SearchItem[]): Viewport | undefined => {
	if (!items.filter(item => !!item.viewport).length) {
		return;
	}
	return items
		.filter(item => item.viewport)
		.reduce((boundingViewport: any, item: any) => {
			return {
				southwest: {
					lat: Math.min(item.viewport.southwest.lat, boundingViewport.southwest.lat),
					lng: Math.min(item.viewport.southwest.lng, boundingViewport.southwest.lng),
				},
				northeast: {
					lat: Math.max(item.viewport.northeast.lat, boundingViewport.northeast.lat),
					lng: Math.max(item.viewport.northeast.lng, boundingViewport.northeast.lng),
				},
			};
		}, items[0].viewport);
};

export const searchService = {
	getShortTitle,
	getBoundingViewport,
};

export default searchService;
