// Please use this file for any general purpose utility functions which may be reused throughout the application

export const distanceBetweenCoordinatePairs = ({
	longitude1,
	latitude1,
	longitude2,
	latitude2,
}) => {
	// https://en.wikipedia.org/wiki/Haversine_formula
	// Returns distance in kilometers between two pairs of coordinates
	// Any confusion regarding a & c variables: https://en.wikipedia.org/wiki/Haversine_formula#/media/File:Law-of-haversines.svg
	let Radius = 6371; // Radius of the earth in km
	let radiansDeltaLatitude = degToRadians(latitude2 - latitude1);
	let radiansDeltaLongitude = degToRadians(longitude2 - longitude1);
	let a =
		Math.sin(radiansDeltaLatitude / 2) * Math.sin(radiansDeltaLatitude / 2) +
		Math.cos(degToRadians(latitude1)) *
			Math.cos(degToRadians(latitude2)) *
			Math.sin(radiansDeltaLongitude / 2) *
			Math.sin(radiansDeltaLongitude / 2);
	let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
	let distanceKm = Radius * c;
	return distanceKm;
};

const degToRadians = deg => {
	return deg * (Math.PI / 180);
};

export const formatSlug = s => {
	return s
		.toLowerCase()
		.replace(/\//g, '-')
		.split(' ')
		.join('-');
};
