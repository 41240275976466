import api from 'api';
import authContainer from 'containers/auth';
import userContainer from 'containers/user';
import NProgress from 'nprogress';
import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { analyticsService, EventName, LoginMethod } from 'services/analyticsService';

const APP_ID = 175172636506899;

class FBLogin extends React.Component {
	responseFacebook = async response => {
		const { accessToken } = response;
		const { cb } = this.props;
		NProgress.start();

		try {
			const authResponse = await api.auth.facebook({
				token: accessToken,
			});

			if (authResponse.status === 200) {
				await authContainer.set({
					token: authResponse.data.token,
				});

				const userProfileV2Response = await api.userV2.getProfile();
				await userContainer.set(userProfileV2Response.data);
				analyticsService.trackEvent(EventName.Login, { auth_method: LoginMethod.Facebook });
				analyticsService.trackEvent(EventName.SetUser, {
					digs_user_id: userProfileV2Response.data.uuid,
				});

				NProgress.done();
				cb();
			}
		} catch (error) {
			console.log(error.message);
			console.error(error);
			NProgress.done();
		}
	};

	render() {
		const { button } = this.props;
		return (
			<FacebookLogin
				appId={`${APP_ID}`}
				autoLoad={false}
				fields="name,picture,email"
				callback={this.responseFacebook}
				render={renderProps => button(renderProps)}
			/>
		);
	}
}

export default FBLogin;
