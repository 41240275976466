export default {
	mapBoxTheme: 'mapbox://styles/brendandigsconnect/ckbs1yhxh6tni1ip9zk4ujyeb',
	colors: {
		blue: '#034082',
		brightBlue: '#266AF6',
		darkTurquoise: '#017B8A',
		darkTurquoiseFaded: '#F2F9F9',
		darkBlue: '#041F47',
		grey60: '#687991', //darkBlue @ 60% opacity
		grey40: '#9ba5b5', //darkBlue @ 40% opacity
		grey20: '#CDD2DA', //darkBlue @ 20% opacity
		grey10: '#E6E9Ed', //darkBlue @ 10% opacity
		grey05: '#F1F2F4', //darkBlue @ 5% opacity
		//grey02: '#f4f4f5', //darkBlue @ 2% opacity
		grey01: '#f6f6f7', //darkBlue @ 1% opacity
		pink: '#FF0052',
		transparent: 'transparent',
		black: '#222',
		lightBlue: '#94a0af',
		linkBlue: '#047D8B',
		white: '#fff',
		translucentWhite: 'rgba(255, 255, 255, 0)',
		translucentDarkTurquoiseFaded: 'rgba(242, 249, 249, 0)',
		lightGray: '#f6f6f6',
		gray: '#ebedf1',
		shadedGray: '#d9dce4',
		darkGray: '#a9b2c0',
		red: '#F44336',
		facebookBlue: '#3A5998',
		googleRed: '#DD4B39',
		orange: '#F95D27',
		yellow: '#FFC563',
		divider: 'rgb(235, 237, 240)',
		background: '#fafafa',
		infoGrey: '#575863',
		green: '#17BF5F',
		placeholder: '#999',
		iconBackground: '#3C97A1',
		bronze: '#CD7F32',
		silver: '#808080',
		gold: '#D4AF37',
		platinum: '#CFCBC3',
		scrollbar: 'rgba(16, 40, 79, 0.3)',
	},
	fonts: {
		Gilroy:
			'Gilroy, San Francisco, SF Pro Text, -apple-system, system-ui, BlinkMacSystemFont, Roboto, Helvetica Neue, Segoe UI, Arial, sans-serif',
	},
	//TODO: Cleanup fontsizes to use rem
	fontSizes: {
		xxsmall: '.56em',
		xsmall: '0.75em',
		small: '0.875em',
		normal: '0.92em',
		medium: '1em',
		large: '1.2em',
		xlarge: '1.5em',
		xxlarge: '2em',
		xxxlarge: '2.4em',
		header: '3.3em',
		hero: '3.75em',
	},
	fontWeights: {
		thin: 300,
		normal: 400,
		semi: 500,
		bold: 700,
		heavy: 800,
		black: 900,
	},
	lineHeight: {
		none: '1',
		tight: '1.25',
		snug: '1.375',
		normal: '1.5',
		relaxed: '1.7',
		loose: '2',
	},
	radii: {
		none: '0',
		default: '0.9em',
		half: '50%',
		full: '9999px',
	},
	boxShadow: {
		small: '0 0 16px rgba(0, 0, 0, .1)',
		default: '0 20px 40px rgba(0, 0, 0, .1)',
		large: '0 40px 100px 0 rgba(0, 0, 0, 0.1)',
		none: 'none',
	},
	headerHeight: '85px',
	mobileHeaderHeight: '72px',
	filterHeaderHeight: '60px',
	topBar: {
		height: '60px',
	},
	zIndices: {
		mapOverlay: 140000,
		footer: 150000,
		header: 155000,
		modal: 160000,
	},
	letterSpacings: {
		tighter: '-0.05em',
		tight: '-0.025em',
		normal: '0',
		wide: '0.025em',
		wider: '0.05em',
		widest: '0.1em',
	},
	inputs: {
		height: '56px',
		borderRadius: '16px',
	},
};
