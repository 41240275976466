enum ModalEnum {
	Unknown = '',
	Login = 'login',
	Signup = 'signup',
	ProductUpdate = 'productUpdate',
	MarketingConsent = 'marketingConsent',
	Generic = 'generic',
}

export default ModalEnum;
