import React from 'react';

import styled from 'styled-components';
import ReactSelect from 'react-select';
import ReactCountryFlag from './ReactCountryFlag';

import theme from 'config/theme';
import { countryCodes } from './countryCodes';

const { colors, inputs } = theme;

const options = countryCodes.map(code => ({
	...code,
	label: code.country,
	value: `+${code.dial_code}`,
}));

const OptionContent = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

const Text = styled.div<{ marginLeft?: boolean; marginRight?: boolean }>`
	font-size: 16px;
	font-weight: 600;
	${({ marginLeft }) => marginLeft && 'margin-left: 16px;'}
	${({ marginRight }) => marginRight && 'margin-right: 16px;'}
`;

const styles = {
	control: (provided: any) => ({
		...provided,
		backgroundColor: colors.white,
		color: colors.darkBlue,
		border: `2px solid ${colors.darkBlue}`,
		padding: '0 12px',
		borderRadius: inputs.borderRadius,
		boxShadow: 'none',
		height: inputs.height,
		'&:hover': {
			borderColor: colors.darkBlue,
		},
	}),
	container: (provided: any) => ({
		...provided,
		width: '100%',
		marginBottom: '16px',
	}),
	indicatorSeparator: () => ({}),
	dropdownIndicator: (provided: any) => ({
		...provided,
		color: colors.darkBlue,
		'&:hover': {
			color: colors.darkBlue,
		},
	}),
	menu: (provided: any) => ({
		...provided,
		backgroundColor: colors.white,
		borderRadius: '16px',
		padding: '10px',
		zIndex: '1000',
	}),
	option: (provided: any, state: any) => ({
		...provided,
		color: state.isFocused && colors.pink,
		padding: '10px',
		boxShadow: 'none',
		background: 'none',
	}),
	singleValue: (provided: any) => ({
		...provided,
		color: colors.darkBlue,
	}),
};

const formatOptionLabelFactory = (showDialCode: boolean) => ({ value, label, code }: any) => (
	<OptionContent>
		{!!showDialCode && <Text marginRight>{value}</Text>}
		<ReactCountryFlag countryCode={code} svg />
		<Text marginLeft>{label}</Text>
	</OptionContent>
);

export interface CountryCode {
	countryCode: string;
	dialCode: string;
}

interface CountryCodeSelectProps {
	value: string;
	showDialCode?: boolean;
	onChange(value: CountryCode): void;
}

export const CountryCodeSelect = ({
	value = 'ZA',
	showDialCode = true,
	onChange,
}: CountryCodeSelectProps) => (
	<ReactSelect
		styles={styles}
		value={options.find(o => o.value === value || o.code === value) || options[0]}
		formatOptionLabel={formatOptionLabelFactory(showDialCode)}
		options={options}
		onChange={(option: any) =>
			onChange({
				countryCode: option.code,
				dialCode: option.value,
			})
		}
	/>
);
