const staticPageMeta: { [index: string]: { title: string; desc: string } } = {
	about: {
		title: 'About us',
		desc: 'Get to know our Digsmates.',
	},
	faq: {
		title: 'Frequently Asked Questions',
		desc: "We've got all the answers you dig.",
	},
	partners: {
		title: 'Our Partners',
		desc: 'Our Educational Institutions partners.',
	},
	terms_and_conditions: {
		title: 'Terms and Conditions',
		desc: 'Read before you click agree!',
	},
	privacy: {
		title: 'Privacy Policy',
		desc: 'DigsConnect privacy policy',
	},
	safety: {
		title: 'Safety',
		desc: 'We dig keeping you safe on our platform.',
	},
	how_it_works: {
		title: 'How it Works',
		desc: 'Check out how we connect you with landlords.',
	},
	landlord_dashboard: {
		title: 'Landlord Dashboard',
		desc: 'One stop shop for all your landlord related statistics.',
	},
	landlord_resources: {
		title: 'Landlord Resources',
		desc: 'For awesome prospect landlords.',
	},
	landing: {
		title: 'Student Accommodation • DigsConnect.com',
		desc: `Book student housing & young adult housing with DigsConnect.com. We help students & young adults rent their perfect apartment, house or room around South Africa. Search by city, area and university. Free dedicated support with your booking.`,
	},
	contact_us: {
		title: 'Contact Us',
		desc: `We'd dig to hear from you.`,
	},
	pricing: {
		title: 'Pricing',
		desc: 'DigsConnect pricing for landlords.',
	},
	find_a_digs: {
		title: 'Student Accommodation • DigsConnect.com',
		desc: `Book student housing & young adult housing with
    DigsConnect.com. We help students & young adults rent their perfect
    apartment, house or room around South Africa. Search by city, area and
    university. Free dedicated support with your booking.`,
	},
	enquiry_flow: {
		title: 'Enquiry Form',
		desc:
			"Africa's largest Student Accommodation platform. DigsConnect.com connects students with accommodation providers and with other students looking for accommodation",
	},
	create_listing: {
		title: 'Create listing',
		desc: 'Create a listing on DigsConnect',
	},
	inbox: {
		title: 'Inbox | DigsConnect',
		desc: 'Manage booking conversations between tenants and landlords',
	},
	default: {
		title: 'DigsConnect',
		desc: `Book student housing & young adult housing with DigsConnect.com. We help students & young adults rent their perfect apartment, house or room around South Africa. Search by city, area and university. Free dedicated support with your booking.`,
	},
};

function getStatic(page: string) {
	if (!staticPageMeta[page]) return staticPageMeta['default'];
	return staticPageMeta[page];
}

function getSearch(location: any, uni = false) {
	if (uni) {
		const uni_title: string = uni_title_abbr(location);
		return {
			title: `Student Accommodation near ${uni_title} | DigsConnect.com`,
			desc: `Find student accommodation near ${location}. Choose from several options within walking distance of campus. Book your room today!`,
		};
	} else {
		if (!location) {
			return {
				title: 'Accommodation in South Africa | Digsconnect.com',
				desc:
					'Find student & young adult accommodation in South Africa with DigsConnect.com. Find your ideal home. Book your room today!',
			};
		}
		return {
			title: `Accommodation ${location} | DigsConnect.com`,
			desc: `Find student & young adult accommodation in ${location} with DigsConnect.com. Find your ideal home. Book your room today!`,
		};
	}
}

function uni_title_abbr(uni: string): string {
	if (!uni) return 'South Africa';
	const exceptions = [
		{ reg: /varsity college/i, value: 'Varsity College' },
		{ reg: /red . yellow/i, value: 'Red & Yellow' },
		{ reg: /.*witwaterstrand.*/i, value: 'Wits' },
		{ reg: /.*rhodes.*/i, value: 'Rhodes' },
		{ reg: /.*university.*south.*africa/i, value: 'UNISA' },
		{ reg: /.*university.*free.*state/i, value: 'UFS / Kovsies' },
	];

	for (let e of exceptions) {
		if (e.reg.test(uni)) return e.value;
	}

	const disallowed = [/of/gi, /the/gi];

	const words = uni.split(' ');
	let result = '';
	if (words.length === 1) return uni;

	outer: for (let word of words) {
		for (let r of disallowed) {
			if (r.test(word)) continue outer;
		}
		result += (word[0] || '').toUpperCase();
	}

	return result;
}

export const PageMeta = {
	getStatic,
	getSearch,
};
