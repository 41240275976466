import Swal from 'sweetalert2';

// TODO: Consider moving this into a notifications/alerts service instead of a lib entry

export const notifySuccess = (message: string): void => {
	Swal.fire({
		title: message,
		type: 'success',
		showConfirmButton: false,
		toast: true,
		timer: 4000,
		position: 'top',
		customClass: {
			container: 'custom-container-toast-class',
		},
	});
};

export const notifyError = (message: string, timer: number = 3000): void => {
	Swal.fire({
		title: message,
		type: 'warning',
		showConfirmButton: false,
		toast: true,
		timer: timer,
		position: 'top',
		customClass: {
			container: 'custom-container-toast-class',
		},
	});
};

export const notifyFailure = (message: string): void => {
	Swal.fire({
		title: message,
		type: 'error',
		showConfirmButton: false,
		toast: true,
		timer: 4000,
		position: 'top',
		customClass: {
			container: 'custom-container-toast-class',
		},
	});
};
