import * as Sentry from '@sentry/browser';
import { sentry_errors } from 'constants/sentry_errors';

const initializeSentry = env => {
	Sentry.init({
		dsn: 'https://19475731f8f54bb09be00f4c5e6c4094@sentry.io/1550655',
		beforeSend(event, hint) {
			if (hint.originalException === 'Timeout') return null;
			return event;
		},
		ignoreErrors: sentry_errors,
		environment: env,
	});
};

export default initializeSentry;
