import React, { Component } from 'react';

import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { Formik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Subscribe } from 'unstated';

import api from 'api';
import Input from '../../shared-components/Input';
import FormElementWrapper from '../../shared-components/FormElementWrapper';
import FormElementError from '../../shared-components/FormElementError';
import ResendButton from '../../shared-components/ResendButton';
import BottomCTAWrapper from '../BottomCTAWrapper';
import authContainer from 'containers/auth';
import theme from 'config/theme';
import { Button } from 'lib/Button';
import { Title } from 'lib/Title';

const { colors } = theme;

class CellphoneStepTwoSection extends Component {
	static propTypes = {
		updatedPhoneNumber: PropTypes,
		onUpdateSuccess: PropTypes.func,
	};

	static defaultProps = {
		onUpdateSuccess: () => {},
	};

	state = { isResendingOtp: false };

	resendSubmit = async () => {
		const errorMessage = 'Something went wrong, the OTP could not be sent';
		const successMessage = 'An OTP has been sent to your phone';
		this.setState({ isResendingOtp: true });

		try {
			const { resendVerificationCode } = api.user;

			const response = await resendVerificationCode();
			if (response.status >= 200 && response.status < 300) {
				this.fireSubmissionSuccess(successMessage);
			} else {
				this.fireSubmissionError(errorMessage);
			}
		} catch (err) {
			console.log(err);
			this.fireSubmissionError(errorMessage);
		} finally {
			this.setState({ isResendingOtp: false });
		}
	};

	fireSubmissionSuccess = message => {
		Swal.fire({
			title: message,
			type: 'success',
			showConfirmButton: false,
			toast: true,
			timer: 3000,
			position: 'top',
			customClass: {
				container: 'custom-container-toast-class',
			},
		});
	};

	fireSubmissionError = message => {
		Swal.fire({
			title: message,
			type: 'warning',
			showConfirmButton: false,
			toast: true,
			timer: 4000,
			position: 'top',
			customClass: {
				container: 'custom-container-toast-class',
			},
		});
	};

	onSubmitOtp = async (values, { setSubmitting }) => {
		const { onUpdateSuccess } = this.props;
		const errorMessage = 'Something went wrong, please contact support';

		try {
			const response = await api.user.verifyNumber(`${values.otp}`);
			if (response.status >= 200 && response.status < 300) {
				onUpdateSuccess();
			} else {
				this.fireSubmissionError(response.data.error.message || errorMessage);
			}
		} catch (err) {
			this.fireSubmissionError(errorMessage);
		} finally {
			setSubmitting(false);
		}
	};

	render() {
		const { isResendingOtp } = this.state;
		const { updatedPhoneNumber } = this.props;

		const validationSchema = Yup.object().shape({
			// Make this optional temporarily
			otp: Yup.string().required('Required'),
		});
		return (
			<StyledCellphoneStepTwoSection>
				<Formik
					onSubmit={this.onSubmitOtp}
					initialValues={{ otp: '' }}
					validationSchema={validationSchema}
				>
					{({ values, isSubmitting, handleChange, handleSubmit, errors, touched }) => {
						return (
							<StyledForm onSubmit={handleSubmit}>
								<div>
									<Title>Cellphone number</Title>
									<SubTitle>We’ve sent you an OTP, enter it below</SubTitle>
									<div className="columns">
										<div className="column is-two-thirds">
											<FormElementWrapper>
												<Input disabled value={updatedPhoneNumber} />
											</FormElementWrapper>
											<FormElementWrapper>
												<Input
													placeholder="OTP"
													type="number"
													name="otp"
													value={values.otp}
													onChange={handleChange}
												/>
												{errors.otp && touched.otp && (
													<FormElementError>{errors.otp}</FormElementError>
												)}
											</FormElementWrapper>
											<SubTitle>
												Not receiving an OTP?{' '}
												<ResendLink onClick={this.resendSubmit}>Resend</ResendLink>
											</SubTitle>
										</div>
									</div>
								</div>
								<BottomCTAWrapper>
									<div className="columns">
										<div className="column is-two-thirds">
											<div className="columns is-mobile">
												<div className="column is-one-third">
													<ButtonWrapper>
														<ResendButton
															type="button"
															onClick={this.props.onBackClick}
															disabled={isSubmitting || isResendingOtp}
															loading={isResendingOtp}
														>
															Back
														</ResendButton>
													</ButtonWrapper>
												</div>
												<div className="column is-two-thirds">
													<Button
														isOutline
														type="submit"
														disabled={isSubmitting || isResendingOtp}
														isLoading={isSubmitting}
													>
														Continue
													</Button>
												</div>
											</div>
										</div>
									</div>
								</BottomCTAWrapper>
							</StyledForm>
						);
					}}
				</Formik>
			</StyledCellphoneStepTwoSection>
		);
	}
}

export default function CellphoneStepTwoSectionWithAuth(props) {
	return (
		<Subscribe to={[authContainer]}>
			{auth => <CellphoneStepTwoSection {...props} auth={auth} />}
		</Subscribe>
	);
}

/**styled components */
const StyledCellphoneStepTwoSection = styled.div`
	height: 100%;
`;

const ButtonWrapper = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

const ResendLink = styled.span`
	color: ${colors.darkTurquoise};
	text-decoration: underline;
	cursor: pointer;
`;

const StyledForm = styled.form`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
`;

const SubTitle = styled.h3`
	color: #9da7b7;
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 36px;
`;
