import styled from 'styled-components';
import theme from 'config/theme';
import { BREAKPOINT_MOBILE_PX, BREAKPOINT_TABLET_PX } from 'constants/breakPoints';

const { colors } = theme;

interface TitleProps {
	noMargin?: boolean;
	standardMargin?: boolean;
	smallMargin?: boolean;
	smallMobileText?: boolean;
	mediumMobileText?: boolean;
	success?: boolean;
	mobiCentered?: boolean;
	bold?: boolean;
	largeFont?: boolean;
}

export const Title = styled.h2<TitleProps>`
  ${({ success }) => (success ? 'font-size: 32px;' : 'font-size: 24px;')}
  ${({ bold }) => (bold ? 'font-weight: 700;' : 'font-weight: 600;')}
  ${({ largeFont }) => largeFont && 'font-size: 32px;'}
  
  line-height: 1.2;
  margin-bottom: 32px;
  ${({ success }) => success && `color: ${colors.pink}`}
  ${({ standardMargin }) => standardMargin && 'margin-bottom: 16px;'}
  ${({ smallMargin }) => smallMargin && 'margin-bottom: 8px;'}
  ${({ noMargin }) => noMargin && 'margin-bottom: 0;'}
  ${({ mediumMobileText }) =>
		mediumMobileText &&
		`
    @media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
      font-size: 20px;
    }
  `}
  ${({ smallMobileText }) =>
		smallMobileText &&
		`
    @media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
      font-size: 16px;
    }
  `}

  ${({ mobiCentered }) =>
		mobiCentered &&
		`
  @media (max-width: ${BREAKPOINT_TABLET_PX}px) {
    text-align: center;
  }
`}
`;
