import React, { useState, useEffect, useRef } from 'react';

import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import api from 'api';
import { ReactComponent as SearchSvg } from 'assets/images/icons/search-outline.svg';
import theme from 'config/theme';
import {
	LOCATION_SEARCH_DEBOUNCE_MILLISECONDS,
	LOCATION_SEARCH_RESULTS_MAX_DISPLAY_COUNT,
} from 'constants/index';
import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';
import SearchResult from 'components/search-drawer/SearchResult';
import { SearchItem, SearchItemType } from 'models/search';
import ChipCollection from 'lib/ChipCollection';
import searchService from 'services/searchService';
import searchContainer from 'containers/search';
import { formatSlug } from 'utils';
import { fadeInAnimation } from 'animations';
import WarningTooltip from 'lib/WarningTooltip';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';

// TODO: Install qs types and import as per other 3rd party imports
const qs = require('qs');
const { colors, fonts } = theme;

const LandingSearchBar = withRouter(
	({
		history,
		searchIconOnly,
		background,
		allowEmptySearch,
		syncSearchState,
		syncFromQueryParams,
		clearOnSearch,
		isButtonExternal,
	}: any) => {
		const [searchList, setSearchList] = useState([]);
		const [selectedItems, setSelectedItems] = useState<SearchItem[]>(
			syncSearchState ? searchContainer.state.selectedItems : [],
		);
		const [focusedResult, setFocusedResult] = useState<SearchItem>();
		const listRef = useRef(null);
		const inputRef = useRef(null);
		const [term, setTerm] = useState<string | null>(null);
		const [timeoutId, setTimeoutId] = useState<number>();
		const [showWarningTooptip, setShowWarningTooltip] = useState<boolean>(false);

		useEffect(() => {
			if (!syncFromQueryParams) {
				return;
			}
			const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true }) || {};
			if (queryParams.locations) {
				if (typeof queryParams.locations === 'string') {
					api.searchV2.getLocation(queryParams.locations).then(({ data }) => {
						searchContainer.setSelectedItem(data);
					});
				} else {
					Promise.all(queryParams.locations.map((id: number) => api.searchV2.getLocation(id))).then(
						(responses: any) => {
							searchContainer.setSelectedItems(responses.map((response: any) => response.data));
						},
					);
				}
			}
			if (queryParams.university) {
				api.searchV2.getUniversity(queryParams.university).then(({ data }) => {
					searchContainer.setSelectedItem(data);
				});
			}
			// TODO: Fix and test
			// eslint-disable-next-line
		}, [syncFromQueryParams]);

		useEffect(() => {
			if (syncSearchState) {
				setSelectedItems(searchContainer.state.selectedItems);
			}

			const onSearch = () => {
				if (syncSearchState) {
					setSelectedItems(searchContainer.state.selectedItems);
				}
			};

			searchContainer.subscribe(onSearch);
			return () => searchContainer.unsubscribe(onSearch);
		}, [syncSearchState]);

		useEffect(() => {
			if (syncSearchState) {
				searchContainer.setSelectedItems(selectedItems);
			}
		}, [selectedItems, syncSearchState]);

		useEffect(() => {
			if (timeoutId) {
				clearTimeout(timeoutId);
			}

			setTimeoutId(
				setTimeout(() => {
					if (term === null) {
						return;
					}
					api.searchV2.getSearchResults(term || '').then(({ data }) => {
						setSearchList(data);
						setFocusedResult(data?.length > 0 ? data[0] : undefined);
					});
				}, LOCATION_SEARCH_DEBOUNCE_MILLISECONDS) as any,
			);
			// eslint-disable-next-line
		}, [term]);

		useEffect(() => {
			if (!featureFlagContainer.isEnabled(FeatureFlag.HomepageEnterToSearch)) {
				return;
			}

			if (!searchList?.length || !listRef?.current) {
				return;
			}

			const keyCodes = {
				enter: 13,
				down: 40,
				up: 38,
			};

			const handleKeyPress = (event: any) => {
				if (event.keyCode === keyCodes.enter && focusedResult) {
					setTimeout(() => {
						handleSearchResultSelect(focusedResult);
					}, 0);
				}
				if (event.keyCode === keyCodes.down) {
					setFocusedResult(() => {
						const index = searchList.findIndex(
							(item: SearchItem) => focusedResult && item?.id === focusedResult?.id,
						);
						const newIndex = index + 1 === searchList.length ? 0 : index + 1;
						return searchList[newIndex];
					});
				}
				if (event.keyCode === keyCodes.up) {
					setFocusedResult(() => {
						const index = searchList.findIndex(
							(item: SearchItem) => focusedResult && item?.id === focusedResult?.id,
						);
						const newIndex = index === 0 ? searchList.length - 1 : index - 1;
						return searchList[newIndex];
					});
				}
			};

			const handlePageClick = (e: any) => {
				if (listRef && listRef.current) {
					if (!(listRef.current as any).contains(e.target)) {
						setSearchList([]);
						return;
					}
				}
			};

			window.addEventListener('keydown', handleKeyPress);
			window.addEventListener('click', handlePageClick);

			// Remove event listener on component unmount
			return () => {
				window.removeEventListener('keydown', handleKeyPress);
				window.addEventListener('click', handlePageClick);
			};
		}, [searchList, focusedResult]);

		const handleSearchInput = (value: string) => {
			setTerm(value);
		};

		const handleSearchResultSelect = (result: SearchItem) => {
			if (selectedItems.some(item => item.type === result.type && item.id === result.id)) {
				return;
			}

			setShowWarningTooltip(false);
			setTerm(null);
			setSearchList([]);

			// Prevent multiple item selection if university is selected
			if (
				result.type === SearchItemType.University ||
				selectedItems.some(item => item.type === SearchItemType.University)
			) {
				setSelectedItems([result]);
				if (featureFlagContainer.isEnabled(FeatureFlag.HomepageEnterToSearch)) {
					inputRef.current && (inputRef.current as any).focus();
				}
				return;
			}

			setSelectedItems([...selectedItems, result]);
			if (featureFlagContainer.isEnabled(FeatureFlag.HomepageEnterToSearch)) {
				inputRef.current && (inputRef.current as any).focus();
			}
		};

		const handleChipRemove = (chipValue: any) => {
			setSelectedItems(selectedItems.filter(item => item.id !== chipValue));
		};

		const handleSearchClick = () => {
			if (!selectedItems.length) {
				if (allowEmptySearch) {
					history.push('/digs-search');
					return;
				}
				setShowWarningTooltip(true);
				return;
			}

			searchContainer.setSelectedItems(selectedItems);
			localStorage.setItem('search_label', selectedItems[0] ? selectedItems[0].label : '');
			localStorage.setItem(
				'search_label_slug',
				selectedItems[0] ? formatSlug(selectedItems[0].label) : '',
			);

			const query = selectedItems
				.map(
					item =>
						`${item.type === SearchItemType.Location ? 'locations' : 'university'}=${item.id}`,
				)
				.join('&');

			if (clearOnSearch) {
				setSelectedItems([]);
			}

			history.push(`/digs-search?${query}`);
		};

		const chips = selectedItems.map(item => ({
			label: searchService.getShortTitle(item),
			value: item.id,
		}));

		return (
			<>
				<form
					style={{ display: 'block' }}
					onSubmit={e => {
						e.preventDefault();
						e.stopPropagation();
						if (
							featureFlagContainer.isEnabled(FeatureFlag.HomepageEnterToSearch) &&
							searchList?.length
						) {
							return;
						}
						handleSearchClick();
					}}
				>
					<SearchContainer>
						<WhiteBackground />
						<InputContainer style={{ background: background || undefined }} thin={searchIconOnly}>
							<ChipsContainer>
								<ChipCollection chips={chips} onRemoveChip={handleChipRemove} />
								<StandardInput
									type="text"
									placeholder={
										chips.length ? '...add more' : 'Search by city, suburb or university...'
									}
									ref={inputRef}
									style={chips.length ? undefined : { width: '100%' }}
									value={term || ''}
									onChange={event => handleSearchInput(event.target.value)}
								/>
							</ChipsContainer>
							{searchIconOnly && (
								<IconSearchButton type="submit">
									<SearchIcon />
								</IconSearchButton>
							)}
						</InputContainer>
						{!searchIconOnly && !isButtonExternal && (
							<SearchButton type="button" onClick={handleSearchClick}>
								<SearchButtonText>Search</SearchButtonText>
								<SearchIcon />
							</SearchButton>
						)}
						{showWarningTooptip && (
							<WarningTooltipContainer isTop={isButtonExternal}>
								<WarningTooltip
									text={`Please enter a city, suburb or university you would like to view listings for before clicking 'Search'`}
									onClose={() => setShowWarningTooltip(false)}
									pointerBottom={isButtonExternal}
								/>
							</WarningTooltipContainer>
						)}
					</SearchContainer>
					{searchList.length > 0 && (
						<ResultsPopupContainer>
							<ResultContainer ref={listRef}>
								{searchList
									.slice(0, LOCATION_SEARCH_RESULTS_MAX_DISPLAY_COUNT)
									.map((result: any, i) => (
										<SearchResult
											key={i}
											onClick={() => handleSearchResultSelect(result)}
											university={result.type !== 'location'}
											title={result.label}
											isSelected={result?.id === focusedResult?.id}
										/>
									))}
							</ResultContainer>
						</ResultsPopupContainer>
					)}
					{!searchIconOnly && isButtonExternal && (
						<SearchButtonContainer>
							<SearchButton type="button" allBordersRounded onClick={handleSearchClick}>
								<SearchButtonText>Search</SearchButtonText>
								<SearchIcon />
							</SearchButton>
						</SearchButtonContainer>
					)}
				</form>
			</>
		);
	},
);

export default LandingSearchBar;

const ResultsPopupContainer = styled.div`
	position: relative;
	width: 100%;
	z-index: 1;
`;

const ResultContainer = styled.ul`
	border-radius: 8px;
	border: ${colors.grey10} 1px solid;
	margin-top: 16px;
	padding: 12px 0;
	overflow-y: auto;
	max-height: 266px;
	position: absolute;
	top: 0;
	width: 100%;
	background: ${colors.white};
	color: ${colors.darkBlue};
`;

const InputContainer = styled.div<{ thin?: boolean }>`
	background-color: ${colors.white};
	border-radius: 16px;
	padding: 4px;
	padding-left: 16px;
	height: fit-content;
	min-height: ${({ thin }) => (thin ? '50px' : '56px')};
	width: 100%;
	display: flex;
	justify-content: space-between;
	animation: ${fadeInAnimation} 200ms;
`;

const StandardInput = styled.input`
	background-color: transparent;
	color: ${colors.darkBlue};
	outline: none;
	flex: 1;
	margin-top: -4px;
	min-height: 32px;

	::placeholder {
		text-overflow: ellipsis;
	}

	@media (min-width: ${BREAKPOINT_TABLET_PX}px) {
		width: auto;
		margin-bottom: 4px;
	}
`;

const ChipsContainer = styled.div`
	margin-top: 4px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	flex: 1;
`;

const SearchButton = styled.button<{ allBordersRounded?: boolean }>`
	font-size: 14px;
	font-weight: 600;
	font-family: ${fonts.Gilroy};
	height: 56px;
	background: ${colors.pink};
	color: ${colors.white};
	border: none;
	border-radius: 16px;
	padding: 0 20px;
	display: flex;
	align-items: center;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	${({ allBordersRounded }) => (allBordersRounded ? 'border-radius: 16px' : '')}
`;

const IconSearchButton = styled.button`
	height: 40px;
	background: ${colors.pink};
	border: none;
	display: flex;
	align-items: center;
	width: 40px;
	padding: 0;
	justify-content: center;
	border-radius: 40px;
	margin-left: 8px;
`;

const SearchButtonText = styled.span`
	margin-right: 4px;
`;

const SearchIcon = styled(SearchSvg)`
	fill: ${colors.white};
	height: 20px;
	width: 20px;
`;

const SearchContainer = styled.div`
	display: flex;
	min-height: 56px;
	border-radius: 16px;
	position: relative;
	z-index: 1;
`;

const WarningTooltipContainer = styled.div<{ isTop?: boolean }>`
	position: absolute;
	width: 100%;
	${({ isTop }) => (isTop ? `bottom: 72px;` : 'top: 72px;')}

  @media (min-width: ${BREAKPOINT_TABLET_PX}px) {
		max-width: 280px;
	}
`;

const SearchButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 16px;
`;

const WhiteBackground = styled.div`
	position: absolute;
	background: ${colors.white};
	height: 56px;
	top: 0;
	width: 100%;
	z-index: -1;
	border-radius: 16px;
`;
