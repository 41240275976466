import SocialLinks from 'components/common/social-links';
import theme from 'config/theme';
import React, { Component } from 'react';
import styled from 'styled-components';
import Logo from 'components/icons/logo';
import BurgerMenu from '../burger-menu';
import {
	CopyRightText,
	LogoContainer,
	MenuButton,
	MenuLink,
	OverlayContainer,
	Section,
	SocialMediaContainer,
} from './styles';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';

const { colors } = theme;

const Inner = styled.div`
	height: fit-content;
	flex: 1;
	overflow-y: scroll;
`;

class MobileHeader extends Component {
	state = {
		isVisible: false,
		isSearching: false,
	};

	scrollableContainerRef = React.createRef();

	static defaultProps = {
		color: colors.white,
	};

	toggleSearch = () => this.setState({ isSearching: !this.state.isSearching });

	renderItems = (items = [], small) =>
		items.map(({ label, onClick, to, href, openInNewTab }) => (
			<>
				{(!!to || !!href) && (
					<MenuLink
						key={label}
						onClick={e => {
							this.setState({ isVisible: false });
							if (onClick) {
								e.preventDefault();
								e.stopPropagation();
								onClick();
							}
						}}
						small={small}
						to={to}
						href={href}
						as={href && 'a'}
						target={openInNewTab ? '_blank' : '_self'}
					>
						{label}
					</MenuLink>
				)}
				{!to && !href && (
					<MenuButton
						key={label}
						onClick={() => {
							this.setState({ isVisible: false });
							if (onClick) {
								onClick();
							}
						}}
						small={small}
					>
						{label}
					</MenuButton>
				)}
			</>
		));

	toggleModal = () => {
		if (this.props.onToggle) {
			this.props.onToggle();
		}
		this.setState({ isVisible: !this.state.isVisible }, () => {
			if (this.state.isVisible) {
				if (this.scrollableContainerRef.current) {
					this.scrollableContainerRef.current.scrollTop = 0;
				}
			}
		});
	};

	render() {
		let { isVisible } = this.state;
		const { topItems, bottomItems, color, mobilePopupOnly, isOpen } = this.props;

		if (isOpen !== undefined) {
			isVisible = isOpen;
		}

		return (
			<>
				{!mobilePopupOnly && (
					<BurgerMenu onClick={this.toggleModal} color={color} visible={isVisible} />
				)}
				<OverlayContainer visible={isVisible}>
					<LogoContainer>
						<Logo color={colors.white} />
						<BurgerMenu onClick={this.toggleModal} color={color} visible={isVisible} />
					</LogoContainer>
					<Inner ref={this.scrollableContainerRef}>
						<Section bordered noPadding>
							{this.renderItems(topItems)}
						</Section>
						{!featureFlagContainer.isEnabled(FeatureFlag.Simplify) && (
							<>
								<Section>{this.renderItems(bottomItems, true)}</Section>
								<SocialMediaContainer>
									<SocialLinks color={colors.white} />
								</SocialMediaContainer>
							</>
						)}
						<CopyRightText>
							© 2019 DigsConnect. All Rights Reserved. Terms & conditions
						</CopyRightText>
					</Inner>
				</OverlayContainer>
			</>
		);
	}
}

export default MobileHeader;
