// DOCS: https://www.digsconnect.com/docs/web/#user

import client from '../utils/fetch-client';

const path = '/users';

export default {
	getPublicProfile(uuid = '') {
		return client.get(`${path}/${uuid}`);
	},
};
