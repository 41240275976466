import React from 'react';

import { Link, withRouter } from 'react-router-dom';
import Popup from 'reactjs-popup';
import styled from 'styled-components';

import api from 'api';
import theme from 'config/theme';
import authContainer from 'containers/auth';
import userContainer from 'containers/user';
import routes from 'config/routes';
import { Divider } from 'lib/Divider';
import { analyticsService, EventName } from 'services/analyticsService';
import { featureFlagContainer, FeatureFlag } from 'containers/featureFlags';
import useRouter from 'hooks/useRouter';

const { colors } = theme;

const Trigger = styled.button.attrs({
	type: 'button',
})`
	background: transparent;
	outline: none;
	margin-left: 40px;
`;

const Picture = styled.img`
	width: 60px;
	height: 60px;
	min-width: 60px;
	border-radius: 50%;
`;

const List = styled.ul`
	margin-top: 8px;
	margin-bottom: 8px;
`;

const ListItem = styled.li`
	font-size: 14px;
	font-weight: 400;
	padding: 0;
	margin: 0;
`;

const StyledLink = styled(Link)`
	color: inherit;
	text-decoration: none;
	display: block;
	padding: 14px;
	outline: none;

	&:hover {
		background: ${colors.grey05};
	}
`;

const popupStyleOverride = {
	content: {
		width: '240px',
		marginTop: '4px',
		borderRadius: '8px',
		padding: '0',
		border: `1px solid ${colors.grey10}`,
		boxShadow: '0 0 3em 0 rgba(0, 0, 0, 0.05)',
		animation: '200ms fade-in ease-in',
		maxHeight: '80vh',
		overflowY: 'auto',
	},
	overlay: {
		marginTop: '0',
		opacity: '.5',
	},
};

const SlimDivider = styled(Divider)`
	margin-top: 8px;
	margin-bottom: 8px;
`;

const ProfilePopup = ({ picture, name, location, history, hasListings, hasInvoices }) => {
	popupStyleOverride.overlay.backgroundColor =
		location.pathname === '/' ? colors.transparent : colors.white;

	const [goTo] = useRouter();

	const handleLogoutClicked = async () => {
		await api.auth.logout();
		await userContainer.clear();
		await authContainer.clear();
		analyticsService.trackEvent(EventName.SetUser, { digs_user_id: '' });
		history.push('/');
	};

	return (
		<Popup
			arrow={false}
			lockScroll={true}
			overlayStyle={popupStyleOverride.overlay}
			contentStyle={popupStyleOverride.content}
			trigger={
				<Trigger>
					<Picture src={picture} alt={name} />
				</Trigger>
			}
			position="bottom right"
		>
			{close => (
				<List>
					{hasListings !== undefined && (
						<ListItem>
							<StyledLink to={routes.inbox}>Inbox</StyledLink>
						</ListItem>
					)}
					<ListItem>
						<StyledLink to={routes.account_dashboard}>Account</StyledLink>
					</ListItem>
					<ListItem>
						<StyledLink to={routes.favourites}>Favourites</StyledLink>
					</ListItem>
					<SlimDivider />
					{featureFlagContainer.isEnabled(FeatureFlag.LandlordDashboard) && hasListings && (
						<ListItem>
							<StyledLink
								to={routes.manage_listing_dashboard}
								onClick={e => {
									e.preventDefault();
									e.stopPropagation();
									goTo(routes.manage_listing_dashboard);
								}}
							>
								Dashboard
							</StyledLink>
						</ListItem>
					)}
					<ListItem>
						<StyledLink
							to={routes.create_listing}
							onClick={e => {
								e.preventDefault();
								e.stopPropagation();
								goTo(routes.create_listing);
							}}
						>
							Create a listing
						</StyledLink>
					</ListItem>
					{hasListings && (
						<ListItem>
							<StyledLink to={routes.manage_listing} onClick={close}>
								Manage listings
							</StyledLink>
						</ListItem>
					)}
					{hasInvoices && !featureFlagContainer.isEnabled(FeatureFlag.HideInvoices) && (
						<ListItem>
							<StyledLink to={routes.manage_listing_invoices} onClick={close}>
								Invoices
							</StyledLink>
						</ListItem>
					)}
					<SlimDivider />
					<ListItem>
						<StyledLink onClick={handleLogoutClicked}>Logout</StyledLink>
					</ListItem>
				</List>
			)}
		</Popup>
	);
};

export default withRouter(ProfilePopup);
