export interface Coordinate {
	lat: number;
	lng: number;
}

export interface Viewport {
	southwest: Coordinate;
	northeast: Coordinate;
}

export interface SearchItem {
	id: number;
	label: string;
	viewport?: Viewport;
	type: SearchItemType;
	country_code: string;
	parent_location_id: string;
}

export enum SearchItemType {
	Location = 'location',
	University = 'uni',
}
