import React, { useState } from 'react';

import styled from 'styled-components';

import { Modal } from 'lib/Modal';
import theme from 'config/theme';
import Button from 'lib/Button';
import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';
import { TopBar } from 'lib/TopBar';
import api from 'api';

const { colors } = theme;

interface MarketingConsentModalProps {
	onClose(): void;
}

const MarketingConsentModal = ({ onClose }: MarketingConsentModalProps) => {
	const [isLoading, setIsLoading] = useState(false);
	const handleAccept = async () => {
		setIsLoading(true);
		await api.userV2.updateProfile({
			marketing_consented_input: true,
		});
		setIsLoading(false);
		onClose();
	};

	return (
		<Modal width={500} onBackgroundClick={onClose}>
			<TopBar title={'Marketing consent'} onClose={onClose} />
			<ModalContent>
				<Text>
					I consent to receive marketing emails from DigsConnect and third-party partners. I
					understand I can opt out at any time.
				</Text>
			</ModalContent>
			<BottomBar>
				<CancelButton onClick={onClose}>Skip</CancelButton>
				<Button
					height={48}
					isPink
					noMargin
					maxWidth={100}
					onClick={handleAccept}
					isLoading={isLoading}
				>
					Agree
				</Button>
			</BottomBar>
		</Modal>
	);
};

export default MarketingConsentModal;

const ModalContent = styled.div`
	padding: 24px;
	padding-bottom: 8px;
	overflow-y: auto;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		padding-bottom: 120px;
	}
`;

const BottomBar = styled.div`
	padding: 16px 24px;
	justify-content: center;
	flex-shrink: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: ${colors.white};
	}
`;

const Text = styled.p`
	line-height: 20px;
`;

const CancelButton = styled.div`
	color: ${colors.grey60};
	cursor: pointer;
`;
