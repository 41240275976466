import React from 'react';

import styled from 'styled-components';

import { Modal } from 'lib/Modal';
import theme from 'config/theme';
import useRouter from 'hooks/useRouter';
import { TopBar } from 'lib/TopBar';

const { colors, fontWeights, fonts } = theme;

interface InfoPopupModalProps {
	popupText: string;
	popupTitle: string;
	onClose(): void;
}
const InfoPopupModal = ({ popupText, popupTitle, onClose }: InfoPopupModalProps) => {
	return (
		<Modal mobileBottomsheet slideUp width={375} onBackgroundClick={onClose}>
			<TopBar title={popupTitle} onClose={onClose} />
			<ModalContainer>
				<Container>
					<TextContainer>
						<Description>{popupText}</Description>
					</TextContainer>
				</Container>
			</ModalContainer>
		</Modal>
	);
};

export default InfoPopupModal;

const ModalContainer = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
`;

const Container = styled.div`
	background: ${colors.white};
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
`;

const TextContainer = styled.div`
	display: flex;
	padding: 16px;
`;

const Description = styled.p`
	color: ${colors.darkBlue};
	font-weight: ${fontWeights.normal};
	font-size: 16px;
	font-family: ${fonts.Gilroy};
	line-height: 150%;
`;
