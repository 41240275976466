import styled from 'styled-components';
import theme from 'config/theme';

const { colors } = theme;

interface InfoTextProps {
	noMargin?: boolean;
	textAlign?: string;
}

export const InfoText = styled.div<InfoTextProps>`
	font-size: 14px;
	line-height: 1.3;
	margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '16px')};
	color: ${colors.grey60};
	${({ textAlign }) => (textAlign ? `text-align: ${textAlign};` : '')}
`;
