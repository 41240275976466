import theme from 'config/theme';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const { fontWeights, fontSizes, colors, fonts, zIndices, lineHeight } = theme;

export const OverlayContainer = styled.div`
	height: 100%;
	width: 100vw;
	overflow-y: hidden;
	-webkit-overflow-scrolling: touch;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	transition: transform 0.3s;
	transform: translateX(${({ visible }) => (visible ? '0' : '-100%')});
	padding: 0 16px;
	background-color: ${colors.darkBlue};
	z-index: ${zIndices.modal};
	display: flex;
	flex-direction: column;
`;

export const LogoContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 72px;
`;

const menuItemStyles = `
  padding: 0;
  margin: 12px 0;
  color: ${colors.white};
  font-weight: ${fontWeights.bold};
  font-family: ${fonts.Gilroy};
  line-height: ${lineHeight.normal};
  outline: none;
`;

export const MenuButton = styled.button`
  ${menuItemStyles}
  font-size: ${({ small }) => (small ? fontSizes.small : fontSizes.xlarge)};
  text-align: left;
  background: none;
  border: none;
`;

export const MenuLink = styled(Link)`
  ${menuItemStyles}
  font-size: ${({ small }) => (small ? fontSizes.small : fontSizes.xlarge)};
  text-decoration: none;
`;

export const Section = styled.section`
	display: flex;
	flex-direction: column;
	padding: 20px 0;

	${({ bordered }) =>
		bordered &&
		`
    border-bottom: solid 1px ${colors.translucentWhite};
  `}
	${({ noPadding }) => noPadding && `padding: 0;`}
`;

export const SocialMediaContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 215px;
	margin-bottom: 16px;
`;

export const CopyRightText = styled.p`
	color: ${colors.gray};
	margin-top: auto;
	font-size: ${fontSizes.xxsmall};
	font-weight: ${fontWeights.thin};
	font-family: ${fonts.Gilroy};
	color: ${colors.translucentWhite};
`;
