import React, { useRef, useEffect } from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import { ReactComponent as SendSvg } from 'assets/icons/arrow-right-filled.svg';
import { ReactComponent as LockedIcon } from 'assets/icons/lock.svg';
import { ReactComponent as InfoSvg } from 'assets/icons/info-outlined.svg';
import { useScreenWidth } from 'hooks/useScreenWidth';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';

const { colors, fonts } = theme;

interface MessageInputProps {
	message: string;
	isLocked: boolean;
	onChange(value: string): void;
	onFocus(): void;
	onBlur(): void;
	onSubmit(): void;
}

const MessageInput = ({
	message,
	isLocked,
	onFocus,
	onBlur,
	onChange,
	onSubmit,
}: MessageInputProps) => {
	const textAreaRef = useRef<HTMLTextAreaElement>(null);
	const screenWidth = useScreenWidth();

	useEffect(() => {
		if (message) {
			return;
		}

		if (textAreaRef.current) {
			textAreaRef.current.style.height = '24px';
			textAreaRef.current.style.height = `${Math.min(textAreaRef.current.scrollHeight, 80)}px`;
		}
	}, [message]);

	useEffect(() => {
		const textarea = textAreaRef.current;

		if (!textarea) {
			return;
		}

		const handleInput = () => {
			if (textAreaRef.current) {
				textarea.style.height = '24px';
				textarea.style.height = `${Math.min(textAreaRef.current.scrollHeight, 80)}px`;
			}
		};

		const handleKeyDown = (event: any) => {
			if (event.key === 'Enter' && !event.shiftKey) {
				event.preventDefault();
				onSubmit();
			}
		};

		textarea.addEventListener('input', handleInput);
		textarea.addEventListener('keydown', handleKeyDown);

		return () => {
			textarea.removeEventListener('input', handleInput);
			textarea.removeEventListener('keydown', handleKeyDown);
		};
	}, [textAreaRef.current, message]);

	useEffect(() => {
		if (!textAreaRef.current) {
			return;
		}

		const handleFocus = () => {
			onFocus();
		};

		const handleBlur = () => {
			onBlur();
		};

		textAreaRef.current?.addEventListener('focus', handleFocus);
		textAreaRef.current?.addEventListener('blur', handleBlur);

		return () => {
			textAreaRef.current?.removeEventListener('focus', handleFocus);
			textAreaRef.current?.removeEventListener('blur', handleBlur);
		};
	}, [textAreaRef.current, onFocus, onBlur]);

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		onChange(event.target.value);
	};

	const handleSubmit = () => {
		onSubmit();
	};

	return (
		<Container
			onSubmit={e => {
				e.preventDefault();
				handleSubmit();
			}}
		>
			{isLocked && (
				<LockedIcon
					style={{
						width: 24,
						height: 24,
						minWidth: 24,
						fill: colors.grey60,
						marginRight: 4,
					}}
				/>
			)}
			<TextArea
				value={message}
				onChange={handleChange}
				ref={textAreaRef}
				placeholder={isLocked ? 'Messaging disabled' : 'Write a message'}
				disabled={isLocked}
				rows={1}
			/>
			{!isLocked && (
				<SendButton type="submit">
					<SendSvg />
				</SendButton>
			)}
			{isLocked && screenWidth > BREAKPOINT_MOBILE_PX && (
				<PopupTrigger>
					<InfoSvg style={{ marginRight: 8 }} />
					<InfoPopup>Messaging is disabled on cancelled or expired conversations.</InfoPopup>
				</PopupTrigger>
			)}
		</Container>
	);
};

const Container = styled.form`
	background: ${colors.grey05};
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	border-radius: 16px;
	padding: 12px 8px;
`;

const TextArea = styled.textarea`
	font-family: ${fonts.Gilroy};
	background: transparent;
	resize: none;
	flex: 1;
	outline: none;
	max-height: 200px;
	height: 24px;
	font-size: 16px;
`;

const SendButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	background: transparent;
	outline: none;
	-webkit-tap-highlight-color: transparent;
`;

const InfoPopup = styled.div`
	position: absolute;
	padding: 8px 16px;
	border-radius: 12px;
	background: ${colors.white};
	right: 8px;
	bottom: 8px;
	font-size: 12px;
	font-weight: 400;
	cursor: pointer;
	color: ${colors.darkBlue};
	line-height: 150%;
	z-index: 1;
	width: fit-content;
	white-space: nowrap;
	visibility: hidden;
	box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
`;

const PopupTrigger = styled.div`
	position: relative;
	width: fit-content;
	cursor: pointer;

	&:hover ${InfoPopup} {
		visibility: visible;
	}
`;

export default MessageInput;
