export const wizardSteps = {
	BASIC_DETAILS_SECTION: 0,
	VERIFY_EMAIL_SECTION: 1,
	PROFILE_PICTURE_SECTION: 2,
	BIOGRAPHY_SECTION: 3,
	CELLPHONE_SECTION_STEP_ONE: 4,
	CELLPHONE_SECTION_STEP_TWO: 5,
	IDENTIFICATION_SECTION: 6,
	BIRTH_AND_GENDER: 7,
	SETUP_COMPLETE_SECTION: 8,
};

export const wizardStepsOrder = [
	wizardSteps.VERIFY_EMAIL_SECTION,
	wizardSteps.BASIC_DETAILS_SECTION,
	wizardSteps.PROFILE_PICTURE_SECTION,
	wizardSteps.CELLPHONE_SECTION_STEP_ONE,
	wizardSteps.CELLPHONE_SECTION_STEP_TWO,
	wizardSteps.IDENTIFICATION_SECTION,
	wizardSteps.BIRTH_AND_GENDER,
	wizardSteps.SETUP_COMPLETE_SECTION,
];

const visibleStepNames = {
	[wizardSteps.BASIC_DETAILS_SECTION]: 'Basic details',
	[wizardSteps.VERIFY_EMAIL_SECTION]: 'Verify email',
	[wizardSteps.PROFILE_PICTURE_SECTION]: 'Add a profile picture',
	[wizardSteps.BIOGRAPHY_SECTION]: 'Tell use about yourself',
	[wizardSteps.CELLPHONE_SECTION_STEP_TWO]: 'Verify cellphone number',
	[wizardSteps.IDENTIFICATION_SECTION]: 'Identity information',
	[wizardSteps.BIRTH_AND_GENDER]: 'Birthdate and gender',
};

export const visibleBreadCrumbs = wizardStepsOrder
	.filter(step => !!visibleStepNames[step])
	.map(step => ({
		id: step,
		name: visibleStepNames[step],
	}));

export const isStepComplete = (profileStep, userProfile) => {
	const {
		first_name,
		last_name,
		email,
		profile_picture,
		cellphone,
		identification_number,
		gender,
		birth_date,
		nationality,
	} = userProfile;

	if (profileStep === wizardSteps.BASIC_DETAILS_SECTION) {
		return !!(first_name && last_name && email);
	}
	if (profileStep === wizardSteps.VERIFY_EMAIL_SECTION) {
		return !!(email && email.verified);
	}
	if (profileStep === wizardSteps.PROFILE_PICTURE_SECTION) {
		return !!(profile_picture && !profile_picture.is_default);
	}
	if (profileStep === wizardSteps.CELLPHONE_SECTION_STEP_TWO) {
		return !!(cellphone && cellphone.verified);
	}
	if (profileStep === wizardSteps.IDENTIFICATION_SECTION) {
		return !!(nationality && identification_number);
	}
	if (profileStep === wizardSteps.BIRTH_AND_GENDER) {
		return !!(birth_date && gender);
	}
	return false;
};

export function getResumableWizardState(userProfile, ignoreEmail) {
	const {
		first_name,
		last_name,
		email,
		profile_picture,
		cellphone,
		identification_number,
		gender,
		birth_date,
		nationality,
	} = userProfile;
	if (!ignoreEmail && !email.verified) return wizardSteps.VERIFY_EMAIL_SECTION;

	if (first_name === '' || last_name === '' || email === null)
		return wizardSteps.BASIC_DETAILS_SECTION;

	if (!profile_picture || profile_picture.is_default) return wizardSteps.PROFILE_PICTURE_SECTION;

	if (!cellphone) return wizardSteps.CELLPHONE_SECTION_STEP_ONE;

	if (!cellphone.verified) return wizardSteps.CELLPHONE_SECTION_STEP_TWO;

	if (!nationality || !identification_number) return wizardSteps.IDENTIFICATION_SECTION;

	if (!birth_date || !gender) return wizardSteps.BIRTH_AND_GENDER;

	return wizardSteps.SETUP_COMPLETE_SECTION;
}

export default wizardSteps;
