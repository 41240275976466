export enum InboxType {
	Unknown = '',
	Tenant = 'tenant',
	Landlord = 'landlord',
}

export enum QueryParamKey {
	Type = 'forcetype',
	OpenFirstMessage = 'openfirstmessage',
}
