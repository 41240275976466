import { Property, Room, Feature, Photo, CapacityRoom, Searchable } from 'models/property';
import { Response } from './types';
import client from '../utils/fetch-client';

const path = '/v2/user/properties';

export default {
	getProperties(): Promise<Response<Property[]>> {
		return client.get(path);
	},
	getPropertiesV2(): Promise<Response<Property[]>> {
		return client.get(`${path}?new_serializer=true`);
	},
	create(): Promise<Response<Property>> {
		return client.post(path);
	},
	updateSearchable(uuid: string): Promise<Response<Searchable>> {
		return client.put(`${path}/${uuid}/searchable`);
	},
	deleteProperty(uuid: string): Promise<any> {
		return client.delete(`${path}/${uuid}`);
	},
	updateCapacity(
		uuid: string,
		availablePlaces: number,
		rooms: CapacityRoom[],
	): Promise<Response<Property>> {
		return client.put(`${path}/${uuid}/capacity`, {
			body: {
				uuid: uuid,
				total_places_available_value: availablePlaces,
				rooms: rooms,
			},
		});
	},
	get(uuid: string): Promise<Response<Property>> {
		return client.get(`${path}/${uuid}`);
	},
	update(property: Property): Promise<Response<Property>> {
		return client.put(`${path}/${property.uuid}`, {
			body: property,
		});
	},
	getPhotos(uuid: string): Promise<Response<Photo[]>> {
		return client.get(`${path}/${uuid}/photos`);
	},
	updatePhotoOrdinals(uuid: string, photos: Photo[]): Promise<Response<Photo[]>> {
		return client.put(`${path}/${uuid}/photos`, {
			body: photos,
		});
	},
	uploadPhoto(uuid: string, formData: FormData): Promise<Response<Photo>> {
		return client.post(`${path}/${uuid}/photos`, {
			body: formData,
			unsetContentType: true,
		});
	},
	uploadVideo(uuid: string, videoLink: string): Promise<void> {
		return client.post(`${path}/${uuid}/videos`, {
			body: { url: videoLink },
		});
	},
	deletePhoto(uuid: string, imageId: string): Promise<any> {
		return client.delete(`${path}/${uuid}/photos/${imageId}`);
	},
	updatePhoto(uuid: string, imageId: string, photo: Photo): Promise<any> {
		return client.put(`${path}/${uuid}/photos/${imageId}`, {
			body: photo,
		});
	},
	getPropertyFeatures(uuid: string): Promise<Response<Feature[]>> {
		return client.get(`${path}/${uuid}/features`);
	},
	updatePropertyFeatures(uuid: string, features: Feature[]): Promise<Response<Feature[]>> {
		return client.put(`${path}/${uuid}/features`, {
			body: features,
		});
	},
	uploadRoomPhoto(uuid: string, roomUuid: string, formData: FormData): Promise<Response<Photo>> {
		return client.post(`${path}/${uuid}/rooms/${roomUuid}/photos`, {
			body: formData,
			unsetContentType: true,
		});
	},
	deleteRoomPhoto(uuid: string, roomUuid: string, imageId: string): Promise<any> {
		return client.delete(`${path}/${uuid}/rooms/${roomUuid}/photos/${imageId}`);
	},
	getPhotoTags(): Promise<Response<{ tags: string[] }>> {
		return client.get(`${path}/photos/tags`);
	},
	updateRoom(uuid: string, roomUuid: string, room: Room): Promise<Response<Room[]>> {
		return client.put(`${path}/${uuid}/rooms/${roomUuid}`, {
			body: room,
		});
	},
	getRoomFeatures(uuid: string, roomUuid: string): Promise<Response<Feature[]>> {
		return client.get(`${path}/${uuid}/rooms/${roomUuid}/features`);
	},
	complete(uuid: string): Promise<Response<void>> {
		return client.put(`${path}/${uuid}/complete`);
	},
	updateRoomFeatures(
		uuid: string,
		roomUuid: string,
		features: Feature[],
	): Promise<Response<Feature[]>> {
		return client.put(`${path}/${uuid}/rooms/${roomUuid}/features`, {
			body: features,
		});
	},
};
