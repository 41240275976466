import React from 'react';

import { Modal } from 'lib/Modal';
import Button from 'lib/Button';
import { Title, ModalContainer, Heading, BasicText, ModalHeader } from './styles';
import Cross from 'lib/Cross';

interface EnquiryInfoModalProps {
	onClose(): void;
	EnquiryInfoStep: any;
}

const EnquiryInfoModal = ({ onClose, EnquiryInfoStep }: EnquiryInfoModalProps) => {
	return (
		<Modal slideUp onBackgroundClick={onClose}>
			<ModalContainer>
				<ModalHeader>
					<Heading>{EnquiryInfoStep.header}</Heading>
					<Cross onClick={onClose} />
				</ModalHeader>
				{EnquiryInfoStep.content.map((step: { title: string; reason: string }) => (
					<>
						<Title key={step.title}>{step.title}</Title>
						<BasicText key={step.reason}>{step.reason}</BasicText>
					</>
				))}
				<Button onClick={onClose} isPink>
					Close
				</Button>
			</ModalContainer>
		</Modal>
	);
};

export default EnquiryInfoModal;
