import React from 'react';

import styled from 'styled-components';
import Icon from 'react-eva-icons';

import theme from 'config/theme';

const { colors } = theme;

const DesktopBreadCrumbItem = ({ text, completed }) => (
	<StyledDesktopBreadCrumbItem>
		{completed ? (
			<Icon name="checkmark-circle-2" size="large" fill={colors.white} />
		) : (
			<NotCompletedIndicator />
		)}

		<Text completed={completed}>{text}</Text>
	</StyledDesktopBreadCrumbItem>
);

export default DesktopBreadCrumbItem;

/**styled components */
const StyledDesktopBreadCrumbItem = styled.div`
	margin-bottom: 28px;
	display: flex;
	justify-content: start;
	align-items: center;
`;

const Text = styled.div`
	font-size: 18px;
	font-weight: 700;
	color: ${({ completed }) => (completed ? '#ffffff;' : 'rgba(255, 255, 255, 0.54);')};
	margin-left: 10px;
`;

const NotCompletedIndicator = styled.div`
	border-radius: 50%;
	background-color: rgba(255, 255, 255, 0.54);
	height: 19px;
	width: 19px;
	margin: 2px;
`;
