export default {
	home: '/',
	about: '/about',
	partners: '/partners',
	how_it_works: '/how-it-works',
	terms_and_conditions: '/terms-and-conditions',
	privacy: '/privacy',
	landlord_resources: '/landlord-resources',
	faqs: '/faqs/',
	contact_us: '/contact-us/',
	contact_us_landlord: '/contact-us?reason=landlord_manager',
	messages: '/messages/:conversationId?',
	wishlist: '/wishlist/',
	listings: '/listings/',
	listings_edit: '/listings-edit/:id',
	listing_edit: '/listing-edit/:id',
	edit_digs: '/edit-digs/:id?',
	digs_search_legacy: '/digs/search', // deprecated
	digs_search: '/digs-search',
	partners_search: '/partners-search',
	digs_detail: '/digs/view/:city?/:suburb?/:title?/:id',
	digs_detail_preview: '/digs/:id/preview',
	applications: '/applications',
	my_bookings: '/my-bookings/:bookingId?',
	applications_detail: '/applications-detail',
	institution: '/institution',
	verifications: '/institution/verifications',
	verification_detail: '/institution/verifications/:id',
	manage_digs: '/manage-digs',
	manage_alerts: '/account/alerts',
	payment: '/payment',
	user_profile: '/user-profile',
	create_listing_edit: '/create-listing/:id?',
	create_listing: '/create-listing',
	add_a_digs_legacy: '/add-a-digs/:property_uuid/:component?',
	favourites: '/favourites',
	book: '/digs/enquire/:listingId/:propertyId/:roomId?',
	pricing: '/pricing',
	verificationsDisclaimer: '/verifications-disclaimer',
	landlordLanding: '/landlord',
	safety: '/safety',
	manage_listing: '/manage-listing',
	manage_listing_invoices: '/manage-listing?tab=1',
	manage_listing_dashboard: '/manage-listing?tab=2',
	inbox: '/inbox',
	inbox_route_definition: '/inbox/:id?',
	profile: '/profile/', // deprecated
	account: '/account',
	account_dashboard: '/account/dashboard',
	account_profile: '/acount/profile',
	account_notifications: '/acount/notifications',
};
