import styled from 'styled-components';
import theme from 'config/theme';
import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';

const { fonts, colors, zIndices } = theme;

export const FilterContainer = styled.div<{ borderLeft?: string }>`
	position: relative;
	display: inline-block;
	margin-right: 8px;
	flex-shrink: 0;
	${({ borderLeft }) =>
		borderLeft &&
		`
		border-left: 1px solid ${colors.gray};
		padding-left: 16px;
		margin-left: 8px;
	`}
	margin-bottom: 8px;
`;

export const PopupContainer = styled.div`
	position: absolute;
	top: calc(100% + 8px);
	z-index: ${zIndices.modal};
`;

export const Popup = styled.div<{ rightAlign?: boolean; width?: string }>`
	user-select: none;
	background-color: gray;
	width: 600px;
	max-height: 60vh;
	display: flex;
	flex-direction: column;
	border: lightgray 1px solid;
	position: fixed;
	background-color: white;
	border-radius: 16px;
	z-index: ${zIndices.modal};
	box-shadow: rgba(0, 0, 0, 0.2) 0 0 20px;
	font-family: ${fonts.Gilroy};
	${({ rightAlign }) => rightAlign && 'right: 0;'}
	${({ width }) => width && `width: ${width};`}
	overflow: hidden;
	@media (max-width: 768px) {
		max-height: 100vh;
		position: fixed;
		bottom: 0;
		top: unset;
		left: 0;
		width: 100vw;
		padding: 0;
	}
`;

export const PopupInner = styled.div`
	overflow-y: auto;
	padding: 24px;
	@media (max-width: 768px) {
		overflow: unset;
		padding: 16px;
	}
`;

export const PopupBottom = styled.div`
	display: flex;
	padding: 16px 24px;
	border-top: 1px solid ${colors.gray};
	justify-content: center;
`;

export const PopupButtonContainer = styled.div`
	width: 100%;

	@media (min-width: ${BREAKPOINT_TABLET_PX + 1}px) {
		width: 200px;
	}
`;

export const PillContainer = styled.div<{ active?: boolean }>`
	font-size: 14px;
	font-weight: 500;
	border-radius: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 40px;
	padding: 0 12px;
	cursor: pointer;
	user-select: none;
	border: 1px solid ${colors.shadedGray};
	color: ${colors.darkBlue};
	transition: all 200ms;
	box-sizing: border-box;

	&:hover {
		border-color: ${colors.darkBlue};
	}

	${({ active }) =>
		active &&
		`
			background: ${colors.gray};
			border-color: ${colors.darkBlue};
		`}
`;
