import React from 'react';

import styled from 'styled-components';

import Title from '../../shared-components/Title';
import BottomCTAWrapper from '../BottomCTAWrapper';
import ResendButton from '../../shared-components/ResendButton';
import MobileBreadCrumbs from './MobileBreadCrumbs';
import theme from 'config/theme';
import { TopBar } from 'lib/TopBar';
import { Button } from 'lib/Button';

const { zIndices, colors } = theme;

const MobileWizardStepOverview = ({ userProfile, onContinueClick, onBackClick }) => (
	<StyledMobileWizardStepOverview>
		<TopBar onClose={onBackClick} />
		<ContentWrapper>
			<Title>Complete your profile</Title>
			<SubTitle>
				In order to start applying to or create a listing you need to complete your profile, you can
				see what’s left below
			</SubTitle>
			<MobileBreadCrumbs userProfile={userProfile} />
		</ContentWrapper>
		<BottomCTAWrapper>
			<div className="columns">
				<div className="column is-two-thirds">
					<div className="columns is-mobile">
						<div className="column is-one-third">
							<ResendButtonWrapper>
								<ResendButton type="button" onClick={onBackClick}>
									Back
								</ResendButton>
							</ResendButtonWrapper>
						</div>
						<div className="column is-two-thirds">
							<Button isOutline onClick={onContinueClick}>
								{' '}
								Continue{' '}
							</Button>
						</div>
					</div>
				</div>
			</div>
		</BottomCTAWrapper>
	</StyledMobileWizardStepOverview>
);

export default MobileWizardStepOverview;

/**styled components */
const StyledMobileWizardStepOverview = styled.div`
	z-index: ${zIndices.modal};
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: ${colors.white};
	display: flex;
	flex-direction: column;
`;

const ContentWrapper = styled.div`
	flex: 1;
	padding-top: 24px;
	padding-left: 24px;
	padding-right: 24px;
	padding-bottom: 90px;
	overflow-y: auto;
`;

const SubTitle = styled.p`
	color: #9da7b7;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 32px;
`;

const ResendButtonWrapper = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;
