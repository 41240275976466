import * as React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import { SelectOption } from 'models/selectOption';
import { ReactComponent as CrossIcon } from 'assets/images/icons/cross-rounded.svg';

const { colors } = theme;

interface ChipCollectionProps {
	chips: SelectOption[];
	onRemoveChip?(value: any): void;
}

export const ChipCollection = ({ chips, onRemoveChip = () => {} }: ChipCollectionProps) => (
	<>
		{chips.map(chip => (
			<Chip key={chip.value} onClick={() => onRemoveChip(chip.value)}>
				<span>{chip.label}</span>
				<ChipRemove />
			</Chip>
		))}
	</>
);

const Chip = styled.div`
	background: ${colors.darkBlue};
	color: ${colors.white};
	border-radius: 48px;
	padding: 6px 8px;
	display: inline-flex;
	margin-right: 4px;
	margin-bottom: 4px;
	align-items: center;
	font-size: 14px;
	font-weight: 600;
	cursor: pointer;
`;

const ChipRemove = styled(CrossIcon)`
	fill: ${colors.white};
	margin-left: 8px;
	height: 10px;
`;
