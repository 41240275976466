import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ModalContainer = styled.div`
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
`;

export const Title = styled.h3`
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 16px;
	text-align: center;
`;

export const BasicText = styled.div`
	line-height: 1.3;
	margin-bottom: 16px;
	text-align: center;
`;

export const StyledLink = styled(Link)`
	font-size: 16px;
	text-decoration: underline;
	margin-bottom: 16px;
`;

export const ButtonContainer = styled.div`
	margin-top: 8px;
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 350px;
	flex-wrap: wrap;

	button {
		margin-bottom: 16px;
	}
`;
