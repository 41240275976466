import { useState, useEffect } from 'react';

const getWidth = () =>
	window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

export const useScreenWidth = () => {
	let [width, setWidth] = useState(getWidth());

	useEffect(() => {
		const eventName = 'resize';
		const resizeListener = () => {
			setWidth(getWidth());
		};

		window.addEventListener(eventName, resizeListener);

		return () => {
			window.removeEventListener(eventName, resizeListener);
		};
	}, []);

	return width;
};
