import React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import { Cross } from 'lib/Cross';

const { colors } = theme;

interface WarningTooltipProps {
	text: string;
	pointerBottom?: boolean;
	hidePointer?: boolean;
	onClose(): void;
}

const WarningTooltip = ({ text, pointerBottom, hidePointer, onClose }: WarningTooltipProps) => (
	<Container className="test-element">
		{!hidePointer && <Pointer isBottom={pointerBottom} />}
		<CloseContainer>
			<StyledCross onClick={onClose} />
		</CloseContainer>
		<Text>{text}</Text>
	</Container>
);

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	background: ${colors.pink};
	border-radius: 16px;
	padding: 16px 24px;
	position: relative;
`;

const pointerSize = 16;

const Pointer = styled.div<{ isBottom?: boolean }>`
	width: ${pointerSize}px;
	height: ${pointerSize}px;
	background: ${colors.pink};
	position: absolute;
	transform: rotate(-45deg);
	z-index: 1;
	${({ isBottom }) => (isBottom ? `bottom: -${pointerSize / 2}px` : `top: -${pointerSize / 2}px`)}
`;

const Text = styled.p`
	font-size: 14px;
	font-weight: 600;
	color: ${colors.white};
	margin-bottom: 16px;
`;

const CloseContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
	margin-bottom: 8px;
`;

const StyledCross = styled(Cross)`
	fill: ${colors.white};
	height: 10px;
	min-height: 10px;
`;

export default WarningTooltip;
