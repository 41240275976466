import { Container } from 'unstated';

interface State {
	v2: any;
}

const defaultState: State = {
	v2: {},
};

class User extends Container<State> {
	state: State = defaultState;

	set(userInfo: any) {
		const prevState = this.state || {};
		const newState = {
			...prevState,
			v2: userInfo,
		};
		return this.setState(newState);
	}

	clear() {
		return this.setState(defaultState);
	}
}

export default new User();
