import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { isProd } from './containers/featureFlags';
import App from './components/app';
import * as serviceWorker from './sw';

const rootElement = document.getElementById('root');
if (isProd()) {
	ReactDOM.render(
		<GoogleReCaptchaProvider reCaptchaKey="6LdbtsIkAAAAAM5Q4J6OJVMdawKGydH0_B3rpOEx">
			<App />
		</GoogleReCaptchaProvider>,
		rootElement,
	);
} else {
	ReactDOM.render(
		<GoogleReCaptchaProvider reCaptchaKey="6LdrkqgkAAAAADRLIHvhR7218y4ZUK_5TZCUvIsB">
			<App />
		</GoogleReCaptchaProvider>,
		rootElement,
	);
}
serviceWorker.unregister();
