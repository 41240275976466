import * as React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';

const { colors } = theme;

const width = 80;
const swingDegrees = 10;

const Container = styled.div`
	width: ${width}px;
	height: ${width}px;
	position: relative;
	margin: auto;
	margin-top: 16px;
	margin-bottom: ${(width * 3) / 4}px;
`;

const Rectangle = styled.div`
	width: ${width / 4}px;
	background: ${colors.blue};
	height: ${width / 2}px;
	position: absolute;
	right: 0;
	top: 0;
`;

const Circle = styled.div`
	border: ${width / 4}px solid ${colors.blue};
	border-radius: ${width}px;
	height: ${width}px;
	width: ${width}px;
	box-sizing: border-box;
`;

const HalfCircle = styled(Circle)`
	margin-top: -${width / 2}px;
`;

const HalfCircleContainer = styled.div`
	overflow: hidden;
	animation: swing ease-in-out 700ms infinite alternate;
	transform-origin: center 0;

	@keyframes swing {
		0% {
			transform: rotate(${swingDegrees}deg);
		}
		100% {
			transform: rotate(-${swingDegrees}deg);
		}
	}
`;

const SwingLoader = () => (
	<Container>
		<Rectangle />
		<Circle />
		<HalfCircleContainer>
			<HalfCircle />
		</HalfCircleContainer>
	</Container>
);

export const Loader = React.memo(SwingLoader);
