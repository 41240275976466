import React from 'react';

import { Modal } from 'lib/Modal';
import { Title, ModalContainer, SubText, ButtonContainer } from './styles';
import Button from 'lib/Button';

const CancelEnquiryModal = ({onClose, navigateBackToListing}: any) => {
	return (
		<Modal>
			<ModalContainer>
				<Title>You are about to cancel this enquiry process</Title>
				<SubText>Are you sure?</SubText>
				<ButtonContainer>
					<Button onClick={onClose} maxWidth={150} isOutline>No, continue</Button>
					<Button onClick={navigateBackToListing} maxWidth={150} isPink>Yes, Cancel</Button>
				</ButtonContainer>
			</ModalContainer>
		</Modal>
	);
};

export default CancelEnquiryModal;
