import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ResendButton from '../../shared-components/ResendButton';
import { Formik } from 'formik';
import BottomCTAWrapper from '../BottomCTAWrapper';
import api from 'api';
import Swal from 'sweetalert2';
import { Button } from 'lib/Button';
import { Title } from 'lib/Title';

class VerifyEmailSection extends Component {
	static propTypes = {
		email: PropTypes.isRequired,
		onUpdateSuccess: PropTypes.func,
	};

	state = {
		isLoading: false,
	};

	static defaultProps = {
		email: '',
		onUpdateSuccess: () => {},
	};

	fireSubmissionError = message => {
		Swal.fire({
			title: message,
			type: 'warning',
			showConfirmButton: false,
			toast: true,
			timer: 4000,
			position: 'top',
			customClass: {
				container: 'custom-container-toast-class',
			},
		});
	};

	fireSubmissionSuccess = message => {
		Swal.fire({
			title: message,
			type: 'success',
			showConfirmButton: false,
			toast: true,
			timer: 3000,
			position: 'top',
			customClass: {
				container: 'custom-container-toast-class',
			},
		});
	};

	resendSubmit = async (values, { setSubmitting }) => {
		const errorMessage = 'Something went wrong, the verification email could not be sent';
		const successMessage = 'Verification email sent';

		try {
			const { sendEmailConfirmation } = api.user;

			const response = await sendEmailConfirmation();

			if (response.status >= 200 && response.status < 300) {
				this.fireSubmissionSuccess(successMessage);
			} else {
				this.fireSubmissionError(errorMessage);
			}
		} catch (err) {
			this.fireSubmissionError(errorMessage);
		} finally {
			setSubmitting(false);
		}
	};

	onContinueClick = () => {
		const { onUpdateSuccess } = this.props;
		this.setState({
			isLoading: true,
		});
		api.userV2
			.getProfile()
			.then(res => res.data)
			.then(data => {
				onUpdateSuccess();
				this.setState({
					isLoading: false,
				});
			});
	};

	render() {
		const { isLoading } = this.state;
		const { email } = this.props;

		return (
			<StyledVerifyEmailSection>
				<div>
					<Title>Verify your email</Title>
					<SubTitle>We’ve sent a link to:</SubTitle>
					<DisplayEmail>{email}</DisplayEmail>
					<SubTitle>
						Tap on the link to verify your email. You can also continue and verify your email later.
					</SubTitle>
				</div>
				<BottomCTAWrapper>
					<div className="columns">
						<div className="column is-two-thirds">
							<div className="columns is-mobile">
								<div className="column  is-one-third">
									<Formik onSubmit={this.resendSubmit}>
										{({ isSubmitting, handleSubmit }) => {
											return (
												<ResendFormWrapper onSubmit={handleSubmit}>
													<ResendButton
														disabled={isSubmitting}
														loading={isSubmitting}
														type="submit"
													>
														Resend
													</ResendButton>
												</ResendFormWrapper>
											);
										}}
									</Formik>
								</div>

								<div className="column is-two-thirds">
									<Button isOutline onClick={this.onContinueClick} isLoading={isLoading}>
										Continue
									</Button>
								</div>
							</div>
						</div>
					</div>
				</BottomCTAWrapper>
			</StyledVerifyEmailSection>
		);
	}
}

export default VerifyEmailSection;

/**styled components */
const StyledVerifyEmailSection = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const SubTitle = styled.h3`
	color: #9da7b7;
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 5px;
`;

const DisplayEmail = styled.div`
	color: #041f47;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 22px;
`;

const ResendFormWrapper = styled.form`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;
