import React, { useState } from 'react';

import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import Swal from 'sweetalert2';

import api from 'api';
import Input from '../../shared-components/Input';
import FormElementWrapper from '../../shared-components/FormElementWrapper';
import FormElementError from '../../shared-components/FormElementError';
import BottomCTAWrapper from '../BottomCTAWrapper';
import { CountryCodeSelect } from 'lib/CountryCodeSelect';
import MobileNumberInput from 'lib/MobileNumberInput';
import { Button } from 'lib/Button';
import { Title } from 'lib/Title';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';
import { notifyError } from 'lib/Notifications';

const CellphoneStepOneSection = ({ number, region, onUpdateSuccess }) => {
	const [countryCode, setCountryCode] = useState(region || 'ZA');
	const [mobileNumber, setMobileNumber] = useState(number || '');

	const onFormSubmit = async (values, { setSubmitting }) => {
		if (featureFlagContainer.isEnabled(FeatureFlag.SingleMobileNumberInput)) {
			const errorMessage = 'Something went wrong, please contact support';
			try {
				const response = await api.userV2.updateProfile({
					cellphone: {
						number: mobileNumber,
						region: countryCode,
					},
				});
				if (response.status >= 200 && response.status < 300) {
					onUpdateSuccess();
				} else {
					notifyError(response.data.error.message || errorMessage);
				}
			} catch (err) {
				notifyError(errorMessage);
			} finally {
				setSubmitting(false);
			}
			return;
		}
		const errorMessage = 'Something went wrong, please contact support';
		try {
			const response = await api.userV2.updateProfile({
				cellphone: {
					number: values.cell_number,
					region: countryCode,
				},
			});
			if (response.status >= 200 && response.status < 300) {
				onUpdateSuccess();
			} else {
				console.log(response.data);
				notifyError(response.data.error.message || (response.data.length && response.data[0]) || errorMessage);
			}
		} catch (err) {
			console.log(err);
			notifyError(errorMessage);
		} finally {
			setSubmitting(false);
		}
	};

	const handleCountryChange = country => {
		const { countryCode } = country;
		setCountryCode(countryCode);
	};

	const initialOTPSchema = Yup.object().shape({
		cell_number: Yup.string().required('Required'),
	});

	return (
		<StyledCellphoneStepOneSection>
			<Formik
				initialValues={{ cell_number: number || '' }}
				onSubmit={onFormSubmit}
				validationSchema={
					featureFlagContainer.isEnabled(FeatureFlag.SingleMobileNumberInput)
						? undefined
						: initialOTPSchema
				}
			>
				{({ values, isSubmitting, handleChange, handleSubmit, errors, touched }) => {
					return (
						<StyledForm onSubmit={handleSubmit}>
							<div>
								<Title>Cellphone number</Title>
								<SubTitle>Enter your cellphone number below (WhatsApp preferred)</SubTitle>

								<div className="columns">
									<div className="column is-two-thirds">
										{featureFlagContainer.isEnabled(FeatureFlag.SingleMobileNumberInput) ? (
											<MobileNumberInput
												country={countryCode}
												mobileNumber={mobileNumber}
												onCountrySelect={setCountryCode}
												onMobileNumberChange={setMobileNumber}
											/>
										) : (
											<>
												<CountryCodeSelect
													value={countryCode}
													onChange={value => setCountryCode(value.countryCode)}
												/>
												<FormElementWrapper>
													<Input
														type="text"
														name="cell_number"
														value={values.cell_number}
														onChange={handleChange}
														placeholder="Cellphone number"
													/>
													{errors.cell_number && touched.cell_number && (
														<FormElementError>{errors.cell_number}</FormElementError>
													)}
												</FormElementWrapper>
											</>
										)}
									</div>
								</div>
							</div>
							<BottomCTAWrapper>
								<div className="columns">
									<div className="column is-two-thirds">
										<div className="columns is-mobile">
											<div className="column is-one-third"></div>
											<div className="column is-two-thirds ">
												<Button
													disabled={isSubmitting}
													isOutline
													type="submit"
													isLoading={isSubmitting}
												>
													Send OTP
												</Button>
											</div>
										</div>
									</div>
								</div>
							</BottomCTAWrapper>
						</StyledForm>
					);
				}}
			</Formik>
		</StyledCellphoneStepOneSection>
	);
};

export default CellphoneStepOneSection;

/**styled components */
const StyledCellphoneStepOneSection = styled.div`
	height: 100%;
`;

const SubTitle = styled.h3`
	color: #9da7b7;
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 36px;
`;

const StyledForm = styled.form`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
`;
