import authContainer from 'containers/auth';
import client from '../utils/fetch-client';

const path = '/v2/user/digs-alert';

interface Alert {
	search_filter: { [key: string]: any };
	active: boolean;
}

export default {
	create: (alert: Alert) => {
		const payload = alert.search_filter.locations
			? {
					...alert,
					search_filter: {
						...alert.search_filter,
						latitude: undefined,
						longitude: undefined,
						locations: alert.search_filter.locations,
					},
			  }
			: {
					...alert,
					search_filter: {
						...alert.search_filter,
						latitude: undefined,
						longitude: undefined,
					},
			  };

		if (!authContainer.state.token) {
			return Promise.resolve();
		}

		return client.post(`${path}`, {
			body: payload,
		});
	},
};
