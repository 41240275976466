import api from 'api';
import authContainer from 'containers/auth';
import userContainer from 'containers/user';
import NProgress from 'nprogress';
import React from 'react';
import GoogleLogin from 'react-google-login';
import { featureFlagContainer, FeatureFlag, isProd } from 'containers/featureFlags';
import { analyticsService, EventName, LoginMethod } from 'services/analyticsService';

const APP_ID = isProd()
	? '552880343241-32qt59h52c7rmpuili96hdjntfqjtipc'
	: '552880343241-n3888c9rd4857fpnvhkfn9kfctto2ub2';

class GLogin extends React.Component {
	responseGoogle = async response => {
		const { accessToken } = response;
		const { cb } = this.props;
		NProgress.start();
		try {
			console.log(`${isProd()}: ${APP_ID}`);
			if (isProd() || !featureFlagContainer.isEnabled(FeatureFlag.GoogleLoginV2)) {
				var authResponse = await api.auth.google({
					token: accessToken,
				});
			} else {
				var authResponse = await api.auth.googleV2({
					token: accessToken,
				});
			}

			if (authResponse.status === 200) {
				await authContainer.set({
					token: authResponse.data.token,
				});

				const userProfileV2Response = await api.userV2.getProfile();
				await userContainer.set(userProfileV2Response.data);
				analyticsService.trackEvent(EventName.Login, { auth_method: LoginMethod.Google });
				analyticsService.trackEvent(EventName.SetUser, {
					digs_user_id: userProfileV2Response.data.uuid,
				});

				NProgress.done();
				cb();
			}
		} catch (error) {
			console.error(error);
			NProgress.done();
		}
	};

	render() {
		const { button } = this.props;
		return (
			<GoogleLogin
				clientId={`${APP_ID}.apps.googleusercontent.com`}
				onSuccess={this.responseGoogle}
				onFailure={this.responseGoogle}
				render={renderProps => button(renderProps)}
			/>
		);
	}
}

export default GLogin;
