import React from 'react';

import { Modal } from 'lib/Modal';
import { Video } from 'lib/Video';

interface LandlordLandingTestimonyModalProps {
	onClose(): void;
}

const LandlordLandingTestimonyModal = ({ onClose }: LandlordLandingTestimonyModalProps) => {
	return (
		<Modal slideUp onBackgroundClick={onClose}>
			<Video videoUrl="https://www.youtube.com/embed/uZh4BKfEMjE" />
		</Modal>
	);
};

export default LandlordLandingTestimonyModal;
