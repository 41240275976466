import styled from 'styled-components';

export const Title = styled.h1`
	font-weight: bold;
	font-size: 32px;
	text-align: center;
`;

export const SubText = styled.p`
	font-size: 18px;
	margin: 18px 0;
`;

export const ModalContainer = styled.div`
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 350px;
	flex-wrap: wrap;

	button {
		margin-bottom: 16px;
	}
`;
