const format = (n: number, decimals?: boolean): string =>
	(decimals ? (n || 0).toFixed(2) : (n || 0).toString()).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const formatMobileNumber = (regionPrefix: string, number: string, plus?: boolean) =>
	plus ? '+' + regionPrefix + number : regionPrefix + number;

const formatReduced = (num: number) => {
	// Determine the appropriate suffix based on the magnitude of the number
	const suffixes = ['', 'K', 'M', 'B', 'T'];
	if (num === 0) {
		return 0;
	}
	const suffixIndex = Math.floor(Math.log10(num) / 3);
	const suffix = suffixes[suffixIndex];

	// Calculate the truncated value
	const truncated = num / Math.pow(10, suffixIndex * 3);

	// Format the truncated value with the appropriate decimal places
	let formatted = '';
	if (truncated < 10) {
		formatted = truncated.toFixed(1);
	} else {
		formatted = Math.floor(truncated).toString();
	}

	// Concatenate the formatted value and the suffix
	return formatted + suffix;
};
const formatCurrency = (
	n: number,
	currencySymbol: string,
	decimals?: boolean,
	reduced?: boolean,
) => {
	if (reduced) {
		return currencySymbol + formatReduced(n);
	}
	return currencySymbol ? `${currencySymbol} ${format(n, decimals)}` : format(n, decimals);
};

export const numberFormatService = {
	format,
	formatMobileNumber,
	formatCurrency,
};

export default numberFormatService;
