import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';

import { Helmet } from 'react-helmet';
import styled, { keyframes } from 'styled-components';
import { PageMeta } from 'constants/page_meta';
import Header from 'components/common/header';
import { Link, useHistory, useParams } from 'react-router-dom';

import theme from 'config/theme';
import useSearch from 'hooks/useSearch';
import { ReactComponent as EmptyStateIllustrationSvg } from 'assets/images/inbox/inbox-empty-state.svg';
import { ReactComponent as NotFoundIllustrationSvg } from 'assets/illustrations/not-found.svg';
import { ReactComponent as SearchSvg } from 'assets/images/icons/search-outline.svg';
import { ReactComponent as MenuSvg } from 'assets/icons/burger-menu.svg';
import { ReactComponent as ArchiveSvg } from 'assets/icons/archive.svg';
import { ReactComponent as SelectChatSvg } from 'assets/illustrations/inbox-select-conversation.svg';
import { ReactComponent as SearchIconSvg } from 'assets/images/icons/search-outline.svg';
import { ReactComponent as OptionDots } from 'assets/icons/more-vertical-dots.svg';
import { ReactComponent as OptionDotsHorizontal } from 'assets/icons/more-horizontal-dots.svg';
import { ReactComponent as Bin } from 'assets/icons/bin.svg';
import { ReactComponent as HelpSvg } from 'assets/icons/question-outlined.svg';
import { ReactComponent as InfoSvg } from 'assets/icons/info-outlined.svg';
import { ReactComponent as BackArrow } from 'assets/icons/arrow-left.svg';
import { ReactComponent as MoreHorizontalDots } from 'assets/icons/more-horizontal-dots.svg';
import { ReactComponent as DocumentSvg } from 'assets/icons/document.svg';
import { ReactComponent as CrossSvg } from 'assets/icons/cross.svg';
import { ReactComponent as RefreshSvg } from 'assets/icons/refresh.svg';

import Button from 'lib/Button';
import routes from 'config/routes';
import { BREAKPOINT_TABLET_LARGE_PX, BREAKPOINT_TABLET_PX } from 'constants/breakPoints';
import api from 'api';
import { useScreenWidth } from 'hooks/useScreenWidth';
import { InboxType, QueryParamKey } from './types';
import withAuth from 'components/common/with-auth';
import { Loader } from 'lib/Loader';
import {
	Conversation,
	ConversationAction,
	ConversationAttachments,
	ConversationBookings,
	ConversationMessages,
	ConversationStatus,
	PaginatedInbox,
} from 'models/inbox';
import Input from 'lib/Input';
import dateService from 'services/dateService';
import { ReactComponent as FilterSvg } from 'assets/icons/filter.svg';
import { ReactComponent as UnreadSvg } from 'assets/icons/unread.svg';
import { ReactComponent as PinnedSvg } from 'assets/icons/pinned.svg';
import { ReactComponent as UnpinnedSvg } from 'assets/icons/unpin.svg';
import { ReactComponent as EyeSvg } from 'assets/icons/eye.svg';
import { ReactComponent as TickSvg } from 'assets/icons/tick.svg';
import { userProfileService } from 'services/userProfileService';
import modalContainer from 'containers/modal';
import { notifySuccess } from 'lib/Notifications';
import ThreeDotsLoader from 'lib/ThreeDotsLoader';
import { Modal } from 'lib/Modal';
import userContainer from 'containers/user';
import MoreOptionsBottomSheet from './MoreOptionsBottomSheet';
import CancelEnquiryModal from './CancelEnquiryModal';
import ReportConversationModal from './ReportConversationModal';
import ChatPanel from './ChatPanel';
import HelpModal from './HelpModal';
import FilterModal from './FilterModal';
import RightPanel from './RightPanel';
import { TopBar } from 'lib/TopBar';
import { ModalType } from 'constants/modalTypes';
import useRouter from 'hooks/useRouter';
import ConfirmPlacementModal from './ConfirmPlacementModal';
import { Property } from 'models/property';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';

const { colors, headerHeight } = theme;

const headerMobileHeight = 45;

const meta = PageMeta.getStatic('inbox');

const Inbox = () => {
	const { getParam } = useSearch();
	const { id } = useParams<{ id?: string }>();
	const [inboxType, setInboxType] = useState<InboxType>(
		(getParam(QueryParamKey.Type) as InboxType) || InboxType.Unknown,
	);
	const [showArchived, setShowArchived] = useState(false);
	const [inbox, setInbox] = useState<PaginatedInbox>();
	const [isLoading, setIsLoading] = useState(true);
	const screenWidth = useScreenWidth();
	const [search, setSearch] = useState('');
	const [showUnread, setShowUnread] = useState(false);
	const [openMenuId, setOpenMenuId] = useState('');
	const [remainingBookingSlots, setRemainingBookingSlots] = useState();
	const [selectedMoreChat, setSelectedMoreChat] = useState<Conversation>();
	const [selectedReportChat, setSelectedReportChat] = useState<Conversation>();
	const [selectedCancelChat, setSelectedCancelChat] = useState<Conversation>();
	const [pageNumber, setPageNumber] = useState(1);
	const scrollableDivRef = useRef<HTMLDivElement>(null);
	const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);
	const [selectedChat, setSelectedChat] = useState<Conversation | null>();
	const [selectedChatMessages, setSelectedChatMessages] = useState<ConversationMessages>();
	const [selectedChatBookings, setSelectedChatBookings] = useState<ConversationBookings>();
	const [selectedChatAttachments, setSelectedChatAttachments] = useState<ConversationAttachments>();
	const [isLoadingChatMessages, setIsLoadingChatMessages] = useState<boolean>(false);
	const [isLoadingNextMessagesPage, setIsLoadingNextMessagesPage] = useState<boolean>(false);
	const [showHelpModal, setShowHelpModal] = useState(false);
	const [showFiltersModal, setShowFiltersModal] = useState(false);
	const [showRightPanel, setShowRightPanel] = useState(true);
	const [activeFilters, setActiveFilters] = useState<string[][]>([]);
	const [showMobileDetails, setShowMobileDetails] = useState(false);
	const [isProfileLocked, setIsProfileLocked] = useState(false);
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [properties, setProperties] = useState<Property[]>([]);
	const [propertiesDictionary, setPropertiesDictionary] = useState<{ [key: string]: Property }>({});
	const [goTo] = useRouter();
	const [showMenu, setShowMenu] = useState(false);
	const [refreshing, setRefreshing] = useState(false);
	const history = useHistory();

	const isDesktop = screenWidth > BREAKPOINT_TABLET_PX;
	const isMobile = !isDesktop;

	useEffect(() => {
		if (!featureFlagContainer.isEnabled(FeatureFlag.InboxAllowIncompleteProfile)) {
			api.userV2
				.getProfile()
				.then(res => res.data)
				.then(data => {
					if (!userProfileService.isComplete(data)) {
						setIsProfileLocked(true);
					}
				});
		}

		api.property
			.getPropertiesV2()
			.then(response => response.data)
			.then(userProperties => {
				setProperties(userProperties.filter(property => property.complete));

				if (!getParam(QueryParamKey.Type)) {
					const hasProperties = !!userProperties && Boolean(userProperties.length);
					setInboxType(hasProperties ? InboxType.Landlord : InboxType.Tenant);
				}
			});
	}, []);

	useEffect(() => {
		setPropertiesDictionary(
			properties.reduce(
				(acc, val) => ({
					...acc,
					[val.uuid as string]: val,
				}),
				{},
			),
		);
	}, [properties]);

	useEffect(() => {
		if (showArchived) {
			setShowUnread(false);
		}
	}, [showArchived]);

	useEffect(() => {
		if (!selectedChat) {
			setSelectedChatMessages(undefined);
			setShowRightPanel(true);
			if (selectedChat === null) {
				history.replace(`${routes.inbox}`);
			}
			return;
		}

		history.replace(`${routes.inbox}/${selectedChat.uuid}`);
		setSelectedChatBookings(undefined);
		setSelectedChatAttachments(undefined);
		setIsLoadingChatMessages(true);
		api.inbox
			.getConversationMessages(selectedChat.uuid)
			.then(res => res.data)
			.then(chatMessages => {
				setSelectedChatMessages(chatMessages);
				setIsLoadingChatMessages(false);
			});

		api.inbox
			.getInboxBookings(selectedChat.uuid)
			.then(res => res.data)
			.then(data => {
				setSelectedChatBookings(data);
			});

		api.inbox
			.getInboxAttachments(selectedChat.uuid)
			.then(res => res.data)
			.then(data => {
				setSelectedChatAttachments(data);
			});

		// mark selected message as read
		setInbox(
			prev =>
				prev && {
					...prev,
					results: prev?.results.map(item =>
						item.uuid === selectedChat.uuid
							? {
									...item,
									unread_messages: 0,
							  }
							: item,
					),
				},
		);
	}, [selectedChat, history]);

	useEffect(() => {
		if (inboxType === InboxType.Unknown) {
			return;
		}
		setIsLoading(true);
		if (inboxType === InboxType.Tenant) {
			api.inbox
				.getTenantInbox(false, '', false, 1, activeFilters)
				.then(res => res.data)
				.then(inbox => {
					setInbox(inbox);
					setIsLoading(false);
					if (id) {
						const chat = inbox.results.find(item => item.uuid === id);
						if (chat) {
							setSelectedChat(chat);
							return;
						}
					}
					if (getParam(QueryParamKey.OpenFirstMessage) === 'true' && inbox.results.length > 0) {
						setSelectedChat(inbox.results[0]);
					}
				});

			api.userV2
				.getBookingProfile()
				.then(res => res.data)
				.then(payload => {
					setRemainingBookingSlots(payload.booking_requests_remaining);
				});

			return;
		}
		if (inboxType === InboxType.Landlord) {
			api.inbox
				.getLandlordInbox(false, '', false, 1, activeFilters)
				.then(res => res.data)
				.then(inbox => {
					setInbox({ ...inbox });
					setIsLoading(false);
					if (id) {
						const chat = inbox.results.find(item => item.uuid === id);
						if (chat) {
							setSelectedChat(chat);
							return;
						}
					}
					if (getParam(QueryParamKey.OpenFirstMessage) === 'true' && inbox.results.length > 0) {
						setSelectedChat(inbox.results[0]);
					}
				});
		}
	}, [inboxType, activeFilters]);

	useEffect(() => {
		handleInboxReload();
	}, [showArchived, search, showUnread]);

	useEffect(() => {
		const handleScroll = () => {
			if (isLoadingNextPage) {
				return;
			}
			const scrollableDiv = scrollableDivRef.current;
			if (scrollableDiv) {
				const { scrollTop, scrollHeight, clientHeight } = scrollableDiv;
				const distanceToBottom = scrollHeight - scrollTop - clientHeight;
				if (distanceToBottom < 100) {
					handleFetchNextPage();
				}
			}
		};

		const scrollableDiv = scrollableDivRef.current;

		if (!scrollableDiv) {
			return;
		}

		scrollableDiv.addEventListener('scroll', handleScroll);

		return () => {
			scrollableDiv.removeEventListener('scroll', handleScroll);
		};
	}, [scrollableDivRef.current, isLoadingNextPage]);

	const handleCompleteProfileClick = () => {
		modalContainer.set(ModalType.ProfileSetup, {
			onComplete: () => {
				setIsProfileLocked(false);
				modalContainer.set('');
				notifySuccess('Profile updated');
			},
		});
	};

	const handleInboxReload = (showLoader: boolean = true, callback?: () => void) => {
		if (!inboxType) {
			return;
		}
		if (showLoader) {
			setIsLoading(true);
		}
		setPageNumber(1);
		if (selectedChat) {
			setSelectedChat({ ...selectedChat });
		}
		if (inboxType === InboxType.Landlord) {
			api.inbox
				.getLandlordInbox(showArchived, search, showUnread, 1, activeFilters)
				.then(res => res.data)
				.then(inbox => {
					setInbox({ ...inbox });
					setIsLoading(false);
					callback && callback();
				});
			return;
		}
		if (inboxType === InboxType.Tenant) {
			api.inbox
				.getTenantInbox(showArchived, search, showUnread, 1, activeFilters)
				.then(res => res.data)
				.then(inbox => {
					setInbox(inbox);
					setIsLoading(false);
					callback && callback();
				});
		}
	};

	const handleFetchNextPage = () => {
		if (!inbox?.next || !inboxType) {
			return;
		}
		setIsLoadingNextPage(true);
		if (inboxType === InboxType.Landlord) {
			api.inbox
				.getLandlordInbox(showArchived, search, showUnread, pageNumber + 1, activeFilters)
				.then(res => res.data)
				.then(inbox => {
					setInbox(
						prev =>
							prev && {
								...prev,
								next: inbox.next,
								results: [...prev.results, ...inbox.results].filter(
									(item, i, arr) => i === arr.findIndex(x => x.uuid === item.uuid),
								),
							},
					);
					setPageNumber(pageNumber + 1);
					setIsLoadingNextPage(false);
				})
				.catch(() => {
					setIsLoadingNextPage(false);
				});
			return;
		}
		if (inboxType === InboxType.Tenant) {
			api.inbox
				.getTenantInbox(showArchived, search, showUnread, pageNumber + 1, activeFilters)
				.then(res => res.data)
				.then(inbox => {
					setInbox(
						prev =>
							prev && {
								...prev,
								next: inbox.next,
								results: [...prev.results, ...inbox.results].filter(
									(item, i, arr) => i === arr.findIndex(x => x.uuid === item.uuid),
								),
							},
					);
					setPageNumber(pageNumber + 1);
					setIsLoadingNextPage(false);
				})
				.catch(() => {
					setIsLoadingNextPage(false);
				});
		}
	};

	const handleConversationAction = async (chat: Conversation, action: ConversationAction) => {
		await api.inbox.executeAction(chat.uuid, action);
		if (action === ConversationAction.Pin) {
			notifySuccess('Conversation pinned');
		}
		if (action === ConversationAction.Unpin) {
			notifySuccess('Conversation unpinned');
		}
		if (action === ConversationAction.Archive) {
			notifySuccess('Conversation archived');
		}
		if (action === ConversationAction.Restore) {
			notifySuccess('Conversation restored');
		}
		setOpenMenuId('');
		handleInboxReload(false);
	};

	const handleReportConversation = (chat: Conversation) => {
		setSelectedReportChat(chat);
	};

	const handleCancelConversation = (chat: Conversation) => {
		setSelectedCancelChat(chat);
	};

	const handleConfirmPlacement = (chat: Conversation) => {
		setShowConfirmModal(true);
	};

	const handleSendMessage = async (message: string) => {
		if (!selectedChat) {
			return;
		}

		await api.inbox.postConversationMessage(selectedChat.uuid, message);
		const res = await api.inbox.getConversationMessages(selectedChat.uuid);
		setSelectedChatMessages(
			prev =>
				prev && {
					...prev,
					messages: {
						...prev.messages,
						results: [
							...res.data.messages.results.slice(
								0,
								res.data.messages.results.findIndex(item => item.uuid === prev.messages.last_uuid),
							),
							...prev.messages.results,
						].filter((x, i, arr) => arr.findIndex(item => item.uuid === x.uuid) === i),
					},
				},
		);
	};

	const handleFileUpload = async (file: File) => {
		if (!selectedChat) {
			return;
		}

		await api.inbox.uploadConversationFile(selectedChat.uuid, file);
		const res = await api.inbox.getConversationMessages(selectedChat.uuid);
		setSelectedChatMessages(
			prev =>
				prev && {
					...prev,
					messages: {
						...prev.messages,
						results: [
							...res.data.messages.results.slice(
								0,
								res.data.messages.results.findIndex(item => item.uuid === prev.messages.last_uuid),
							),
							...prev.messages.results,
						].filter((x, i, arr) => arr.findIndex(item => item.uuid === x.uuid) === i),
					},
				},
		);
	};

	const handleLoadNextMessagesPage = async () => {
		if (!selectedChat || !selectedChatMessages) {
			return;
		}
		if (selectedChatMessages.messages.num_pages <= selectedChatMessages.messages.current_page) {
			return;
		}

		const nextPage = selectedChatMessages.messages.current_page + 1;
		setIsLoadingNextMessagesPage(true);
		const res = await api.inbox.getConversationMessages(
			selectedChat.uuid,
			nextPage,
			selectedChatMessages.messages.last_uuid,
		);
		setIsLoadingNextMessagesPage(false);
		setSelectedChatMessages(
			prev =>
				prev && {
					...prev,
					messages: {
						...prev.messages,
						current_page: nextPage,
						results: [...prev.messages.results, ...res.data.messages.results].filter(
							(x, i, arr) => arr.findIndex(item => item.uuid === x.uuid) === i,
						),
					},
				},
		);
	};

	const handleMenuToggle = () => {
		setShowMenu(prev => !prev);
	};

	const handleRefreshClick = () => {
		setRefreshing(true);
		handleInboxReload(false, () => {
			setRefreshing(false);
		});
	};

	const getTitle = (inboxType: string) => {
		if (inboxType === InboxType.Tenant) {
			return 'Tenant';
		}
		if (inboxType === InboxType.Landlord) {
			return 'Landlord';
		}
		return '';
	};

	const getColor = (status: ConversationStatus) => {
		if (status === ConversationStatus.Pending) {
			return colors.orange;
		}
		if (status === ConversationStatus.Connected) {
			return colors.darkTurquoise;
		}
		if (status === ConversationStatus.Confirmed) {
			return colors.brightBlue;
		}
		if (status === ConversationStatus.Cancelled) {
			return colors.grey60;
		}
		if (status === ConversationStatus.Expired) {
			return colors.grey60;
		}
		return colors.darkBlue;
	};

	const getPlaceholder = () => {
		if (showArchived) {
			return 'Search archived';
		}
		if (showUnread) {
			return 'Search unread';
		}
		return 'Search';
	};

	return (
		<InboxContainer>
			<Header mobilePopupOnly isMobileMenuOpen={showMenu} onToggleMobileMenu={handleMenuToggle} />
			<Container>
				<Helmet>
					<title>{meta.title}</title>
					<meta name="description" content={meta.desc} />
				</Helmet>
				{isDesktop && <Header searchable />}
				{isLoading && !inbox?.results.length ? (
					<LoaderContainer>
						<Loader />
					</LoaderContainer>
				) : (
					<PageContainer>
						<MobileMenuContainer>
							{selectedChat ? (
								<>
									<RowFlexStart>
										<BackArrow
											style={{
												minWidth: 24,
												width: 24,
												height: 24,
											}}
											onClick={() => setSelectedChat(null)}
										/>
										<HeaderText
											style={{
												flex: 1,
												textOverflow: 'ellipsis',
												overflow: 'hidden',
												whiteSpace: 'nowrap',
											}}
										>
											{selectedChat.users
												.slice(0, selectedChat.users.length - 1)
												.map(user => user.first_name)
												.join(', ') +
												` & ${selectedChat.users[selectedChat.users.length - 1].first_name}`}
										</HeaderText>
										<MoreHorizontalDots
											style={{
												minWidth: 24,
												width: 24,
											}}
											onClick={() => setSelectedMoreChat(selectedChat)}
										/>
									</RowFlexStart>
								</>
							) : (
								<>
									<HeaderText>
										{getTitle(inboxType || '')} inbox{showArchived && ' - Archived'} ({inbox?.count}
										)
									</HeaderText>
									<RowFlexEnd>
										<RefreshIcon
											isRotating={refreshing}
											style={{ cursor: 'pointer' }}
											onClick={handleRefreshClick}
										/>
										<IconButtonContainer
											selected={showArchived}
											style={{ marginRight: 4 }}
											onClick={() => setShowArchived(prev => !prev)}
										>
											<ArchiveIcon selected={showArchived} />
										</IconButtonContainer>
										<IconButtonContainer onClick={() => handleMenuToggle()}>
											<MenuIcon />
										</IconButtonContainer>
									</RowFlexEnd>
								</>
							)}
						</MobileMenuContainer>
						{
							<PanelsContainer>
								{(isDesktop || (isMobile && !selectedChat)) && (
									<LeftPanel>
										{isDesktop && (
											<LeftPanelHeader>
												<HeaderText>
													{getTitle(inboxType || '')} inbox{showArchived && ' - Archived'} (
													{inbox?.count})
												</HeaderText>
												<RowFlexEnd>
													<IconButtonContainer onClick={handleRefreshClick}>
														<PopupContainer>
															<RefreshIcon isRotating={refreshing} style={{ cursor: 'pointer' }} />
															<Popup>Refresh</Popup>
														</PopupContainer>
													</IconButtonContainer>
													<IconButtonContainer
														selected={showArchived}
														onClick={() => setShowArchived(prev => !prev)}
													>
														<PopupContainer>
															<ArchiveIcon selected={showArchived} />
															<Popup>{showArchived ? 'Hide archived' : 'Show archived'}</Popup>
														</PopupContainer>
													</IconButtonContainer>
												</RowFlexEnd>
											</LeftPanelHeader>
										)}
										<ConversationsContainer
											style={isProfileLocked ? { overflow: 'hidden' } : undefined}
											ref={scrollableDivRef}
										>
											{isProfileLocked && <BackdropBlur />}
											{isProfileLocked && isMobile && (
												<FloatingBottomButtonContainer>
													<Button maxWidth={240} onClick={handleCompleteProfileClick}>
														Complete profile to unlock
													</Button>
												</FloatingBottomButtonContainer>
											)}
											<SearchBarRow>
												<SearchBarContainer>
													<Input
														prefixIcon={
															<SearchIconSvg
																style={{ width: 24, fill: colors.grey60, marginRight: 8 }}
															/>
														}
														value={search}
														placeholder={getPlaceholder()}
														hideErrorMargin
														onChange={value => setSearch(value.toString())}
													/>
												</SearchBarContainer>
												{!showArchived && (
													<>
														<IconButtonContainer
															isPink
															selected={showUnread}
															onClick={() => setShowUnread(prev => !prev)}
														>
															<PopupContainer>
																<UnreadSvg
																	style={showUnread ? { fill: colors.white } : undefined}
																/>
																<Popup>{showUnread ? 'Hide unread' : 'Show unread'}</Popup>
															</PopupContainer>
														</IconButtonContainer>
														<IconButtonContainer onClick={() => setShowFiltersModal(prev => !prev)}>
															<PopupContainer>
																<FilterSvg />
																<Popup>Filters</Popup>
															</PopupContainer>
															{Boolean(activeFilters.length) && (
																<FiltersActiveCount>{activeFilters.length || 0}</FiltersActiveCount>
															)}
														</IconButtonContainer>
													</>
												)}
											</SearchBarRow>
											{remainingBookingSlots === 0 && inboxType === InboxType.Tenant && (
												<TenantWarningBox>
													<HelpSvg style={{ fill: colors.red, minWidth: 24, marginRight: 16 }} />
													<div>
														You’ve reached your maximum active enquiries. Cancel any conversations
														that you are no longer interested in.
													</div>
												</TenantWarningBox>
											)}
											{Boolean(inbox?.results.length) && isLoading ? (
												<LoaderContainer>
													<ThreeDotsLoader />
												</LoaderContainer>
											) : (
												<>
													{inbox?.results.map(chat => (
														<ConversationRowItem
															chat={chat}
															key={chat.uuid}
															isMobile={!isDesktop}
															inboxType={inboxType || InboxType.Tenant}
															onAction={action => handleConversationAction(chat, action)}
															onMoreClick={() => setSelectedMoreChat(chat)}
															onCancel={() => handleCancelConversation(chat)}
															onReport={() => handleReportConversation(chat)}
														>
															<InboxChatContainer>
																<InboxChatSelectable
																	onClick={() => {
																		setSelectedChat(chat);
																	}}
																	selected={chat.uuid === selectedChat?.uuid}
																>
																	<InboxChatIconColumn>
																		<ProfilePictureIcon
																			src={chat.other_users[0].profile_picture.url}
																		/>
																		{chat.pinned && <PinnedSvg />}
																	</InboxChatIconColumn>
																	<InboxChatRow>
																		<InboxChatMiddleSection>
																			<InboxChatText bold={chat.unread_messages > 0}>
																				{chat.other_users[0].first_name}{' '}
																				{chat.other_users[0].last_name}
																			</InboxChatText>
																			<InboxChatText small>{chat.property_title}</InboxChatText>
																			{chat.latest_message && (
																				<InboxChatText bold={chat.unread_messages > 0}>
																					{chat.latest_message.message}
																				</InboxChatText>
																			)}
																			<InboxChatText bold small color={getColor(chat.status)}>
																				{chat.status_readable}
																			</InboxChatText>
																		</InboxChatMiddleSection>
																		<InboxChatRightSection>
																			<InboxChatDateDisplay>
																				{dateService.formatForDisplay(
																					chat.latest_message?.date_created || '',
																					true,
																				)}
																			</InboxChatDateDisplay>
																			{isDesktop && (
																				<div style={{ position: 'relative' }}>
																					<OptionDotsIcon
																						style={{ height: 24, marginRight: 4 }}
																						onClick={e => {
																							e.preventDefault();
																							e.stopPropagation();
																							setOpenMenuId(prev =>
																								prev === chat.uuid ? '' : chat.uuid,
																							);
																						}}
																					/>
																					{openMenuId === chat.uuid && (
																						<ConversationPopupMenu
																							chat={chat}
																							inboxType={
																								(inboxType || InboxType.Tenant) as InboxType
																							}
																							onClose={() => setOpenMenuId('')}
																							onAction={action =>
																								handleConversationAction(chat, action)
																							}
																							onReport={() => handleReportConversation(chat)}
																							onCancel={() => handleCancelConversation(chat)}
																						/>
																					)}
																				</div>
																			)}
																		</InboxChatRightSection>
																	</InboxChatRow>
																	{Boolean(chat.unread_messages) && (
																		<InboxChatMessageCount>
																			{chat.unread_messages}
																		</InboxChatMessageCount>
																	)}
																</InboxChatSelectable>
															</InboxChatContainer>
														</ConversationRowItem>
													))}
												</>
											)}
										</ConversationsContainer>
									</LeftPanel>
								)}
								{((isMobile && !!selectedChat) || isDesktop) && (
									<CenterPanel>
										{isProfileLocked ? (
											<>
												<EmptyStateContainer>
													<NotFoundIllustrationSvg />
													<EmptyStateText>
														In order to view your inbox, we just need a few last details from you
													</EmptyStateText>
													<Button onClick={handleCompleteProfileClick}>
														Complete profile to unlock
													</Button>
												</EmptyStateContainer>
											</>
										) : (
											<>
												{Boolean(inbox?.results.length) || showUnread || search ? (
													<>
														{!!selectedChat ? (
															<SelectedChatContainer>
																<ChatPanel
																	myId={userContainer.state.v2.uuid}
																	myInboxType={inboxType}
																	chat={selectedChat}
																	chatMessages={selectedChatMessages}
																	isLoading={isLoadingChatMessages}
																	isExpanded={!showRightPanel}
																	isLoadingNextPage={isLoadingNextMessagesPage}
																	onFileUpload={handleFileUpload}
																	contextMenuElement={
																		isMobile && (
																			<ChatContextMenu>
																				<RowFlexSpaceBetween>
																					<InboxChatText style={{ whiteSpace: 'nowrap' }}>
																						{selectedChat.property_title}
																					</InboxChatText>
																					<InboxChatStatus
																						bold
																						small
																						color={getColor(selectedChat.status)}
																					>
																						{selectedChat.status_readable}
																					</InboxChatStatus>
																				</RowFlexSpaceBetween>
																				<ChatContextScrollActions>
																					<InboxChatContextAction
																						onClick={() => {
																							setShowMobileDetails(true);
																						}}
																					>
																						<EyeSvg style={{ width: 16, marginRight: 8 }} />
																						View details
																					</InboxChatContextAction>

																					{selectedChat.status === ConversationStatus.Connected && (
																						<>
																							{inboxType === InboxType.Landlord && (
																								<InboxChatContextAction
																									outlined
																									onClick={() =>
																										handleConfirmPlacement(selectedChat)
																									}
																								>
																									<TickSvg
																										style={{
																											width: 16,
																											marginRight: 8,
																											stroke: colors.darkBlue,
																										}}
																									/>
																									Confirm placement
																								</InboxChatContextAction>
																							)}
																							<InboxChatContextAction
																								outlined
																								onClick={() =>
																									handleCancelConversation(selectedChat)
																								}
																							>
																								<CrossSvg
																									style={{
																										width: 8,
																										marginRight: 8,
																									}}
																								/>
																								Cancel enquiry
																							</InboxChatContextAction>
																						</>
																					)}
																					{inboxType === InboxType.Tenant &&
																						selectedChat.status === ConversationStatus.Pending && (
																							<InboxChatContextAction
																								outlined
																								onClick={() =>
																									handleCancelConversation(selectedChat)
																								}
																							>
																								<CrossSvg
																									style={{
																										width: 8,
																										marginRight: 8,
																									}}
																								/>
																								Cancel enquiry
																							</InboxChatContextAction>
																						)}
																				</ChatContextScrollActions>
																			</ChatContextMenu>
																		)
																	}
																	onHelpClick={() => setShowHelpModal(true)}
																	onReportClick={() => setSelectedReportChat(selectedChat)}
																	onExpandToggle={() => setShowRightPanel(prev => !prev)}
																	onSendMessage={handleSendMessage}
																	onNextPageRequest={handleLoadNextMessagesPage}
																/>
																{showRightPanel && (
																	<DesktopRightPanelContainer>
																		<RightPanel
																			bookings={selectedChatBookings}
																			attachments={selectedChatAttachments}
																			inboxType={inboxType}
																			chat={selectedChat}
																			onCancel={() => handleCancelConversation(selectedChat)}
																			onConfirm={() => handleConfirmPlacement(selectedChat)}
																			propertyDictionary={propertiesDictionary}
																		/>
																	</DesktopRightPanelContainer>
																)}
															</SelectedChatContainer>
														) : (
															<UnselectedChatContainer>
																<SelectChatSvg style={{ marginBottom: -16 }} />
																<p>Select one of your conversations to view your chat</p>
															</UnselectedChatContainer>
														)}
													</>
												) : (
													<EmptyStateContainer>
														<EmptyStateIllustration />
														{showArchived ? (
															<>
																<EmptyStateTitle>Archived</EmptyStateTitle>
																<EmptyStateText>
																	This is a list of all your archived conversations. When you
																	archive a conversation, you’ll find it here.
																</EmptyStateText>
																<Button isOutline onClick={() => setShowArchived(false)}>
																	Return to active inbox
																</Button>
															</>
														) : (
															<>
																{inboxType === InboxType.Tenant && (
																	<>
																		<EmptyStateTitle>Tenant inbox</EmptyStateTitle>
																		<EmptyStateText>
																			Your tenant inbox is currently empty. Send enquiries to
																			listings, and you’ll find your conversations with the
																			landlords here.
																		</EmptyStateText>
																		<MarginBottom>
																			<Button link={routes.digs_search}>
																				<>
																					<SearchIcon /> Search listings
																				</>
																			</Button>
																		</MarginBottom>
																		<EmptyStateBottomText>
																			Have a property you want to list?{' '}
																			<LinkText to={routes.create_listing}>
																				Create your listing
																			</LinkText>
																		</EmptyStateBottomText>
																	</>
																)}
																{inboxType === InboxType.Landlord && (
																	<>
																		<EmptyStateTitle>Landord inbox</EmptyStateTitle>
																		<EmptyStateText>
																			Your landlord inbox is currently empty. Enquiries to your
																			listings from potential tenants will appear here. Check back
																			later.
																		</EmptyStateText>
																		<EmptyStateBottomText>
																			Want to change something?{' '}
																			<LinkText to={routes.manage_listing}>
																				Manage your listings
																			</LinkText>
																		</EmptyStateBottomText>
																	</>
																)}
															</>
														)}
													</EmptyStateContainer>
												)}
											</>
										)}
									</CenterPanel>
								)}
							</PanelsContainer>
						}
					</PageContainer>
				)}
			</Container>
			{selectedChat && showMobileDetails && (
				<Modal onBackgroundClick={() => setShowMobileDetails(false)}>
					<TopBar title="Enquiry" closeOnRight onClose={() => setShowMobileDetails(false)} />
					<ScrollableContainer>
						<RightPanel
							bookings={selectedChatBookings}
							attachments={selectedChatAttachments}
							inboxType={inboxType}
							onCancel={() => handleCancelConversation(selectedChat)}
							onConfirm={() => handleConfirmPlacement(selectedChat)}
							chat={selectedChat}
							propertyDictionary={propertiesDictionary}
						/>
					</ScrollableContainer>
				</Modal>
			)}
			{selectedMoreChat && (
				<Modal mobileBottomsheet onBackgroundClick={() => setSelectedMoreChat(undefined)}>
					<MoreOptionsBottomSheet
						chat={selectedMoreChat}
						onClose={() => setSelectedMoreChat(undefined)}
						onAction={action => {
							handleConversationAction(selectedMoreChat, action);
							setSelectedMoreChat(undefined);
						}}
						onReport={() => {
							setSelectedMoreChat(undefined);
							handleReportConversation(selectedMoreChat);
						}}
					/>
				</Modal>
			)}
			{selectedCancelChat && (
				<CancelEnquiryModal
					inboxType={inboxType as InboxType}
					chat={selectedCancelChat}
					onClose={() => setSelectedCancelChat(undefined)}
					onSuccess={() => handleInboxReload()}
				/>
			)}
			{selectedChat && showConfirmModal && (
				<ConfirmPlacementModal
					inboxType={inboxType as InboxType}
					chat={selectedChat}
					booking={selectedChatBookings}
					onClose={() => setShowConfirmModal(false)}
					onSuccess={() => handleInboxReload()}
					completedProperties={properties}
				/>
			)}
			{selectedReportChat && (
				<ReportConversationModal
					chat={selectedReportChat}
					onClose={() => setSelectedReportChat(undefined)}
					onSuccess={() => {}}
				/>
			)}
			{showHelpModal && (
				<HelpModal
					onClose={() => {
						setShowHelpModal(false);
					}}
				/>
			)}
			{showFiltersModal && (
				<FilterModal
					inboxType={inboxType}
					filters={activeFilters}
					onClose={() => {
						setShowFiltersModal(false);
					}}
					onApply={filters => {
						setActiveFilters(filters);
					}}
				/>
			)}
		</InboxContainer>
	);
};

export default withAuth(Inbox);

const InboxContainer = styled.main`
	height: 100vh;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	padding-top: ${headerMobileHeight}px;

	@media (min-width: ${BREAKPOINT_TABLET_PX}px) {
		padding-top: ${headerHeight};
	}
`;

const PageContainer = styled.div`
	border-top: 1px solid ${colors.grey20};
	flex-grow: 1;
	display: flex;
	height: 100%;
`;

const EmptyStateContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	text-align: center;
	padding-top: 24px;
	padding: 16px;
	width: 100%;
`;

const EmptyStateText = styled.div`
	color: ${colors.grey60};
	max-width: 400px;
	margin-bottom: 16px;
`;

const EmptyStateTitle = styled.div`
	font-size: 20px;
	margin-bottom: 16px;
`;

const EmptyStateBottomText = styled(EmptyStateText)`
	color: ${colors.darkBlue};
	position: fixed;
	line-height: 20px;
	margin-bottom: 0;
	bottom: 16px;
	padding: 0 16px;
	max-width: unset;

	@media (min-width: ${BREAKPOINT_TABLET_PX}px) {
		bottom: 24px;
	}
`;

const LinkText = styled(Link)`
	font-weight: 600;
	text-decoration: underline;
	color: ${colors.darkBlue};
	white-space: nowrap;
`;

const SearchIcon = styled(SearchSvg)`
	fill: ${colors.white};
	width: 24px;
	margin-right: 8px;
`;

const MarginBottom = styled.div`
	margin-bottom: 16px;
	width: 100%;
`;

const EmptyStateIllustration = styled(EmptyStateIllustrationSvg)`
	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		height: 180px;
	}
`;

const MobileMenuContainer = styled.div`
	position: fixed;
	height: ${headerMobileHeight}px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 100;
	top: 0;
	left: 0;
	right: 0;
	padding: 0 16px;
	background: ${colors.white};

	@media (min-width: ${BREAKPOINT_TABLET_PX}px) {
		display: none;
	}
`;

const HeaderText = styled.div`
	font-weight: 600;
`;

const RowFlexEnd = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 12px;
`;

const RowFlexStart = styled(RowFlexEnd)`
	justify-content: flex-start;
	width: 100%;
`;

const RowFlexSpaceBetween = styled(RowFlexEnd)`
	justify-content: space-between;
`;

const MenuIcon = styled(MenuSvg)`
	cursor: pointer;
`;

const ArchiveIcon = styled(ArchiveSvg)<{ selected?: boolean }>`
	fill: ${({ selected }) => (selected ? colors.white : colors.darkBlue)};
	cursor: pointer;
`;

const IconButtonContainer = styled.div<{ isPink?: boolean; selected?: boolean }>`
	padding: 4px;
	background: ${({ selected, isPink }) =>
		selected ? (isPink ? colors.pink : colors.darkTurquoise) : colors.white};
	border-radius: 80px;
	width: 32px;
	height: 32px;
	min-width: 32px;
	max-width: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	position: relative;
`;

const InboxChatIconColumn = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	gap: 8px;
`;

const LoaderContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 24vh;
`;

const PanelsContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
`;

const LeftPanel = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	height: 100%;

	@media (min-width: ${BREAKPOINT_TABLET_PX}px) {
		width: 360px;
		min-width: 360px;
		border-right: 1px solid ${colors.grey20};
	}
`;

const LeftPanelHeader = styled.div`
	padding: 16px 24px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const CenterPanel = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	flex-direction: column;
	width: 100%;

	@media (min-width: ${BREAKPOINT_TABLET_PX}px) {
		width: calc(100% - 360px);
		min-width: 360px;
		border-right: 1px solid ${colors.grey20};
	}
`;

const SelectedChatContainer = styled.div`
	display: flex;
	flex: 1;
	width: 100%;
	height: 100%;
`;

const UnselectedChatContainer = styled.div`
	padding: 16px;
	width: 100%;
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;
	flex-direction: column;
`;

const ConversationsContainer = styled.div`
	flex-grow: 1;
	width: 100%;
	padding: 24px 0;
	overflow-y: auto;
	position: relative;

	@media (min-width: ${BREAKPOINT_TABLET_LARGE_PX}px) {
		border-top: 1px solid ${colors.grey20};
	}

	::-webkit-scrollbar-thumb {
		background: transparent;
	}

	:hover {
		::-webkit-scrollbar-thumb {
			background: ${colors.scrollbar};
		}
	}
`;

const OptionDotsIcon = styled(OptionDots)`
	visibility: hidden;
	cursor: pointer;
	:hover {
		cursor: pointer;
	}
`;

const OptionDotsMenuContainer = styled.div`
	position: absolute;
	top: calc(100% + 4px);
	right: 0;
	z-index: 1000;
	height: 100px;
`;

const OptionDotsMenu = styled.div`
	border-radius: 8px;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
	background: ${colors.white};
`;

const OptionDotsMenuItem = styled.div<{ color?: string }>`
	width: 100%;
	padding: 8px 16px;
	font-size: 14px;
	line-height: 21px;
	cursor: pointer;
	white-space: nowrap;
	color: ${({ color }) => color || colors.darkBlue};

	:hover {
		background: ${colors.grey05};
	}
`;

const InboxChatContainer = styled.div`
	padding: 0;
	display: flex;
	gap: 8px;
	width: 100vw;
	min-width: 100vw;
	justify-content: flex-start;
	align-items: flex-start;
	border-bottom: 1px solid ${colors.grey10};
	position: relative;

	&:hover ${OptionDotsIcon} {
		visibility: visible;
	}

	@media (min-width: ${BREAKPOINT_TABLET_PX}px) {
		width: unset;
		min-width: unset;
	}
`;

const InboxChatSelectable = styled.div<{ selected?: boolean }>`
	padding: 16px;
	display: flex;
	gap: 8px;
	width: 100%;
	justify-content: flex-start;
	align-items: flex-start;
	position: relative;
	border-radius: 0;

	@media (min-width: ${BREAKPOINT_TABLET_PX}px) {
		cursor: pointer;
		background: ${({ selected }) => (selected ? colors.grey05 : colors.white)};
	}
`;

const InboxChatWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
	flex-wrap: nowrap;
	overflow-x: auto;
	scrollbar-width: 0;
	scrollbar-color: transparent transparent;

	::-webkit-scrollbar {
		width: 0;
		height: 0;
		background-color: transparent;
	}

	::-webkit-scrollbar-thumb {
		background-color: transparent;
	}
`;

const InboxChatMobileAction = styled.div<{ background?: string }>`
	width: 90px;
	min-width: 90px;
	color: ${({ background }) => (background ? colors.white : colors.darkBlue)};
	background: ${({ background }) => (background ? background : colors.grey05)};
	display: flex;
	flex-direction: column;
	gap: 16px;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	font-weight: 600;
`;

const InboxChatMessageCount = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${colors.pink};
	color: ${colors.white};
	font-weight: 600;
	width: 20px;
	height: 20px;
	border-radius: 16px;
	position: absolute;
	bottom: 16px;
	right: 24px;
	font-size: 12px;
`;

const FiltersActiveCount = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background: ${colors.darkBlue};
	color: ${colors.white};
	font-weight: 600;
	width: 16px;
	height: 16px;
	border-radius: 16px;
	position: absolute;
	right: 24px;
	font-size: 12px;
	bottom: 0;
	left: -4px;
`;

const ProfilePictureIcon = styled.img`
	width: 40px;
	height: 40px;
	border-radius: 40px;
`;

const InboxChatRightSection = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-start;
	gap: 16px;
`;

const InboxChatMiddleSection = styled.div`
	flex: 1;
	white-space: nowrap;
	width: 0;
`;

const InboxChatRow = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex: 1;
`;

const InboxChatText = styled.div<{ color?: string; bold?: boolean; small?: boolean }>`
	font-weight: 400;
	margin-bottom: 4px;
	text-overflow: ellipsis;
	overflow: hidden;
	color: ${({ color }) => color};
	font-weight: ${({ bold }) => (bold ? 600 : 400)};
	font-size: ${({ small }) => (small ? 14 : 16)}px;
	line-height: 20px;
`;

const InboxChatStatus = styled(InboxChatText)`
	text-overflow: unset;
	overflow: visible;
	white-space: nowrap;
`;

const InboxChatDateDisplay = styled.div`
	color: ${colors.grey60};
	font-size: 14px;
`;

const SearchBarRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 0 16px;
	margin-bottom: 8px;
`;

const SearchBarContainer = styled.div`
	margin-right: 8px;
	flex: 1;
`;

const TenantWarningBox = styled.div`
	border: 1px solid ${colors.grey60};
	border-radius: 16px;
	color: ${colors.red};
	font-size: 12px;
	font-weight: 600;
	line-height: 18px;
	padding: 8px 16px;
	margin: 16px 16px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;

const ChatContextMenu = styled.div`
	position: fixed;
	left: 0;
	top: 45px;
	background: ${colors.white};
	z-index: 1;
	padding: 16px;
	border-top: 1px solid ${colors.grey20};
	border-bottom: 1px solid ${colors.grey20};
	width: 100%;
`;

const ChatContextScrollActions = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: nowrap;
	overflow-x: auto;
	gap: 8px;
	margin: 0 -16px;
	padding: 0 16px;
`;

const InboxChatContextAction = styled.div<{ outlined?: boolean }>`
	border-radius: 8px;
	padding: 8px 16px;
	color: ${({ outlined }) => (outlined ? colors.darkBlue : colors.white)};
	font-weight: 600;
	font-size: 14px;
	background: ${({ outlined }) => (outlined ? colors.white : colors.darkBlue)};
	border: ${({ outlined }) => (outlined ? `2px solid ${colors.darkBlue}` : 'none')};
	margin-top: 8px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: nowrap;
	height: 36px;
	white-space: nowrap;
	cursor: pointer;
`;

const ConversationPopupMenu = ({
	chat,
	inboxType,
	onClose,
	onAction,
	onReport,
	onCancel,
}: {
	chat: Conversation;
	inboxType: InboxType;
	onClose(): void;
	onAction(action: ConversationAction): void;
	onReport(): void;
	onCancel(): void;
}) => {
	const ref = useRef(null);

	const handleClickOutside = (event: Event) => {
		if (ref.current && !(ref.current as any).contains(event.target)) {
			onClose();
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside);
		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	return (
		<OptionDotsMenuContainer ref={ref}>
			<OptionDotsMenu>
				{!chat.archived && (
					<>
						{chat.pinned ? (
							<OptionDotsMenuItem onClick={() => onAction(ConversationAction.Unpin)}>
								Unpin conversation
							</OptionDotsMenuItem>
						) : (
							<OptionDotsMenuItem onClick={() => onAction(ConversationAction.Pin)}>
								Pin conversation
							</OptionDotsMenuItem>
						)}
					</>
				)}
				{!chat.archived &&
					(chat.status === ConversationStatus.Confirmed ||
						chat.status === ConversationStatus.Cancelled ||
						chat.status === ConversationStatus.Expired) && (
						<OptionDotsMenuItem onClick={() => onAction(ConversationAction.Archive)}>
							Archive
						</OptionDotsMenuItem>
					)}
				{chat.archived && (
					<OptionDotsMenuItem onClick={() => onAction(ConversationAction.Restore)}>
						Restore
					</OptionDotsMenuItem>
				)}
				{(chat.status === ConversationStatus.Connected ||
					chat.status === ConversationStatus.Confirmed ||
					(chat.status === ConversationStatus.Pending && inboxType !== InboxType.Tenant)) && (
					<OptionDotsMenuItem color={colors.red} onClick={onCancel}>
						Cancel enquiry
					</OptionDotsMenuItem>
				)}
				<OptionDotsMenuItem onClick={onReport}>Report</OptionDotsMenuItem>
			</OptionDotsMenu>
		</OptionDotsMenuContainer>
	);
};

const ConversationRowItem = ({
	chat,
	children,
	isMobile,
	inboxType,
	onAction,
	onMoreClick,
	onReport,
	onCancel,
}: {
	chat: Conversation;
	children: any;
	isMobile: boolean;
	inboxType: InboxType;
	onAction(action: ConversationAction): void;
	onMoreClick(): void;
	onReport(): void;
	onCancel(): void;
}) => {
	const ref = useRef(null);
	const actionBlockWidth = 90;
	const leftActions = 1;

	const getNearestBlockWidthMultiple = (value: number): number => {
		const sign = value >= 0 ? 1 : -1;
		const absoluteNumber = Math.abs(value);
		const nearestMultiple = Math.round(absoluteNumber / actionBlockWidth) * actionBlockWidth;
		return nearestMultiple * sign;
	};

	const handleScroll = () => {
		if (!isMobile) {
			return;
		}
		const divElement: HTMLDivElement | null = ref.current as HTMLDivElement | null;
		if (divElement) {
			const scrollLeft = divElement.scrollLeft;
			const scrollDestination = getNearestBlockWidthMultiple(scrollLeft);
			divElement.scrollTo({
				left: scrollDestination,
				behavior: 'smooth',
			});
		}
	};

	useLayoutEffect(() => {
		if (!isMobile) {
			return;
		}

		const divElement: HTMLDivElement | null = ref.current as HTMLDivElement | null;
		if (divElement) {
			setTimeout(() => {
				divElement.scrollLeft = actionBlockWidth * leftActions;
				divElement.scrollTo({
					left: actionBlockWidth * leftActions,
					behavior: 'smooth',
				});
			}, 0);
			divElement.addEventListener('touchend', handleScroll);

			return () => {
				divElement.removeEventListener('touchend', handleScroll);
			};
		}
	}, [leftActions, isMobile, chat.archived]);

	const handleActionClick = () => {
		const divElement: HTMLDivElement | null = ref.current as HTMLDivElement | null;

		if (divElement) {
			const scrollDestination = actionBlockWidth * leftActions;
			divElement.scrollTo({
				left: scrollDestination,
				behavior: 'smooth',
			});
		}
	};

	if (!isMobile) {
		return <>{children}</>;
	}

	return (
		<InboxChatWrapper ref={ref}>
			{chat.archived ? (
				<InboxChatMobileAction
					background={colors.darkTurquoise}
					onClick={() => {
						handleActionClick();
						onAction(ConversationAction.Restore);
					}}
				>
					<ArchiveIcon selected />
					<span>Restore</span>
				</InboxChatMobileAction>
			) : (
				<>
					{chat.pinned ? (
						<InboxChatMobileAction
							onClick={() => {
								handleActionClick();
								onAction(ConversationAction.Unpin);
							}}
						>
							<UnpinnedSvg />
							<span>Unpin</span>
						</InboxChatMobileAction>
					) : (
						<InboxChatMobileAction
							onClick={() => {
								handleActionClick();
								onAction(ConversationAction.Pin);
							}}
						>
							<PinnedSvg />
							<span>Pin</span>
						</InboxChatMobileAction>
					)}
				</>
			)}
			{children}
			{chat.archived ? (
				<>
					<InboxChatMobileAction
						onClick={() => {
							handleActionClick();
							onReport();
						}}
					>
						<InfoSvg />
						<span>Report</span>
					</InboxChatMobileAction>
				</>
			) : (
				<>
					<InboxChatMobileAction
						onClick={() => {
							handleActionClick();
							onMoreClick();
						}}
					>
						<OptionDotsHorizontal />
						<span>More</span>
					</InboxChatMobileAction>
					{(chat.status === ConversationStatus.Connected ||
						(chat.status === ConversationStatus.Pending && inboxType === InboxType.Tenant)) && (
						<InboxChatMobileAction
							background={colors.red}
							onClick={() => {
								handleActionClick();
								onCancel();
							}}
						>
							<Bin style={{ fill: colors.white }} />
							<span>Cancel</span>
						</InboxChatMobileAction>
					)}
				</>
			)}
		</InboxChatWrapper>
	);
};

const DesktopRightPanelContainer = styled.div`
	display: none;
	min-width: 400px;
	overflow: auto;

	::-webkit-scrollbar-thumb {
		background: transparent;
	}

	:hover {
		::-webkit-scrollbar-thumb {
			background: ${colors.scrollbar};
		}
	}

	@media (min-width: ${BREAKPOINT_TABLET_LARGE_PX}px) {
		display: block;
		width: 400px;
		border-left: 1px solid ${colors.grey20};
	}
`;

const BackdropBlur = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	height: 100%;
	top: 0;
	backdrop-filter: blur(3px);
	z-index: 100;
`;

const FloatingBottomButtonContainer = styled.div`
	position: absolute;
	bottom: 32px;
	display: flex;
	justify-content: center;
	width: 100%;
	z-index: 101;
`;

const ScrollableContainer = styled.div`
	overflow-y: auto;
`;

const Popup = styled.div`
	position: fixed;
	padding: 12px;
	border-radius: 8px;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
	background: ${colors.white};
	z-index: 100;
	visibility: hidden;
	font-size: 12px;
`;

const PopupContainer = styled.div`
	position: absolute;

	&:hover ${Popup} {
		visibility: visible;
	}
`;

const rotate = keyframes`
  from {
    transform:  scaleX(-1) rotate(360deg);
  }
  to {
    transform:  scaleX(-1) rotate(0deg);
  }
`;

const RefreshIcon = styled(RefreshSvg)<{ isRotating?: boolean }>`
	animation: ${rotate} 2s linear infinite;
	transform: scaleX(-1);
	${({ isRotating }) => (isRotating ? `` : 'animation: none')};
`;
