import { Feature } from './property';
import { TenantGender, TenantType, Room as PropertyRoom } from './property';

export enum FundingType {
	Private = 'private',
	Bursary = 'bursary',
	Nsfas = 'nsfas',
	Organisation = 'organisation',
}

export enum UserType {
	Student = 'student',
	YoungProfessional = 'young_prof',
}

export enum LeaseType {
	SingleRoom = 'multi',
	EntirePlace = 'single',
}

export interface Campus {
	id: number;
	name: string;
}

export interface Institution {
	id: number;
	name: string;
}

export interface UniversityFaculty {
	id: number;
	name: string;
}

interface Photo {
	url: string;
	caption?: string;
	tag?: string;
}

/** @deprecated - Use Room in property.ts instead */
export interface Room extends PropertyRoom {}

export interface Location {
	city: string;
	suburb: string;
	province: string;
	country: string;
}

export interface Currency {
	code: string;
	symbol: string;
	currency_symbol: string;
	currency_code: string;
}

export interface Listing {
	uuid: string;
	landlord: Landlord;
	title: string;
	lease_term: number;
	lease_term_flexible: boolean;
	availability_date: string;
	booking_information: {
		property_uuid: string;
	};
	photos: Photo[];
	rooms: PropertyRoom[];
	lease_type: LeaseType;
	location: Location;
	major_type: string;
	sub_type: string;
	price: number;
	bedroom_count: number;
	university_distances: any[];
	currency: Currency;
	billing_cycle: string;
	external: boolean;
	features: Feature[];
	furnished: FurnishedType;
	display_price_from?: boolean;
	property_size?: number;
	property_size_unit?: string;
	total_bathrooms?: number;
	total_occupants: number;
	preferred_genders?: TenantGender;
	tenant_preferences?: TenantType[];
	total_shared_bathrooms?: number;
}

export interface Landlord {
	uuid: string;
	join_date: string;
	first_name: string;
	id_verified: boolean;
	email_verified: boolean;
	profile_picture: {
		url: string;
		is_default: boolean;
		face_detected: boolean;
	};
	social_verified: boolean;
	last_name_initial: string;
	cellphone_verified: boolean;
	is_trusted_landlord: boolean;
}

export interface BookingProfile {
	occupant_type?: UserType;
	payment_provider?: FundingType;
	identification_type?: string;
	identification_number?: string;
	home_address?: { formatted_address: string };
	home_address_data?: any;
	high_school?: string;
	matric_year?: string;
	institution?: Institution;
	campus?: Campus;
	level_of_study?: string;
	year_of_study?: string;
	student_number?: string;
	university_faculty?: UniversityFaculty;
	university_degree?: string;
	status?: any;
	documents?: { uuid: string }[];
	booking_requests_remaining?: number;
	occupation?: string;
}

export interface NoInstitutionData {
	institution_name: string;
	country: string;
}

export interface NoIdInstitution {
	name: string;
}

export enum ListingFeature {
	Parking = 'off-street parking',
	Wifi = 'wifi included',
}

export enum FurnishedType {
	Unknown = '',
	Fully = 'fully',
	Semi = 'semi',
	Unfurnished = 'none',
}

export enum ListingFeatureCategory {
	Furniture = 'Furniture',
	Rules = 'Rules',
	OfferedFeatures = 'This place offers',
}
