import React from 'react';

const Twitter = ({ color }) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		{color ? (
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M19.0156 6.67164V6.67364V6.67164ZM8.51263 18.0146C8.53663 18.0146 8.56263 18.0156 8.58663 18.0176C14.2816 17.9806 17.6806 13.6296 17.6806 9.42364C17.6806 9.04164 17.8766 8.79464 18.0506 8.57564C18.2086 8.37564 18.4536 8.06764 18.6716 7.62364C17.8786 7.61564 17.1706 7.26364 16.7586 6.78164C16.3276 6.32064 15.7086 6.03864 15.0406 6.01864C14.3966 5.99264 13.7396 6.24064 13.2516 6.70364C12.6016 7.32064 12.3236 8.25164 12.5256 9.13164L12.7866 10.2706L11.6206 10.3526C8.60463 10.5786 6.51463 9.28764 5.07963 7.95164C5.08563 9.97164 5.75663 12.6666 8.80663 14.4046L9.76463 14.9506L9.12763 15.8516C8.46463 16.7906 7.46663 17.3896 6.45063 17.7726C7.12163 17.9326 7.81163 18.0146 8.49963 18.0146H8.51163H8.51263ZM8.51263 20.0176C8.48663 20.0176 8.45863 20.0166 8.43263 20.0146C6.82963 20.0036 5.22163 19.6306 3.77763 18.9316C3.23463 18.6676 2.92763 18.0896 3.01463 17.4896C3.10263 16.8826 3.56863 16.4066 4.17263 16.3086C5.21763 16.1366 6.08463 15.8306 6.71263 15.4216C2.83563 12.5226 2.93763 8.18264 3.18863 6.34464C3.26263 5.80664 3.64563 5.35764 4.16363 5.20064C4.68963 5.04064 5.23463 5.20064 5.59463 5.60964C7.13063 7.35064 8.66563 8.22564 10.4656 8.35864C10.5176 7.19364 11.0136 6.07064 11.8746 5.25264C12.7516 4.41964 13.8986 3.98364 15.1026 4.01964C16.3076 4.05664 17.4256 4.56464 18.2496 5.44964C18.3336 5.54564 18.6476 5.71964 19.0266 5.54964C19.5236 5.32664 20.0886 5.40064 20.5006 5.74464C20.9116 6.08564 21.0866 6.62264 20.9586 7.14564C20.6356 8.47664 19.9976 9.33464 19.6756 9.74564C19.5076 14.8506 15.3596 20.0176 8.51263 20.0176Z"
				fill={color}
			/>
		) : (
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M19.0156 6.67164V6.67364V6.67164ZM8.51263 18.0146C8.53663 18.0146 8.56263 18.0156 8.58663 18.0176C14.2816 17.9806 17.6806 13.6296 17.6806 9.42364C17.6806 9.04164 17.8766 8.79464 18.0506 8.57564C18.2086 8.37564 18.4536 8.06764 18.6716 7.62364C17.8786 7.61564 17.1706 7.26364 16.7586 6.78164C16.3276 6.32064 15.7086 6.03864 15.0406 6.01864C14.3966 5.99264 13.7396 6.24064 13.2516 6.70364C12.6016 7.32064 12.3236 8.25164 12.5256 9.13164L12.7866 10.2706L11.6206 10.3526C8.60463 10.5786 6.51463 9.28764 5.07963 7.95164C5.08563 9.97164 5.75663 12.6666 8.80663 14.4046L9.76463 14.9506L9.12763 15.8516C8.46463 16.7906 7.46663 17.3896 6.45063 17.7726C7.12163 17.9326 7.81163 18.0146 8.49963 18.0146H8.51163H8.51263ZM8.51263 20.0176C8.48663 20.0176 8.45863 20.0166 8.43263 20.0146C6.82963 20.0036 5.22163 19.6306 3.77763 18.9316C3.23463 18.6676 2.92763 18.0896 3.01463 17.4896C3.10263 16.8826 3.56863 16.4066 4.17263 16.3086C5.21763 16.1366 6.08463 15.8306 6.71263 15.4216C2.83563 12.5226 2.93763 8.18264 3.18863 6.34464C3.26263 5.80664 3.64563 5.35764 4.16363 5.20064C4.68963 5.04064 5.23463 5.20064 5.59463 5.60964C7.13063 7.35064 8.66563 8.22564 10.4656 8.35864C10.5176 7.19364 11.0136 6.07064 11.8746 5.25264C12.7516 4.41964 13.8986 3.98364 15.1026 4.01964C16.3076 4.05664 17.4256 4.56464 18.2496 5.44964C18.3336 5.54564 18.6476 5.71964 19.0266 5.54964C19.5236 5.32664 20.0886 5.40064 20.5006 5.74464C20.9116 6.08564 21.0866 6.62264 20.9586 7.14564C20.6356 8.47664 19.9976 9.33464 19.6756 9.74564C19.5076 14.8506 15.3596 20.0176 8.51263 20.0176Z"
				fill="#041F47"
			/>
		)}
	</svg>
);

export default Twitter;
