import React, { useState } from 'react';

import styled from 'styled-components';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import moment from 'moment';

import './DateInput.scss';
import theme from 'config/theme';
import { validationErrors } from 'config/validationErrors';
import { Select } from 'lib/Select';

const { colors, inputs } = theme;

const ValidationError = styled.div`
	font-size: 12px;
	color: ${colors.pink};
	margin-top: -16px;
	padding: 4px 16px;
`;

const InputContainer = styled.div`
	margin-bottom: 16px;
`;

interface MonthYearSelectorProps {
	date: any;
	localeUtils: any;
	onChange(value: any): void;
}

const currentYear = new Date().getFullYear() - 18;
const currentMonth = new Date().getMonth();
const fromMonth = new Date(currentYear - 100, 0);
const toMonth = new Date(currentYear, currentMonth);
const years: any[] = [];
for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
	years.push(i);
}

const MonthYearSelector = ({ date, localeUtils, onChange }: MonthYearSelectorProps) => {
	const months = localeUtils.getMonths();

	return (
		<div
			onClick={e => {
				e.preventDefault();
				e.stopPropagation();
			}}
			className="DayPicker-Caption"
		>
			<Select
				placeholder={'Year'}
				options={years.map((y: any) => ({ label: y, value: y })).reverse()}
				selectedOption={years
					.map((y: any) => ({ label: y, value: y }))
					.find(({ value }) => value === date.getFullYear())}
				onChange={({ value }) => onChange(new Date(value, date.getMonth()))}
			/>
			<Select
				placeholder={'Month'}
				options={months.map((m: any) => ({ label: m, value: m }))}
				selectedOption={months.map((m: any) => ({ label: m, value: m }))[date.getMonth()]}
				onChange={({ value }) => onChange(new Date(date.getFullYear(), months.indexOf(value)))}
			/>
		</div>
	);
};

interface DateInputProps {
	placeholder: string;
	value?: string;
	isErrorState?: boolean;
	errorMessage?: string;
	allowMonthYearSelection?: boolean;
	onChange(date: Date): void;
}

export const formatDate = (value: string) => {
	return (
		value &&
		moment(value, 'YYYY-MM-DD')
			.locale('en')
			.format('Do MMMM YYYY')
	);
};

export const DateInput = ({
	placeholder,
	value = '',
	isErrorState = false,
	errorMessage = validationErrors.InvalidField,
	allowMonthYearSelection = false,
	onChange,
}: DateInputProps) => {
	const [date, setDate] = useState<any>(toMonth);

	return (
		<>
			<InputContainer className={'date-input'}>
				<DayPickerInput
					format=""
					value={formatDate(value)}
					placeholder={placeholder}
					keepFocus={false}
					onDayChange={onChange}
					inputProps={{
						style: {
							color: colors.darkBlue,
							background: colors.grey05,
							borderRadius: inputs.borderRadius,
							padding: 16,
							width: '100%',
							height: inputs.height,
							outline: 'none',
						},
					}}
					dayPickerProps={{
						...(allowMonthYearSelection
							? {
									month: date,
									fromMonth: fromMonth,
									toMonth: toMonth,
									captionElement: ({ date, localeUtils }) => (
										<MonthYearSelector date={date} localeUtils={localeUtils} onChange={setDate} />
									),
							  }
							: {}),
						modifiersStyles: {
							today: {
								color: colors.darkTurquoise,
							},
							selected: {
								color: colors.white,
								background: colors.darkTurquoise,
								width: 24,
								height: 24,
							},
						},
					}}
				/>
			</InputContainer>
			{!!isErrorState && !!errorMessage && <ValidationError>{errorMessage}</ValidationError>}
		</>
	);
};
