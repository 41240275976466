// DOCS: https://www.digsconnect.com/docs/web/#user

import client from '../utils/fetch-client';

const path = '/user';

export default {
	updateEmail(email = '') {
		return client.post(`${path}/email`, {
			body: {
				email,
			},
		});
	},

	sendEmailConfirmation() {
		return client.post(`${path}/email/resend-verification`);
	},

	verifyNumber(code) {
		return client.post(`${path}/cellphone/verify`, {
			body: {
				code,
			},
		});
	},

	resendVerificationCode() {
		return client.post(`${path}/cellphone/verify/resend`);
	},

	acceptTandCs() {
		return client.post(`${path}/accept-tandcs`);
	},
};
