import React, { useState, useEffect } from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import SearchResult from 'components/search-drawer/SearchResult';
import {
	LOCATION_SEARCH_DEBOUNCE_MILLISECONDS,
	LOCATION_SEARCH_RESULTS_MAX_DISPLAY_COUNT,
} from 'constants/index';
import ChipsInput from 'lib/ChipsInput';
import searchService from 'services/searchService';
import { SearchItem, SearchItemType } from 'models/search';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';

const { colors } = theme;

const keyCodes = {
	enter: 13,
	down: 40,
	up: 38,
};

interface LocationSearchProps {
	searchList: SearchItem[];
	selectedSearchItems: SearchItem[];
	onSearch(term: string): void;
	onSelectedSearchItemsChange(items: SearchItem[]): void;
	onSearchItemAdded(): void;
}

const LocationSearch = ({
	searchList,
	selectedSearchItems,
	onSearch,
	onSelectedSearchItemsChange,
	onSearchItemAdded,
}: LocationSearchProps) => {
	const [term, setTerm] = useState<string | null>(null);
	const [timeoutId, setTimeoutId] = useState<number>();
	const [focusedResult, setFocusedResult] = useState<SearchItem>();

	useEffect(() => {
		if (timeoutId) {
			clearTimeout(timeoutId);
		}

		setTimeoutId(
			setTimeout(() => {
				if (term === null) {
					return;
				}
				onSearch(term || '');
			}, LOCATION_SEARCH_DEBOUNCE_MILLISECONDS) as any,
		);
		// eslint-disable-next-line
	}, [term]);

	useEffect(() => {
		if (!featureFlagContainer.isEnabled(FeatureFlag.HomepageEnterToSearch)) {
			return;
		}
		if (searchList?.length) {
			setFocusedResult(searchList[0]);
		}
	}, [searchList]);

	useEffect(() => {
		if (!featureFlagContainer.isEnabled(FeatureFlag.HomepageEnterToSearch)) {
			return;
		}
		const handleKeyPress = (event: any) => {
			if (event.keyCode === keyCodes.enter && focusedResult) {
				setTimeout(() => {
					handleSearchResultSelect(focusedResult);
				}, 0);
			}
			if (event.keyCode === keyCodes.down) {
				setFocusedResult(() => {
					const index = searchList.findIndex(
						(item: SearchItem) => focusedResult && item?.id === focusedResult?.id,
					);
					const newIndex = index + 1 === searchList.length ? 0 : index + 1;
					return searchList[newIndex];
				});
			}
			if (event.keyCode === keyCodes.up) {
				setFocusedResult(() => {
					const index = searchList.findIndex(
						(item: SearchItem) => focusedResult && item?.id === focusedResult?.id,
					);
					const newIndex = index === 0 ? searchList.length - 1 : index - 1;
					return searchList[newIndex];
				});
			}
		};
		window.addEventListener('keydown', handleKeyPress);

		// Remove event listener on component unmount
		return () => {
			window.removeEventListener('keydown', handleKeyPress);
		};
	}, [searchList, focusedResult]);

	const handleSearchInput = (value: string) => {
		setTerm(value);
	};

	const handleSearchResultSelect = (result: SearchItem) => {
		if (selectedSearchItems.some(item => item.type === result.type && item.id === result.id)) {
			return;
		}

		// Prevent multiple item selection if university is selected
		if (
			result.type === SearchItemType.University ||
			selectedSearchItems.some(item => item.type === SearchItemType.University)
		) {
			onSelectedSearchItemsChange([result]);
		} else {
			onSelectedSearchItemsChange([...selectedSearchItems, result]);
		}

		setTerm(null);
		onSearchItemAdded();
	};

	const handleChipRemove = (chipValue: any) => {
		onSelectedSearchItemsChange(selectedSearchItems.filter(item => item.id !== chipValue));
	};

	return (
		<>
			<ChipsInput
				onChange={handleSearchInput}
				value={term || ''}
				placeholder={'Search by city, suburb or university...'}
				chips={selectedSearchItems.map(item => ({
					label: searchService.getShortTitle(item),
					value: item.id,
				}))}
				onRemoveChip={handleChipRemove}
			/>
			{searchList.length > 0 && (
				<ResultContainer>
					{searchList.slice(0, LOCATION_SEARCH_RESULTS_MAX_DISPLAY_COUNT).map((result: any, i) => (
						<SearchResult
							key={i}
							onClick={() => handleSearchResultSelect(result)}
							university={result.type !== 'location'}
							title={result.label}
							isSelected={result?.id === focusedResult?.id}
						/>
					))}
				</ResultContainer>
			)}
		</>
	);
};

const ResultContainer = styled.div`
	border-radius: 8px;
	border: ${colors.grey10} 1px solid;
	margin-top: 16px;
	padding: 12px 0;
	overflow-y: auto;
	max-height: 266px;
`;

export default LocationSearch;
