import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import api from 'api';
import { TopBar } from 'lib/TopBar';
import { Modal } from 'lib/Modal';
import Button from 'lib/Button';
import theme from 'config/theme';
import { SelectOption } from 'lib/Select';
import CheckboxRow from 'lib/CheckboxRow';
import { InboxType } from './types';
import ThreeDotsLoader from 'lib/ThreeDotsLoader';

const { colors } = theme;

interface FilterGroup {
	title: string;
	key: string;
	options: SelectOption[];
}

interface FilterModalProps {
	inboxType: InboxType;
	filters: string[][];
	onClose(): void;
	onApply(filters: string[][]): void;
}

const displayTextMap = {
	status: 'Enquiry status',
	gender: 'Tenants',
	occupation: 'Occupation',
	payer: 'Fee payer',
};

const FilterModal = ({ inboxType, filters, onApply, onClose }: FilterModalProps) => {
	const [isLoading, setIsLoading] = useState(false);
	const [selectedFilters, setSelectedFilters] = useState<string[][]>([]);
	const [filterGroups, setFilterGroups] = useState<FilterGroup[]>([]);

	useEffect(() => {
		setFilterGroups([]);
		api.inbox.getInboxFitlers(inboxType).then(response => {
			if (!response || !response.data) {
				return;
			}
			setFilterGroups(() => {
				return Object.keys(response.data).reduce<FilterGroup[]>(
					(acc, key) => [
						...acc,
						{
							key,
							title: (displayTextMap as any)[key] || '',
							options: (response.data as any)[key] || [],
						},
					],
					[],
				);
			});
		});
	}, [inboxType]);

	useEffect(() => {
		setSelectedFilters(filters);
	}, [filters]);

	const handleSubmit = () => {
		onApply(selectedFilters);
		onClose();
	};

	const handleClear = () => {
		onApply([]);
		onClose();
	};

	const handleToggleFilter = (filter: string, category: string) => {
		setSelectedFilters(prev => {
			if (prev.some(tuple => filter === tuple[0] && category === tuple[1])) {
				return prev.filter(tuple => tuple[0] !== filter || tuple[1] !== category);
			}
			return [...prev, [filter, category]];
		});
	};

	return (
		<Modal onBackgroundClick={onClose} width={500}>
			<TopBar title="Filter" onClose={onClose} />
			<ContentContainer>
				{!filterGroups.length && (
					<LoaderContainer>
						<ThreeDotsLoader />
					</LoaderContainer>
				)}
				{filterGroups.map(item => (
					<div key={item.title}>
						<SubTitle>{item.title}</SubTitle>
						{item.options.map(filter => (
							<CheckboxRow
								key={filter.value}
								text={filter.label}
								checked={selectedFilters.some(
									tuple => filter.value === tuple[0] && item.key === tuple[1],
								)}
								onClick={() => handleToggleFilter(filter.value, item.key)}
							/>
						))}
					</div>
				))}
			</ContentContainer>
			<BottomBar>
				<Button isOutline isLoading={isLoading} onClick={handleClear}>
					Clear all
				</Button>
				<Button isLoading={isLoading} onClick={handleSubmit}>
					Apply
				</Button>
			</BottomBar>
		</Modal>
	);
};

const ContentContainer = styled.div`
	padding: 16px;
	overflow-y: auto;
`;

const BottomBar = styled.div`
	border-top: 1px solid ${colors.grey10};
	padding: 16px;
	display: flex;
	justify-content: space-between;
	gap: 16px;
`;

const Text = styled.div`
	font-size: 16px;
	margin-bottom: 4px;
	line-height: 150%;
`;

const SubTitle = styled(Text)`
	font-weight: 600;
	margin-bottom: 16px;
`;

const LoaderContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export default FilterModal;
