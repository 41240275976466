import { Container } from 'unstated';

import { analyticsService, PageName } from 'services/analyticsService';
import { ModalType } from 'constants/modalTypes';

class Modal extends Container {
	constructor() {
		super();

		this.state = {
			active: '',
			appBanner: true,
		};
	}

	setAppBanner = (appBanner = true) => this.setState({ appBanner });

	set = (active = '', props = {}, children = null) => {
		if (active === ModalType.Login) {
			analyticsService.trackPage(PageName.Login);
		}
		if (active === ModalType.Signup) {
			analyticsService.trackPage(PageName.Signup);
		}
		this.setState({
			active,
			props,
      children,
		});
	};

  open = (type, children, config) => {
    this.set(type, {...config}, children);
  }

  close = () => {
    this.set('');
  }

	clear = () => {
		this.set('');
	};
}

export default new Modal();
