import React, { useState, useRef, useEffect } from 'react';

import { Modal } from 'lib/Modal';
import { Input } from 'lib/Input';
import { Title, ModalContainer, SubText, CloseButton } from './styles';
import Button from 'lib/Button';
import { Loader } from 'lib/Loader';
import { Cross } from 'lib/Cross';

interface NoInstitutionFoundModalProps {
	onClose(): void;
	createUserInstitution: any;
	isOpen: boolean;
	onPersonalInfoFieldChange(fieldChange: { [fieldName: string]: any }): void;
}

const NoInstitutionFoundModal = ({
	onClose,
	createUserInstitution,
	isOpen,
}: NoInstitutionFoundModalProps) => {
	const [institutionName, setInstitutionName] = useState<string>('');
	const [country, setCountry] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [showValidationErrors, setShowValidationErrors] = useState<boolean>(false);

	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (isOpen && inputRef.current) {
			inputRef.current.focus();
		}
	}, [isOpen]);
	const handleFieldChange = (change: { [key: string]: any }): void => {
		if (change.institutionName !== undefined) {
			setInstitutionName(change.institutionName);
			return;
		}
		if (change.country !== undefined) {
			setCountry(change.country);
			return;
		}
	};

	const handleSubmit = () => {
		if (!institutionName || !country) {
			setShowValidationErrors(true);
			return;
		}
		setIsLoading(true);
		createUserInstitution({
			institution_name: institutionName,
			country: country,
		}).then(() => {
			onClose();
			setIsLoading(true);
		});
	};

	return (
		<Modal slideUp onBackgroundClick={onClose}>
			{isLoading ? (
				<Loader />
			) : (
				<ModalContainer>
					<CloseButton>
						<Cross onClick={onClose} />
					</CloseButton>
					<Title>Add your institution</Title>
					<SubText>Please fill in the details about the institution you're studying.</SubText>
					<Input
						inputRef={inputRef}
						placeholder="Name of institution"
						onChange={value => handleFieldChange({ institutionName: value })}
						value={institutionName}
						isErrorState={showValidationErrors && !institutionName}
					/>
					<Input
						placeholder="Country"
						onChange={value => handleFieldChange({ country: value })}
						value={country}
						isErrorState={showValidationErrors && !country}
					/>
					<Button onClick={handleSubmit}>Submit</Button>
				</ModalContainer>
			)}
		</Modal>
	);
};

export default NoInstitutionFoundModal;
