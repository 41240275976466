import { Response } from './types';
import client from '../utils/fetch-client';
import { InvoiceDetail, InvoicesResponse, OutstandingDetails } from 'models/invoices';

const path = '/v2/user/invoices';

export default {
	getInvoices(params?: {
		filter?: string;
		search?: string;
		page?: number;
	}): Promise<Response<InvoicesResponse>> {
		let queryStringValues: string[] = [];
		if (params?.search) {
			queryStringValues = [...queryStringValues, `search_term=${params.search}`];
		}
		if (params?.filter) {
			queryStringValues = [...queryStringValues, `${params.filter.toLowerCase()}=true`];
		}
		if (params?.page) {
			queryStringValues = [...queryStringValues, `page=${params.page}`];
		}
		const queryString = queryStringValues.length
			? queryStringValues.reduce((acc, val) => `${acc}${acc === '?' ? '' : '&'}${val}`, '?')
			: '';

		return client.get(`${path}${queryString}`);
	},
	getOutstanding(): Promise<Response<OutstandingDetails>> {
		return client.get(`${path}/outstanding`);
	},
	getInvoice(id: string): Promise<Response<InvoiceDetail>> {
		return client.get(`${path}/${id}`);
	},
	postDispute(id: string, reason: string, message?: string): Promise<Response<any>> {
		return client.post(`${path}/${id}/dispute`, {
			body: {
				reason,
				message,
			},
		});
	},
};
