import styled from 'styled-components';

import theme from 'config/theme';

const { colors } = theme;

export const Divider = styled.div<{ noMargin?: boolean }>`
	border-bottom: 1px solid ${colors.grey10};
	margin: 24px 0;
	margin: ${({ noMargin }) => (noMargin ? '0px' : '24px 0px')};
`;
