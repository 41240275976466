import React from 'react';

import styled from 'styled-components';

import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';
import theme from 'config/theme';
import ThreeDotsLoader from '../ThreeDotsLoader';
import { analyticsService, EventName } from 'services/analyticsService';
import { Link } from 'react-router-dom';

const { colors, fonts } = theme;

interface BaseButtonProps {
	height?: number;
	isFullWidth?: boolean;
	isDisabled?: boolean;
	whiteBackground?: boolean;
	noMargin?: boolean;
	maxWidth?: number;
	autoWidth?: boolean;
}

const BaseButton = styled.button<BaseButtonProps>`
  font-size: 14px;
  font-weight: 600;
  font-family: ${fonts.Gilroy};
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  padding: 0 16px;
  height: ${({ height }) => `${height || 56}px`};
  user-select: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  ${({ isDisabled }) => (isDisabled ? '' : 'cursor: pointer;')}
  width: 100%;
  ${({ autoWidth }) => autoWidth && `width: auto;`}
  ${({ noMargin }) => noMargin && 'margin: 0;'}
  ${({ isFullWidth, maxWidth }) => !isFullWidth && maxWidth && `max-width: ${maxWidth}px;`}

  @media (min-width: ${BREAKPOINT_TABLET_PX}px) {
    ${({ isFullWidth, maxWidth }) => !isFullWidth && `max-width: ${maxWidth || 280}px;`}
  }
`;

const FilledButton = styled(BaseButton)<{ color?: string; textColor?: string }>`
	color: ${({ textColor }) => textColor || colors.white};
	background: ${({ color }) => color || colors.darkBlue};
	${({ isDisabled }) => (isDisabled ? `background: ${colors.grey20};` : '')}
	position: relative;
	overflow: hidden;

	&:after {
		content: '';
		background: ${colors.white};
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		opacity: 0;
		transition: all 400ms;
	}

	&:active:after {
		opacity: 0.4;
	}
`;

const OutlinedButton = styled(BaseButton)<{ color?: string }>`
	background: ${({ whiteBackground }) => colors.white || colors.transparent};
	color: ${({ color }) => color || colors.darkBlue};
	border: 2px solid ${({ color }) => color || colors.darkBlue};
	${({ isDisabled }) =>
		isDisabled
			? `
    color: ${colors.grey20};
	  border: 2px solid ${colors.grey20};
  `
			: ''}
	transition: background 400ms;

	&:active {
		background: ${colors.shadedGray};
		transition: 0s;
	}
`;

const StyledLink = styled(Link)<{ isFullWidth?: boolean; maxWidth?: number }>`
	width: 100%;
	${({ isFullWidth, maxWidth }) => !isFullWidth && maxWidth && `max-width: ${maxWidth}px;`}
`;

interface ButtonProps {
	isOutline?: boolean;
	noMargin?: boolean;
	isGreen?: boolean;
	isPink?: boolean;
	isWhite?: boolean;
	isDisabled?: boolean;
	isGrey?: boolean;
	isRed?: boolean;
	whiteBackground?: boolean;
	height?: number;
	maxWidth?: number;
	autoWidth?: boolean;
	isFullWidth?: boolean;
	clickableRef?: any;
	children: string | React.ReactElement;
	isLoading?: boolean;
	analyticsName?: string;
	link?: string;
	isExternalLink?: boolean;
	openLinkInNewTab?: boolean;
	className?: string;
	type?: 'submit' | 'button';
	onClick?(e: any): void;
}

const getBackgroundColor = (
	isPink: boolean,
	isGreen: boolean,
	isWhite: boolean,
	isGrey: boolean,
	isRed: boolean,
) => {
	if (isPink) {
		return colors.pink;
	}
	if (isGreen) {
		return colors.darkTurquoise;
	}
	if (isWhite) {
		return colors.white;
	}
	if (isGrey) {
		return colors.darkGray;
	}
	if (isRed) {
		return colors.red;
	}
	return colors.darkBlue;
};

const getTextColor = (isWhite: boolean) => {
	if (isWhite) {
		return colors.pink;
	}
	return colors.white;
};

export const Button = ({
	isOutline = false,
	isLoading = false,
	isPink = false,
	isGreen = false,
	isWhite = false,
	isGrey = false,
	isRed = false,
	isExternalLink = false,
	whiteBackground,
	clickableRef,
	analyticsName,
	link,
	openLinkInNewTab,
	isFullWidth,
	isDisabled,
	maxWidth,
	...rest
}: ButtonProps) => {
	const color = getBackgroundColor(isPink, isGreen, isWhite, isGrey, isRed);
	const textColor = getTextColor(isWhite);

	const handleClick = (e: any) => {
		if (isDisabled) {
			return;
		}
		analyticsService.trackEvent(EventName.ButtonClick, {
			button_name: analyticsName || '',
			button_text: rest.children && rest.children.toString(),
		});

		if (rest.onClick) {
			rest.onClick(e);
		}
	};

	const button = isOutline ? (
		<OutlinedButton
			ref={clickableRef}
			{...rest}
			isFullWidth={isFullWidth}
			isDisabled={isDisabled}
			maxWidth={maxWidth}
			whiteBackground={whiteBackground}
			onClick={link ? undefined : handleClick}
			color={color}
		>
			{isLoading ? <ThreeDotsLoader color={color} /> : rest.children}
		</OutlinedButton>
	) : (
		<FilledButton
			ref={clickableRef}
			{...rest}
			isFullWidth={isFullWidth}
			maxWidth={maxWidth}
			isDisabled={isDisabled}
			onClick={link ? undefined : handleClick}
			color={color}
			textColor={textColor}
		>
			{isLoading ? <ThreeDotsLoader color={colors.white} /> : rest.children}
		</FilledButton>
	);

	if (link) {
		return (
			<StyledLink
				to={isExternalLink ? { pathname: link } : link}
				onClick={handleClick}
				isFullWidth={isFullWidth}
				maxWidth={maxWidth}
				target={openLinkInNewTab ? '_blank' : ''}
			>
				{button}
			</StyledLink>
		);
	}

	return button;
};
