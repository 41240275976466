import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FormElementWrapper from '../../shared-components/FormElementWrapper';
import FormElementError from '../../shared-components/FormElementError';
import authContainer from 'containers/auth';
import { Subscribe } from 'unstated';
import Input from '../../shared-components/Input';
import { Formik } from 'formik';
import * as Yup from 'yup';
import api from 'api';
import Swal from 'sweetalert2';
import BottomCTAWrapper from '../BottomCTAWrapper';
import { Button } from 'lib/Button';
import { Title } from 'lib/Title';

class BasicDetailsSection extends Component {
	static propTypes = {
		onUpdateSuccess: PropTypes.func,
		email: PropTypes.bool,
	};

	static defaultProps = {
		onUpdateSuccess: () => {},
	};

	fireSubmissionError = message => {
		Swal.fire({
			title: message,
			type: 'warning',
			showConfirmButton: false,
			toast: true,
			timer: 4000,
			position: 'top',
			customClass: {
				container: 'custom-container-toast-class',
			},
		});
	};

	handleFormSubmit = async (values, { isSubmitting, setSubmitting }) => {
		const { onUpdateSuccess, email } = this.props;

		const errorMessage = 'Something went wrong, please contact support';

		try {
			const { updateProfile } = api.userV2;

			const updateProfilePayload = {
				first_name: values.first_name,
				last_name: values.last_name,
			};

			const updateProfileResponse = await updateProfile(updateProfilePayload);

			const updateProfileSuccess =
				updateProfileResponse.status >= 200 && updateProfileResponse.status < 300;

			let updateEmailSuccess = true;
			if (!email) {
				const { updateEmail } = api.user;

				const updatedEmailResponse = await updateEmail(values.email);

				updateEmailSuccess =
					updatedEmailResponse.status >= 200 && updatedEmailResponse.status < 300;
			}
			if (updateProfileSuccess && updateEmailSuccess) {
				onUpdateSuccess();
			} else {
				this.fireSubmissionError(errorMessage);
			}
		} catch (err) {
			console.log(err);
			this.fireSubmissionError(errorMessage);
		} finally {
			setSubmitting(false);
		}
	};

	render() {
		const validationSchema = Yup.object().shape({
			first_name: Yup.string().required('Required'),
			last_name: Yup.string().required('Required'),
			email: Yup.string().required('Required'),
		});

		const { email } = this.props;

		return (
			<StyledBasicEducationSection>
				<Formik
					initialValues={{
						first_name: '',
						last_name: '',
						email: email ? email.email : '',
					}}
					onSubmit={this.handleFormSubmit}
					validationSchema={validationSchema}
				>
					{({ values, handleChange, handleSubmit, handleBlur, touched, errors, isSubmitting }) => {
						return (
							<StyledForm onSubmit={handleSubmit}>
								<div>
									<Title>Basic Details</Title>
									<SubTitle>Please confirm your basic information below</SubTitle>
									<div className="columns">
										<div className="column is-two-thirds">
											<FormElementWrapper>
												<Input
													type="text"
													name="first_name"
													value={values.first_name}
													onChange={handleChange}
													onBlur={handleBlur}
													placeholder="First name (as per ID)"
												/>
												{errors.first_name && touched.first_name && (
													<FormElementError>{errors.first_name}</FormElementError>
												)}
											</FormElementWrapper>
											<FormElementWrapper>
												<Input
													type="text"
													name="last_name"
													value={values.last_name}
													onChange={handleChange}
													onBlur={handleBlur}
													placeholder="Last name (as per ID)"
												/>
												{errors.last_name && touched.last_name && (
													<FormElementError>{errors.last_name}</FormElementError>
												)}
											</FormElementWrapper>

											{email ? null : (
												<FormElementWrapper>
													<Input
														type="email"
														name="email"
														value={values.email}
														onChange={handleChange}
														onBlur={handleBlur}
														placeholder="Email"
													/>
													{errors.email && touched.email && (
														<FormElementError>{errors.email}</FormElementError>
													)}
												</FormElementWrapper>
											)}
										</div>
									</div>
								</div>

								<BottomCTAWrapper>
									<div className="columns">
										<div className="column is-two-thirds">
											<Button
												isOutline
												type="submit"
												disabled={isSubmitting}
												isLoading={isSubmitting}
											>
												Continue
											</Button>
										</div>
									</div>
								</BottomCTAWrapper>
							</StyledForm>
						);
					}}
				</Formik>
			</StyledBasicEducationSection>
		);
	}
}

export default function BasicDetailsSectionWithAuth(props) {
	return (
		<Subscribe to={[authContainer]}>
			{auth => <BasicDetailsSection {...props} auth={auth} />}
		</Subscribe>
	);
}

/**styled components */
const StyledBasicEducationSection = styled.div`
	height: 100%;
`;

const SubTitle = styled.h3`
	color: #9da7b7;
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 36px;
`;

const StyledForm = styled.form`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
`;
