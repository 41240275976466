import client from '../utils/fetch-client';

const path = '/content/contact-us';

export default {
	contactUsV2(name, surname, email, mobile_number, reason, message) {
		return client.post(path + '?new_serializer=true', {
			body: {
				first_name: name,
				last_name: surname,
				email: email,
				cell_number: mobile_number,
				reason: reason,
				message: message,
			},
		});
	},
	contactUsV2NoMobile(name, surname, email, reason, message) {
		return client.post(path + '?new_serializer=true', {
			body: {
				first_name: name,
				last_name: surname,
				email: email,
				reason: reason,
				message: message,
			},
		});
	},
	create: ({ name, surname, email, message } = {}) => {
		return client.post(path, {
			body: {
				first_name: name,
				last_name: surname,
				email,
				message,
			},
		});
	},
};
