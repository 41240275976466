import React from 'react';

import styled from 'styled-components';

import { Modal } from 'lib/Modal';
import { TopBar } from 'lib/TopBar';
import theme from 'config/theme';
import Button from 'lib/Button';
import routes from 'config/routes';
import { BREAKPOINT_MOBILE_PX, BREAKPOINT_TABLET_PX } from 'constants/breakPoints';
import api from 'api';
import { imgixManageDigsUrl } from 'config/imgix';

const { colors } = theme;

interface ProductUpdateModalProps {
	onClose(): void;
	userName: string;
}

const ProductUpdateModal = ({ userName, onClose }: ProductUpdateModalProps) => {
	return (
		<Modal width={600} onBackgroundClick={onClose}>
			<TopBar
				closeOnRight
				title="Product Update"
				subTitle="Create listing"
				onClose={onClose}
			></TopBar>
			<ModalContent>
				<Text>Hello {userName}</Text>
				<Text>
					Exciting news! We've recently updated the 'Create a listing' process, to make your
					listings more attractive to tenants-helping you find tenants quicker! These include:
				</Text>
				<BulletedList>
					<BulletedListItem>Multiple images for rooms.</BulletedListItem>
					<BulletedListItem>
						Extra property details (Property size, shared bathrooms etc)
					</BulletedListItem>
					<BulletedListItem>Prefered tenants and capacity.</BulletedListItem>
					<BulletedListItem>Atmosphere tags, to help tenants find what they need.</BulletedListItem>
					<BulletedListItem>Picture tags, to better outline the space.</BulletedListItem>
				</BulletedList>
				<Text>
					<b>
						Please update your active listings, by completing these new fields which have been
						highlighted on your ‘Edit listing’ page.
					</b>
				</Text>
				<DisplayImage src={`${imgixManageDigsUrl}?auto=compress&auto=format`} />
			</ModalContent>
			<BottomBar>
				<Button
					link={routes.manage_digs}
					onClick={onClose}
				>
					Update my listings
				</Button>
			</BottomBar>
		</Modal>
	);
};

export default ProductUpdateModal;

const ModalContent = styled.div`
	padding: 48px;
	overflow-y: auto;
	padding-bottom: 32px;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		padding: 24px;
	}
`;

const BulletedList = styled.ul`
	margin-top: 16px;
	margin-left: 16px;
	list-style: disc outside none;
	margin-bottom: 24px;
`;

const BulletedListItem = styled.li`
	list-style: disc outside none;
	line-height: 20px;
`;

const BottomBar = styled.div`
	display: flex;
	padding: 16px 24px;
	border-top: 1px solid ${colors.gray};
	justify-content: center;
	flex-shrink: 0;
	border-top: 1px solid ${colors.grey10};
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: white;
	}
`;

const DisplayImage = styled.img`
	border-radius: 16px;
	height: 300px;
	object-fit: cover;
	width: 100%;
	margin-top: 24px;
`;

const Text = styled.p`
	margin-bottom: 16px;
	line-height: 20px;
`;
