import React, { useState } from 'react';
import styled from 'styled-components';

import api from 'api';
import { TopBar } from 'lib/TopBar';
import { Conversation, ConversationAction, ConversationStatus } from 'models/inbox';
import { Modal } from 'lib/Modal';
import Button from 'lib/Button';
import { InboxType } from './types';
import theme from 'config/theme';
import { SelectOption } from 'lib/Select';
import RadioInput from './RadioInput';
import TextAreaInput from './TextAreaInput';
import { notifyError, notifySuccess } from 'lib/Notifications';

const { colors } = theme;

const CancelEnquiryModal = ({
	chat,
	inboxType,
	onClose,
	onSuccess,
}: {
	inboxType: InboxType;
	chat: Conversation;
	onClose(): void;
	onSuccess(): void;
}) => {
	const [selectedOption, setSelectedOption] = useState<SelectOption>();
	const [otherText, setOtherText] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const getOptions = (): SelectOption[] => {
		return [
			'Property no longer available',
			'Not a good fit',
			`Can’t get a hold of the ${inboxType === InboxType.Tenant ? 'landlord' : 'tenant'}`,
			'Found another place',
			'Move in date is not what I want',
			'Other',
		].map(text => ({ value: text, label: text }));
	};

	const handleSubmit = async () => {
		if (
			!selectedOption ||
			(selectedOption.value === 'Other' && (otherText.length < 10 || otherText.length > 500))
		) {
			return;
		}

		setIsLoading(true);
		const res = await api.inbox.updateInbox(chat.uuid, {
			status: ConversationStatus.Cancelled,
			status_reason: selectedOption?.value,
			status_message: otherText || undefined,
		});
		await api.inbox.executeAction(chat.uuid, ConversationAction.Archive);
		setIsLoading(false);

		if (res.status !== 200) {
			notifyError('Failed to cancel enquiry');
			return;
		}

		onClose();
		notifySuccess('Enquiry cancelled successfully');
		onSuccess();
	};

	return (
		<Modal mobileBottomsheet onBackgroundClick={onClose}>
			<TopBar title="Cancel Enquiry" onClose={onClose} />
			<ContentContainer>
				<SubTitle>Why are you wanting to cancel this enquiry?</SubTitle>
				<Text>Please select a reason</Text>
				<RadioOptionsContainer>
					<RadioInput
						options={getOptions()}
						selectedOption={selectedOption}
						onOptionChange={setSelectedOption}
					/>
				</RadioOptionsContainer>
				{selectedOption?.value === 'Other' && (
					<>
						<Text>
							Mind sharing a message with the{' '}
							{inboxType === InboxType.Tenant ? 'landlord' : 'tenant'} so they understand why?
						</Text>
						<TextAreaInput value={otherText} setValue={setOtherText} />
					</>
				)}
			</ContentContainer>
			<BottomBar>
				<Button
					isLoading={isLoading}
					isDisabled={
						!selectedOption ||
						(selectedOption.value === 'Other' && (otherText.length < 10 || otherText.length > 500))
					}
					onClick={handleSubmit}
				>
					Submit
				</Button>
			</BottomBar>
		</Modal>
	);
};

const ContentContainer = styled.div`
	padding: 16px;
	overflow-y: auto;
`;

const BottomBar = styled.div`
	border-top: 1px solid ${colors.grey10};
	padding: 16px;
`;

const Text = styled.div`
	font-size: 16px;
	margin-bottom: 4px;
	line-height: 150%;
`;

const SubTitle = styled(Text)`
	font-weight: 600;
`;

const RadioOptionsContainer = styled.div`
	margin-top: 8px;
	margin-bottom: 16px;
`;

export default CancelEnquiryModal;
