import client from '../utils/fetch-client';

let qs = require('qs');
const path = '/v2/search';

export default {
	getSearchResults(term = '') {
		return client.get(`${path}/searchlist?q=${term}`);
	},

	getUniversity(id) {
		return client.get(`${path}/university/${id}`);
	},

	getLocation(id) {
		return client.get(`${path}/location/${id}`);
	},

	searchDigs(params = {}) {
		let queryParams = qs.stringify(params, { indices: false });
		return client.get(`${path}/digs?${queryParams}`);
	},

	getNextPage(searchQuery) {
		return client.get(`${path}/digs${searchQuery}`);
	},

	digs(id) {
		return client.get(`${path}/digs/${id}`);
	},

	digsPreview(id) {
		return client.get(`${path}/digs/${id}/preview`);
	},

	getSearchFeatures() {
		return client.get(`${path}/digs/features`);
	},

	getSimilarListings(listingId) {
		return client.get(`${path}/digs/${listingId}/similar`);
	},

	reportListing(listingId, reason, message) {
		return client.post(`${path}/digs/${listingId}/report`, {
			body: { reason: reason, message: message },
		});
	},

	getCommunity(listingId) {
		return client.get(`${path}/digs/${listingId}/community`);
	},
	getLandlordStats(landlordId) {
		return client.get(`${path}/landlord-stats/${landlordId}`);
	},

	getPriceFilter(countryCode) {
		return client.get(`${path}/price-filter/${countryCode}`);
	},
};
