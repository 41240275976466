import React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import { TopBar } from 'lib/TopBar';
import Button from 'lib/Button';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';
import ModalImage from 'assets/images/invoicing/modalimage.png';
import userContainer from 'containers/user';
import { Subscribe } from 'unstated';

const { colors } = theme;

interface InvoicesProductTourModalContentProps {
	onView(): void;
	onClose(): void;
}

const InvoicesProductTourModalContent = ({
	onView,
	onClose,
}: InvoicesProductTourModalContentProps) => {
	return (
		<>
			<TopBar title="New Feature!" subTitle="Invoices" closeOnRight onClose={onClose} />
			<Container>
				<Text>
					Hello{' '}
					<Subscribe to={[userContainer]}>
						{user => {
							return <>{user.state.v2.first_name}</>;
						}}
					</Subscribe>
				</Text>
				<Text>
					Exciting news! We've recently launched our new invoice page. Any invoices you receive for
					placing tenants on DigsConnect, will be listed here. The new invoice page allows you to:
				</Text>
				<Ul>
					<Li>View breakdowns of your invoice</Li>
					<Li>Download and share invoices</Li>
					<Li>Query invoices</Li>
				</Ul>
				<Text>
					<b>Please take a moment to view our Product Tour of the new invoice page.</b>
				</Text>
				<Image src={ModalImage} />
			</Container>
			<ModalActionBar>
				<Button onClick={onView}>View Tour</Button>
			</ModalActionBar>
		</>
	);
};

export default InvoicesProductTourModalContent;

const Container = styled.div`
	padding: 24px;
	overflow: auto;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		padding-bottom: 100px;
	}
`;

const ModalActionBar = styled.div`
	flex-shrink: 0;
	border-top: 1px solid ${colors.grey10};
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 24px;
	padding-top: 16px;
	gap: 16px;

	@media (max-width: 768px) {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: white;
		min-width: 320px;
	}
`;

const Text = styled.p`
	color: ${colors.darkBlue};
	line-height: 21px;
	margin-bottom: 16px;
`;

const Image = styled.img`
	margin-bottom: 24px;
`;

const Ul = styled.ul`
	list-style-type: disc;
	margin-left: 24px;
	margin-bottom: 16px;
`;

const Li = styled.li`
	list-style-type: disc;
	margin-bottom: 8px;
`;
