import theme from 'config/theme';
import React from 'react';
import styled from 'styled-components';

const { colors } = theme;

const Button = styled.button.attrs({
	type: 'button',
})`
	background-color: ${colors.transparent};
	padding: 10px;
	margin-right: -10px;
	outline: none;
`;

const BurgerMenu = ({ color, visible, ...props }) => (
	<Button {...props}>
		{visible ? (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24.041"
				height="24.042"
				viewBox="0 0 24.041 24.042"
			>
				<g transform="translate(-.979 -.979)" fill="#fff">
					<rect width="30" height="4" rx="2" transform="rotate(-45 27.278 9.914)" />
					<rect width="30" height="4" rx="2" transform="rotate(45 .722 5.086)" />
				</g>
			</svg>
		) : (
			<svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 30 24">
				<rect width="30" height="4" rx="2" fill={color} />
				<rect width="30" height="4" rx="2" transform="translate(0 10)" fill={color} />
				<rect width="15" height="4" rx="2" transform="translate(15 20)" fill={color} />
			</svg>
		)}
	</Button>
);

BurgerMenu.defaultProps = {
	color: colors.white,
};

export default BurgerMenu;
