import * as React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import { validationErrors } from 'config/validationErrors';

const { colors, inputs } = theme;

interface InputProps {
	placeholder: string;
	value?: string | number;
	type?: string;
	suffix?: string;
	isErrorState?: boolean;
	errorMessage?: string;
	disabled?: boolean;
	pattern?: string;
	prefixIcon?: any;
	suffixIcon?: any;
	prefix?: string;
	hideErrorMargin?: boolean;
	lightBackground?: boolean;
	fontSize?: number;
	onChange(value: string | number): void;
	inputRef?: React.RefObject<HTMLInputElement>;
}

export const Input = ({
	placeholder,
	value,
	suffix,
	pattern,
	prefixIcon,
	suffixIcon,
	prefix,
	type = 'text',
	isErrorState = false,
	errorMessage = validationErrors.InvalidField,
	disabled = false,
	hideErrorMargin = false,
	lightBackground = false,
	fontSize,
	onChange,
	inputRef,
}: InputProps) => (
	<MainContainer>
		<InputContainer noMargin={hideErrorMargin} lightBackground={lightBackground}>
			{Boolean(prefixIcon) && prefixIcon}
			{Boolean(prefix) && <PrefixContainer fontSize={fontSize}>{prefix}</PrefixContainer>}
			<StandardInput
				ref={inputRef}
				type={type}
				placeholder={placeholder}
				pattern={pattern}
				value={value}
				disabled={disabled}
				onChange={event => onChange(event.target.value)}
				lightBackground={lightBackground}
			/>
			{!!suffix && <Suffix>{suffix}</Suffix>}
			{Boolean(suffixIcon) && suffixIcon}
		</InputContainer>
		{!!isErrorState && !!errorMessage && <ValidationError>{errorMessage}</ValidationError>}
	</MainContainer>
);

const InputContainer = styled.div<{ noMargin?: boolean; lightBackground?: boolean }>`
	background-color: ${({ lightBackground }) => (lightBackground ? colors.grey01 : colors.grey05)};
	border-radius: ${inputs.borderRadius};
	padding: 16px;
	height: ${inputs.height};
	width: 100%;
	margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '16px')};
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const PrefixContainer = styled.div<{ fontSize?: number }>`
	display: inline-block;
	align-items: center;
	${({ fontSize }) => (!!fontSize ? 'font-size: ${fontSize}px;' : 'font-size: auto;')};
	font-weight: 500;
`;

const StandardInput = styled.input<{ lightBackground?: boolean; fontSize?: number }>`
	background-color: ${({ lightBackground }) => (lightBackground ? colors.grey01 : colors.grey05)};
	color: ${colors.darkBlue};
	width: 100%;
	${({ fontSize }) => (!!fontSize ? 'font-size: ${fontSize}px;' : 'font-size: auto;')};
	outline: none;
	&:disabled {
		color: ${colors.grey60};
		&:hover {
			cursor: not-allowed;
		}
	}
	::placeholder {
		text-overflow: ellipsis;
	}
`;

const Suffix = styled.div`
	flex-grow: 0;
	color: ${colors.darkGray};
	font-size: 14px;
	font-weight: 500;
	margin-left: 8px;
`;

const ValidationError = styled.div`
	font-size: 12px;
	color: ${colors.pink};
	margin-top: -16px;
	padding: 4px 16px;
`;

const MainContainer = styled.div`
	width: 100%;
`;
