// DOCS: https://www.digsconnect.com/docs/web/#user

import client from '../utils/fetch-client';

const path = '/v2/user';

export default {
	getProfile() {
		return client.get(`${path}`);
	},

	getStudentProfile() {
		return client.get(`${path}/student-profile`);
	},

	updateStudentProfile(values = {}) {
		return client.put(`${path}/student-profile`, {
			body: { ...values },
		});
	},

	getBookingProfile() {
		return client.get(`${path}/booking-profile?new_serializer=true`);
	},

	updateBookingProfile(values = {}) {
		return client.put(`${path}/booking-profile?new_serializer=true`, {
			body: { ...values },
		});
	},

	getInstitutions(value) {
		// TODO: remove is_new once deployed and backend logic has been removed
		return client.get(
			`${path}/student-profile/institutions${value ? '?search_term=' + value + '&' : '?'}is_new=1`,
		);
	},

	createUserInstitution(values = {}) {
		return client.post(`${path}/create-user-institution?new_serializer=true`, {
			body: { ...values },
		});
	},

	getCourses() {
		return client.get(`${path}/student-profile/courses`);
	},

	updateProfile(values = {}) {
		return client.put(`${path}`, {
			body: { ...values },
		});
	},

	getUserDocuments() {
		return client.get(`${path}/documents`);
	},

	getGenderOptions() {
		return client.get(`${path}/profile/gender-choices`);
	},

	getLandlordStats() {
		return client.get(`${path}/landlord/stats`);
	},
	getLandlordGraph(value = '') {
		return client.get(`${path}/landlord/graph?graph_number=${value}`);
	},
	getNotifications() {
		return client.get(`${path}/notifications`);
	},
	getNotificationSettings() {
		return client.get(`${path}/notification-management`);
	},
	updateNotificationSettings(payload) {
		return client.put(`${path}/notification-management`, {
			body: payload,
		});
	},
	uploadProfilePhoto(formData) {
		return client.post(`${path}/profile-picture`, {
			body: formData,
			unsetContentType: true,
		});
	},
	deleteUser() {
		return client.delete(`${path}/delete`);
	},
};
