import React from 'react';
import styled from 'styled-components';
import InputRange from 'react-input-range';

import './styles.scss';
import numberFormatService from 'services/numberFormatService';
import { InfoText } from 'lib/InfoText';

const InputRangeContainer = styled.div`
	padding-top: 8px;
	padding-left: 24px;
	padding-right: 24px;
	margin: 0 8px;
`;

const TitleContainer = styled.div`
	display: flex;
	justify-content: center;
`;

// TODO: Remove below export and only export as default
export const PriceRange = ({ max, min, values, setValue, symbol }) => {
	const minDisplay = numberFormatService.formatCurrency(values.min, symbol);
	const maxDisplay =
		values.max === max
			? `${numberFormatService.formatCurrency(values.max, symbol)}+`
			: `${numberFormatService.formatCurrency(values.max, symbol)}`;

	return (
		<>
			<TitleContainer>
				<InfoText>
					{minDisplay} - {maxDisplay}
				</InfoText>
			</TitleContainer>
			<InputRangeContainer>
				<InputRange
					maxValue={max}
					minValue={min}
					value={values}
					onChange={value => setValue(value)}
					formatLabel={value => `${numberFormatService.formatCurrency(value, symbol)}`}
					step={100}
				/>
			</InputRangeContainer>
		</>
	);
};

export default PriceRange;
