import React, { useState } from 'react';

import ReactSelect, { components } from 'react-select';
import styled from 'styled-components';

import theme from 'config/theme';
import { validationErrors } from 'config/validationErrors';
import { NoOptionsMessage } from '../CustomNoOptions';
import { ReactComponent as SearchIcon } from 'assets/images/icons/icon-search.svg';

const { colors, inputs } = theme;

const getCustomStyles = (
	isOutline: boolean,
	isThin: boolean,
	isDisabled: boolean,
	isStyledPlaceholder: boolean,
) => ({
	control: (provided: any) => ({
		...provided,
		backgroundColor: isOutline ? colors.white : colors.grey05,
		color: isDisabled ? colors.grey20 : colors.darkBlue,
		opacity: isDisabled ? 0.6 : 1,
		padding: '0 12px',
		border: isOutline ? `2px solid ${colors.darkBlue}` : 'none',
		borderRadius: inputs.borderRadius,
		boxShadow: 'none',
		height: isThin ? 28 : inputs.height,
		fontWeight: isOutline ? 600 : 400,
	}),
	container: (provided: any) => ({
		...provided,
		width: '100%',
		marginBottom: '16px',
	}),
	indicatorSeparator: () => ({}),
	dropdownIndicator: (provided: any) => ({
		...provided,
		color: 'black',
	}),
	menu: (provided: any) => ({
		...provided,
		backgroundColor: 'white',
		borderRadius: '16px',
		padding: '10px',
		zIndex: '1000',
	}),
	option: (provided: any, state: any) => ({
		...provided,
		color: state.isFocused && colors.pink,
		padding: '10px',
		boxShadow: 'none',
		background: 'none',
	}),
	singleValue: (provided: any) => ({
		...provided,
		color: colors.darkBlue,
	}),
	placeholder: (provided: any) => ({
		...provided,
		color: isStyledPlaceholder ? colors.darkBlue : provided.color,
	}),
});

export interface SelectOption {
	label: string;
	value: any;
	searchTerms?: string[];
}

interface SelectProps {
	options: SelectOption[];
	selectedOption?: SelectOption;
	placeholder?: string;
	isErrorState?: boolean;
	isOutline?: boolean;
	errorMessage?: string;
	onDropDownClick?: any;
	onNoInstitutionFoundClick?: any;
	hasSearchIcon?: boolean;
	isThin?: boolean;
	isDisabled?: boolean;
	isStyledPlaceholder?: boolean;
	onChange(value: SelectOption): void;
	onInputChange?(value?: string): void;
}

const ValidationError = styled.div`
	font-size: 12px;
	color: ${colors.pink};
	margin-top: -16px;
	padding: 4px 16px;
`;

export const Select = ({
	options,
	selectedOption,
	placeholder = '',
	isErrorState = false,
	isOutline = false,
	isThin = false,
	isDisabled = false,
	isStyledPlaceholder = false,
	errorMessage = validationErrors.InvalidField,
	onChange,
	onInputChange,
	onDropDownClick,
	onNoInstitutionFoundClick,
}: SelectProps) => {
	const [searchValue, setSearchValue] = useState<String>();

	const handleInputChange = (value: string) => {
		if (isDisabled) {
			return;
		}
		setSearchValue(value);
		onInputChange && onInputChange(value);
	};

	return (
		<div>
			<ReactSelect
				searchValue={searchValue}
				title="Can't find your institution?"
				buttonText="Add Institution"
				onButtonClick={onNoInstitutionFoundClick}
				components={onNoInstitutionFoundClick && { NoOptionsMessage, DropdownIndicator }}
				onMenuOpen={onDropDownClick}
				options={options}
				filterOption={(candidate: { data: SelectOption }, input: string) => {
					const option = candidate.data;
					if (typeof option.label != 'string') {
						return true;
					}
					return (
						option.label.toLowerCase().includes(input.toLowerCase()) ||
						(option.searchTerms || []).some(term =>
							term.toLowerCase().includes(input.toLowerCase()),
						)
					);
				}}
				styles={getCustomStyles(isOutline, isThin, isDisabled, isStyledPlaceholder)}
				value={selectedOption}
				onChange={(option: any) => onChange(option)}
				placeholder={placeholder}
				onInputChange={handleInputChange}
				isDisabled={isDisabled}
			/>
			{isErrorState && !!errorMessage && <ValidationError>{errorMessage}</ValidationError>}
		</div>
	);
};

const DropdownIndicator = (props: any) => {
	return (
		<components.DropdownIndicator {...props}>
			<SearchIcon />
		</components.DropdownIndicator>
	);
};
