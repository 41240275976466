import api from 'api';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';

declare var window: any;

export enum EventName {
	// removed to force use of "trackPage" method
	// PageView = 'page_view',
	ButtonClick = 'button_click',
	Login = 'login',
	Signup = 'signup',
	SetUser = 'set_user',
	EnquirySubmit = 'enquiry_submit',
	SimilarListingClick = 'similar_listing_click',
	PartnersListingClick = 'partners_listing_click',
	EnquiryEvent = 'enquiry_event',
	CreateListingEvent = 'create_listing_event',
	FavouriteClick = 'favourite_listing_click',
	ListingSearch = 'listing_search',
}

export enum PageName {
	Landing = 'landing',
	LandingLandlord = 'landing_landlord',
	LandingMsdf = 'landing_msdf',
	Search = 'search',
	Listing = 'listing',
	Enquiry = 'enquiry',
	Login = 'login',
	Signup = 'signup',
	Partners = 'partners_page',
	CreateListing = 'create_listing',
	ListingPreview = 'listing_preview',
}

export enum EnquiryTrackingSteps {
	EnquiryStart = 'enquiry_start',
	EnquiryPersonalInfo = 'enquiry_about_yourself',
	EnquiryFundingInfo = 'enquiry_leasing_arrangements',
	EnquiryMobileVerify = 'enquiry_verification',
	EnquiryAdditionalInfo = 'enquiry_connect_with_landlord',
	EnquirySuccess = 'enquiry_success',
	EnquiryFailed = 'enquiry_failed',
	UndefinedEnquiryPage = 'undefined_enquiry_page',
}

export enum CreateListingStepEvent {
	Unknown = '',
	HowTo = 'create_listing_how_to',
	YourListingPropertyType = 'create_listing_property_type',
	YourListingAvailability = 'create_listing_availability',
	YourListingRooms = 'create_listing_rooms',
	YourListingDetails = 'create_listing_details',
	Features = 'create_listing_features',
	OccupantsCapacity = 'create_listing_capacity',
	OccupantsRules = 'create_listing_rules',
	LocationAtmosphere = 'create_listing_atmosphere',
	LocationAddress = 'create_listing_address',
	PicturesUpload = 'create_listing_photos',
	PicturesVideo = 'create_listing_video',
	NameListing = 'create_listing_name_listing',
	Pricing = 'create_listing_pricing',
	Preview = 'create_listing_preview',
	Success = 'create_listing_success',
}

export enum LoginMethod {
	Default = 'default',
	Google = 'google',
	Facebook = 'facebook',
}

export interface EventParameters {
	button_text?: string;
	button_name?: string;
	auth_method?: string;
	digs_user_id?: string;
	similar_listing_id?: string;
	partners_listing_id?: string;
	// Enquiry Event vars:
	enquiry_step?: string;
	create_listing_step?: CreateListingStepEvent;
	logged_in?: boolean;
	mobile_verified?: boolean;
	originally_logged_in?: boolean;
	originally_verified?: boolean;
	digs_listing_uuid?: string;
	is_favourite?: boolean;
}

const trackEvent = async (eventName: EventName, parameters?: EventParameters) => {
	if (featureFlagContainer.isEnabled(FeatureFlag.Analytics)) {
		if (eventName === EventName.SetUser) {
			trackUserBookingProfile(eventName, parameters);
			return;
		}

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: eventName,
			...parameters,
		});
	}
};

const trackUserBookingProfile = async (eventName: EventName, parameters?: EventParameters) => {
	window.dataLayer = window.dataLayer || [];
	
	if (!parameters?.digs_user_id) {
		window.dataLayer.push({
			event: eventName,
			...parameters,
			university: '',
			university_campus: '',
			level_of_study: '',
			occupant_type: '',
			occupation: '',
			payment_provider: '',
		});
		return;
	}

	const bookingProfile = await api.userV2.getBookingProfile();

	window.dataLayer.push({
		event: eventName,
		...parameters,
		university: bookingProfile.data.institution,
		university_campus: bookingProfile.data.campus?.name || '',
		level_of_study: bookingProfile.data.level_of_study,
		occupant_type: bookingProfile.data.occupant_type,
		occupation: bookingProfile.data.occupation,
		payment_provider: bookingProfile.data.payment_provider,
	});
};

const trackPage = (pageName: PageName, parameters?: EventParameters) => {
	if (featureFlagContainer.isEnabled(FeatureFlag.Analytics)) {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'page_view',
			page_name: pageName,
			...parameters,
		});
	}
};

export const analyticsService = {
	trackEvent,
	trackPage,
};
