export const countryCodes = [
	{
		dial_code: '93',
		flag: '\ud83c\udde6\ud83c\uddeb',
		code: 'AF',
		country: 'Afghanistan',
	},
	{
		dial_code: '358',
		flag: '\ud83c\udde6\ud83c\uddfd',
		code: 'AX',
		country: 'Aland Islands',
	},
	{
		dial_code: '355',
		flag: '\ud83c\udde6\ud83c\uddf1',
		code: 'AL',
		country: 'Albania',
	},
	{
		dial_code: '213',
		flag: '\ud83c\udde9\ud83c\uddff',
		code: 'DZ',
		country: 'Algeria',
	},
	{
		dial_code: '1684',
		flag: '\ud83c\udde6\ud83c\uddf8',
		code: 'AS',
		country: 'American Samoa',
	},
	{
		dial_code: '376',
		flag: '\ud83c\udde6\ud83c\udde9',
		code: 'AD',
		country: 'Andorra',
	},
	{
		dial_code: '244',
		flag: '\ud83c\udde6\ud83c\uddf4',
		code: 'AO',
		country: 'Angola',
	},
	{
		dial_code: '1264',
		flag: '\ud83c\udde6\ud83c\uddee',
		code: 'AI',
		country: 'Anguilla',
	},
	{
		dial_code: '672',
		flag: '\ud83c\udde6\ud83c\uddf6',
		code: 'AQ',
		country: 'Antarctica',
	},
	{
		dial_code: '1268',
		flag: '\ud83c\udde6\ud83c\uddec',
		code: 'AG',
		country: 'Antigua and Barbuda',
	},
	{
		dial_code: '54',
		flag: '\ud83c\udde6\ud83c\uddf7',
		code: 'AR',
		country: 'Argentina',
	},
	{
		dial_code: '374',
		flag: '\ud83c\udde6\ud83c\uddf2',
		code: 'AM',
		country: 'Armenia',
	},
	{
		dial_code: '297',
		flag: '\ud83c\udde6\ud83c\uddfc',
		code: 'AW',
		country: 'Aruba',
	},
	{
		dial_code: '61',
		flag: '\ud83c\udde6\ud83c\uddfa',
		code: 'AU',
		country: 'Australia',
	},
	{
		dial_code: '43',
		flag: '\ud83c\udde6\ud83c\uddf9',
		code: 'AT',
		country: 'Austria',
	},
	{
		dial_code: '994',
		flag: '\ud83c\udde6\ud83c\uddff',
		code: 'AZ',
		country: 'Azerbaijan',
	},
	{
		dial_code: '1242',
		flag: '\ud83c\udde7\ud83c\uddf8',
		code: 'BS',
		country: 'Bahamas',
	},
	{
		dial_code: '973',
		flag: '\ud83c\udde7\ud83c\udded',
		code: 'BH',
		country: 'Bahrain',
	},
	{
		dial_code: '880',
		flag: '\ud83c\udde7\ud83c\udde9',
		code: 'BD',
		country: 'Bangladesh',
	},
	{
		dial_code: '1246',
		flag: '\ud83c\udde7\ud83c\udde7',
		code: 'BB',
		country: 'Barbados',
	},
	{
		dial_code: '375',
		flag: '\ud83c\udde7\ud83c\uddfe',
		code: 'BY',
		country: 'Belarus',
	},
	{
		dial_code: '32',
		flag: '\ud83c\udde7\ud83c\uddea',
		code: 'BE',
		country: 'Belgium',
	},
	{
		dial_code: '501',
		flag: '\ud83c\udde7\ud83c\uddff',
		code: 'BZ',
		country: 'Belize',
	},
	{
		dial_code: '229',
		flag: '\ud83c\udde7\ud83c\uddef',
		code: 'BJ',
		country: 'Benin',
	},
	{
		dial_code: '1441',
		flag: '\ud83c\udde7\ud83c\uddf2',
		code: 'BM',
		country: 'Bermuda',
	},
	{
		dial_code: '975',
		flag: '\ud83c\udde7\ud83c\uddf9',
		code: 'BT',
		country: 'Bhutan',
	},
	{
		dial_code: '591',
		flag: '\ud83c\udde7\ud83c\uddf4',
		code: 'BO',
		country: 'Bolivia',
	},
	{
		dial_code: '387',
		flag: '\ud83c\udde7\ud83c\udde6',
		code: 'BA',
		country: 'Bosnia and Herzegovina',
	},
	{
		dial_code: '267',
		flag: '\ud83c\udde7\ud83c\uddfc',
		code: 'BW',
		country: 'Botswana',
	},
	{
		dial_code: '47',
		flag: '\ud83c\udde7\ud83c\uddfb',
		code: 'BV',
		country: 'Bouvet Island',
	},
	{
		dial_code: '55',
		flag: '\ud83c\udde7\ud83c\uddf7',
		code: 'BR',
		country: 'Brazil',
	},
	{
		dial_code: '246',
		flag: '\ud83c\uddee\ud83c\uddf4',
		code: 'IO',
		country: 'British Indian Ocean Territory',
	},
	{
		dial_code: '1284',
		flag: '\ud83c\uddfb\ud83c\uddec',
		code: 'VG',
		country: 'British Virgin Islands',
	},
	{
		dial_code: '673',
		flag: '\ud83c\udde7\ud83c\uddf3',
		code: 'BN',
		country: 'Brunei',
	},
	{
		dial_code: '359',
		flag: '\ud83c\udde7\ud83c\uddec',
		code: 'BG',
		country: 'Bulgaria',
	},
	{
		dial_code: '226',
		flag: '\ud83c\udde7\ud83c\uddeb',
		code: 'BF',
		country: 'Burkina Faso',
	},
	{
		dial_code: '257',
		flag: '\ud83c\udde7\ud83c\uddee',
		code: 'BI',
		country: 'Burundi',
	},
	{
		dial_code: '855',
		flag: '\ud83c\uddf0\ud83c\udded',
		code: 'KH',
		country: 'Cambodia',
	},
	{
		dial_code: '237',
		flag: '\ud83c\udde8\ud83c\uddf2',
		code: 'CM',
		country: 'Cameroon',
	},
	{
		dial_code: '1',
		flag: '\ud83c\udde8\ud83c\udde6',
		code: 'CA',
		country: 'Canada',
	},
	{
		dial_code: '238',
		flag: '\ud83c\udde8\ud83c\uddfb',
		code: 'CV',
		country: 'Cape Verde',
	},
	{
		dial_code: '1345',
		flag: '\ud83c\uddf0\ud83c\uddfe',
		code: 'KY',
		country: 'Cayman Islands',
	},
	{
		dial_code: '236',
		flag: '\ud83c\udde8\ud83c\uddeb',
		code: 'CF',
		country: 'Central African Republic',
	},
	{
		dial_code: '235',
		flag: '\ud83c\uddf9\ud83c\udde9',
		code: 'TD',
		country: 'Chad',
	},
	{
		dial_code: '56',
		flag: '\ud83c\udde8\ud83c\uddf1',
		code: 'CL',
		country: 'Chile',
	},
	{
		dial_code: '86',
		flag: '\ud83c\udde8\ud83c\uddf3',
		code: 'CN',
		country: 'China',
	},
	{
		dial_code: '61',
		flag: '\ud83c\udde8\ud83c\uddfd',
		code: 'CX',
		country: 'Christmas Island',
	},
	{
		dial_code: '61',
		flag: '\ud83c\udde8\ud83c\udde8',
		code: 'CC',
		country: 'Cocos Islands',
	},
	{
		dial_code: '57',
		flag: '\ud83c\udde8\ud83c\uddf4',
		code: 'CO',
		country: 'Colombia',
	},
	{
		dial_code: '269',
		flag: '\ud83c\uddf0\ud83c\uddf2',
		code: 'KM',
		country: 'Comoros',
	},
	{
		dial_code: '682',
		flag: '\ud83c\udde8\ud83c\uddf0',
		code: 'CK',
		country: 'Cook Islands',
	},
	{
		dial_code: '506',
		flag: '\ud83c\udde8\ud83c\uddf7',
		code: 'CR',
		country: 'Costa Rica',
	},
	{
		dial_code: '385',
		flag: '\ud83c\udded\ud83c\uddf7',
		code: 'HR',
		country: 'Croatia',
	},
	{
		dial_code: '53',
		flag: '\ud83c\udde8\ud83c\uddfa',
		code: 'CU',
		country: 'Cuba',
	},
	{
		dial_code: '357',
		flag: '\ud83c\udde8\ud83c\uddfe',
		code: 'CY',
		country: 'Cyprus',
	},
	{
		dial_code: '420',
		flag: '\ud83c\udde8\ud83c\uddff',
		code: 'CZ',
		country: 'Czechia',
	},
	{
		dial_code: '243',
		flag: '\ud83c\udde8\ud83c\udde9',
		code: 'CD',
		country: 'Democratic Republic of the Congo',
	},
	{
		dial_code: '45',
		flag: '\ud83c\udde9\ud83c\uddf0',
		code: 'DK',
		country: 'Denmark',
	},
	{
		dial_code: '253',
		flag: '\ud83c\udde9\ud83c\uddef',
		code: 'DJ',
		country: 'Djibouti',
	},
	{
		dial_code: '1767',
		flag: '\ud83c\udde9\ud83c\uddf2',
		code: 'DM',
		country: 'Dominica',
	},
	{
		dial_code: '1',
		flag: '\ud83c\udde9\ud83c\uddf4',
		code: 'DO',
		country: 'Dominican Republic',
	},
	{
		dial_code: '593',
		flag: '\ud83c\uddea\ud83c\udde8',
		code: 'EC',
		country: 'Ecuador',
	},
	{
		dial_code: '20',
		flag: '\ud83c\uddea\ud83c\uddec',
		code: 'EG',
		country: 'Egypt',
	},
	{
		dial_code: '503',
		flag: '\ud83c\uddf8\ud83c\uddfb',
		code: 'SV',
		country: 'El Salvador',
	},
	{
		dial_code: '240',
		flag: '\ud83c\uddec\ud83c\uddf6',
		code: 'GQ',
		country: 'Equatorial Guinea',
	},
	{
		dial_code: '291',
		flag: '\ud83c\uddea\ud83c\uddf7',
		code: 'ER',
		country: 'Eritrea',
	},
	{
		dial_code: '372',
		flag: '\ud83c\uddea\ud83c\uddea',
		code: 'EE',
		country: 'Estonia',
	},
	{
		dial_code: '251',
		flag: '\ud83c\uddea\ud83c\uddf9',
		code: 'ET',
		country: 'Ethiopia',
	},
	{
		dial_code: '500',
		flag: '\ud83c\uddeb\ud83c\uddf0',
		code: 'FK',
		country: 'Falkland Islands',
	},
	{
		dial_code: '298',
		flag: '\ud83c\uddeb\ud83c\uddf4',
		code: 'FO',
		country: 'Faroe Islands',
	},
	{
		dial_code: '679',
		flag: '\ud83c\uddeb\ud83c\uddef',
		code: 'FJ',
		country: 'Fiji',
	},
	{
		dial_code: '358',
		flag: '\ud83c\uddeb\ud83c\uddee',
		code: 'FI',
		country: 'Finland',
	},
	{
		dial_code: '33',
		flag: '\ud83c\uddeb\ud83c\uddf7',
		code: 'FR',
		country: 'France',
	},
	{
		dial_code: '594',
		flag: '\ud83c\uddec\ud83c\uddeb',
		code: 'GF',
		country: 'French Guiana',
	},
	{
		dial_code: '689',
		flag: '\ud83c\uddf5\ud83c\uddeb',
		code: 'PF',
		country: 'French Polynesia',
	},
	{
		dial_code: '262',
		flag: '\ud83c\uddf9\ud83c\uddeb',
		code: 'TF',
		country: 'French Southern Territories',
	},
	{
		dial_code: '241',
		flag: '\ud83c\uddec\ud83c\udde6',
		code: 'GA',
		country: 'Gabon',
	},
	{
		dial_code: '220',
		flag: '\ud83c\uddec\ud83c\uddf2',
		code: 'GM',
		country: 'Gambia',
	},
	{
		dial_code: '995',
		flag: '\ud83c\uddec\ud83c\uddea',
		code: 'GE',
		country: 'Georgia',
	},
	{
		dial_code: '49',
		flag: '\ud83c\udde9\ud83c\uddea',
		code: 'DE',
		country: 'Germany',
	},
	{
		dial_code: '233',
		flag: '\ud83c\uddec\ud83c\udded',
		code: 'GH',
		country: 'Ghana',
	},
	{
		dial_code: '350',
		flag: '\ud83c\uddec\ud83c\uddee',
		code: 'GI',
		country: 'Gibraltar',
	},
	{
		dial_code: '30',
		flag: '\ud83c\uddec\ud83c\uddf7',
		code: 'GR',
		country: 'Greece',
	},
	{
		dial_code: '299',
		flag: '\ud83c\uddec\ud83c\uddf1',
		code: 'GL',
		country: 'Greenland',
	},
	{
		dial_code: '1473',
		flag: '\ud83c\uddec\ud83c\udde9',
		code: 'GD',
		country: 'Grenada',
	},
	{
		dial_code: '590',
		flag: '\ud83c\uddec\ud83c\uddf5',
		code: 'GP',
		country: 'Guadeloupe',
	},
	{
		dial_code: '1671',
		flag: '\ud83c\uddec\ud83c\uddfa',
		code: 'GU',
		country: 'Guam',
	},
	{
		dial_code: '502',
		flag: '\ud83c\uddec\ud83c\uddf9',
		code: 'GT',
		country: 'Guatemala',
	},
	{
		dial_code: '44',
		flag: '\ud83c\uddec\ud83c\uddec',
		code: 'GG',
		country: 'Guernsey',
	},
	{
		dial_code: '224',
		flag: '\ud83c\uddec\ud83c\uddf3',
		code: 'GN',
		country: 'Guinea',
	},
	{
		dial_code: '245',
		flag: '\ud83c\uddec\ud83c\uddfc',
		code: 'GW',
		country: 'Guinea-Bissau',
	},
	{
		dial_code: '592',
		flag: '\ud83c\uddec\ud83c\uddfe',
		code: 'GY',
		country: 'Guyana',
	},
	{
		dial_code: '509',
		flag: '\ud83c\udded\ud83c\uddf9',
		code: 'HT',
		country: 'Haiti',
	},
	{
		dial_code: '0',
		flag: '\ud83c\udded\ud83c\uddf2',
		code: 'HM',
		country: 'Heard Island and McDonald Islands',
	},
	{
		dial_code: '504',
		flag: '\ud83c\udded\ud83c\uddf3',
		code: 'HN',
		country: 'Honduras',
	},
	{
		dial_code: '852',
		flag: '\ud83c\udded\ud83c\uddf0',
		code: 'HK',
		country: 'Hong Kong',
	},
	{
		dial_code: '36',
		flag: '\ud83c\udded\ud83c\uddfa',
		code: 'HU',
		country: 'Hungary',
	},
	{
		dial_code: '354',
		flag: '\ud83c\uddee\ud83c\uddf8',
		code: 'IS',
		country: 'Iceland',
	},
	{
		dial_code: '91',
		flag: '\ud83c\uddee\ud83c\uddf3',
		code: 'IN',
		country: 'India',
	},
	{
		dial_code: '62',
		flag: '\ud83c\uddee\ud83c\udde9',
		code: 'ID',
		country: 'Indonesia',
	},
	{
		dial_code: '98',
		flag: '\ud83c\uddee\ud83c\uddf7',
		code: 'IR',
		country: 'Iran',
	},
	{
		dial_code: '964',
		flag: '\ud83c\uddee\ud83c\uddf6',
		code: 'IQ',
		country: 'Iraq',
	},
	{
		dial_code: '353',
		flag: '\ud83c\uddee\ud83c\uddea',
		code: 'IE',
		country: 'Ireland',
	},
	{
		dial_code: '44',
		flag: '\ud83c\uddee\ud83c\uddf2',
		code: 'IM',
		country: 'Isle of Man',
	},
	{
		dial_code: '972',
		flag: '\ud83c\uddee\ud83c\uddf1',
		code: 'IL',
		country: 'Israel',
	},
	{
		dial_code: '39',
		flag: '\ud83c\uddee\ud83c\uddf9',
		code: 'IT',
		country: 'Italy',
	},
	{
		dial_code: '225',
		flag: '\ud83c\udde8\ud83c\uddee',
		code: 'CI',
		country: 'Ivory Coast',
	},
	{
		dial_code: '1876',
		flag: '\ud83c\uddef\ud83c\uddf2',
		code: 'JM',
		country: 'Jamaica',
	},
	{
		dial_code: '81',
		flag: '\ud83c\uddef\ud83c\uddf5',
		code: 'JP',
		country: 'Japan',
	},
	{
		dial_code: '44',
		flag: '\ud83c\uddef\ud83c\uddea',
		code: 'JE',
		country: 'Jersey',
	},
	{
		dial_code: '962',
		flag: '\ud83c\uddef\ud83c\uddf4',
		code: 'JO',
		country: 'Jordan',
	},
	{
		dial_code: '7',
		flag: '\ud83c\uddf0\ud83c\uddff',
		code: 'KZ',
		country: 'Kazakhstan',
	},
	{
		dial_code: '254',
		flag: '\ud83c\uddf0\ud83c\uddea',
		code: 'KE',
		country: 'Kenya',
	},
	{
		dial_code: '686',
		flag: '\ud83c\uddf0\ud83c\uddee',
		code: 'KI',
		country: 'Kiribati',
	},
	{
		dial_code: '383',
		flag: '\ud83c\uddfd\ud83c\uddf0',
		code: 'XK',
		country: 'Kosovo',
	},
	{
		dial_code: '965',
		flag: '\ud83c\uddf0\ud83c\uddfc',
		code: 'KW',
		country: 'Kuwait',
	},
	{
		dial_code: '996',
		flag: '\ud83c\uddf0\ud83c\uddec',
		code: 'KG',
		country: 'Kyrgyzstan',
	},
	{
		dial_code: '856',
		flag: '\ud83c\uddf1\ud83c\udde6',
		code: 'LA',
		country: 'Laos',
	},
	{
		dial_code: '371',
		flag: '\ud83c\uddf1\ud83c\uddfb',
		code: 'LV',
		country: 'Latvia',
	},
	{
		dial_code: '961',
		flag: '\ud83c\uddf1\ud83c\udde7',
		code: 'LB',
		country: 'Lebanon',
	},
	{
		dial_code: '266',
		flag: '\ud83c\uddf1\ud83c\uddf8',
		code: 'LS',
		country: 'Lesotho',
	},
	{
		dial_code: '231',
		flag: '\ud83c\uddf1\ud83c\uddf7',
		code: 'LR',
		country: 'Liberia',
	},
	{
		dial_code: '218',
		flag: '\ud83c\uddf1\ud83c\uddfe',
		code: 'LY',
		country: 'Libya',
	},
	{
		dial_code: '423',
		flag: '\ud83c\uddf1\ud83c\uddee',
		code: 'LI',
		country: 'Liechtenstein',
	},
	{
		dial_code: '370',
		flag: '\ud83c\uddf1\ud83c\uddf9',
		code: 'LT',
		country: 'Lithuania',
	},
	{
		dial_code: '352',
		flag: '\ud83c\uddf1\ud83c\uddfa',
		code: 'LU',
		country: 'Luxembourg',
	},
	{
		dial_code: '853',
		flag: '\ud83c\uddf2\ud83c\uddf4',
		code: 'MO',
		country: 'Macao',
	},
	{
		dial_code: '389',
		flag: 'Flag:',
		code: 'MK',
		country: 'Macedonia',
	},
	{
		dial_code: '261',
		flag: '\ud83c\uddf2\ud83c\uddec',
		code: 'MG',
		country: 'Madagascar',
	},
	{
		dial_code: '265',
		flag: '\ud83c\uddf2\ud83c\uddfc',
		code: 'MW',
		country: 'Malawi',
	},
	{
		dial_code: '60',
		flag: '\ud83c\uddf2\ud83c\uddfe',
		code: 'MY',
		country: 'Malaysia',
	},
	{
		dial_code: '960',
		flag: '\ud83c\uddf2\ud83c\uddfb',
		code: 'MV',
		country: 'Maldives',
	},
	{
		dial_code: '223',
		flag: '\ud83c\uddf2\ud83c\uddf1',
		code: 'ML',
		country: 'Mali',
	},
	{
		dial_code: '356',
		flag: '\ud83c\uddf2\ud83c\uddf9',
		code: 'MT',
		country: 'Malta',
	},
	{
		dial_code: '692',
		flag: '\ud83c\uddf2\ud83c\udded',
		code: 'MH',
		country: 'Marshall Islands',
	},
	{
		dial_code: '596',
		flag: '\ud83c\uddf2\ud83c\uddf6',
		code: 'MQ',
		country: 'Martinique',
	},
	{
		dial_code: '222',
		flag: '\ud83c\uddf2\ud83c\uddf7',
		code: 'MR',
		country: 'Mauritania',
	},
	{
		dial_code: '230',
		flag: '\ud83c\uddf2\ud83c\uddfa',
		code: 'MU',
		country: 'Mauritius',
	},
	{
		dial_code: '262',
		flag: '\ud83c\uddfe\ud83c\uddf9',
		code: 'YT',
		country: 'Mayotte',
	},
	{
		dial_code: '52',
		flag: '\ud83c\uddf2\ud83c\uddfd',
		code: 'MX',
		country: 'Mexico',
	},
	{
		dial_code: '691',
		flag: '\ud83c\uddeb\ud83c\uddf2',
		code: 'FM',
		country: 'Micronesia',
	},
	{
		dial_code: '373',
		flag: '\ud83c\uddf2\ud83c\udde9',
		code: 'MD',
		country: 'Moldova',
	},
	{
		dial_code: '377',
		flag: '\ud83c\uddf2\ud83c\udde8',
		code: 'MC',
		country: 'Monaco',
	},
	{
		dial_code: '976',
		flag: '\ud83c\uddf2\ud83c\uddf3',
		code: 'MN',
		country: 'Mongolia',
	},
	{
		dial_code: '382',
		flag: '\ud83c\uddf2\ud83c\uddea',
		code: 'ME',
		country: 'Montenegro',
	},
	{
		dial_code: '1664',
		flag: '\ud83c\uddf2\ud83c\uddf8',
		code: 'MS',
		country: 'Montserrat',
	},
	{
		dial_code: '212',
		flag: '\ud83c\uddf2\ud83c\udde6',
		code: 'MA',
		country: 'Morocco',
	},
	{
		dial_code: '258',
		flag: '\ud83c\uddf2\ud83c\uddff',
		code: 'MZ',
		country: 'Mozambique',
	},
	{
		dial_code: '95',
		flag: '\ud83c\uddf2\ud83c\uddf2',
		code: 'MM',
		country: 'Myanmar',
	},
	{
		dial_code: '264',
		flag: '\ud83c\uddf3\ud83c\udde6',
		code: 'NA',
		country: 'Namibia',
	},
	{
		dial_code: '674',
		flag: '\ud83c\uddf3\ud83c\uddf7',
		code: 'NR',
		country: 'Nauru',
	},
	{
		dial_code: '977',
		flag: '\ud83c\uddf3\ud83c\uddf5',
		code: 'NP',
		country: 'Nepal',
	},
	{
		dial_code: '31',
		flag: '\ud83c\udde7\ud83c\uddf6',
		code: 'NL',
		country: 'Netherlands',
	},
	{
		dial_code: '599',
		flag: '\ud83c\udde7\ud83c\uddf6',
		code: 'AN',
		country: 'Netherlands Antilles',
	},
	{
		dial_code: '687',
		flag: '\ud83c\uddf3\ud83c\udde8',
		code: 'NC',
		country: 'New Caledonia',
	},
	{
		dial_code: '64',
		flag: '\ud83c\uddf3\ud83c\uddff',
		code: 'NZ',
		country: 'New Zealand',
	},
	{
		dial_code: '505',
		flag: '\ud83c\uddf3\ud83c\uddee',
		code: 'NI',
		country: 'Nicaragua',
	},
	{
		dial_code: '227',
		flag: '\ud83c\uddf3\ud83c\uddea',
		code: 'NE',
		country: 'Niger',
	},
	{
		dial_code: '234',
		flag: '\ud83c\uddf3\ud83c\uddec',
		code: 'NG',
		country: 'Nigeria',
	},
	{
		dial_code: '683',
		flag: '\ud83c\uddf3\ud83c\uddfa',
		code: 'NU',
		country: 'Niue',
	},
	{
		dial_code: '672',
		flag: '\ud83c\uddf3\ud83c\uddeb',
		code: 'NF',
		country: 'Norfolk Island',
	},
	{
		dial_code: '850',
		flag: '\ud83c\uddf0\ud83c\uddf5',
		code: 'KP',
		country: 'North Korea',
	},
	{
		dial_code: '1670',
		flag: '\ud83c\uddf2\ud83c\uddf5',
		code: 'MP',
		country: 'Northern Mariana Islands',
	},
	{
		dial_code: '47',
		flag: '\ud83c\uddf3\ud83c\uddf4',
		code: 'NO',
		country: 'Norway',
	},
	{
		dial_code: '968',
		flag: '\ud83c\uddf4\ud83c\uddf2',
		code: 'OM',
		country: 'Oman',
	},
	{
		dial_code: '92',
		flag: '\ud83c\uddf5\ud83c\uddf0',
		code: 'PK',
		country: 'Pakistan',
	},
	{
		dial_code: '680',
		flag: '\ud83c\uddf5\ud83c\uddfc',
		code: 'PW',
		country: 'Palau',
	},
	{
		dial_code: '970',
		flag: '\ud83c\uddf5\ud83c\uddf8',
		code: 'PS',
		country: 'Palestinian Territories',
	},
	{
		dial_code: '507',
		flag: '\ud83c\uddf5\ud83c\udde6',
		code: 'PA',
		country: 'Panama',
	},
	{
		dial_code: '675',
		flag: '\ud83c\uddf5\ud83c\uddec',
		code: 'PG',
		country: 'Papua New Guinea',
	},
	{
		dial_code: '595',
		flag: '\ud83c\uddf5\ud83c\uddfe',
		code: 'PY',
		country: 'Paraguay',
	},
	{
		dial_code: '51',
		flag: '\ud83c\uddf5\ud83c\uddea',
		code: 'PE',
		country: 'Peru',
	},
	{
		dial_code: '63',
		flag: '\ud83c\uddf5\ud83c\udded',
		code: 'PH',
		country: 'Philippines',
	},
	{
		dial_code: '64',
		flag: '\ud83c\uddf5\ud83c\uddf3',
		code: 'PN',
		country: 'Pitcairn',
	},
	{
		dial_code: '48',
		flag: '\ud83c\uddf5\ud83c\uddf1',
		code: 'PL',
		country: 'Poland',
	},
	{
		dial_code: '351',
		flag: '\ud83c\uddf5\ud83c\uddf9',
		code: 'PT',
		country: 'Portugal',
	},
	{
		dial_code: '1787',
		flag: '\ud83c\uddf5\ud83c\uddf7',
		code: 'PR',
		country: 'Puerto Rico',
	},
	{
		dial_code: '974',
		flag: '\ud83c\uddf6\ud83c\udde6',
		code: 'QA',
		country: 'Qatar',
	},
	{
		dial_code: '242',
		flag: 'Demo',
		code: 'CG',
		country: 'Republic of the Congo',
	},
	{
		dial_code: '262',
		flag: '\ud83c\uddf7\ud83c\uddea',
		code: 'RE',
		country: 'Reunion',
	},
	{
		dial_code: '40',
		flag: '\ud83c\uddf7\ud83c\uddf4',
		code: 'RO',
		country: 'Romania',
	},
	{
		dial_code: '7',
		flag: '\ud83c\uddf7\ud83c\uddfa',
		code: 'RU',
		country: 'Russia',
	},
	{
		dial_code: '250',
		flag: '\ud83c\uddf7\ud83c\uddfc',
		code: 'RW',
		country: 'Rwanda',
	},
	{
		dial_code: '590',
		flag: '\ud83c\udde7\ud83c\uddf1',
		code: 'BL',
		country: 'Saint Barthelemy',
	},
	{
		dial_code: '290',
		flag: '\ud83c\uddf8\ud83c\udded',
		code: 'SH',
		country: 'Saint Helena',
	},
	{
		dial_code: '1869',
		flag: '\ud83c\uddf0\ud83c\uddf3',
		code: 'KN',
		country: 'Saint Kitts and Nevis',
	},
	{
		dial_code: '1758',
		flag: '\ud83c\uddf1\ud83c\udde8',
		code: 'LC',
		country: 'Saint Lucia',
	},
	{
		dial_code: '590',
		flag: '\ud83c\uddf2\ud83c\uddeb',
		code: 'MF',
		country: 'Saint Martin',
	},
	{
		dial_code: '508',
		flag: '\ud83c\uddf5\ud83c\uddf2',
		code: 'PM',
		country: 'Saint Pierre and Miquelon',
	},
	{
		dial_code: '1784',
		flag: '\ud83c\uddfb\ud83c\udde8',
		code: 'VC',
		country: 'Saint Vincent and the Grenadines',
	},
	{
		dial_code: '685',
		flag: 'Am',
		code: 'WS',
		country: 'Samoa',
	},
	{
		dial_code: '378',
		flag: '\ud83c\uddf8\ud83c\uddf2',
		code: 'SM',
		country: 'San Marino',
	},
	{
		dial_code: '239',
		flag: '\ud83c\uddf8\ud83c\uddf9',
		code: 'ST',
		country: 'Sao Tome and Principe',
	},
	{
		dial_code: '966',
		flag: '\ud83c\uddf8\ud83c\udde6',
		code: 'SA',
		country: 'Saudi Arabia',
	},
	{
		dial_code: '221',
		flag: '\ud83c\uddf8\ud83c\uddf3',
		code: 'SN',
		country: 'Senegal',
	},
	{
		dial_code: '381',
		flag: '\ud83c\uddf7\ud83c\uddf8',
		code: 'RS',
		country: 'Serbia',
	},
	{
		dial_code: '248',
		flag: '\ud83c\uddf8\ud83c\udde8',
		code: 'SC',
		country: 'Seychelles',
	},
	{
		dial_code: '232',
		flag: '\ud83c\uddf8\ud83c\uddf1',
		code: 'SL',
		country: 'Sierra Leone',
	},
	{
		dial_code: '65',
		flag: '\ud83c\uddf8\ud83c\uddec',
		code: 'SG',
		country: 'Singapore',
	},
	{
		dial_code: '421',
		flag: '\ud83c\uddf8\ud83c\uddf0',
		code: 'SK',
		country: 'Slovakia',
	},
	{
		dial_code: '386',
		flag: '\ud83c\uddf8\ud83c\uddee',
		code: 'SI',
		country: 'Slovenia',
	},
	{
		dial_code: '677',
		flag: '\ud83c\uddf8\ud83c\udde7',
		code: 'SB',
		country: 'Solomon Islands',
	},
	{
		dial_code: '252',
		flag: '\ud83c\uddf8\ud83c\uddf4',
		code: 'SO',
		country: 'Somalia',
	},
	{
		dial_code: '27',
		flag: '\ud83c\uddff\ud83c\udde6',
		code: 'ZA',
		country: 'South Africa',
	},
	{
		dial_code: '500',
		flag: '\ud83c\uddec\ud83c\uddf8',
		code: 'GS',
		country: 'South Georgia and the South Sandwich Islands',
	},
	{
		dial_code: '82',
		flag: '\ud83c\uddf0\ud83c\uddf7',
		code: 'KR',
		country: 'South Korea',
	},
	{
		dial_code: '211',
		flag: '\ud83c\uddf8\ud83c\uddf8',
		code: 'SS',
		country: 'South Sudan',
	},
	{
		dial_code: '34',
		flag: '\ud83c\uddea\ud83c\uddf8',
		code: 'ES',
		country: 'Spain',
	},
	{
		dial_code: '94',
		flag: '\ud83c\uddf1\ud83c\uddf0',
		code: 'LK',
		country: 'Sri Lanka',
	},
	{
		dial_code: '249',
		flag: '\ud83c\uddf8\ud83c\udde9',
		code: 'SD',
		country: 'Sudan',
	},
	{
		dial_code: '597',
		flag: '\ud83c\uddf8\ud83c\uddf7',
		code: 'SR',
		country: 'Suriname',
	},
	{
		dial_code: '47',
		flag: '\ud83c\uddf8\ud83c\uddef',
		code: 'SJ',
		country: 'Svalbard and Jan Mayen',
	},
	{
		dial_code: '46',
		flag: '\ud83c\uddf8\ud83c\uddea',
		code: 'SE',
		country: 'Sweden',
	},
	{
		dial_code: '41',
		flag: '\ud83c\udde8\ud83c\udded',
		code: 'CH',
		country: 'Switzerland',
	},
	{
		dial_code: '963',
		flag: '\ud83c\uddf8\ud83c\uddfe',
		code: 'SY',
		country: 'Syria',
	},
	{
		dial_code: '886',
		flag: '\ud83c\uddf9\ud83c\uddfc',
		code: 'TW',
		country: 'Taiwan',
	},
	{
		dial_code: '992',
		flag: '\ud83c\uddf9\ud83c\uddef',
		code: 'TJ',
		country: 'Tajikistan',
	},
	{
		dial_code: '255',
		flag: '\ud83c\uddf9\ud83c\uddff',
		code: 'TZ',
		country: 'Tanzania',
	},
	{
		dial_code: '66',
		flag: '\ud83c\uddf9\ud83c\udded',
		code: 'TH',
		country: 'Thailand',
	},
	{
		dial_code: '670',
		flag: '\ud83c\uddf9\ud83c\uddf1',
		code: 'TL',
		country: 'Timor-Leste',
	},
	{
		dial_code: '228',
		flag: '\ud83c\uddf9\ud83c\uddec',
		code: 'TG',
		country: 'Togo',
	},
	{
		dial_code: '690',
		flag: '\ud83c\uddf9\ud83c\uddf0',
		code: 'TK',
		country: 'Tokelau',
	},
	{
		dial_code: '676',
		flag: '\ud83c\uddf9\ud83c\uddf4',
		code: 'TO',
		country: 'Tonga',
	},
	{
		dial_code: '1868',
		flag: '\ud83c\uddf9\ud83c\uddf9',
		code: 'TT',
		country: 'Trinidad and Tobago',
	},
	{
		dial_code: '216',
		flag: '\ud83c\uddf9\ud83c\uddf3',
		code: 'TN',
		country: 'Tunisia',
	},
	{
		dial_code: '90',
		flag: '\ud83c\uddf9\ud83c\uddf7',
		code: 'TR',
		country: 'Turkey',
	},
	{
		dial_code: '993',
		flag: '\ud83c\uddf9\ud83c\uddf2',
		code: 'TM',
		country: 'Turkmenistan',
	},
	{
		dial_code: '1649',
		flag: '\ud83c\uddf9\ud83c\udde8',
		code: 'TC',
		country: 'Turks and Caicos Islands',
	},
	{
		dial_code: '688',
		flag: '\ud83c\uddf9\ud83c\uddfb',
		code: 'TV',
		country: 'Tuvalu',
	},
	{
		dial_code: '1340',
		flag: '\ud83c\uddfb\ud83c\uddee',
		code: 'VI',
		country: 'U.S. Virgin Islands',
	},
	{
		dial_code: '256',
		flag: '\ud83c\uddfa\ud83c\uddec',
		code: 'UG',
		country: 'Uganda',
	},
	{
		dial_code: '380',
		flag: '\ud83c\uddfa\ud83c\udde6',
		code: 'UA',
		country: 'Ukraine',
	},
	{
		dial_code: '971',
		flag: '\ud83c\udde6\ud83c\uddea',
		code: 'AE',
		country: 'United Arab Emirates',
	},
	{
		dial_code: '44',
		flag: '\ud83c\uddec\ud83c\udde7',
		code: 'GB',
		country: 'United Kingdom',
	},
	{
		dial_code: '1',
		flag: '\ud83c\uddfa\ud83c\uddf8',
		code: 'US',
		country: 'United States',
	},
	{
		dial_code: '598',
		flag: '\ud83c\uddfa\ud83c\uddfe',
		code: 'UY',
		country: 'Uruguay',
	},
	{
		dial_code: '998',
		flag: '\ud83c\uddfa\ud83c\uddff',
		code: 'UZ',
		country: 'Uzbekistan',
	},
	{
		dial_code: '678',
		flag: '\ud83c\uddfb\ud83c\uddfa',
		code: 'VU',
		country: 'Vanuatu',
	},
	{
		dial_code: '379',
		flag: '\ud83c\uddfb\ud83c\udde6',
		code: 'VA',
		country: 'Vatican',
	},
	{
		dial_code: '58',
		flag: '\ud83c\uddfb\ud83c\uddea',
		code: 'VE',
		country: 'Venezuela',
	},
	{
		dial_code: '84',
		flag: '\ud83c\uddfb\ud83c\uddf3',
		code: 'VN',
		country: 'Vietnam',
	},
	{
		dial_code: '681',
		flag: '\ud83c\uddfc\ud83c\uddeb',
		code: 'WF',
		country: 'Wallis and Futuna',
	},
	{
		dial_code: '967',
		flag: '\ud83c\uddfe\ud83c\uddea',
		code: 'YE',
		country: 'Yemen',
	},
	{
		dial_code: '260',
		flag: '\ud83c\uddff\ud83c\uddf2',
		code: 'ZM',
		country: 'Zambia',
	},
	{
		dial_code: '263',
		flag: '\ud83c\uddff\ud83c\uddfc',
		code: 'ZW',
		country: 'Zimbabwe',
	},
	{
		dial_code: '268',
		flag: '\ud83c\uddf8\ud83c\uddff',
		code: 'SZ',
		country: 'eSwatini',
	},
];
