import client from '../utils/fetch-client';

const path = '/v2/favourites';

export default {
	createFavourite: searchUuid => {
		return client.post(`${path}`, {
			body: {
				uuid: searchUuid,
			},
		});
	},

	getFavourites: () => {
		return client.get(`${path}`);
	},

	deleteFavourite: searchUuid => {
		return client.delete(`${path}/${searchUuid}`);
	},
};
