import React from 'react';

import { ReactComponent as RadioUnselected } from 'assets/images/icons/outline.svg';
import { ReactComponent as RadioSelected } from 'assets/images/icons/selected.svg';
import { SelectOption } from 'models/selectOption';
import styled from 'styled-components';
import theme from 'config/theme';

const { colors } = theme;

interface RadioInputProps {
	options: SelectOption[];
	selectedOption?: SelectOption;
	onOptionChange(option: SelectOption): void;
}

const RadioInput = ({ options, selectedOption, onOptionChange }: RadioInputProps) => {
	return (
		<>
			{options.map(option => (
				<OptionItem
					selected={option.value === selectedOption?.value}
					key={option.value}
					onClick={() => onOptionChange(option)}
				>
					<RadioToggleContainer>
						{option.value === selectedOption?.value ? <RadioSelected /> : <RadioUnselected />}
					</RadioToggleContainer>
					{option.label}
				</OptionItem>
			))}
		</>
	);
};

const RadioToggleContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 32px;
	min-height: 32px;
`;

const OptionItem = styled.div<{ selected: boolean }>`
	display: flex;
    align-items: center;
	border: 1px solid ${({ selected }) => (selected ? colors.darkBlue : colors.grey10)};
	border-radius: 4px;
	background: ${({ selected }) => (selected ? colors.darkTurquoiseFaded : colors.white)};
	font-weight: ${({ selected }) => (selected ? 600 : 400)};
	padding: 8px 16px;
	cursor: pointer;
    margin-bottom: 4px;

    :hover {
        border-color: ${({ selected }) => (selected ? colors.darkBlue : colors.grey20)};
    }
`;

export default RadioInput;
