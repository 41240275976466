import { useCallback } from 'react';

import { useHistory } from 'react-router-dom';

import routes from 'config/routes';
import modalContainer from 'containers/modal';
import authContainer from 'containers/auth';
import { ModalType } from 'constants/modalTypes';

const useRouter = () => {
	const history = useHistory();
	const goTo = useCallback<(route: string) => void>(
		route => {
			if (route === routes.create_listing && !authContainer.state.token) {
				modalContainer.set(ModalType.Login, {
					onLoginSuccess: () => {
						history.push(route);
					},
				});
				return;
			}

			history.push(route);
		},
		[history],
	);

	return [goTo];
};

export default useRouter;
