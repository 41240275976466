import deepAssign from 'deep-assign';
import fetchResponseEnhancer from 'fetch-response-enhancer';
import toQueryString from 'utils/to-query-string';
import authContainer from 'containers/auth';
import userContainer from 'containers/user';
import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import theme from 'config/theme';
import preval from 'preval.macro';
import api from 'api';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';

const BUILD_DATE = preval`module.exports = new Date().toLocaleString();`;

const MySwal = withReactContent(Swal);

const { colors } = theme;

export const methods = {
	get: 'GET',
	delete: 'DELETE',
	head: 'HEAD',
	options: 'OPTIONS',
	post: 'POST',
	put: 'PUT',
	patch: 'PATCH',
};

class Client {
	constructor(config = {}) {
		delete config.fetch;
		this.config = config;
	}

	async request(path = '', config, catchError = true) {
		const url = `/api${path}${config.params ? toQueryString(config.params) : ''}`;
		let defaultHeaders = {
			'X-Frontend': BUILD_DATE,
		};
		if (!config.unsetContentType) {
			defaultHeaders = {
				...defaultHeaders,
				'Content-Type': 'application/json',
			};

			if (config.isForm) {
				defaultHeaders = {
					...defaultHeaders,
					'Content-Type': 'application/x-www-form-urlencoded',
				};
			}
		}
		const opts = deepAssign(
			{
				headers: defaultHeaders,
				credentials: 'same-origin',
			},
			this.config,
			config,
		);

		if (typeof opts.body === 'object' && opts.headers['Content-Type'] === 'application/json') {
			opts.body = JSON.stringify(opts.body || {});
		}

		const res = await window.fetch(url, {
			...opts,
			// TODO: Clean this up once token is removed entirely
			headers: {
				...opts.headers,
				Authorization: '',
			},
		});
		const response = res.clone();
		try {
			const enhancedRes = await fetchResponseEnhancer(res, opts);
			enhancedRes.url = url;
			return enhancedRes;
		} catch (e) {
			if (response.status === 400) {
				const json = await response.json();
				if (json.hasOwnProperty('incomplete_fields')) {
					let message = json.incomplete_fields.map(a => {
						return a.detail;
					});
					await MySwal.fire({
						html: (
							<ul className={'errorList'}>
								{message.map(a => (
									<li>{a}</li>
								))}
							</ul>
						),
						confirmButtonColor: colors.pink,
					});
				}
				return {
					status: response.status,
					data: json,
				};
			} else if (response.status === 401 && !url.includes('auth') && catchError) {
				if (!Swal.isVisible()) {
					await Swal.fire({
						text: 'Your session has expired, logging out',
						type: 'warning',
						timer: 2000,
						toast: true,
						position: 'top',
						onBeforeOpen: () => {
							Swal.showLoading();
						},
					});
					await userContainer.clear();
					await authContainer.clear();
					// TODO: Call logout endpoint (remove auth requierment from logout endpoint, currently need valid JWT to hit the endpoint)
					if (featureFlagContainer.isEnabled(FeatureFlag.UnhandledRejection)) {
						const loggedIn = authContainer.state.token;
						if (loggedIn) {
							await api.auth.logout();
						} else {
							await api.auth.refreshToken();
						}
					}
				}

				if (featureFlagContainer.isEnabled(FeatureFlag.UnhandledRejection)) {
					try {
						return await Promise.reject(console.error('Unauthorised'));
					} catch (error) {
						console.error(error);
					}
				} else {
					return Promise.reject('Unauthorised');
				}
				window.location.reload();
			} else if (response.status === 204) {
				return response;
			}
			return e;
		}
	}

	get(path, config = {}) {
		return this.request(path, deepAssign(config, { method: methods.get }));
	}

	delete(path, config = {}) {
		return this.request(path, deepAssign(config, { method: methods.delete }));
	}

	head(path, config = {}) {
		return this.request(path, deepAssign(config, { method: methods.delete }));
	}

	options(path, config = {}) {
		return this.request(path, deepAssign(config, { method: methods.options }));
	}

	post(path, config = {}, catchError = true) {
		return this.request(path, deepAssign(config, { method: methods.post }), catchError);
	}

	put(path, config = {}) {
		return this.request(path, deepAssign(config, { method: methods.put }));
	}

	patch(path, config = {}) {
		return this.request(path, deepAssign(config, { method: methods.patch }));
	}
}

export default new Client();
