import React, { useRef, useState } from 'react';

import styled from 'styled-components';

import { Button } from 'lib/Button';
import { Modal } from 'lib/Modal';
import theme from 'config/theme';
import routes from 'config/routes';
import useRouter from 'hooks/useRouter';
import { ReactComponent as EmailSvg } from 'assets/images/digs-listing/email.svg';
import { TopBar } from 'lib/TopBar';

const { colors, fontWeights, fonts, zIndices } = theme;

interface ContactUsProps {
	onClose(): void;
}
const ContactUsModal = ({ onClose }: ContactUsProps) => {
	const [goTo] = useRouter();

	return (
		<Modal mobileBottomsheet slideUp width={375} onBackgroundClick={onClose}>
			<TopBar title={'Need help?'} onClose={onClose} />
			<ModalContainer>
				<PaddingContainer>
					<Container>
						<TitleContainer>
							<Description>
								We want your listing live as much as you do! Hit the “Request assistance” button
								below, and we’ll get in touch to assist.
							</Description>
						</TitleContainer>
						<ButtonContainer>
							<Button
								height={50}
								maxWidth={200}
								onClick={e => {
									e.stopPropagation();
									e.preventDefault();
									goTo(routes.contact_us_landlord);
									onClose();
								}}
							>
								Request assistance
							</Button>
						</ButtonContainer>
						<OrContainer>
							<Divider />
							<OrText>OR</OrText>
							<Divider />
						</OrContainer>
						<InfoContainer>
							<InfoTitle>Contact us on:</InfoTitle>
							<InfoText>
								<EmailSvg />
								<MailRef href="mailto:landlords@digsconnect.com">landlords@digsconnect.com</MailRef>
							</InfoText>
						</InfoContainer>
					</Container>
				</PaddingContainer>
			</ModalContainer>
		</Modal>
	);
};

export default ContactUsModal;

const ModalContainer = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
`;
const OrText = styled.div`
	font-color: ${colors.infoGrey};
`;

const MailRef = styled.a`
	color: ${colors.darkBlue};

	&:hover {
		cursor: pointer;
		text-decoration: underline;
	}
`;
const OrContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 34px;
	justify-content: center;
	align-items: center;
	width: 100%;
`;
const ButtonContainer = styled.div`
	justify-content: center;
	align-items: center;
`;

const PaddingContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 24px;
`;

const Container = styled.div`
	background: #ffffff;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
`;

const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const Divider = styled.div`
	border: 1px solid ${colors.infoGrey};
	width: 100%;
`;

const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 16px;
	width: 100%;
`;
const InfoTitle = styled.div`
	font-weight: ${fontWeights.bold};
	line-height: 110%;
`;

const InfoText = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0px;
	gap: 8px;
`;

const ModalTitle = styled.div`
	color: ${colors.darkBlue};
	font-weight: ${fontWeights.bold};
	font-family: ${fonts.Gilroy};
	font-size: 20px;
	flex: 1;
	align-items: center;
	text-align: center;
	margin-top: 15px;
	margin-bottom: 15px;
	font-weight: 700;
	line-height: 150%;
`;

const Description = styled.p`
	color: ${colors.darkBlue};
	font-weight: ${fontWeights.normal};
	font-size: 16px;
	font-family: ${fonts.Gilroy};
	line-height: 150%;
`;
