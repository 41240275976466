export const ModalType = {
	ProfileSetup: 'profile-setup',
	ProfileSetupInstant: 'profile-setup-instant',
	Login: 'login',
	Signup: 'signup',
	MoreFilters: 'more-filters',
	CancelEnquiry: 'cancel-enquiry',
	NoInstitutionFound: 'no-institution-found',
	enquiryInfo: 'enquiry-info',
	termsAndConditions: 'terms-and-conditions',
	LandlordLandingTestimony: 'landlord-landing-testimony',
	ReportListing: 'report-listing',
	ContactUs: 'contact-us',
	TrustedLandlord: 'trusted-land',
	InfoPopup: 'info-popup',
	LandlordLandingProductUpdate: 'landlord-landing-product-update',
};
