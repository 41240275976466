import Loading from 'components/common/page-loading';
import React from 'react';
import Loadable from 'react-loadable';

export default function loadable(loader) {
	return Loadable({
		loader: loader,
		loading: Loading,
		render(loaded, props) {
			return <Loading>{React.createElement(loaded.default, props)}</Loading>;
		},
	});
}
