import React from 'react';

import styled from 'styled-components';

import DesktopBreadCrumbItem from './DesktopBreadCrumbItem';
import { visibleBreadCrumbs, isStepComplete } from '../userProfileSetupHelpers';

const DesktopBreadCrumbs = ({ userProfile }) => (
	<StyledDesktopBreadCrumbs>
		{visibleBreadCrumbs.map(({ id, name }) => (
			<DesktopBreadCrumbItem key={id} text={name} completed={isStepComplete(id, userProfile)} />
		))}
	</StyledDesktopBreadCrumbs>
);

export default DesktopBreadCrumbs;

const StyledDesktopBreadCrumbs = styled.div`
	overflow-y: auto;
`;
