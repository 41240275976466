import { css } from 'styled-components';
import dotloader from 'assets/animations/dotloader.svg';

import theme from './theme';

const { colors, fontSizes, fontWeights, boxShadow, radii } = theme;

export const flexBetween = css`
	align-items: center;
	display: flex;
	justify-content: space-between;
`;

export const flexCenter = `
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const coverImage = css`
	background-color: ${colors.grey05};
	background-image: url(${({ src }) => src});
	background-position: center;
	background-size: cover;
`;

export const btnLarge = css`
	border-radius: 4em;
	background-color: ${colors.white};
	padding: 2em;
	width: 15.5em;
	font-size: ${fontSizes.medium};
	font-weight: ${fontWeights.bold};
	font-family: inherit;
	box-shadow: ${boxShadow.default};
	outline: none;
	transition: transform 50ms ease-in;
	display: inline-block;
	${({ loading }) => `
    background-image: url(${loading ? dotloader : null});
    content: "\\200b";
  `}
	background-repeat: no-repeat;
	background-position: center;
	&:active {
		transform: scale(0.95);
	}
`;

export const btn = css`
	padding: 0.9em 1.25em;
	border-radius: ${radii.default};
	width: 10em;
	font-size: ${fontSizes.medium};
	font-weight: ${fontWeights.bold};
	font-family: inherit;
	outline: none;
	transition: transform 50ms ease-in;
	&:active {
		transform: scale(0.95);
	}

	${({ primary }) =>
		primary
			? `
    color: ${colors.white};
    background-color: ${colors.pink};
    box-shadow: 0 0.5em 1em 0 rgba(255, 0, 82, 0.3);
  `
			: `
    color: ${colors.pink};
    background-color: ${colors.white};
    box-shadow: ${boxShadow.default};
  `}
`;

export const noPrint = css`
	@media print {
		display: none;
	}
`;
