import React, { useState } from 'react';

import styled from 'styled-components';

import { Title } from 'lib/Title';
import { Input } from 'lib/Input';
import { Button } from 'lib/Button';
import theme from 'config/theme';
import { CountryCodeSelect, CountryCode } from 'lib/CountryCodeSelect';

const { colors } = theme;

const Section = styled.div`
	margin-top: 32px;
	margin-bottom: 48px;
`;

const SubTitle = styled.div<{ noMargin?: boolean }>`
	font-size: 14px;
	font-weight: 600;
	margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '16px')};
`;

const Container = styled.div`
	height: 100%;
	position: relative;
`;

const FooterBar = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;

	@media (max-width: 1080px) {
		position: fixed;
		padding: 16px 24px;
		border-top: 1px solid ${colors.grey10};
	}
`;

const CountryCodeValue = {
	SouthAfrica: 'ZA',
};

interface PersonalInfoProps {
	isLoading?: boolean;
	nationality?: string;
	idNumber?: string;
	onSubmit(data: { nationality: string; value: string }): void;
}

export const IdentityInfo = ({ isLoading, idNumber, nationality, onSubmit }: PersonalInfoProps) => {
	const [showErrors, setShowErrors] = useState(false);
	const [nationalityValue, setNationalityValue] = useState(
		nationality || CountryCodeValue.SouthAfrica,
	);
	const [idNumberValue, setIdNumberValue] = useState<string | number>(idNumber || '');

	const handleCountryChange = ({ countryCode }: CountryCode) => {
		setNationalityValue(countryCode);
	};

	const handleSubmit = (event: any) => {
		event.preventDefault();
		if (!nationalityValue || !idNumberValue) {
			setShowErrors(true);
			return;
		}

		onSubmit({
			nationality: nationalityValue,
			value: idNumberValue.toString(),
		});
	};

	return (
		<Container>
			<Title>Identity information</Title>
			<form onSubmit={handleSubmit}>
				<Section>
					<SubTitle>What is your nationality?</SubTitle>
					<CountryCodeSelect
						value={nationalityValue}
						showDialCode={false}
						onChange={handleCountryChange}
					/>
					<Input
						placeholder={
							nationalityValue === CountryCodeValue.SouthAfrica ? 'ID Number' : 'Passport number'
						}
						onChange={value => setIdNumberValue(value)}
						value={idNumberValue}
						isErrorState={showErrors && !idNumberValue}
					/>
				</Section>
				<FooterBar>
					<Button isOutline type="submit" isLoading={isLoading}>
						Continue
					</Button>
				</FooterBar>
			</form>
		</Container>
	);
};
