import React from 'react';

import { Link } from 'react-router-dom';

import theme from 'config/theme';

const { colors } = theme;

const Logo = ({ color }) => (
	<Link to="/">
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="89px"
			height="48px"
			viewBox="0 0 89 48"
			fill={color}
		>
			<g>
				<path
					d="M6.539,3.268A3.27,3.27,0,1,1,3.27,0a3.269,3.269,0,0,1,3.27,3.268"
					transform="translate(82.461 27.866)"
				/>
				<path
					d="M13.041,30.921A12.986,12.986,0,0,1,5.75,28.7a13.071,13.071,0,0,1-4.725-5.733A13.007,13.007,0,0,1,2.227,10.6,13.079,13.079,0,0,1,7.965,5.882,13.042,13.042,0,0,1,19.559,6.6V0h6.521V17.889a12.966,12.966,0,0,1-2.227,7.286A13.075,13.075,0,0,1,18.116,29.9,12.964,12.964,0,0,1,13.041,30.921Zm0-19.546a6.5,6.5,0,1,0,2.537.512A6.485,6.485,0,0,0,13.041,11.375Z"
					transform="translate(0 3.547)"
				/>
				<path
					d="M19.559,0a6.517,6.517,0,0,1-6.517,6.517A6.518,6.518,0,0,1,6.521,0H0A13.035,13.035,0,0,0,13.042,13.031,13.033,13.033,0,0,0,26.08,0Z"
					transform="translate(37.07 34.969)"
				/>
				<path d="M0,0H7.1V7.093H0Z" fill="none" transform="translate(28.259)" />
				<g transform="translate(28.259)">
					<path d="M7.1,3.547A3.548,3.548,0,1,1,3.549,0,3.547,3.547,0,0,1,7.1,3.547" />
				</g>
				<path
					d="M13.038,26.063a12.982,12.982,0,0,1-7.29-2.226A13.07,13.07,0,0,1,1.025,18.1a13.009,13.009,0,0,1,1.2-12.358A13.075,13.075,0,0,1,7.963,1.024a13.027,13.027,0,0,1,11.594.724V.8h6.52V13.245h-.007A13.022,13.022,0,0,1,13.038,26.063Zm0-19.547a6.5,6.5,0,1,0,2.538.512A6.481,6.481,0,0,0,13.038,6.515Z"
					transform="translate(37.072 8.319)"
				/>
				<path
					d="M10.908,11.016C8.859,10.1,7.09,9.317,7.09,7.927A2.581,2.581,0,0,1,9.865,5.508a4.638,4.638,0,0,1,3.681,1.506l.087.118L17,3.091l-.059-.073A9.993,9.993,0,0,0,9.351,0C4.205,0,.611,3.17.611,7.71c0,4.438,3.68,6.04,6.637,7.328,2.043.89,3.808,1.658,3.808,3.107,0,1.511-1.413,2.187-2.812,2.187A6.465,6.465,0,0,1,4.127,18.37l-.084-.091L0,22.592l.058.077a8.458,8.458,0,0,0,2.125,1.817,12.267,12.267,0,0,0,6.608,1.807c5.278,0,8.964-3.17,8.964-7.71,0-4.519-3.8-6.209-6.848-7.566"
					transform="translate(64.36 8.205)"
				/>
				<path d="M0,48H89V0H0Z" fill="none" />
				<g>
					<path d="M0,24.2H6.52V0H0Z" transform="translate(28.547 9.118)" />
				</g>
			</g>
		</svg>
	</Link>
);

Logo.defaultProps = {
	color: colors.white,
};

export default Logo;
