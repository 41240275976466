import { btn, flexBetween, flexCenter, noPrint } from 'config/mixins';
import theme from 'config/theme';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const { fontWeights, fontSizes, colors, headerHeight, mobileHeaderHeight, zIndices } = theme;

const headerVariants = {
	light: colors.white,
	dark: colors.darkBlue,
};

export const Container = styled.header`
	${noPrint};
	${flexBetween};
	align-items: center;
	padding: 0px 40px;
	height: ${headerHeight};
	position: fixed;
	top: 0;
	width: 100%;
	z-index: ${zIndices.header};
	background-color: ${props => props.bgColor || colors.white};
	align-items: center;
	border-bottom: ${({ withBorder }) => (withBorder ? `1px solid ${colors.grey10}` : 'none')};
	transition: background-color 0.5s;
	@media (max-width: 900px) {
		padding: 0 16px;
		height: ${mobileHeaderHeight};
	}
`;

export const Nav = styled.nav`
	margin-left: 20px;
`;

export const NavList = styled.ul`
	${flexCenter};
	list-style: none;
`;

export const NavItem = styled.li`
	display: flex;
	text-align: center;
	color: ${props => headerVariants[props.variant]};
	font-size: ${fontSizes.medium};
	font-weight: ${fontWeights.bold};
	margin: 0 40px;
	white-space: nowrap;
`;

export const NavLink = styled(Link)`
	color: inherit;
	text-decoration: none;
	transition: color 200ms ease-in-out;

	:hover {
		color: ${colors.pink};
	}
`;

export const SignupBtn = styled.button.attrs({
	type: 'button',
})`
	${btn};
	color: ${colors.pink};
	margin: 0;
`;

export const NavBtn = styled.button.attrs({
	type: 'button',
})`
	text-align: center;
	color: inherit;
	font-family: inherit;
	font-size: ${fontSizes.medium};
	font-weight: ${fontWeights.bold};
	background-color: ${colors.transparent};
	outline: none;
	transition: color 200ms ease-in-out;

	&:hover,
	&:active {
		color: ${colors.pink};
	}
`;

export const LogoContainer = styled.div`
	${flexCenter};
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	@media (max-width: 900px) {
		margin-top: 0;
		${({ findADigs }) => findADigs && 'flex-grow: 1;'}
	}
`;

export const MobileContainer = styled.div`
	${flexCenter};
`;

export const NotificationBubble = styled.div`
	width: 10px;
	height: 10px;
	background-color: ${colors.pink};
	display: inline-block;
	border-radius: 50%;
	margin: 0 5px;
	cursor: pointer;
	box-shadow: 0 0 0 rgba(255, 0, 82, 0.4);
	animation: pulse 1s infinite;

	@-webkit-keyframes pulse {
		0% {
			-webkit-box-shadow: 0 0 0 0 rgba(255, 0, 82, 0.8);
		}
		70% {
			-webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
		}
		100% {
			-webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
		}
	}
	@keyframes pulse {
		0% {
			-moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.8);
			box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.8);
		}
		70% {
			-moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
			box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
		}
		100% {
			-moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
			box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
		}
	}
`;
