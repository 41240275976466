import { useEffect } from 'react';

import api from 'api';
import { EventType } from 'constants/events';
import routes from 'config/routes';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';
import { useHistory } from 'react-router-dom';

const isLandlordRedirectEnabled = true;

export const withLandlordRedirect = (WrappedComponent, route = '') => {
	if (isLandlordRedirectEnabled) {
		const landlordAppDomain = window.location.host.startsWith('localhost')
			? 'app.staging.digsconnect.com'
			: `app.${window.location.host.replace('www.', '')}`;
		return () => {
			const history = useHistory();
			useEffect(() => {
				if (route === routes.create_listing) {
					api.events
						.send({
							event_type: EventType.AddADigs,
						})
						.then(() => {
							window.location.replace(
								`https://${landlordAppDomain}/#${window.location.pathname}${window.location.search}`,
							);
						});
					return;
				}
				if (route === routes.manage_digs) {
					history.replace(routes.manage_listing);
					return;
				}
				window.location.replace(
					`https://${landlordAppDomain}/#${window.location.pathname}${window.location.search}`,
				);
			}, []);
			return null;
		};
	}
	return WrappedComponent || (() => null);
};
