import theme from 'config/theme';
import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';
import styled from 'styled-components';

const { colors, fontWeights, fontSizes, fonts, zIndices } = theme;

/* Containers */
export const ContainerMain = styled.div`
	-webkit-overflow-scrolling: touch;
	display: flex;
	height: 100%;

	@media (max-width: 576px) {
		flex-direction: column;
	}
`;

export const ContainerLeft = styled.div`
	background-color: ${colors.blue};
	max-width: 312px;
	width: 90%;
	position: relative;
	padding: 30px;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: hidden;

	@media (max-width: ${BREAKPOINT_TABLET_PX}px) {
		display: none;
	}
`;

/* Image */
export const Img = styled.div`
	position: absolute;
	bottom: 40px;
	width: 90%;

	@media (max-width: 576px) {
		top: 0px;
		right: -130px;
	}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
`;

export const MainContent = styled.div`
	padding: 0 24px;
	padding-top: 24px;
	flex: 1;
	overflow-y: auto;
`;

export const Header = styled.header`
	display: flex;
	justify-content: space-between;
	width: 100%;
	z-index: ${zIndices.modal};
	left: 0;
	padding: 20px 0;
	height: 100px;
	align-items: center;
	top: 0;

	@media (max-width: 700px) {
		padding: 30px;
		background-color: ${colors.white};
	}
`;

/* Buttons */
export const CloseButton = styled.button`
	font-weight: ${fontWeights.bold};
	font-size: ${fontSizes.large};
	color: ${colors.darkBlue};
	background: none;
	cursor: pointer;
	outline: none;
	text-align: right;
	margin-top: -24px;
	margin-bottom: 10px;

	@media (max-width: 576px) {
		color: ${colors.black};
		margin-right: -10px;
		margin-bottom: 0;
	}
`;

export const Description = styled.p`
	color: ${colors.lightBlue};
	font-weight: ${fontWeights.normal};
	font-size: ${fontSizes.small};
	font-family: ${fonts.Gilroy};
	margin-bottom: 20px;
`;

export const Text = styled.p`
  font-size: ${fontSizes.small};
  font-family: ${fonts.Gilroy};
  margin-top: 1.5em;
  ${({ centered }) => centered && 'text-align: center;'}
  ${({ onClick }) =>
		onClick
			? `
    cursor: pointer;
    color: ${colors.pink};
    text-decoration: underline;
    font-weight: ${fontWeights.normal};
  `
			: `
    color: ${colors.lightBlue};
    font-weight: ${fontWeights.thin};
  `}
  ${({ tip }) =>
		tip &&
		`
    padding-left: 1em;
    font-size: 0.7em;
    margin-top: 0.5em;
  `}
`;

export const Input = styled.input`
	width: 100%;
	height: 60px;
	font-size: ${fontSizes.medium};
	border-bottom: 2px solid ${colors.darkBlue};
	margin-bottom: 20px;
	color: ${colors.darkBlue};
	outline: none;
	background: ${colors.transparent};

	&::placeholder {
		color: ${colors.lightBlue};
	}

	&:focus {
		border-bottom-color: ${colors.pink};
	}
`;

export const InputFieldsContainer = styled.div`
	margin-bottom: 24px;
`;

export const FormContainer = styled.div`
	margin-bottom: 24px;
`;
