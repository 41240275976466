import React from 'react';

import MobileBreadCrumbItem from './MobileBreadCrumbItem';
import { visibleBreadCrumbs, isStepComplete } from '../userProfileSetupHelpers';

const MobileBreadCrumbs = ({ userProfile }) => (
	<>
		{visibleBreadCrumbs.map(({ id, name }) => (
			<MobileBreadCrumbItem key={id} text={name} completed={isStepComplete(id, userProfile)} />
		))}
	</>
);

export default MobileBreadCrumbs;
