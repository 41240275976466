import React from 'react';

const Facebook = ({ color }) => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		{color ? (
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M10 20.3457H12V14.1457C12 13.5927 12.447 13.1457 13 13.1457H15.24L15.685 11.5457H13C12.447 11.5457 12 11.0987 12 10.5457V7.8457C12 6.7977 12.897 5.9457 14 5.9457H16V4.3457H14C11.794 4.3457 10 5.9157 10 7.8457V10.5457C10 11.0987 9.553 11.5457 9 11.5457H7V13.1457H9C9.553 13.1457 10 13.5927 10 14.1457V20.3457ZM13 22.3457H9C8.447 22.3457 8 21.8987 8 21.3457V15.1457H6C5.447 15.1457 5 14.6987 5 14.1457V10.5457C5 9.9927 5.447 9.5457 6 9.5457H8V7.8457C8 4.8137 10.691 2.3457 14 2.3457H17C17.553 2.3457 18 2.7927 18 3.3457V6.9457C18 7.4977 17.553 7.9457 17 7.9457H14V9.5457H17C17.312 9.5457 17.607 9.6927 17.796 9.9407C17.985 10.1897 18.047 10.5127 17.964 10.8137L16.964 14.4127C16.844 14.8457 16.449 15.1457 16 15.1457H14V21.3457C14 21.8987 13.553 22.3457 13 22.3457Z"
				fill={color}
			/>
		) : (
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M10 20.3457H12V14.1457C12 13.5927 12.447 13.1457 13 13.1457H15.24L15.685 11.5457H13C12.447 11.5457 12 11.0987 12 10.5457V7.8457C12 6.7977 12.897 5.9457 14 5.9457H16V4.3457H14C11.794 4.3457 10 5.9157 10 7.8457V10.5457C10 11.0987 9.553 11.5457 9 11.5457H7V13.1457H9C9.553 13.1457 10 13.5927 10 14.1457V20.3457ZM13 22.3457H9C8.447 22.3457 8 21.8987 8 21.3457V15.1457H6C5.447 15.1457 5 14.6987 5 14.1457V10.5457C5 9.9927 5.447 9.5457 6 9.5457H8V7.8457C8 4.8137 10.691 2.3457 14 2.3457H17C17.553 2.3457 18 2.7927 18 3.3457V6.9457C18 7.4977 17.553 7.9457 17 7.9457H14V9.5457H17C17.312 9.5457 17.607 9.6927 17.796 9.9407C17.985 10.1897 18.047 10.5127 17.964 10.8137L16.964 14.4127C16.844 14.8457 16.449 15.1457 16 15.1457H14V21.3457C14 21.8987 13.553 22.3457 13 22.3457Z"
				fill="#041F47"
			/>
		)}
	</svg>
);

export default Facebook;
