import client from '../utils/fetch-client';

const path = '/v2/content/';

export default {
	getBlogFeed() {
		return client.get(`${path}blog-feed`);
	},

	getListingBlocks() {
		return client.get(`${path}listing-blocks`);
	},

	getPopularLocations: block => {
		return client.get(`${path}popular-locations?block=${block}`);
	},

	getUniversitySearch() {
		return client.get(`${path}university-search`);
	},

	getPartners() {
		return client.get(`${path}partners`);
	},
};
