import { Response } from './types';
import client from '../utils/fetch-client';
import {
	ConversationAction,
	ConversationAttachments,
	ConversationBookings,
	ConversationMessages,
	ConversationStatus,
	InboxFilters,
	PaginatedInbox,
} from 'models/inbox';
import { InboxType } from 'components/inbox/types';

const path = '/v2/user/inbox';

const buildFiltersQueryString = (filters: string[][]): string => {
	return filters.reduce((acc, filter) => `${acc}&${filter[1]}=${filter[0]}`, '');
};

export default {
	getLandlordInbox(
		archived: boolean,
		searchTerm: string,
		unread: boolean,
		page: number,
		filters: string[][],
		page_size?: number,
		status?: string,
	): Promise<Response<PaginatedInbox>> {
		return client.get(
			`${path}/landlord?archived=${Boolean(archived)}&search_term=${searchTerm}${
				unread ? '&read=false' : ''
			}&page=${page || 1}${status ? `&status=${status}` : ''}${
				page_size ? `&page_size=${page_size}` : ''
			}${buildFiltersQueryString(filters)}`,
		);
	},
	getTenantInbox(
		archived: boolean,
		searchTerm: string,
		unread: boolean,
		page: number,
		filters: string[][],
	): Promise<Response<PaginatedInbox>> {
		return client.get(
			`${path}/tenant?archived=${Boolean(archived)}&search_term=${searchTerm}${
				unread ? '&read=false' : ''
			}&page=${page || 1}${buildFiltersQueryString(filters)}`,
		);
	},
	executeAction(
		id: string,
		action: ConversationAction,
		additionalPayload?: { reason?: string },
		newSerializer?: boolean,
	): Promise<Response<{}>> {
		return client.put(`${path}/${id}/action${newSerializer ? '?new_serializer=True' : ''}`, {
			body: {
				...(additionalPayload || {}),
				action,
			},
		});
	},
	updateInbox(
		id: string,
		payload: {
			status: ConversationStatus;
			status_reason: string;
			status_message?: string;
		},
	): Promise<Response<{}>> {
		return client.put(`${path}/${id}?new_serializer=True`, {
			body: payload,
		});
	},
	confirmConversation({
		id,
		...payload
	}: {
		id: string;
		property_uuid: string;
		room_uuid?: string;
		occupancy_duration: number;
		occupancy_start_date: string;
		occupant_rental: number;
	}): Promise<Response<{}>> {
		return client.put(`${path}/${id}?new_serializer=True`, {
			body: {
				...payload,
				status: ConversationStatus.Confirmed,
			},
		});
	},
	getConversationMessages(
		id: string,
		page: number = 1,
		lastId?: string,
	): Promise<Response<ConversationMessages>> {
		return client.get(
			`${path}/${id}?new_serializer=True&page=${page}${lastId ? `&last_uuid=${lastId}` : ''}`,
		);
	},
	postConversationMessage(conversationId: string, message: string): Promise<Response<any>> {
		return client.post(`${path}/${conversationId}/messages`, {
			body: {
				message,
			},
		});
	},
	uploadConversationFile(conversationId: string, file: File) {
		const formData = new FormData();
		formData.append('file', file);

		return client.post(`${path}/${conversationId}/messages`, {
			body: formData,
			unsetContentType: true,
		});
	},
	getInboxFitlers(type: InboxType): Promise<Response<InboxFilters>> {
		return client.get(`${path}/filters?inbox_type=${type}`);
	},
	getInboxBookings(conversationId: string): Promise<Response<ConversationBookings>> {
		return client.get(`${path}/${conversationId}/bookings?new_serializer=True`);
	},
	getInboxAttachments(conversationId: string): Promise<Response<ConversationAttachments>> {
		return client.get(`${path}/${conversationId}/attachments`);
	},
};
