import styled from 'styled-components';

import theme from 'config/theme';
import { btn } from 'config/mixins';

const { colors, fontWeights, fontSizes, fonts, zIndices } = theme;

const MOBILE_BREAKPOINT = 720;

/* Containers */
export const ModalContainer = styled.div`
	width: 100%;
	margin: auto;
	background-color: rgba(0, 0, 0, 0.4);
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: ${zIndices.modal};
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ContainerMain = styled.div`
	display: flex;
	width: 100%;
	flex-direction: row;
	height: 100%;

	@media (max-width: ${MOBILE_BREAKPOINT}px) {
		flex-direction: column;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		border-radius: 0;
		max-width: 100%;
		height: auto;
		max-height: none;
	}
`;

export const ContainerLeft = styled.div`
	background-color: ${colors.blue};
	width: 90%;
	max-width: 312px;
	height: 100%;
	position: relative;
	padding: 30px;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: hidden;

	@media (max-width: ${MOBILE_BREAKPOINT}px) {
		display: none;
	}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
`;

export const MainContent = styled.div`
	padding: 0 24px;
	padding-top: 24px;
	flex: 1;
	overflow-y: auto;
`;

/* Header */
export const Header = styled.div`
	display: flex;
	justify-content: space-between;

	z-index: ${zIndices.modal};
	height: 100px;
	align-items: center;

	@media (max-width: 700px) {
		padding: 30px;
		background-color: ${colors.white};
	}
`;

/* Image */
export const Img = styled.div`
	position: absolute;
	bottom: 40px;
	width: 100%;

	@media (max-width: ${MOBILE_BREAKPOINT}px) {
		top: 0px;
		right: -130px;
	}
`;

/* Buttons */
export const CloseButton = styled.button`
	font-weight: ${fontWeights.bold};
	font-size: ${fontSizes.large};
	color: ${colors.darkBlue};
	background: none;
	cursor: pointer;
	outline: none;
	text-align: right;
	margin-top: -14px;
	margin-right: -20px;
	margin-bottom: 10px;

	@media (max-width: ${MOBILE_BREAKPOINT}px) {
		color: ${colors.black};
		margin-right: -10px;
		margin-bottom: 0;
	}
`;

export const LoginWithButton = styled.button`
	${btn}
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: ${colors.white};
	color: ${colors.darkBlue};
	border: 1px solid ${colors.darkBlue};
	border-radius: 16px;
	font-weight: 500;
	font-size: 0.8em;
	width: 100%;
	height: 56px;
	min-height: 56px;
	margin-bottom: 16px;
	box-shadow: none;
	display: flex;
	align-items: center;
`;

export const LoginButton = styled.button`
	${btn}
	border-radius: 16px;
	margin: 1em 0;
	height: 56px;
	width: 100%;
	padding: 0;

	&:disabled {
		opacity: 0.7;
		pointer-events: none;
	}

	@media (max-width: ${MOBILE_BREAKPOINT}px) {
		margin: 0.4em 0;
	}
`;

export const Title = styled.h2`
	font-weight: ${fontWeights.bold};
	font-family: ${fonts.Gilroy};
	color: ${colors.darkBlue};
	text-align: left;
	font-size: ${fontSizes.xlarge};
`;

export const Description = styled.p`
	color: ${colors.lightBlue};
	font-weight: 400;
	font-size: ${fontSizes.small};
	font-family: ${fonts.Gilroy};
	margin-top: 6px;
	margin-bottom: 20px;
	text-align: center;
`;

export const Text = styled.p`
  font-size: ${fontSizes.small};
  font-family: ${fonts.Gilroy};
  margin-bottom: 16px;
  
  ${({ centered }) => centered && 'text-align: center;'}
  ${({ onClick }) =>
		onClick
			? `
    cursor: pointer;
    color: ${colors.pink};
    text-decoration: underline;
    font-weight: ${fontWeights.semi};
  `
			: `
    color: ${colors.grey60};
    font-weight: ${fontWeights.normal};
  `}
  ${({ tip }) =>
		tip &&
		`
    padding-left: 1em;
    font-size: 0.7em;
    margin-top: -8px;
    margin-bottom: 8px;
  `}

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 0.5em;
    ${({ centered }) => centered && 'font-size: 14px;'}
  }
`;

export const TextLink = styled.span`
	cursor: pointer;
	color: ${colors.darkTurquoise};
	text-decoration: underline;
	margin-left: 4px;
`;

export const ModalTitleContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: 16px;
`;

export const ForgotPasswordContainer = styled.div`
	margin-left: 16px;
	margin-top: -8px;
	margin-bottom: 16px;
`;

export const ForgotPasswordText = styled.span`
	color: ${colors.grey60};
	font-size: 12px;
	font-family: ${fonts.Gilroy};
	cursor: pointer;
	text-decoration: underline;

	:hover {
		color: ${colors.pink};
	}
`;

export const FormContainer = styled.div`
	margin-bottom: 16px;
`;

export const CreateOrLoginContainer = styled.div`
	display: flex;
	flex-direction: row;
	padding: 16px 24px;
	align-items: center;
	justify-content: center;
	margin-bottom: 16px;
	background-color: ${colors.darkTurquoiseFaded};
	border-radius: 15px;

	@media (max-width: ${MOBILE_BREAKPOINT}px) {
		padding: 8px 24px;
	}
`;

export const CheckboxText = styled.p`
	font-size: 12px;
	line-height: 1.3;
	margin-bottom: 0;
	color: ${({ isError }) => (isError ? colors.red : colors.grey60)};
`;

export const CheckboxLink = styled.a`
	cursor: pointer;
	color: ${colors.darkTurquoise};
	text-decoration: underline;
`;
