import styled from 'styled-components';

import { ReactComponent as CrossIcon } from 'assets/images/icons/cross-rounded.svg';
import theme from 'config/theme';

const { colors } = theme;

export const Cross = styled(CrossIcon)`
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
	fill: ${colors.darkBlue};
	min-width: 12px;
	min-height: 12px;
`;
