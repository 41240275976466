import styled from 'styled-components';
import theme from 'config/theme';

const { fontSizes, fontWeights, colors } = theme;

const StyledInput = styled.input`
	width: 100%;
	font-size: ${fontSizes.normal};
	background-color: ${({ disabled }) =>
		disabled ? 'rgba(4, 31, 71, 0.1);' : 'rgba(4, 31, 71, 0.04);'};

	color: ${colors.darkBlue};

	${({ disabled }) => (disabled ? 'cursor: not-allowed;' : '')};

	font-weight: ${fontWeights.normal};
	padding: 10px 20px;
	outline: none;
	height: 56px;
	display: flex;
	align-items: center;
	border-radius: 10px;

	&::placeholder {
		color: ${colors.lightBlue};
	}

	&:focus {
		border-bottom-color: ${colors.pink};
	}
`;

export default StyledInput;
