const isComplete = ({
	first_name,
	last_name,
	profile_picture,
	cellphone,
	identification_number,
	birth_date,
	gender,
	nationality,
}) =>
	first_name !== '' &&
	last_name !== '' &&
	profile_picture != null &&
	!profile_picture.is_default &&
	cellphone != null &&
	cellphone.verified &&
	nationality &&
	identification_number &&
	birth_date &&
	gender;

const isEmailVerified = ({ email }) => email && email.verified;

export const userProfileService = {
	isComplete,
	isEmailVerified,
};
