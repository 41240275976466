import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextArea from '../../shared-components/TextArea';

import FormElementWrapper from '../../shared-components/FormElementWrapper';
import FormElementError from '../../shared-components/FormElementError';
import { Formik } from 'formik';
import * as Yup from 'yup';
import api from 'api';
import Swal from 'sweetalert2';
import styled from 'styled-components';
import { Title } from 'lib/Title';
import BottomCTAWrapper from '../BottomCTAWrapper';
import { EventType } from 'constants/events';
import { Button } from 'lib/Button';

class BiographySection extends Component {
	static propTypes = {
		onUpdateSuccess: PropTypes.func,
	};

	static defaultProps = {
		onUpdateSuccess: () => {},
	};

	handleFormSubmit = async (values, { setSubmitting }) => {
		const { onUpdateSuccess } = this.props;

		try {
			const { updateProfile } = api.userV2;
			const response = await updateProfile(values);

			if (response.status >= 200 && response.status < 300) {
				onUpdateSuccess();
				api.events.send({
					event_type: EventType.ProfileAdditionalInfo,
				});
			} else {
				this.fireSubmissionError();
			}
		} catch (err) {
			console.log(err);
			this.fireSubmissionError();
		} finally {
			setSubmitting(false);
		}
	};

	fireSubmissionError = () => {
		Swal.fire({
			title: 'Something went wrong, please contact support',
			type: 'warning',
			showConfirmButton: false,
			toast: true,
			timer: 4000,
			position: 'top',
			customClass: {
				container: 'custom-container-toast-class',
			},
		});
	};

	render() {
		const validationSchema = Yup.object().shape({
			biography: Yup.string().required('Required'),
		});

		return (
			<StyledBiographySection>
				<Formik
					initialValues={{ biography: '' }}
					onSubmit={this.handleFormSubmit}
					validationSchema={validationSchema}
				>
					{({ values, handleChange, handleSubmit, handleBlur, touched, errors, isSubmitting }) => {
						return (
							<StyledForm onSubmit={handleSubmit}>
								<div>
									<Title>Tell us about yourself</Title>
									<SubTitle>Be sure to include all the good stuff </SubTitle>
									<FormElementWrapper>
										<TextArea
											type="text"
											name="biography"
											value={values.biography}
											onChange={handleChange}
											onBlur={handleBlur}
											placeholder="Start typing here..."
										/>
										{errors.biography && touched.biography && (
											<FormElementError>{errors.biography}</FormElementError>
										)}
									</FormElementWrapper>
								</div>
								<BottomCTAWrapper>
									<div className="columns">
										<div className="column is-half">
											<Button
												isOutline
												type="submit"
												disabled={isSubmitting}
												isLoading={isSubmitting}
											>
												Complete
											</Button>
										</div>
									</div>
								</BottomCTAWrapper>
							</StyledForm>
						);
					}}
				</Formik>
			</StyledBiographySection>
		);
	}
}

export default BiographySection;

/**styled components */
const StyledBiographySection = styled.div`
	height: 100%;
`;

const StyledForm = styled.form`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
`;

const SubTitle = styled.h3`
	color: #9da7b7;
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 36px;
`;
