import { Container } from 'unstated';
import storage from './storage';

const AUTH_KEY = 'auth';

const DEFAULT_AUTH_STATE = {
	token: '',
	lastRefresh: '',
};

class Auth extends Container {
	constructor() {
		super();

		const { token, lastRefresh } = storage.get(AUTH_KEY, DEFAULT_AUTH_STATE);

		this.state = {
			token: token,
			lastRefresh: lastRefresh,
		};
	}

	set = (data = {}) => {
		data.token = !!data.token;
		const newState = { ...this.state, ...data };
		return this.setState(newState, () => {
			storage.set(AUTH_KEY, newState);
		});
	};

	clear() {
		return this.setState(DEFAULT_AUTH_STATE, () => {
			storage.set(AUTH_KEY, {});
		});
	}
}

export default new Auth();
