import React from 'react';
import YouTube, { Options } from 'react-youtube';
import './Video.scss';

interface VideoProps {
	videoUrl: string;
	width?: string;
	height?: string;
	containerClassName?: string;
	className?: string;
}

const videoOptions: Options = {
	height: '500px',
	width: '400px',
	playerVars: {
		// https://developers.google.com/youtube/player_parameters
		autoplay: 0,
	},
};

export const Video = ({ videoUrl, width, height, containerClassName, className }: VideoProps) => {
	const videoId = videoUrl.split('https://www.youtube.com/embed/')[1];
	var containerClassNameId = 'digs-youtube-player-container';
	var classNameId = 'digs-youtube-player';

	width ? (videoOptions.width = width) : (videoOptions.width = '400px');
	height ? (videoOptions.height = height) : (videoOptions.width = '500px');
	containerClassName
		? (containerClassNameId = containerClassName)
		: (containerClassNameId = 'digs-youtube-player-container');
	className ? (classNameId = className) : (classNameId = 'digs-youtube-player');

	return (
		<>
			<YouTube
				containerClassName={containerClassNameId}
				className={classNameId}
				videoId={videoId}
				opts={videoOptions}
			/>
		</>
	);
};
