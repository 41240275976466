import styled from 'styled-components';

interface SubTitleProps {
	noMargin?: boolean;
}

const SubTitle = styled.h3<SubTitleProps>`
	font-size: 18px;
	font-weight: 400;
	margin-bottom: ${({ noMargin }) => (noMargin ? '0' : '16px')};
`;

export default SubTitle;
