import React, { useState } from 'react';

import styled from 'styled-components';

import { Title } from 'lib/Title';
import { Select, SelectOption } from 'lib/Select';
import { DateInput } from 'lib/DateInput';
import { Button } from 'lib/Button';
import theme from 'config/theme';

const { colors } = theme;

const Section = styled.div`
	margin-top: 32px;
	margin-bottom: 48px;
`;

const Container = styled.div`
	height: 100%;
	position: relative;
`;

const FooterBar = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;

	@media (max-width: 1080px) {
		position: fixed;
		padding: 16px 24px;
		border-top: 1px solid ${colors.grey10};
	}
`;

interface BirthdateGenderProps {
	isLoading?: boolean;
	birthDate?: string;
	gender?: string;
	genderOptions: SelectOption[];
	onSubmit(data: { birthDate: string; gender: string }): void;
}

export const BirthdateGender = ({
	isLoading,
	birthDate,
	gender,
	genderOptions,
	onSubmit,
}: BirthdateGenderProps) => {
	const [showErrors, setShowErrors] = useState(false);
	const [birthDateValue, setBirthDateValue] = useState<string>(birthDate || '');
	const [genderValue, setGenderValue] = useState<string>(gender || '');

	const handleDateSelect = (date?: Date) => {
		if (!date) {
			setBirthDateValue('');
			return;
		}

		setBirthDateValue(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`);
	};

	const handleSubmit = (event: any) => {
		event.preventDefault();
		if (!birthDateValue || !genderValue) {
			setShowErrors(true);
			return;
		}

		onSubmit({
			birthDate: birthDateValue,
			gender: genderValue,
		});
	};

	return (
		<Container>
			<Title>Birthdate and gender</Title>
			<form onSubmit={handleSubmit}>
				<Section>
					<Select
						placeholder={'Gender'}
						options={genderOptions}
						selectedOption={genderOptions.find(option => option.value === genderValue)}
						onChange={option => setGenderValue(option.value)}
						isErrorState={showErrors && !genderValue}
					/>
					<DateInput
						value={birthDateValue}
						placeholder={'Select your birthdate'}
						onChange={handleDateSelect}
						isErrorState={showErrors && !birthDateValue}
						allowMonthYearSelection={true}
					/>
				</Section>
				<FooterBar>
					<Button isOutline type="submit" isLoading={isLoading}>
						Continue
					</Button>
				</FooterBar>
			</form>
		</Container>
	);
};
