import authContainer from 'containers/auth';
import client from '../utils/fetch-client';

const path = '/v2/events';

export default {
	send: event => {
		if (!authContainer.state.token) {
			return Promise.resolve();
		}

		if (!event || !event.event_type) {
			return Promise.resolve();
		}

		return client.post(`${path}`, {
			body: event,
		});
	},
};
