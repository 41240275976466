import styled from 'styled-components';
import theme from 'config/theme';

const { fontSizes, fontWeights, colors } = theme;

const StyledTextarea = styled.textarea`
	width: 100%;
	font-size: ${fontSizes.normal};
	background-color: rgba(4, 31, 71, 0.04);
	color: ${colors.darkBlue};

	font-weight: ${fontWeights.normal};
	margin-bottom: 20px;
	padding: 20px;
	outline: none;
	min-height: 150px;
	display: flex;
	align-items: center;
	border-radius: 10px;

	&::placeholder {
		color: ${colors.lightBlue};
	}

	&:focus {
		border-bottom-color: ${colors.pink};
	}
`;

export default StyledTextarea;
