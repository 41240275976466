import styled from 'styled-components';

export const Title = styled.h1`
	font-weight: bold;
	font-size: 32px;
	text-align: center;
`;

export const SubText = styled.p`
	font-size: 18px;
	margin: 18px 0;
`;

export const ModalContainer = styled.div`
	padding: 24px;
	padding-top: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	position: relative;
`;

export const CloseButton = styled.div`
	position: absolute;
	top: 24px;
	right: 24px;
	cursor: pointer;
`;
