import React, { Component } from 'react';

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';
import styled from 'styled-components';
import Swal from 'sweetalert2';

import api from 'api';
import BottomCTAWrapper from '../BottomCTAWrapper';
import { Button } from 'lib/Button';
import { Title } from 'lib/Title';
import { API_BASE_URL } from 'config/urls';

registerPlugin(
	FilePondPluginFileValidateType,
	FilePondPluginImageExifOrientation,
	FilePondPluginImagePreview,
	FilePondPluginImageCrop,
	FilePondPluginImageResize,
	FilePondPluginImageTransform,
	FilePondPluginImageEdit,
	FilePondPluginFilePoster,
);

class ProfilePictureSection extends Component {
	static defaultProps = {
		onUpdateSuccess: () => {},
	};

	fireSubmissionSuccess = message => {
		Swal.fire({
			title: message,
			type: 'success',
			showConfirmButton: false,
			toast: true,
			timer: 3000,
			position: 'top',
			customClass: {
				container: 'custom-container-toast-class',
			},
		});
	};

	fireSubmissionError = message => {
		Swal.fire({
			title: message,
			type: 'warning',
			showConfirmButton: false,
			toast: true,
			timer: 4000,
			position: 'top',
			customClass: {
				container: 'custom-container-toast-class',
			},
		});
	};

	onContinueClick = () => {
		const { onUpdateSuccess } = this.props;
		api.userV2
			.getProfile()
			.then(res => res.data)
			.then(data => {
				if (!data.profile_picture.is_default) {
					onUpdateSuccess();
				} else {
					this.fireSubmissionError('Please upload a profile picture');
				}
			});
	};
	render() {
		const url = `${API_BASE_URL}v2/user/profile-picture`;

		return (
			<StyledProfilePictureSection>
				<TopContent>
					<Title>Add a profile picture</Title>
					<SubTitle>Upload a picture of yourself below</SubTitle>
					<FileUploadWrapper>
						<FilePond
							name="file"
							labelIdle={`Drag & Drop your picture or <span class="filepond--label-action">Browse</span>`}
							allowReplace={true}
							server={{
								url: url,
								process: {},
							}}
							acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
							allowMultiple={false}
							allowImagePreview
							imageCropAspectRatio="1:1"
							stylePanelLayout="compact circle"
							ref={ref => (this.pond = ref)}
							imageResizeTargetWidth={200}
							imageResizeTargetHeight={200}
							styleLoadIndicatorPosition="center bottom"
							styleProgressIndicatorPosition="center bottom"
							styleButtonRemoveItemPosition="center bottom"
							styleButtonProcessItemPosition="center bottom"
						/>
					</FileUploadWrapper>
				</TopContent>

				<BottomCTAWrapper>
					<div className="columns">
						<div className="column is-half">
							<Button isOutline onClick={this.onContinueClick}>
								Continue
							</Button>
						</div>
					</div>
				</BottomCTAWrapper>
			</StyledProfilePictureSection>
		);
	}
}

export default ProfilePictureSection;

/**styled components */
const StyledProfilePictureSection = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const FileUploadWrapper = styled.div`
	margin-top: 56px;
	height: 200px;
	width: 200px;
	align-self: center;
`;

const SubTitle = styled.div`
	color: #9da7b7;
	font-size: 16px;
	font-weight: 400;
`;

const TopContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;
