import React, { Component } from 'react';

import theme from 'config/theme';
import styled from 'styled-components';
import { Breakpoint } from '@tiaanduplessis/react-resize';
import DesktopBreadCrumbs from './desktop-bread-crumbs';
import wizardSteps, { getResumableWizardState } from './userProfileSetupHelpers';
import SetupCompleteSection from './setup-complete-section';
import BiographySection from './biography-section';
import ProfilePictureSection from './profile-picture-section';
import VerifyEmailSection from './verify-email-section';
import BasicDetailsSection from './basic-details-section';
import CellphoneStepOneSection from './cellphone-step-one-section';
import CellphoneStepTwoSection from './cellphone-step-two-section';
import api from 'api';
import './user-profile-setup.css';
import _ from 'lodash';
import MobileWizardStepOverview from './mobile-wizard-step-overview';
import userContainer from 'containers/user';
import { EventType } from 'constants/events';
import { TopBar } from 'lib/TopBar';
import { Modal } from 'lib/Modal';
import Title from 'lib/Title';
import { IdentityInfo } from './IdentityInfo';
import { BirthdateGender } from './BirthdateGender';
import { userProfileService } from 'services/userProfileService';

const { colors, topBar } = theme;

const getStepEventType = step => {
	switch (step) {
		case wizardSteps.Intro:
			return EventType.ProfileStart;
		case wizardSteps.BASIC_DETAILS_SECTION:
			return EventType.ProfileBasic;
		case wizardSteps.VERIFY_EMAIL_SECTION:
			return EventType.ProfileVerifyEmail;
		case wizardSteps.PROFILE_PICTURE_SECTION:
			return EventType.ProfilePicture;
		case wizardSteps.CELLPHONE_SECTION_STEP_ONE:
			return EventType.ProfileCellNumber;
		case wizardSteps.IDENTIFICATION_SECTION:
			return EventType.ProfileIdentity;
		case wizardSteps.BIRTH_AND_GENDER:
			return EventType.ProfileGenderBirth;
		case wizardSteps.SETUP_COMPLETE_SECTION:
			return EventType.ProfileComplete;
		default:
			return '';
	}
};

class UserProfileSetup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			currentWizardStep: wizardSteps.BASIC_DETAILS_SECTION,
			userInfo: {},
			showStepsOverview: false,
			isSavingIdDetails: false,
			isSavingBirthGender: false,
			genderOptions: [],
			ignoreEmail: false,
		};
	}

	componentDidMount() {
		api.userV2
			.getProfile()
			.then(res => res.data)
			.then(data => {
				if (userProfileService.isComplete(data) && userProfileService.isEmailVerified(data)) {
					this.props.onComplete();
					return;
				}
				api.events.send({
					event_type: EventType.ProfileStart,
				});
				this.setState(
					{
						userInfo: data,
						currentWizardStep: getResumableWizardState(data, this.state.ignoreEmail),
					},
					() => this.logStepEvent(this.state.currentWizardStep),
				);
			})
			.catch(error => console.log(error));

		api.userV2
			.getGenderOptions()
			.then(res => res.data)
			.then(options => this.setState({ genderOptions: options }))
			.catch(error => console.log(error));
	}

	logStepEvent(step) {
		const eventName = getStepEventType(step);
		if (!step) {
			return;
		}
		api.events.send({ event_type: eventName });
	}

	saveIdentityDetails = ({ nationality, value }) => {
		if (this.state.isSavingIdDetails) {
			return;
		}

		this.setState({
			isSavingIdDetails: true,
		});

		api.userV2
			.updateProfile({
				nationality: nationality,
				identification_number: value,
			})
			.then(() => {
				this.setState({
					isSavingIdDetails: false,
				});
				this.onUpdateProfileSuccess();
			});
	};

	saveBirthdayGender = ({ birthDate, gender }) => {
		if (this.state.isSavingBirthGender) {
			return;
		}

		this.setState({
			isSavingBirthGender: true,
		});

		api.userV2
			.updateProfile({
				birth_date: birthDate,
				gender: gender,
			})
			.then(() => {
				this.setState({
					isSavingBirthGender: false,
				});
				this.onUpdateProfileSuccess();
			});
	};

	onMobileWizardStepsDismiss = () => {
		this.setState({ showStepsOverview: false });
	};

	onUpdateProfileSuccess = () => {
		api.userV2
			.getProfile()
			.then(res => res.data)
			.then(async data => {
				await userContainer.set(data);

				const nextStep = getResumableWizardState(data, this.state.ignoreEmail);

				this.setState(
					{
						userInfo: data,
						currentWizardStep: nextStep,
					},
					() => this.logStepEvent(this.state.currentWizardStep),
				);
			});
	};

	handleEmailContinue = () => {
		this.setState(
			{
				ignoreEmail: true,
			},
			() => {
				this.onUpdateProfileSuccess();
			},
		);
	};

	renderCurrentWizardStep = () => {
		const { onComplete } = this.props;
		const { currentWizardStep, userInfo } = this.state;

		switch (currentWizardStep) {
			case wizardSteps.BASIC_DETAILS_SECTION:
				return (
					<BasicDetailsSection
						onUpdateSuccess={this.onUpdateProfileSuccess}
						email={userInfo.email}
					/>
				);
			case wizardSteps.VERIFY_EMAIL_SECTION:
				return (
					<VerifyEmailSection
						email={userInfo.email.email}
						verified={userInfo.email.verified}
						onUpdateSuccess={this.handleEmailContinue}
					/>
				);
			case wizardSteps.PROFILE_PICTURE_SECTION:
				return <ProfilePictureSection onUpdateSuccess={this.onUpdateProfileSuccess} />;
			case wizardSteps.BIOGRAPHY_SECTION:
				return <BiographySection onUpdateSuccess={this.onUpdateProfileSuccess} />;
			case wizardSteps.CELLPHONE_SECTION_STEP_ONE:
				return (
					<CellphoneStepOneSection
						number={userInfo.cellphone && userInfo.cellphone.number}
						region={userInfo.cellphone && userInfo.cellphone.region}
						onUpdateSuccess={this.onUpdateProfileSuccess}
					/>
				);
			case wizardSteps.CELLPHONE_SECTION_STEP_TWO:
				return (
					<CellphoneStepTwoSection
						updatedPhoneNumber={userInfo.cellphone && userInfo.cellphone.number}
						onUpdateSuccess={this.onUpdateProfileSuccess}
						onBackClick={() => {
							this.setState(
								{
									currentWizardStep: wizardSteps.CELLPHONE_SECTION_STEP_ONE,
								},
								() => this.logStepEvent(this.state.currentWizardStep),
							);
						}}
					/>
				);
			case wizardSteps.IDENTIFICATION_SECTION:
				return (
					<IdentityInfo
						idNumber={userInfo.identification_number}
						nationality={userInfo.nationality}
						isLoading={this.state.isSavingIdDetails}
						onSubmit={this.saveIdentityDetails}
					/>
				);
			case wizardSteps.BIRTH_AND_GENDER:
				return (
					<BirthdateGender
						isLoading={this.state.isSavingBirthGender}
						birthDate={userInfo.birth_date}
						gender={userInfo.gender}
						genderOptions={this.state.genderOptions}
						onSubmit={this.saveBirthdayGender}
					/>
				);
			case wizardSteps.SETUP_COMPLETE_SECTION:
				return (
					<SetupCompleteSection
						onCompleteClick={onComplete}
						isEmailVerified={userProfileService.isEmailVerified(userInfo)}
					/>
				);
			default:
				return null;
		}
	};

	renderMobileContent = () => {
		const { onClose } = this.props;
		const { showStepsOverview, userInfo } = this.state;
		return (
			<>
				<TopBar onClose={onClose} />
				<MobileContentWrapper>
					{!_.isEmpty(userInfo) && this.renderCurrentWizardStep()}
				</MobileContentWrapper>
				{!!showStepsOverview && (
					<MobileWizardStepOverview
						userProfile={userInfo}
						onContinueClick={this.onMobileWizardStepsDismiss}
						onBackClick={onClose}
					/>
				)}
			</>
		);
	};

	renderDesktopContent = () => {
		const { onClose } = this.props;
		const { userInfo } = this.state;

		return (
			<DesktopContentContainer>
				<DesktopSetupLeftContent>
					<DesktopSidebarTitle>Complete your profile</DesktopSidebarTitle>
					<DesktopBreadCrumbs userProfile={userInfo} />
				</DesktopSetupLeftContent>
				<DesktopSetupRightContent>
					<TopBar closeOnRight onClose={onClose} />
					<DesktopMainContentWrapper>
						{!_.isEmpty(userInfo) && this.renderCurrentWizardStep()}
					</DesktopMainContentWrapper>
				</DesktopSetupRightContent>
			</DesktopContentContainer>
		);
	};

	render() {
		const mobileBreakPointPx = 1080;
		const desktopBreakPointPx = mobileBreakPointPx + 1;
		const { onClose } = this.props;

		if (_.isEmpty(this.state.userInfo) && !this.props.showImmediately) {
			return null;
		}

		return (
			<Modal width={960} height={750} onBackgroundClick={onClose}>
				<Breakpoint maxWidth={mobileBreakPointPx}>{this.renderMobileContent()}</Breakpoint>
				<Breakpoint minWidth={desktopBreakPointPx}>{this.renderDesktopContent()}</Breakpoint>
			</Modal>
		);
	}
}

export default UserProfileSetup;

/**styled components */
const DesktopContentContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
`;

const DesktopSetupLeftContent = styled.div`
	width: 384px;
	height: 100%;
	background-color: ${colors.blue};
	border-radius: 15px 0px 0px 15px;
	padding-top: 56px;
  padding-right: 16px;
  padding-left: 56px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	overflow: hidden;
`;

const DesktopSidebarTitle = styled(Title)`
	color: ${colors.white};
  margin-top: 32px;
  margin-bottom: 48px;
`;

const DesktopSetupRightContent = styled.div`
	height: 100%;
	width: 698px;
	background-color: #ffffff;
	border-radius: 0px 15px 15px 0px;
`;

const DesktopMainContentWrapper = styled.div`
	height: calc(100% - ${topBar.height});
	padding: 56px;
	padding-top: 24px;
	overflow-y: auto;
`;

const MobileContentWrapper = styled.div`
	height: calc(100% - ${topBar.height} - 90px);
	padding: 0 24px;
	padding-top: 24px;
	overflow: auto;
`;
