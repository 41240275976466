import Facebook from 'components/icons/facebook';
import Youtube from 'components/icons/youtube';
import Instagram from 'components/icons/instagram';
import Twitter from 'components/icons/twitter';
import social from 'config/social';
import React from 'react';
import styled from 'styled-components';

import { SocialLink } from './styles';
import { SocialContainer } from './styles';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';

const Container = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	margin-bottom: 24px;

	@media (max-width: 650px) {
		align-items: center;
		justify-content: center;
	}
`;

const SocialLinks = ({ color, margin }) =>
	featureFlagContainer.isEnabled(FeatureFlag.Simplify) ? (
		<Container>
			<SocialLink color={color} margin={margin} href={social.twitter}>
				<Twitter color={color} />
			</SocialLink>
			<SocialLink color={color} margin={margin} href={social.facebook}>
				<Facebook color={color} />
			</SocialLink>
			<SocialLink color={color} margin={margin} href={social.instagram}>
				<Instagram color={color} />
			</SocialLink>
			<SocialLink color={color} margin={margin} href={social.youtube}>
				<Youtube color={color} />
			</SocialLink>
		</Container>
	) : (
		<SocialContainer>
			<SocialLink color={color} margin={margin} href={social.twitter}>
				<Twitter color={color} />
			</SocialLink>
			<SocialLink color={color} margin={margin} href={social.facebook}>
				<Facebook color={color} />
			</SocialLink>
			<SocialLink color={color} margin={margin} href={social.instagram}>
				<Instagram color={color} />
			</SocialLink>
			<SocialLink color={color} margin={margin} href={social.youtube}>
				<Youtube color={color} />
			</SocialLink>
		</SocialContainer>
	);

export default SocialLinks;
