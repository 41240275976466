import client from '../utils/fetch-client';

const path = '/v2/bookings';

export default {
	createDraftBooking(listingId) {
		return client.post(`${path}/draft`, {
			body: {
				listing_uuid: listingId,
			},
		});
	},

	createBooking(object) {
		return client.post(`${path}`, {
			body: object,
		});
	},

	getBookings() {
		return client.get(`${path}?type=sent`);
	},

	getConnectionCounter() {
		return client.get(`${path}/counter`);
	},
};
