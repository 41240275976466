import React, { PureComponent } from 'react';

import { Breakpoint } from '@tiaanduplessis/react-resize';
import ReactModal from 'react-modal';
import { withRouter } from 'react-router';
import { Subscribe } from 'unstated';
import throttle from 'lodash.throttle';
import styled from 'styled-components';

import api from 'api';
import Logo from 'components/icons/logo';
import social from 'config/social';
import theme from 'config/theme';
import authContainer from 'containers/auth';
import modalContainer from 'containers/modal';
import userContainer from 'containers/user';
import MobileHeader from './mobile-header';
import ProfilePopup from './profile-popup';
import routes from 'config/routes';

import {
	Container,
	LogoContainer,
	MobileContainer,
	Nav,
	NavBtn,
	NavItem,
	NavLink,
	NavList,
	SignupBtn,
} from './styles';
import './styles.css';
import { ModalType } from 'constants/modalTypes';
import LandingSearchBar from 'components/landing/LandingSearchBar';
import { analyticsService, EventName } from 'services/analyticsService';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';
import { featureFlagContainer, FeatureFlag } from 'containers/featureFlags';
import ModalEnum from 'models/modalEnum';
import InvoicesProductTourModalContent from 'components/dashboards/manage-listing-dashboard/InvoicesProductTourModalContent';

const { colors, fontSizes } = theme;

const SearchBarContainer = styled.div`
	width: 100%;
	max-width: 600px;
	margin-left: 40px;
	height: 50px;

	@media (max-width: ${BREAKPOINT_MOBILE_PX + 1}px) {
		margin-left: 0;
	}
`;

const NotifcationCircle = styled.div`
	height: 20px;
	background: ${colors.pink};
	border: none;
	width: 20px;
	padding: 0;
	border-radius: 20px;
	position: absolute;
	right: -16px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const NotificationText = styled.div`
	font-size: ${fontSizes.xsmall};
	color: ${colors.white};
`;

const Inbox = styled.div`
	position: relative;
	width: 100%;
`;

const ManageListing = styled.div`
	position: relative;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	right: -10px;
`;

ReactModal.setAppElement('#root');

class Header extends PureComponent {
	constructor() {
		super();

		this.state = {
			isSticky: false,
			color: '',
			bgColor: '',
			variant: '',
			hasListings: false,
			hasInvoices: false,
			hasFavourites: false,
			listings: null,
			isLandlordLanding: false,
			isManageListing: false,
			isDashboard: false,
			showSearchBar: true,
			unreadNotifications: 0,
		};

		this.throttledOnScroll = throttle(this.onScroll, 100);
	}

	static defaultProps = {
		showShadow: true,
		variant: 'dark',
		color: null,
		searchable: false,
	};

	componentDidMount() {
		this.setState({
			isLandlordLanding: this.props.location.pathname.startsWith(routes.landlordLanding),
			isDashboard: this.props.location.pathname.startsWith(routes.landlord_dashboard),
			isManageListing: this.props.location.pathname.startsWith(routes.manage_listing),
		});
		window.addEventListener('scroll', this.throttledOnScroll);
		authContainer.subscribe(this.onAuthStateChange);
		if (authContainer.state.token) {
			this.fetchListings();
			this.fetchInvoices();
			this.fetchUnreadNotifications();
			this.fetchFavourites();
		} else {
			this.setState({
				listings: [],
			});
		}
		this.onScroll();
	}

	componentWillMount() {
		const { color, bgColor, variant } = this.props;

		this.setState({
			color,
			bgColor,
			variant: variant ? variant : 'dark',
		});
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.throttledOnScroll);
		authContainer.unsubscribe(this.onAuthStateChange);
	}

	onAuthStateChange = () => {
		if (!authContainer.state.token) {
			return;
		}

		this.fetchListings();
		this.fetchInvoices();
		this.fetchUnreadNotifications();
		this.fetchFavourites();
	};

	fetchListings = () => {
		api.property
			.getProperties()
			.then(res => res.data)
			.then(listings => {
				this.setState({
					hasListings: !!listings && !!listings.length,
					listings: listings,
				});
			});
	};

	fetchFavourites = () => {
		api.favouritesV2
			.getFavourites()
			.then(res => res.data)
			.then(data => {
				console.log(data);
				this.setState({
					hasFavourites: !!data && !!data.length,
				});
			})
			.catch(err => console.log(err));
	};

	fetchInvoices = () => {
		api.invoicing
			.getInvoices()
			.then(res => res.data)
			.then(async invoices => {
				const hasInvoices = Boolean(invoices.results.length);
				this.setState({
					hasInvoices: hasInvoices,
				});

				api.userV2.getProfile().then(userProfileV2Response => {
					if (hasInvoices && !userProfileV2Response.data.has_viewed_invoicing_tour_modal) {
						modalContainer.open(
							ModalEnum.Generic,
							<InvoicesProductTourModalContent
								onView={() => {
									modalContainer.close();
									api.userV2.updateProfile({
										has_viewed_invoicing_tour_modal: true,
									});
									this.props.history.push(routes.manage_listing_invoices);
								}}
								onClose={() => {
									modalContainer.close();
									api.userV2.updateProfile({
										has_viewed_invoicing_tour_modal: true,
									});
								}}
							/>,
							{ width: 500 },
						);
					}
				});
			});
	};

	fetchUnreadNotifications = () => {
		api.messaging
			.getUnreadNotifications()
			.then(res => res.data)
			.then(notifications => {
				api.property
					.getProperties()
					.then(res => res.data)
					.then(listings => {
						const hasListings = !!listings && !!listings.length;
						this.setState({
							unreadNotifications: hasListings
								? notifications.landlord_unread
								: notifications.tenant_unread,
						});
					});
			});
	};

	setModal = (modal = '') => modalContainer.set(modal);

	onScroll = () => {
		const top = window.scrollY;
		const { isLanding } = this.props;

		const logoColor = colors.blue;

		if (isLanding) {
			this.setState({
				showSearchBar: top > 350,
			});
		}

		if (isLanding && top > 20) {
			this.setState({
				isSticky: true,
				color: logoColor,
				bgColor: colors.white,
				variant: 'dark',
			});
		} else if (isLanding && top < 20) {
			this.setState({
				isSticky: false,
				color: colors.white,
				bgColor: colors.transparent,
				variant: 'light',
			});
		} else {
			this.setState({
				isSticky: top > 10,
			});
		}
	};

	handleLogoutClicked = async () => {
		await api.auth.logout();
		await userContainer.clear();
		await authContainer.clear();
		analyticsService.trackEvent(EventName.SetUser, { digs_user_id: '' });
		this.props.history.push('/');
	};

	getTopMenu = (isSignedIn = false) => {
		if (!isSignedIn) {
			return [
				{
					label: 'Sign Up',
					onClick: () => this.setModal('signup'),
				},
				{
					label: 'Login',
					onClick: () => this.setModal('login'),
				},
				...[
					this.state.isLandlordLanding || this.state.isDashboard
						? {
								label: 'Create a listing',
								to: routes.create_listing,
								onClick: () => this.handleLinkClick(routes.create_listing),
						  }
						: {
								label: 'Becoming a landlord',
								to: routes.landlordLanding,
						  },
				],
				...(!featureFlagContainer.isEnabled(FeatureFlag.Simplify)
					? [
							{
								label: 'Find a digs',
								to: routes.digs_search,
							},
					  ]
					: []),
			];
		}

		return [
			{
				label: 'Inbox',
				to: routes.inbox,
			},
			{
				label: 'Account',
				to: routes.account_dashboard,
			},
			...(this.state.hasFavourites
				? [
						{
							label: 'Favourites',
							to: routes.favourites,
						},
				  ]
				: []),
			...(!featureFlagContainer.isEnabled(FeatureFlag.Simplify)
				? [
						{
							label: 'Find a digs',
							to: routes.digs_search,
						},
				  ]
				: []),
			...(this.state.hasListings && featureFlagContainer.isEnabled(FeatureFlag.LandlordDashboard)
				? [
						{
							label: 'Dashboard',
							to: routes.manage_listing_dashboard,
						},
				  ]
				: []),
			...[
				this.state.hasListings || this.state.isLandlordLanding || this.state.isDashboard
					? {
							label: 'Create a listing',
							to: routes.create_listing,
							onClick: () => this.handleLinkClick(routes.create_listing),
					  }
					: {
							label: 'Becoming a landlord',
							to: routes.landlordLanding,
					  },
			],
			...(this.state.hasListings
				? [
						{
							label: 'Manage listings',
							to: routes.manage_listing,
						},
				  ]
				: []),
			...(this.state.hasInvoices && !featureFlagContainer.isEnabled(FeatureFlag.HideInvoices)
				? [
						{
							label: 'Invoices',
							to: routes.manage_listing_invoices,
						},
				  ]
				: []),
			{
				label: 'Logout',
				onClick: () => this.handleLogoutClicked(),
			},
		];
	};

	getBottomMenu = () => [
		{ label: 'Blog', href: social.blog },
		{ label: 'Safety on DigsConnect', to: routes.safety },
		{ label: 'Terms of use', to: routes.terms_and_conditions, openInNewTab: true },
		{ label: 'Privacy policy', to: routes.privacy, openInNewTab: true },
		{ label: 'Our partners', to: routes.partners, openInNewTab: true },
		{ label: 'Contact DigsConnect', to: routes.contact_us },
	];

	// TODO: Let router custom hook handle this once component is a FC
	handleLinkClick = route => {
		const featureRoute = routes.manage_listing;

		if (route === routes.create_listing || route === featureRoute) {
			if (!authContainer.state.token) {
				modalContainer.set(ModalType.Login, {
					onLoginSuccess: () => {
						this.props.history.push(route);
					},
				});
				return;
			}
			this.props.history.push(route);
			return;
		}
	};

	render() {
		const {
			showShadow,
			showMiniShadow,
			isLanding,
			findADigs,
			mobilePopupOnly,
			isMobileMenuOpen,
			onToggleMobileMenu,
		} = this.props;
		const { isSticky, bgColor, color, variant, showSearchBar } = this.state;

		if (mobilePopupOnly) {
			return (
				<Subscribe to={[userContainer, authContainer]}>
					{(user, auth) => {
						const logoColor = color || colors.blue;

						let userProfilePicUrl = '';
						if (user && auth.state.token) {
							userProfilePicUrl =
								user.state.v2 && user.state.v2.profile_picture && user.state.v2.profile_picture.url;
						}

						return (
							<Container
								ref={this.props.elementRef}
								bgColor={bgColor}
								style={{
									height: 0,
									boxShadow:
										isSticky && showShadow
											? '0 20px 40px rgba(0, 0, 0, .3)'
											: showMiniShadow && '0 3px 5px rgba(0, 0, 0, .1)',
								}}
							>
								<MobileContainer>
									<Breakpoint maxWidth={1100}>
										<MobileHeader
											isOpen={isMobileMenuOpen}
											onToggle={onToggleMobileMenu}
											mobilePopupOnly
											topItems={this.getTopMenu(auth.state.token)}
											bottomItems={this.getBottomMenu()}
											color={logoColor}
										/>
									</Breakpoint>
								</MobileContainer>
							</Container>
						);
					}}
				</Subscribe>
			);
		}

		return (
			<Subscribe to={[userContainer, authContainer]}>
				{(user, auth) => {
					console.log(user);
					const logoColor = color || colors.blue;

					let userProfilePicUrl = '';
					if (user && auth.state.token) {
						userProfilePicUrl =
							user.state.v2 && user.state.v2.profile_picture && user.state.v2.profile_picture.url;
					}

					return (
						<Container
							ref={this.props.elementRef}
							bgColor={bgColor}
							style={{
								boxShadow:
									isSticky && showShadow
										? '0 20px 40px rgba(0, 0, 0, .3)'
										: showMiniShadow && '0 3px 5px rgba(0, 0, 0, .1)',
							}}
							withBorder={findADigs}
						>
							<LogoContainer findADigs={findADigs}>
								{findADigs ? (
									<>
										<Breakpoint minWidth={768}>
											<Logo color={logoColor} />
										</Breakpoint>
										<SearchBarContainer>
											<LandingSearchBar
												searchIconOnly
												syncFromQueryParams
												clearOnSearch
												background={colors.grey01}
											/>
										</SearchBarContainer>
									</>
								) : (
									<>
										<Logo color={logoColor} />
										<Breakpoint minWidth={768}>
											{(!isLanding || showSearchBar) && (
												<SearchBarContainer>
													<LandingSearchBar
														searchIconOnly
														syncSearchState
														background={colors.grey01}
													/>
												</SearchBarContainer>
											)}
										</Breakpoint>
									</>
								)}
							</LogoContainer>

							<MobileContainer>
								<Breakpoint maxWidth={1100}>
									<MobileHeader
										topItems={this.getTopMenu(auth.state.token)}
										bottomItems={this.getBottomMenu()}
										color={logoColor}
									/>
								</Breakpoint>
							</MobileContainer>
							<Breakpoint minWidth={1100}>
								<Nav>
									<NavList aria-label="Menu" aria-activedescendant="find-digs">
										{this.state.isLandlordLanding && (
											<NavItem aria-label="Menu item" variant={variant}>
												<NavLink to={routes.home}>For Tenants</NavLink>
											</NavItem>
										)}
										{!this.state.isLandlordLanding &&
											!this.state.isManageListing &&
											!this.state.isDashboard && (
												<NavItem aria-label="Menu item" variant={variant}>
													<NavLink to={routes.landlordLanding}>Becoming a landlord</NavLink>
												</NavItem>
											)}
										{(this.state.isLandlordLanding || this.state.isManageListing) && (
											<>
												{Boolean(auth.state.token) ? (
													<>
														{Boolean(this.state.listings) && (
															<>
																{Boolean(this.state.listings && this.state.listings.length > 0) ? (
																	<NavItem aria-label="Menu item" variant={variant}>
																		<NavLink to={routes.manage_listing}>Manage listings</NavLink>
																	</NavItem>
																) : (
																	<NavItem aria-label="Menu item" variant={variant}>
																		<NavLink to={routes.create_listing}>+ Create a listing</NavLink>
																	</NavItem>
																)}
															</>
														)}
													</>
												) : (
													<>
														{Boolean(this.state.listings) && (
															<NavItem aria-label="Menu item" variant={variant}>
																<NavLink
																	to={routes.create_listing}
																	onClick={e => {
																		e.preventDefault();
																		e.stopPropagation();
																		this.handleLinkClick(routes.create_listing);
																	}}
																>
																	+ Create a listing
																</NavLink>
															</NavItem>
														)}
													</>
												)}
											</>
										)}
										{Boolean(auth.state.token) && (
											<NavItem aria-label="Menu item" variant={variant}>
												<Inbox>
													<NavLink to={routes.inbox}>Inbox</NavLink>
													{this.state.unreadNotifications > 0 && (
														<NotifcationCircle>
															<NotificationText>{Math.min(99, this.state.unreadNotifications)}</NotificationText>
														</NotifcationCircle>
													)}
												</Inbox>
											</NavItem>
										)}

										{auth.state.token ? (
											<ProfilePopup
												name={user.state.v2.first_name}
												picture={userProfilePicUrl}
												hasListings={this.state.hasListings}
												hasInvoices={this.state.hasInvoices}
											/>
										) : (
											<>
												<NavItem aria-label="Menu item" variant={variant}>
													<SignupBtn onClick={() => this.setModal('signup')} type="button">
														Signup
													</SignupBtn>
												</NavItem>
												<NavItem
													aria-label="Menu item"
													onClick={() => this.setModal('login')}
													variant={variant}
												>
													<NavBtn type="button" variant={variant}>
														Login
													</NavBtn>
												</NavItem>
											</>
										)}
									</NavList>
								</Nav>
							</Breakpoint>
						</Container>
					);
				}}
			</Subscribe>
		);
	}
}

export default withRouter(Header);
