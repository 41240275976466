import client from '../utils/fetch-client';

export default {
	newDigsmateMessage(userId, message = '') {
		return client.post(`/messaging/digsmate`, {
			body: {
				user_uuid: userId,
				message_content: message,
			},
		});
	},
	getUnreadNotifications() {
		return client.get(`/v2/user/inbox/notifications`);
	},
};
