import React from 'react';

import styled from 'styled-components';
import Icon from 'react-eva-icons';

const MobileBreadCrumbItem = ({ text, completed }) => (
	<StyledMobileBreadCrumbItem>
		{completed ? (
			<Icon name="checkmark-circle-2" size="large" fill="#017b8a" />
		) : (
			<NotCompletedIndicator />
		)}

		<Text completed={completed}>{text}</Text>
	</StyledMobileBreadCrumbItem>
);

export default MobileBreadCrumbItem;

/**styled components */
const StyledMobileBreadCrumbItem = styled.div`
	margin-bottom: 28px;
	display: flex;
	justify-content: start;
	align-items: center;
`;

const Text = styled.div`
	font-size: 14px;
	font-weight: ${({ completed }) => (completed ? '700' : '400')};
	color: ${({ completed }) => (completed ? '#041f47' : '#9da7b7')};
	margin-left: 16px;
`;

const NotCompletedIndicator = styled.div`
	border-radius: 50%;
	background-color: rgba(4, 31, 71, 0.08);
	height: 19px;
	width: 19px;
	margin: 2px;
`;
