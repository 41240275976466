import React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import { ReactComponent as PlusIcon } from 'assets/images/icons/plus.svg';
import { ReactComponent as MinusIcon } from 'assets/images/icons/minus.svg';

const { colors } = theme;

const STEP_ICON_SIZE = 32;
const STEP_BUTTON_SIZE = 40;
const TENANTS_FILTER_MAX_VALUE = 10;

interface TenantsFilterInputProps {
	count: number;
	onCountChange(newCount: number): void;
}

const TenantsFilterInput = ({ count, onCountChange }: TenantsFilterInputProps) => {
	const handleIncrement = () => {
		onCountChange(count >= TENANTS_FILTER_MAX_VALUE ? TENANTS_FILTER_MAX_VALUE : count + 1);
	};

	const handleDecrement = () => {
		onCountChange(count === 0 ? 0 : count - 1);
	};

	return (
		<ContentContainer>
			<StepButton onClick={handleDecrement}>
				<MinusIcon
					fill={colors.darkBlue}
					style={{ minWidth: STEP_ICON_SIZE, minHeight: STEP_ICON_SIZE }}
				/>
			</StepButton>
			{count ? `${count}+ Tenants` : 'Any'}
			<StepButton onClick={handleIncrement}>
				<PlusIcon
					fill={colors.darkBlue}
					style={{ minWidth: STEP_ICON_SIZE, minHeight: STEP_ICON_SIZE }}
				/>
			</StepButton>
		</ContentContainer>
	);
};

const ContentContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: auto;
	max-width: 320px;
`;

const StepButton = styled.div`
	border: 1px solid ${colors.grey10};
	border-radius: 4px;
	width: ${STEP_BUTTON_SIZE}px;
	height: ${STEP_BUTTON_SIZE}px;
	background: ${colors.white};
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;

export default TenantsFilterInput;
