import React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import CheckedIconBlue from 'assets/images/icons/filter-checkbox-checked.svg';
import CheckedIconPink from 'assets/images/icons/filter-checkbox-checked-pink.svg';

const { colors } = theme;

interface CheckboxProps {
	checked?: boolean;
	children?: any;
	isPink?: boolean;
	onClick(): void;
}

export const Checkbox = ({ checked = false, children, onClick, isPink }: CheckboxProps) => (
	<Container onClick={onClick}>
		<CheckIcon isPink={isPink} checked={checked} />
		<TextContainer>{children}</TextContainer>
	</Container>
);

const Container = styled.div`
	margin-bottom: 16px;
	display: flex;
	justify-content: flex-start;
	cursor: pointer;
	align-items: center;
	user-select: none;
`;

const TextContainer = styled.div`
	display: flex;
	align-items: center;
	margin-right: 8px;
	margin-left: 12px;
`;

const CheckIcon = styled.div<{ checked?: boolean; isPink?: boolean }>`
	${({ checked, isPink }) =>
		checked
			? `background-image: url(${isPink ? CheckedIconPink : CheckedIconBlue})`
			: `border: 2px solid ${colors.darkGray}`};
	width: 20px;
	height: 20px;
	min-width: 20px;
	min-height: 20px;
	background-size: contain;
	background-position: center;
	border-radius: 4px;
`;
