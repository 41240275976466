import api from 'api';
import Input from 'components/common/input';
import Logo from 'components/icons/logo';
import theme from 'config/theme';
import validationMessage from 'config/validation-messages';
import { Formik } from 'formik';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import * as yup from 'yup';
import Hero from './hero.png';
import {
	ContainerMain,
	ContainerLeft,
	Container,
	Description,
	Img,
	MainContent,
	FormContainer,
	InputFieldsContainer,
} from './styles';
import Swal from 'sweetalert2';
import { Errors } from 'constants/errors';
import { Modal } from 'lib/Modal';
import { Button } from 'lib/Button';
import { TopBar } from 'lib/TopBar';
import { Title } from 'lib/Title';

const { colors } = theme;

const forgotPasswordSchema = yup.object().shape({
	email: yup
		.string()
		.email(validationMessage.email)
		.required(validationMessage.required),
});

class ForgotPasswordModal extends Component {
	state = {
		forgotPasswordSent: false,
		failed: false,
	};

	render() {
		const { forgotPasswordSent } = this.state;
		const { onClose } = this.props;

		return (
			<Modal height={450} width={688}>
				<ContainerMain>
					<ContainerLeft>
						<Logo color={colors.white} />
						<Img>
							<img src={Hero} alt={Errors.img_alt_error} />
						</Img>
					</ContainerLeft>
					<Container>
						<TopBar closeOnRight onClose={onClose} />
						<MainContent>
							<Title standardMargin>Forgot your password</Title>
							<Description>Feeling a little los kop today?</Description>
							<FormContainer>
								<Formik
									initialValues={{ email: '' }}
									validationSchema={forgotPasswordSchema}
									onSubmit={async (values, { setSubmitting }) => {
										try {
											const forgotPasswordResponse = await api.auth.resetPassword(values.email);

											if (forgotPasswordResponse.status === 200) {
												this.setState(
													{
														forgotPasswordSent: true,
													},
													() => {
														setSubmitting(false);
													},
												);
											} else {
												this.setState({ failed: true }, () => setSubmitting(false));
											}
											Swal.fire({
												title: 'If you have an account, an email has been sent to you',
												type: 'success',
												showConfirmButton: false,
												toast: true,
												timer: 5000,
												position: 'top',
												customClass: {
													container: 'custom-container-toast-class',
												},
											});
											onClose();
										} catch (error) {
											this.setState({ failed: true }, () => setSubmitting(false));
										}
									}}
								>
									{({
										values,
										handleChange,
										handleBlur,
										handleSubmit,
										isSubmitting,
										errors,
										touched,
									}) => (
										<form onSubmit={handleSubmit}>
											<InputFieldsContainer>
												<Input
													type="email"
													placeholder="Email"
													name="email"
													autoComplete="email"
													onChange={handleChange}
													onBlur={handleBlur}
													value={values.email}
													error={errors.email && touched.email && errors.email}
												/>
											</InputFieldsContainer>
											{forgotPasswordSent ? (
												<Description>If you have an account, an email has been sent</Description>
											) : (
												<Button
													isFullWidth
													isOutline
													type="submit"
													disabled={isSubmitting}
													isLoading={isSubmitting}
												>
													Submit
												</Button>
											)}
										</form>
									)}
								</Formik>
							</FormContainer>
						</MainContent>
					</Container>
				</ContainerMain>
			</Modal>
		);
	}
}

export default withRouter(ForgotPasswordModal);
