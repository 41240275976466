import auth from './routes/auth';
import contactUs from './routes/contact-us';
import contentV2 from './routes/contentV2';
import messaging from './routes/messaging';
import bookings from './routes/bookings';
import search from './routes/search';
import user from './routes/user';
import institution from './routes/institution';
import payment from './routes/payment';
import users from './routes/users';
import property from './routes/property';
import searchV2 from './routes/searchV2';
import userV2 from './routes/userv2';
import applicationsV2 from './routes/applicationsV2';
import messagingV2 from './routes/messagingV2';
import favouritesV2 from './routes/favouritesV2';
import events from './routes/events';
import alerts from './routes/alerts';
import invoicing from './routes/invoicing';
import inbox from './routes/inbox';

export default {
	auth,
	bookings,
	contactUs,
	contentV2,
	property,
	search,
	searchV2,
	user,
	favouritesV2,
	messaging,
	payment,
	institution,
	users,
	userV2,
	applicationsV2,
	messagingV2,
	events,
	alerts,
	invoicing,
	inbox,
};
