import React from 'react';

import styled from 'styled-components';
import theme from 'config/theme';

const { colors, fonts } = theme;

interface TextAreaProps {
	value: string;
	setValue(value: string): void;
}

const TextAreaInput = ({ value, setValue }: TextAreaProps) => (
	<Container>
		<TextArea
			placeholder="Write your message"
			value={value}
			onChange={e => setValue(e.target.value)}
		/>
		<TextAreaCount>{value?.length || 0}/500</TextAreaCount>
	</Container>
);

const Container = styled.div`
	position: relative;
`;

const TextAreaCount = styled.div`
	position: absolute;
	bottom: 16px;
	right: 16px;
`;

const TextArea = styled.textarea`
	font-size: 16px;
	font-family: ${fonts.Gilroy};
	color: ${colors.darkBlue};
	background-color: ${colors.grey05};
	padding: 16px 24px;
	border-radius: 20px;
	outline: none;
	min-height: 160px;
	margin-top: 16px;
	width: 100%;

	&::placeholder {
		color: ${colors.lightBlue};
	}
`;

export default TextAreaInput;
