import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BottomCTAWrapper from '../BottomCTAWrapper';
import { Button } from 'lib/Button';
import { Title } from 'lib/Title';

class SetupCompleteSection extends Component {
	static propTypes = {
		onCompleteClick: PropTypes,
	};

	render() {
		const { onCompleteClick, isEmailVerified } = this.props;
		return (
			<StyledSetupCompleteSection>
				<div>
					<Title>Profile complete!</Title>
					<SubTitle>
						Welcome to DigsConnect, we're glad to have you aboard our housing rocketship!
					</SubTitle>
					<SubTitle>You can now create a digs listing or send a booking enquiry.</SubTitle>
					{!isEmailVerified && (
						<SubTitle>
							We noticed that you haven't yet verified your email address. Please check your inbox.
						</SubTitle>
					)}
				</div>
				<BottomCTAWrapper>
					<div className="columns">
						<div className="column is-half">
							<Button isOutline onClick={onCompleteClick}>
								Continue
							</Button>
						</div>
					</div>
				</BottomCTAWrapper>
			</StyledSetupCompleteSection>
		);
	}
}

export default SetupCompleteSection;

/**styled components */
const StyledSetupCompleteSection = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-between;
`;

const SubTitle = styled.h3`
	color: #9da7b7;
	font-size: 16px;
	font-weight: 400;
	margin-bottom: 36px;
`;
