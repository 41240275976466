import { useCallback } from 'react';

/**
 * Returns two functions, first is to get a url param, second is to update one.
 */
const useSearch = (): {
	getParam: (key: string) => string | undefined;
	setParam: (key: string, value: string) => void;
} => {
	const get = useCallback(
		(key: string) => new URLSearchParams(window.location.search).get(key)?.toString(),
		[],
	);
	const set = useCallback((key: string, value: string) => {
		const search = window.location.search || '';
		const urlParams = new URLSearchParams(search);
		urlParams.set(key, value);
		if (window.history.pushState) {
			const newurl =
				window.location.protocol +
				'//' +
				window.location.host +
				window.location.pathname +
				`?${urlParams.toString()}`;
			window.history.pushState({ path: newurl }, '', newurl);
		}
	}, []);
	return {
		getParam: get,
		setParam: set,
	};
};

export default useSearch;
