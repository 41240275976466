import React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import { Modal } from 'lib/Modal';
import { Cross } from 'lib/Cross';
import Button from 'lib/Button';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';
import ProductUpdateContent from './ProductUpdateContent';
import useRouter from 'hooks/useRouter';
import routes from 'config/routes';

const { colors, fontWeights } = theme;

interface LandlordLandingProductUpdateModalProps {
	landlordName: string;
	onClose(): void;
}

const LandlordLandingProductUpdateModal = ({
	landlordName,
	onClose,
}: LandlordLandingProductUpdateModalProps) => {
	const [goTo] = useRouter();
	return (
		<Modal onBackgroundClick={onClose} mobileBottomsheet>
			<ModalHeaderContainer>
				<ModalTitleContainer>
					<Title>New feature!</Title>
					<Description>Landlord Dashboard</Description>
				</ModalTitleContainer>
				<Cross onClick={onClose} style={{ alignSelf: 'flex-start' }} />
			</ModalHeaderContainer>
			<ModalContent>
				<ProductUpdateContent landlordName={landlordName} />
			</ModalContent>
			<ModalActionBar>
				<Button
					noMargin
					onClick={() => (onClose(), goTo(routes.manage_listing_dashboard))}
					isFullWidth={false}
				>
					View landlord dashboard
				</Button>
			</ModalActionBar>
		</Modal>
	);
};

export default LandlordLandingProductUpdateModal;

const ModalTitleContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-top: 24px;
	flex-direction: column;
	gap: 8px;
	width: 100%;
`;

const ModalHeaderContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 16px;
`;

const ModalContent = styled.div`
	padding: 24px 48px;
	overflow-y: auto;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		padding: 24px;
	}
`;

const ModalActionBar = styled.div`
	flex-shrink: 0;
	border-top: 1px solid ${colors.grey10};
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 24px;
	padding-top: 16px;
`;

const Title = styled.div`
	font-weight: ${fontWeights.bold};
	font-size: 25px;
`;

const Description = styled.div`
	margin-top: 8px;
	margin-bottom: 16px;
	font-size: 16px;
`;
