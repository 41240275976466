import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import api from 'api';
import { TopBar } from 'lib/TopBar';
import { Conversation, ConversationBookings } from 'models/inbox';
import { Modal } from 'lib/Modal';
import Button from 'lib/Button';
import { InboxType } from './types';
import theme from 'config/theme';
import { Select, SelectOption } from 'lib/Select';
import { notifyError, notifySuccess } from 'lib/Notifications';
import Input from 'lib/Input';
import { DateInput } from 'lib/DateInput';
import { Property } from 'models/property';
import conversation from 'containers/conversation';
import { LeaseType } from 'models/listing';

const { colors } = theme;

const ConfirmPlacementModal = ({
	chat,
	inboxType,
	completedProperties,
	booking,
	onClose,
	onSuccess,
}: {
	inboxType: InboxType;
	chat: Conversation;
	completedProperties: Property[];
	booking?: ConversationBookings;
	onClose(): void;
	onSuccess(): void;
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [name, setName] = useState('');
	const [property, setProperty] = useState<string>();
	const [room, setRoom] = useState<string>();
	const [moveInDate, setMoveInDate] = useState('');
	const [leasePeriod, setLeasePeriod] = useState<number>(0);
	const [rentalAmount, setRentalAmount] = useState(0);
	const [propertyOptions, setPropertyOptions] = useState<SelectOption[]>([]);
	const [properties, setProperties] = useState<Property[]>([]);
	const [propertiesDictionary, setPropertiesDictionary] = useState<{ [key: string]: Property }>({});
	const [roomOptions, setRoomOptions] = useState<SelectOption[]>([]);

	useEffect(() => {
		if (!property) {
			return;
		}
		const rooms =
			properties
				.find(p => p.uuid === property)
				?.rooms?.map(room => ({
					label: room.title,
					value: room.uuid,
				})) || [];
		setRoomOptions(rooms);
		if (rooms.length === 1) {
			setRoom(rooms[0].value);
		}
	}, [property, properties]);

	useEffect(() => {
		setPropertiesDictionary(
			properties.reduce(
				(acc, val) => ({
					...acc,
					[val.uuid as string]: val,
				}),
				{},
			),
		);

		setPropertyOptions(
			properties.map(property => ({ label: property.title, value: property.uuid })),
		);
	}, [properties]);

	useEffect(() => {
		setName(`${chat.other_users[0].first_name} ${chat.other_users[0].last_name}`);
		setMoveInDate(booking?.lease_information.occupancy_start_date || '');
		setLeasePeriod(+(booking?.lease_information.occupancy_lease_term?.split(' ')[0] || 0));
		setRentalAmount(booking?.lease_information.occupant_rental || 0);
		setProperty(booking?.booking_information.property_uuid);
		setRoom(booking?.booking_information.room_uuid || '');
	}, [chat, booking, properties]);

	useEffect(() => {
		setProperties(completedProperties);
	}, [completedProperties]);

	const handleSubmit = async () => {
		if (
			!chat?.uuid ||
			!property ||
			(propertiesDictionary[property || ''].lease_type === LeaseType.SingleRoom && !room) ||
			!leasePeriod ||
			!moveInDate ||
			!rentalAmount
		) {
			notifyError('Please fill out all fields to confirm booking');
			return;
		}

		setIsLoading(true);
		const res = await api.inbox.confirmConversation({
			id: chat.uuid,
			property_uuid: property || '',
			room_uuid: room || undefined,
			occupancy_duration: leasePeriod,
			occupancy_start_date: moveInDate,
			occupant_rental: rentalAmount,
		});
		setIsLoading(false);

		if (res.status !== 200) {
			notifyError('Failed to confirm placement');
			return;
		}

		onClose();
		notifySuccess('Confirmed placement');
		onSuccess();
	};

	const handleDateSelect = (date?: Date) => {
		if (!date) {
			return;
		}
		setMoveInDate(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`);
	};

	return (
		<Modal onBackgroundClick={onClose} width={600}>
			<TopBar title="Tenant confirmation" onClose={onClose} />
			<ContentContainer>
				<SubTitle>We just need a few details to confirm this tenant move-in</SubTitle>

				<Text>Who is the tenant?</Text>
				<Input disabled placeholder="" value={name} onChange={(val: string) => {}} />

				<Text>Which property are they moving into?</Text>
				<Select
					selectedOption={propertyOptions.find(option => option.value === property)}
					options={propertyOptions}
					onChange={option => setProperty(option.value)}
				/>
				{propertiesDictionary[property || ''] &&
					propertiesDictionary[property || ''].lease_type === LeaseType.SingleRoom && (
						<>
							<Text>Which room are they occupying?</Text>
							<Select
								selectedOption={roomOptions.find(option => option.value === room)}
								options={roomOptions}
								onChange={option => setRoom(option.value)}
							/>
						</>
					)}
				<Text>When is their move-in date?</Text>
				<DateInput placeholder="" value={moveInDate} onChange={handleDateSelect} />

				<Text>How long is the lease period?</Text>
				<Input
					placeholder=""
					value={leasePeriod}
					// TODO: Pull this from billing_cycle (currently on the search_listing)
					suffix="Months"
					onChange={(val: number) => {
						setLeasePeriod(val);
					}}
				/>

				<Text>How much is the rental amount?</Text>
				<Input
					placeholder=""
					value={rentalAmount}
					prefix={propertiesDictionary[property || '']?.currency.currency_symbol || ''}
					onChange={(val: number) => {
						setRentalAmount(val);
					}}
				/>
			</ContentContainer>
			<BottomBar>
				<Button
					isLoading={isLoading}
					isDisabled={
						false
						// validate
					}
					onClick={handleSubmit}
				>
					Confirm placement
				</Button>
			</BottomBar>
		</Modal>
	);
};

const ContentContainer = styled.div`
	padding: 16px 24px;
	overflow-y: auto;
`;

const BottomBar = styled.div`
	border-top: 1px solid ${colors.grey10};
	padding: 16px;
`;

const Text = styled.div`
	font-size: 16px;
	margin-bottom: 4px;
	line-height: 150%;
`;

const SubTitle = styled(Text)`
	font-weight: 600;
`;

export default ConfirmPlacementModal;
