import React, { useState } from 'react';

import styled from 'styled-components';

import api from 'api';
import { searchContainer } from 'containers/search';
import { formatSlug } from 'utils';
import { FilterContainer, PillContainer } from './CommonStyles';
import theme from 'config/theme';
import { ReactComponent as CaretUp } from 'assets/images/icons/caret-up.svg';
import { ReactComponent as CaretDown } from 'assets/images/icons/caret-down.svg';
import { ReactComponent as FilterSvg } from 'assets/images/icons/filter.svg';
import CheckboxRow from 'lib/CheckboxRow';
import { LEASE_TYPE } from '../constants';
import { Modal } from './Modal';
import { validFeatureCategories } from '../validFeatureCategories';
import { Divider } from 'lib/Divider';
import modalContainer from 'containers/modal';
import { ModalType } from 'constants/modalTypes';
import { LeaseType } from 'models/listing';
import LocationSearch from 'components/LocationSearch';
import TenantsFilterInput from './TenantsFilterInput';
import PriceRange from './PriceRange';

const { fonts } = theme;

const IconContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20px;
`;

const Section = ({ title, collapsable, noDivider, children }) => {
	const [collapsed, setCollapsed] = useState(collapsable || false);
	return (
		<>
			<SectionContainer>
				{title && (
					<SectionTitle onClick={collapsable && (() => setCollapsed(!collapsed))}>
						{title}
						<IconContainer>
							{collapsable && (collapsed ? <CaretDown /> : <CaretUp />)}
						</IconContainer>
					</SectionTitle>
				)}
				{collapsable ? !collapsed && children : children}
			</SectionContainer>
			{!noDivider && <Divider />}
		</>
	);
};

const SectionContainer = styled.div`
	margin: 24px 0;
`;

const SectionTitle = styled.div`
	font: ${fonts.Gilroy};
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const FilterIcon = styled(FilterSvg)`
	margin-right: 8px;
`;

const AllFilters = ({
	filterParams,
	updateFilters,
	features,
	isInternationalLocation,
	priceFilterConfig,
	onClearAllClick,
}) => {
	const modalFilterKeys = [
		'nsfas_accredited',
		'tenants',
		'locations',
		'varsity_college_accredited',
		'aie_accredited',
		'sub_type',
		'furnished',
		'type',
		'features',
	];

	const startingCount = +Boolean(filterParams.min_price || filterParams.max_price);

	const numberOfSelectedFilters = Object.keys(filterParams).reduce((count, key) => {
		if (modalFilterKeys.includes(key) && filterParams[key]) {
			if (key === 'locations' && typeof filterParams[key] !== 'string') {
				return count + filterParams[key].length;
			}
			if (key === 'sub_type' && typeof filterParams[key] !== 'string') {
				return count + filterParams[key].length;
			}
			if (key === 'type' && filterParams[key] !== LeaseType.EntirePlace) {
				return count;
			}
			if (key === 'features' && typeof filterParams[key] !== 'string') {
				return count + filterParams[key].length;
			}
			return count + 1;
		}
		return count;
	}, startingCount);

	const handlePillClick = () => {
		modalContainer.set(ModalType.MoreFilters, {
			filterParams: filterParams,
			updateFilters: updateFilters,
			features: features,
			isInternationalLocation,
			priceFilterConfig: priceFilterConfig,
			onClosePopup: handlePopupClose,
			onClearAllClick: handleClearAllFilters,
		});
	};

	const handlePopupClose = () => {
		modalContainer.clear(null);
	};

	const handleClearAllFilters = () => {
		modalContainer.clear(null);
		onClearAllClick();
	};

	return (
		<FilterContainer>
			<PillContainer active={!!numberOfSelectedFilters} onClick={handlePillClick}>
				<FilterIcon />
				<>All filters {!!numberOfSelectedFilters && `(${numberOfSelectedFilters})`}</>
			</PillContainer>
		</FilterContainer>
	);
};

export default AllFilters;

export const AllFiltersModal = ({
	filterParams,
	updateFilters,
	features,
	isInternationalLocation,
	priceFilterConfig,
	onClosePopup,
	onClearAllClick,
}) => {
	const [subType, setSubType] = useState(filterParams.sub_type || []);
	const [checkedFeatures, setCheckedFeatures] = useState(filterParams.features || []);
	const [leaseType, setLeaseType] = useState(filterParams.type || '');
	const [varsityCollegeAccredited, setVarsityCollegeAccredited] = useState(
		filterParams.varsity_college_accredited,
	);
	const [aieAccredited, setAieAccredited] = useState(filterParams.aie_accredited);
	const [nsfasAccredited, setNsfasAccredited] = useState(filterParams.nsfas_accredited);
	const [isFurnished, setIsFurnished] = useState(!!filterParams.furnished);
	const [tenantCount, setTenantCount] = useState(filterParams.tenants || 0);
	const [range, setRange] = useState({
		min: filterParams.min_price || priceFilterConfig.range_min,
		max: filterParams.max_price || priceFilterConfig.range_max,
	});
	const [searchList, setSearchList] = useState([]);
	const [selectedItems, setSelectedItems] = useState(searchContainer.state.selectedItems || []);

	const onSubType = value => {
		setSubType(
			subType.includes(value) ? subType.filter(item => item !== value) : [...subType, value],
		);
	};

	const checkFeature = id => {
		if (checkedFeatures.includes(id)) {
			setCheckedFeatures([...checkedFeatures.filter(feature_id => feature_id !== id)]);
		} else {
			setCheckedFeatures([...checkedFeatures, id]);
		}
	};

	const groupedFeaturesObj = features.reduce((acc, val) => {
		if (acc[val.category]) {
			return {
				...acc,
				[val.category]: [...acc[val.category], val],
			};
		}
		if (validFeatureCategories.includes(val.category)) {
			return {
				...acc,
				[val.category]: [val],
			};
		}
		return acc;
	}, {});

	const groupedFeatures = Object.keys(groupedFeaturesObj)
		.map(category => ({
			category,
			features: groupedFeaturesObj[category],
		}))
		.sort((a, b) => (a.category.toLowerCase() > b.category.toLowerCase() ? 1 : -1));

	let wifiFeature, parkingFeature;
	groupedFeatures.forEach(group => {
		group.features.forEach(feature => {
			if (feature.name.toLowerCase() === 'wifi') {
				wifiFeature = feature;
			}
			if (feature.name.toLowerCase() === 'off street parking') {
				parkingFeature = feature;
			}
		});
	});

	const onSave = () => {
		let newFilters = {
			varsity_college_accredited: varsityCollegeAccredited || undefined,
			aie_accredited: aieAccredited || undefined,
			nsfas_accredited: nsfasAccredited || undefined,
			tenants: tenantCount || undefined,
			type: leaseType || undefined,
			furnished: isFurnished ? ['semi', 'fully'] : undefined,
			min_price: range.min || undefined,
			max_price: range.max < priceFilterConfig.range_max ? range.max : undefined,
		};
		if (subType !== null) {
			newFilters.sub_type = subType.length > 0 ? subType : undefined;
		}
		if (checkedFeatures) {
			newFilters.features = checkedFeatures.length > 0 ? checkedFeatures : undefined;
		}
		if (selectedItems.length) {
			searchContainer.setSelectedItems(selectedItems);
			localStorage.setItem('search_label', selectedItems[0] ? selectedItems[0].label : '');
			localStorage.setItem(
				'search_label_slug',
				selectedItems[0] ? formatSlug(selectedItems[0].label) : '',
			);
		}
		updateFilters(newFilters);
		onClosePopup();
	};

	const handleSearch = term => {
		api.searchV2.getSearchResults(term).then(({ data }) => {
			setSearchList(data);
		});
	};

	return (
		<Modal
			icon={<FilterIcon />}
			title={'Filters'}
			actionText={'Apply'}
			secondaryActionText={'Clear all'}
			onSecondaryActionClick={onClearAllClick}
			onActionClick={onSave}
			onClose={onClosePopup}
		>
			<>
				<Section title={'Locations'}>
					<LocationSearch
						searchList={searchList}
						selectedSearchItems={selectedItems}
						onSearch={handleSearch}
						onSelectedSearchItemsChange={setSelectedItems}
						onSearchItemAdded={() => setSearchList([])}
					/>
				</Section>
				{priceFilterConfig && (
					<Section title={'Price'}>
						<PriceRange
							max={priceFilterConfig.range_max}
							min={priceFilterConfig.range_min}
							values={range}
							setValue={setRange}
							symbol={priceFilterConfig.symbol}
						/>
					</Section>
				)}
				<Section title={'Number of tenants'}>
					<TenantsFilterInput count={tenantCount} onCountChange={setTenantCount} />
				</Section>
				<Section title={'General'}>
					{Boolean(wifiFeature) && (
						<CheckboxRow
							text={'Wifi'}
							checked={checkedFeatures.includes(wifiFeature.id)}
							onClick={() => checkFeature(wifiFeature.id)}
						/>
					)}
					{Boolean(parkingFeature) && (
						<CheckboxRow
							text={'Parking'}
							checked={checkedFeatures.includes(parkingFeature.id)}
							onClick={() => checkFeature(parkingFeature.id)}
						/>
					)}
					<CheckboxRow
						text={'Furnished'}
						checked={isFurnished}
						onClick={() => setIsFurnished(prev => !prev)}
					/>
				</Section>
				<Section title={'Lease type'}>
					<CheckboxRow
						text={'Entire place'}
						checked={leaseType === LEASE_TYPE.EntirePlace}
						onClick={() =>
							setLeaseType(type => (type === LEASE_TYPE.EntirePlace ? '' : LEASE_TYPE.EntirePlace))
						}
					/>
				</Section>
				{!isInternationalLocation && (
					<Section title={'Verifications'}>
						<CheckboxRow
							text={'The IIE’s Varsity College verified'}
							checked={varsityCollegeAccredited}
							onClick={() => setVarsityCollegeAccredited(accredited => !accredited)}
						/>
						<CheckboxRow
							text={'AIE verified'}
							checked={aieAccredited}
							onClick={() => setAieAccredited(accredited => !accredited)}
						/>
						<CheckboxRow
							text={'NSFAS accredited'}
							checked={nsfasAccredited}
							onClick={() => setNsfasAccredited(accredited => !accredited)}
						/>
					</Section>
				)}
				<Section title={'Property type'}>
					<CheckboxRow
						text={'Apartment'}
						checked={subType.includes('apartment')}
						onClick={() => onSubType('apartment')}
					/>
					<CheckboxRow
						text={'House'}
						checked={subType.includes('house')}
						onClick={() => onSubType('house')}
					/>
					<CheckboxRow
						text={'Cottage'}
						checked={subType.includes('cottage')}
						onClick={() => onSubType('cottage')}
					/>
					<CheckboxRow
						text={'Student residence'}
						checked={subType.includes('residence')}
						onClick={() => onSubType('residence')}
					/>
				</Section>
			</>
		</Modal>
	);
};
