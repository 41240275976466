import React from 'react';
import styled from 'styled-components';

import theme from 'config/theme';
import { TopBar } from 'lib/TopBar';
import { Conversation, ConversationAction } from 'models/inbox';
import useRouter from 'hooks/useRouter';
import routes from 'config/routes';

const { colors } = theme;

const MoreOptionsBottomSheet = ({
	chat,
	onClose,
	onAction,
	onReport,
}: {
	chat: Conversation;
	onClose(): void;
	onAction(action: ConversationAction): void;
	onReport(): void;
}) => {
	const [goTo] = useRouter();
	const getContactRoute = () => routes.contact_us;
	return (
		<>
			<TopBar title="More" closeOnRight onClose={onClose} />
			<List>
				<ListItem onClick={onReport}>Report chat</ListItem>
				<ListItem
					onClick={() =>
						onAction(chat.archived ? ConversationAction.Restore : ConversationAction.Archive)
					}
				>
					{chat.archived ? 'Restore' : 'Archive'} chat
				</ListItem>
				<ListItem onClick={() => goTo(getContactRoute())}>Contact DigsConnect</ListItem>
			</List>
		</>
	);
};

const List = styled.ul``;

const ListItem = styled.div`
	border-bottom: 1px solid ${colors.grey10};
	padding: 24px 16px;
	cursor: pointer;
`;

export default MoreOptionsBottomSheet;
