import React from 'react';
import Button from 'lib/Button';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

interface AuthActionProps {
	isLoading: boolean;
	recaptchaEnabled: boolean;
	onSubmit(token?: string): void;
}

const AuthAction = ({ isLoading, recaptchaEnabled, onSubmit }: AuthActionProps) => {
	const { executeRecaptcha } = useGoogleReCaptcha();

	const handleAction = async () => {
		if (!recaptchaEnabled) {
			onSubmit();
			return;
		}
		if (!executeRecaptcha) {
			return;
		}
		const token = await executeRecaptcha('signup');
		onSubmit(token);
	};

	return (
		<Button isPink isFullWidth type="button" onClick={handleAction} isLoading={isLoading}>
			Continue
		</Button>
	);
};

export default AuthAction;
