import React, { useState } from 'react';
import styled from 'styled-components';

import { TopBar } from 'lib/TopBar';
import { Conversation, ConversationAction } from 'models/inbox';
import { Modal } from 'lib/Modal';
import TextAreaInput from './TextAreaInput';
import Button from 'lib/Button';
import { notifyError, notifySuccess } from 'lib/Notifications';
import theme from 'config/theme';
import api from 'api';

const { colors } = theme;

const isValidMessage = (message: string): boolean => message.length >= 10 && message.length < 500;

const ReportConversationModal = ({
	chat,
	onClose,
	onSuccess,
}: {
	chat: Conversation;
	onClose(): void;
	onSuccess(): void;
}) => {
	const [message, setMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async () => {
		if (!isValidMessage(message)) {
			return;
		}
		setIsLoading(true);
		const res = await api.inbox.executeAction(
			chat.uuid,
			ConversationAction.Report,
			{
				reason: message || '',
			},
			true,
		);

		setIsLoading(false);

		if (res.status !== 200) {
			if ((res.data as any).non_field_errors?.length) {
				notifyError((res.data as any).non_field_errors[0]);
				return;
			}
			notifyError('Failed to report conversation');
			return;
		}

		notifySuccess('Conversation reported successfully');
		onClose();
		onSuccess();
	};

	return (
		<Modal mobileBottomsheet onBackgroundClick={onClose}>
			<TopBar title="Report Conversation" onClose={onClose} />
			<ContentContainer>
				<SubTitle>
					This conversation will be reported to the DigsConnect team. Appropriate action may be
					taken once we review.
				</SubTitle>
				<Text>Please give some context on the reason you are reporting this conversation.</Text>
				<TextAreaInput value={message} setValue={setMessage} />
			</ContentContainer>
			<BottomBar>
				<Button isLoading={isLoading} isDisabled={!isValidMessage(message)} onClick={handleSubmit}>
					Submit report
				</Button>
			</BottomBar>
		</Modal>
	);
};

const ContentContainer = styled.div`
	padding: 16px;
	overflow-y: auto;
`;

const BottomBar = styled.div`
	border-top: 1px solid ${colors.grey10};
	padding: 16px;
`;

const Text = styled.div`
	font-size: 16px;
	margin-bottom: 4px;
	line-height: 150%;
`;

const SubTitle = styled(Text)`
	font-weight: 600;
	margin-bottom: 16px;
`;

export default ReportConversationModal;
