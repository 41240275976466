import React from 'react';

import { Modal } from 'lib/Modal';
import Button from 'lib/Button';
import { Title, ModalContainer, BasicText, StyledLink, ButtonContainer } from './styles';
import routes from 'config/routes';

const TermsAndConditionsModal = ({ onClose, onTermsAcceptedClick }: any) => {
	const handleIAgreeClick = () => {
		onTermsAcceptedClick();
		onClose();
	};
	return (
		<Modal>
			<ModalContainer>
				<Title>
					In order for DigsConnect to send information to our landlords, we require your consent.
				</Title>
				<BasicText>Please agree to the terms and conditions below.</BasicText>
				<StyledLink to={routes.terms_and_conditions}>Terms and conditions</StyledLink>
				<StyledLink to={routes.privacy}>Privacy policy</StyledLink>
				<ButtonContainer>
					<Button onClick={onClose} maxWidth={150} isOutline>
						Cancel
					</Button>
					<Button onClick={handleIAgreeClick} maxWidth={150} isPink>
						I agree
					</Button>
				</ButtonContainer>
			</ModalContainer>
		</Modal>
	);
};

export default TermsAndConditionsModal;
