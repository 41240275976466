import React, { Component } from 'react';

import { Redirect } from 'react-router-dom';
import { Subscribe } from 'unstated';

import authContainer from 'containers/auth';

const withAuth = WrappedComponent => {
	class WithAuth extends Component {
		render() {
			return (
				<Subscribe to={[authContainer]}>
					{container =>
						container.state.token ? <WrappedComponent {...this.props} /> : <Redirect to="/" />
					}
				</Subscribe>
			);
		}
	}

	WithAuth.displayName = `WithAuth(${getDisplayName(WrappedComponent)})`;

	return WithAuth;
};

function getDisplayName(WrappedComponent) {
	return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default withAuth;
