import * as React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import { validationErrors } from 'config/validationErrors';
import { SelectOption } from 'models/selectOption';
import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';
import ChipCollection from 'lib/ChipCollection';

const { colors, inputs } = theme;

interface ChipsInputProps {
	placeholder: string;
	value?: string | number;
	type?: string;
	isErrorState?: boolean;
	errorMessage?: string;
	disabled?: boolean;
	chips: SelectOption[];
	onRemoveChip?(value: any): void;
	onChange(value: string | number): void;
}

export const ChipsInput = ({
	placeholder,
	value,
	type = 'text',
	isErrorState = false,
	errorMessage = validationErrors.InvalidField,
	disabled = false,
	chips,
	onChange,
	onRemoveChip = () => {},
}: ChipsInputProps) => (
	<>
		<InputContainer>
			<ChipsContainer>
				<ChipCollection chips={chips} onRemoveChip={onRemoveChip} />
				<StandardInput
					type={type}
					placeholder={chips.length ? '...add more' : placeholder}
					style={chips.length ? undefined : { width: '100%' }}
					value={value}
					disabled={disabled}
					onChange={event => onChange(event.target.value)}
				/>
			</ChipsContainer>
		</InputContainer>
		{!!isErrorState && !!errorMessage && <ValidationError>{errorMessage}</ValidationError>}
	</>
);

const InputContainer = styled.div`
	background-color: ${colors.grey05};
	border-radius: ${inputs.borderRadius};
	padding: 16px;
	min-height: ${inputs.height};
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StandardInput = styled.input`
	background-color: transparent;
	color: ${colors.darkBlue};
	outline: none;
	flex: 1;

	@media (min-width: ${BREAKPOINT_TABLET_PX}px) {
		width: auto;
		margin-bottom: 4px;
	}
`;

const ValidationError = styled.div`
	font-size: 12px;
	color: ${colors.pink};
	margin-top: -16px;
	padding: 4px 16px;
`;

const ChipsContainer = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
`;
