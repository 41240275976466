import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';

import api from 'api';
import authContainer from 'containers/auth';
import modalContainer from 'containers/modal';
import userContainer from 'containers/user';
import Input from 'components/common/input';
import Logo from 'components/icons/logo';
import validationMessage from 'config/validation-messages';
import theme from 'config/theme';
import Hero from './hero.png';
import Facebook from './facebook.png';
import Google from './google.png';
import FBLogin from './social-login/facebook';
import GLogin from './social-login/google';
import {
	Container,
	ContainerMain,
	ContainerLeft,
	FormContainer,
	Text,
	TextLink,
	Img,
	MainContent,
	ForgotPasswordContainer,
	ForgotPasswordText,
	CheckboxText,
	CheckboxLink,
	CreateOrLoginContainer,
} from './styles';
import { ModalType } from 'constants/modalTypes';
import { Errors } from 'constants/errors';
import { Title } from 'lib/Title';
import { Modal } from 'lib/Modal';
import { TopBar } from 'lib/TopBar';
import { Button } from 'lib/Button';
import { userProfileService } from 'services/userProfileService';
import { Checkbox } from 'lib/Checkbox';
import routes from 'config/routes';
import { analyticsService, EventName, LoginMethod } from 'services/analyticsService';
import ModalEnum from 'models/modalEnum';
import { notifyError } from 'lib/Notifications';
import AuthAction from './AuthAction';
import { FeatureFlag, featureFlagContainer } from 'containers/featureFlags';

const { colors } = theme;

const loginSchema = yup.object().shape({
	email: yup
		.string()
		.email(validationMessage.email)
		.required(validationMessage.required),
	password: yup
		.string()
		.required(validationMessage.required)
		.min(8, validationMessage.short),
});

const signupSchema = yup.object().shape({
	email: yup
		.string()
		.email(validationMessage.email)
		.required(validationMessage.required),
	password: yup
		.string()
		.required(validationMessage.required)
		.min(8, validationMessage.short),
	confirmPassword: yup
		.string()
		.required(validationMessage.required)
		.oneOf([yup.ref('password'), null], "Doesn't match"),
});

class LoginModal extends Component {
	state = {
		loginFailed: false,
		privacyAndTermsChecked: false,
		showPrivacyAndTermsError: false,
		recaptchaToken: '',
		marketingConsentChecked: false,
	};
	scrollableContainerRef = React.createRef();

	componentDidUpdate() {
		if (this.scrollableContainerRef && this.scrollableContainerRef.current) {
			this.scrollableContainerRef.current.scrollTop = 0;
		}
	}

	onClickSignUp = () => {
		this.props.onSignup();
	};

	onSocialLoginSuccess = () => {
		modalContainer.set(ModalType.ProfileSetup);
	};

	handleAltActionClick = () => {
		const { isSignup, onLogin, onSignup } = this.props;

		this.setState({
			loginFailed: false,
		});

		if (!isSignup) {
			onSignup();
			return;
		}

		onLogin();
	};

	handleTermsCheckboxClick = () => {
		this.setState(({ privacyAndTermsChecked }) => ({
			privacyAndTermsChecked: !privacyAndTermsChecked,
		}));
	};
	
	handleMarketingConsentClick = () => {
		this.setState(({ marketingConsentChecked }) => ({
			marketingConsentChecked: !marketingConsentChecked,
		}));
	};

	handleReCaptchaVerify = token => {
		this.setState({
			recaptchaToken: token,
		});
	};

	render() {
		const { loginFailed, privacyAndTermsChecked, marketingConsentChecked, showPrivacyAndTermsError } = this.state;
		const { isSignup, onClose, onLoginSuccess } = this.props;

		return (
			<Modal height={616} width={688}>
				<ContainerMain>
					<ContainerLeft>
						<Logo color={colors.white} />
						<Img>
							<img src={Hero} alt={Errors.img_alt_error} />
						</Img>
					</ContainerLeft>
					<Container>
						<TopBar closeOnRight onClose={onClose} />
						<MainContent ref={this.scrollableContainerRef}>
							{isSignup ? (
								<div>
									<Title standardMargin>Create an account </Title>
									<Text>Enter your details below to create an account</Text>
								</div>
							) : (
								<div>
									<Title standardMargin>Welcome back!</Title>
									<Text>Enter your details below to login</Text>
								</div>
							)}
							<Formik
								initialValues={{ email: '', password: '', confirmPassword: '' }}
								validationSchema={isSignup ? signupSchema : loginSchema}
								onSubmit={async (values, { setSubmitting }) => {
									try {
										if (isSignup) {
											if (!this.state.privacyAndTermsChecked) {
												this.setState({
													showPrivacyAndTermsError: true,
												});
												setSubmitting(false);
												return;
											}

											const registerResponse = await api.auth.registerUser({
												email: values.email,
												password1: values.password,
												password2: values.confirmPassword,
												marketingConsent: marketingConsentChecked,
												recaptcha_response: this.state.recaptchaToken,
											});
											if (registerResponse.status === 201) {
												await authContainer.set({
													token: registerResponse.data.token,
												});

												const userProfileV2Response = await api.userV2.getProfile();

												analyticsService.trackEvent(EventName.Signup, {
													auth_method: LoginMethod.Default,
												});
												analyticsService.trackEvent(EventName.SetUser, {
													digs_user_id: userProfileV2Response.data.uuid,
												});

												await userContainer.set(userProfileV2Response.data);

												this.setState(
													{
														loginFailed: false,
													},
													() => {
														setSubmitting(false);
														modalContainer.set(ModalType.ProfileSetup);
													},
												);
											} else if (registerResponse.status === 400) {
												setSubmitting(false);
												if (
													registerResponse.data.hasOwnProperty('email') &&
													registerResponse.data.email.length
												) {
													notifyError(registerResponse.data.email[0], 8000);
												} else if (registerResponse.data.hasOwnProperty('password1')) {
													notifyError(
														`Please choose a different password. It may not be entirely numeric, or contain your name or your company's name.`,
														9000,
													);
												} else {
													notifyError(
														'Either your email or password is not valid, please try again with different credentials.',
														4000,
													);
												}
											}
										} else {
											const loginResponse = await api.auth.login({
												username: values.email,
												password: values.password,
											});

											if (loginResponse.status === 200) {
												await authContainer.set({
													token: loginResponse.data.token,
												});

												const userProfileV2Response = await api.userV2.getProfile();

												await userContainer.set(userProfileV2Response.data);

												analyticsService.trackEvent(EventName.Login, {
													auth_method: LoginMethod.Default,
												});
												analyticsService.trackEvent(EventName.SetUser, {
													digs_user_id: userProfileV2Response.data.uuid,
												});

												this.setState(
													{
														loginFailed: false,
													},
													async () => {
														if (!userProfileV2Response.data.has_viewed_edit_listing_tour_modal) {
															const response = await api.property.getProperties();
															const listings = response.data;
															if (listings && listings.length) {
																modalContainer.set(ModalEnum.ProductUpdate);
																return;
															}
														}
														setSubmitting(false);
														onClose();
														if (onLoginSuccess) {
															onLoginSuccess();
															return;
														}
														if (!userProfileService.isComplete(userProfileV2Response.data)) {
															modalContainer.set(ModalType.ProfileSetup);
														}
													},
												);
											} else {
												notifyError(loginResponse.data.non_field_errors[0]);
												this.setState(
													{
														loginFailed: true,
													},
													() => setSubmitting(false),
												);
											}
										}
									} catch (error) {
										this.setState(
											{
												loginFailed: true,
											},
											() => setSubmitting(false),
										);
									}
								}}
							>
								{({
									values,
									handleChange,
									handleBlur,
									handleSubmit,
									isSubmitting,
									errors,
									touched,
								}) => (
									<FormContainer>
										<form onSubmit={handleSubmit}>
											<Input
												type="email"
												placeholder="Email"
												name="email"
												autoComplete="email"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.email}
												error={errors.email && touched.email && errors.email}
											/>

											<Input
												aria-describedby="password-help"
												type="password"
												autoComplete={isSignup ? 'new-password' : 'current-password'}
												placeholder="Password"
												name="password"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.password}
												error={errors.password && touched.password && errors.password}
											/>
											{!isSignup && (
												<ForgotPasswordContainer>
													<ForgotPasswordText onClick={this.props.onForgotPassword}>
														{' '}
														Forgot password?{' '}
													</ForgotPasswordText>
												</ForgotPasswordContainer>
											)}
											{isSignup && (
												<>
													<Input
														type="password"
														placeholder="Repeat Password"
														name="confirmPassword"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.confirmPassword}
														error={
															errors.confirmPassword &&
															touched.confirmPassword &&
															errors.confirmPassword
														}
													/>
													<Text id="password-help" tip>
														{' '}
														Passwords: 8 or more characters{' '}
													</Text>
													<Checkbox
														checked={privacyAndTermsChecked}
														onClick={this.handleTermsCheckboxClick}
													>
														<CheckboxText
															isError={!privacyAndTermsChecked && showPrivacyAndTermsError}
														>
															By signing up I agree to the{' '}
															<CheckboxLink href={routes.terms_and_conditions} target="_blank">
																terms of use
															</CheckboxLink>{' '}
															and the{' '}
															<CheckboxLink href={routes.privacy} target="_blank">
																privacy policy
															</CheckboxLink>
														</CheckboxText>
													</Checkbox>
													<Checkbox
														checked={marketingConsentChecked}
														onClick={this.handleMarketingConsentClick}
													>
														<CheckboxText>
															I consent to receive marketing emails from DigsConnect and third-party partners
														</CheckboxText>
													</Checkbox>
												</>
											)}
											<AuthAction
												isLoading={isSubmitting}
												recaptchaEnabled={isSignup}
												onSubmit={token => {
													if (!token) {
														handleSubmit();
														return;
													}

													this.setState({ recaptchaToken: token }, () => {
														handleSubmit();
													});
												}}
											/>
										</form>
									</FormContainer>
								)}
							</Formik>
							<CreateOrLoginContainer>
								<Text style={{ marginBottom: 0 }}>
									{isSignup ? 'Already have an account?' : `Don't have an account?`}
									<TextLink onClick={this.handleAltActionClick}>
										{isSignup ? 'Login' : `Create account`}
									</TextLink>
								</Text>
							</CreateOrLoginContainer>
						</MainContent>
					</Container>
				</ContainerMain>
			</Modal>
		);
	}
}

export default withRouter(LoginModal);
