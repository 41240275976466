import theme from 'config/theme';
import styled, { keyframes } from 'styled-components';

const { fontSizes, colors, radii, fontWeights } = theme;

export const InputContainer = styled.div`
	width: 100%;
	position: relative;
	margin: 16px 0px;
`;

export const Label = styled.label`
	font-weight: ${fontWeights.bold};
`;

export const StyledInput = styled.input.attrs({
	type: 'text',
})`
	width: 100%;
	font-size: ${fontSizes.small};
	padding: 0.6em 1em;
	margin-bottom: 0em;
	color: ${colors.darkBlue};
	outline: none;
	height: 48px;
	background-color: ${colors.grey01};
	border-radius: 12px;

	&::placeholder {
		color: ${colors.grey60};
	}
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const InputError = styled.span.attrs({
	role: 'alert',
})`
	font-size: 12px;
	color: ${colors.red};
	position: absolute;
	right: 0;
	margin-right: 14px;
	top: calc(50% - 6px);
	animation: ${fadeIn} 200ms;
`;

export const TextArea = styled(StyledInput)`
	margin-bottom: 2.5em;
	min-height: 6.25em;
	display: inline-block;
	vertical-align: top;
	font-family: inherit;
	border-radius: ${radii.none};
	resize: none;
	border-radius: 16px;
`;
