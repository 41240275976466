import styled from 'styled-components';

const StyledFormElementError = styled.div`
	margin-top: 8px;
	font-size: 14px;
	font-weight: 600;
	color: #ff0452;
`;

export default StyledFormElementError;
