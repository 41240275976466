import React from 'react';
import styled from 'styled-components';
import theme from 'config/theme';
import { ReactComponent as LLDashboardStock } from 'assets/images/landlord-dashboard/LandlordDashboardStock.svg';

const { colors, fontWeights } = theme;

const Title = styled.div`
	font-weight: ${fontWeights.bold};
	font-size: 25px;
`;

const BodyContainer = styled(Title)`
	align-items: flex-start;
	font-weight: ${fontWeights.normal};
	font-size: 16px;
	text-align: left;
`;

const StaticList = styled.ul`
	margin-bottom: 24px;
	color: ${colors.darkBlue};
	text-align: left;
	li {
		list-style-type: disc;
		margin-left: 32px;
		margin-bottom: 5px;
	}
`;

const SVGContainer = styled.div`
	display: flex;
	background-color: ${colors.darkTurquoiseFaded};
	border-radius: 16px;
	align-items: center;
	padding: 8px;
	justify-content: center;
`;

const Container = styled.div`
	text-align: center;
`;

interface ProductUpdateContentProps {
	landlordName: string;
}

const ProductUpdateContent = ({ landlordName }: ProductUpdateContentProps) => (
	<Container>
		<BodyContainer>
			Hello {landlordName}
			<br />
			<br />
			Exciting news! We have recently launched our new Landlord dashboard. This will give you an
			overview of everything relating to your properties. Including:
			<br />
			<br />
			<StaticList>
				<li>Landlord specific statistics</li>
				<li>New enquiries and recent placements</li>
				<li>Individual listing performance such as enquiries, placements and revenue made</li>
				<li>Notifications regarding new enquiries and invoices</li>
			</StaticList>
			<SVGContainer>
				<LLDashboardStock />
			</SVGContainer>
		</BodyContainer>
	</Container>
);

export default ProductUpdateContent;
