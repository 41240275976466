import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import api from 'api';
import { useParams } from 'react-router-dom';
import { Button } from 'lib/Button';
import { Modal } from 'lib/Modal';
import theme from 'config/theme';
import { ReactComponent as RadioUnselected } from 'assets/images/icons/outline.svg';
import { ReactComponent as RadioSelected } from 'assets/images/icons/selected.svg';
import { Cross } from 'lib/Cross';
import { ReportReason } from './report-listing/types';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';
import { notifyError, notifySuccess } from 'lib/Notifications';

const { colors, fontWeights, fonts, zIndices } = theme;

interface ReportListingProps {
	id: string;
	onClose(): void;
}

interface Params {
	id: string;
}

const radioOptions = [
	{
		type: ReportReason.FakeListing,
		label: ReportReason.FakeListing,
	},
	{
		type: ReportReason.Inaccurate,
		label: ReportReason.Inaccurate,
	},
	{
		type: ReportReason.Phishing,
		label: ReportReason.Phishing,
	},
	{
		type: ReportReason.Safety,
		label: ReportReason.Safety,
	},
	{
		type: ReportReason.Private,
		label: ReportReason.Private,
	},
	{
		type: ReportReason.Other,
		label: ReportReason.Other,
	},
];

const ReportListingModal = ({ id, onClose }: ReportListingProps) => {
	const [reportedReason, setReportedReason] = useState<ReportReason>(ReportReason.None);
	const [submit, setSubmit] = useState(false);
	const [comment, setComment] = useState('');
	const params = useParams<Params>();
	const focusRef = useRef<HTMLTextAreaElement>(null);

	const scrollToBottom = () => {
		if (focusRef.current?.focus) {
			console.log('SCROLL TO BOT');
			focusRef.current.focus();
			document.getElementById('message')?.scrollIntoView();
		}
	};

	const handleInput = () => {
		const inputValue = document.getElementById('message') as HTMLInputElement;
		setComment(inputValue?.value);
		if (inputValue.value == '' && !inputValue.value) {
			setSubmit(false);
		} else {
			setSubmit(true);
		}
	};

	const handleClick = (reason: ReportReason) => {
		setReportedReason(reason);
		if (reason == ReportReason.None || reason == ReportReason.Other) {
			setSubmit(false);
			if (reason == ReportReason.Other) {
				setTimeout(() => {
					scrollToBottom();
				}, 0);
			}
		} else {
			setSubmit(true);
		}
	};

	const handleSubmit = async () => {
		const reportListingResponse = await api.searchV2.reportListing(id, reportedReason, comment);
		if (reportListingResponse.status != 201) {
			notifyError('Error reporting this listing, please try again.');
		} else {
			notifySuccess('Listing succesfully reported');
		}
		onClose();
	};

	return (
		<Modal>
			<ModalContainer>
				<Header>
					<ModalTitle>Report this listing</ModalTitle>
					<Cross onClick={onClose} />
				</Header>
				<Divider />
				<Container>
					<TitleContainer>
						<Title>Please share why you are wanting to report this listing</Title>
						<Description>Select a reason from below:</Description>
					</TitleContainer>
					<RadioContainer>
						{radioOptions.map(option => (
							<RadioOption
								key={option.type}
								id={option.type}
								onClick={() => handleClick(option.type)}
								bordered={reportedReason == option.type}
							>
								<RadioToggleContainer>
									{reportedReason === option.type ? <RadioSelected /> : <RadioUnselected />}
								</RadioToggleContainer>
								<RadioOptionLabel>{option.label}</RadioOptionLabel>
							</RadioOption>
						))}
					</RadioContainer>
					{reportedReason == ReportReason.Other && (
						<TextArea
							ref={focusRef}
							defaultValue=""
							id="message"
							placeholder={'Please specify'}
							onInput={() => {
								handleInput();
							}}
						/>
					)}
				</Container>
				<Divider />
				<ButtonContainer>
					<Button type="submit" isDisabled={!submit} onClick={handleSubmit}>
						Send report
					</Button>
				</ButtonContainer>
			</ModalContainer>
		</Modal>
	);
};

export default ReportListingModal;

const RadioOption = styled.div<{ bordered?: boolean }>`
	display: flex;
	flex-direction: row;
	padding: 0px 16px;
	box-sizing: border-box;
	background: ${colors.white};
	background: ${({ bordered }) => (bordered ? colors.darkTurquoiseFaded : colors.white)};
	border: ${({ bordered }) => (bordered ? '1px solid #041f47' : '1px solid #D9D9D9')};
	font-weight: ${({ bordered }) => (bordered ? fontWeights.bold : fontWeights.normal)};
	border-radius: 5px;
	margin-bottom: 4px;
	gap: 8px;
	justify-content: flex-start;
	width: 100%;
	align-items: center;

	&:hover {
		background: ${colors.darkTurquoiseFaded};
		cursor: pointer;
		font-weight: 700;
	}
`;

const RadioOptionLabel = styled.div`
	color: ${colors.darkBlue};
	margin: 8px 0px;
`;

const RadioContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	align-items: flex-start;
	justify-content: center;
`;

const RadioToggleContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 32px;
	min-height: 32px;
`;

const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow-y: scroll;
`;
const ButtonContainer = styled.div`
	height: 50px;
	width: 150px;
	margin-top: 10px;
	margin-bottom: 10px;
	justify-content: center;
	align-items: center;
`;
const Container = styled.div`
	background: #ffffff;
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 24px;
	width: 100%;
	overflow-y: scroll;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		gap: 8px;
	}
`;

const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const Divider = styled.div`
	border-bottom: 1px solid ${colors.grey10};
`;

const Header = styled.div`
	height: 80px;
	display: flex;
	width: 100%;
	z-index: ${zIndices.modal};
	left: 0;
	padding-right: 15px;

	align-items: center;
	top: 0;

	@media (max-width: 700px) {
		padding: 30px;
		background-color: ${colors.white};
	}
`;
const Title = styled.div`
	color: ${colors.darkBlue};
	font-weight: ${fontWeights.bold};
	font-family: ${fonts.Gilroy};
`;

const ModalTitle = styled.div`
	color: ${colors.darkBlue};
	font-weight: ${fontWeights.bold};
	font-family: ${fonts.Gilroy};
	font-size: 20px;
	flex: 1;
	align-items: center;
	text-align: center;
	margin-top: 15px;
	margin-bottom: 15px;
	font-weight: 700;
	line-height: 150%;
`;

const Description = styled.p`
	color: ${colors.darkBlue};
	font-weight: ${fontWeights.normal};
	font-size: 16px;
	font-family: ${fonts.Gilroy};
	line-height: 150%;
`;

const TextArea = styled.textarea`
	font-size: 16px;
	font-family: ${fonts.Gilroy};
	color: ${colors.darkBlue};
	background-color: ${colors.lightGray};
	padding: 30px 20px;
	border-radius: 20px;
	outline: none;
	min-height: 160px;
	font-weight: ${fontWeights.bold};
	&::placeholder {
		color: ${colors.lightBlue};
	}
`;
