import client from '../utils/fetch-client';

const path = '/institution/';

export default {
	getInstitutionDashboard(id) {
		return client.get(`${path}${id}`);
	},

	getVerifications(id) {
		return client.get(`${path}${id}/verifications`);
	},

	getVerificationDetail(institution_id = '', verification_id = '') {
		return client.get(`${path}${institution_id}/verifications/${verification_id}`);
	},

	getVerificationNotes(institution_id = '', verification_id = '') {
		return client.get(`${path}${institution_id}/verifications/${verification_id}/notes`);
	},

	createVerificationNote(institution_id = '', verification_id = '', body) {
		return client.post(`${path}${institution_id}/verifications/${verification_id}/notes`, {
			body,
		});
	},

	updateVerificationReport(institutionId = '', verificationId = '', body = {}) {
		return client.put(`${path}${institutionId}/verifications/${verificationId}`, {
			...body,
		});
	},
};
