import React from 'react';
import styled from 'styled-components';
import LocationIcon from 'assets/images/icons/location.svg';
import UniversityIcon from 'assets/images/icons/university.svg';
import theme from 'config/theme';
const { fonts, colors } = theme;

const SearchResult = ({ university = false, title = '', isSelected = false, ...props }) => {
	return (
		<Container onClick={() => props.onClick()} isSelected={isSelected}>
			<Icon university={university} />
			<Title>{title}</Title>
		</Container>
	);
};

export default SearchResult;

const Container = styled.li`
	width: 100%;
	padding: 16px 12px;
	display: flex;
	align-items: center;
	cursor: pointer;
	&:hover {
		background-color: ${colors.grey05};
	}
	${({ isSelected }) => (isSelected ? `background-color: ${colors.grey05};` : '')}
	font: ${fonts.Gilroy};
`;

const Title = styled.div``;

const Icon = styled.div`
	background-image: url("${({ university }) => (university ? UniversityIcon : LocationIcon)}");
	width: 16px;
	height: 16px;
	margin: 0 16px 0 8px;
	padding: 3px;
	background-size: contain;
	background-position: center;
`;
