import React from 'react';

import { Motion, spring } from 'react-motion';

import theme from 'config/theme';
import { Loader } from 'lib/Loader';

const { colors } = theme;

const loadingStyle = {
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100vh',
	backgroundColor: colors.white,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
};

const Loading = ({ children, pastDelay }) => (
	<Motion
		defaultStyle={{ showLoading: 0, showContent: 0 }}
		style={{
			showContent: spring(!pastDelay && children ? 1 : 0),
			showLoading: spring(!pastDelay && children ? 0 : 1),
		}}
	>
		{({ showContent, showLoading }) => (
			<>
				{!!showLoading && (
					<div style={{ ...loadingStyle, opacity: showLoading }}>
						<Loader />
					</div>
				)}
				<div style={{ opacity: showContent }}>{children}</div>
			</>
		)}
	</Motion>
);

export default Loading;
