import styled from 'styled-components';
import theme from 'config/theme';

const { colors } = theme;

const StyledBottomCTAWrapper = styled.div`
	@media (max-width: 1080px) {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 16px 24px;
		border-top: 1px solid ${colors.grey10};
		background: ${colors.white};
	}
`;

export default StyledBottomCTAWrapper;
