import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';
import styled from 'styled-components';

export const ModalContainer = styled.div`
	padding: 20px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	height: 100%;
`;

export const Heading = styled.h2`
	font-size: 22px;
	font-weight: 600;
`;

export const Title = styled.h3`
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 16px;
`;

export const ModalHeader = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 16px;
`;

export const BasicText = styled.div`
	line-height: 1.3;
	opacity: 0.8;
	margin-bottom: 16px;
`;
