import React from 'react';
import styled from 'styled-components';

import { TopBar } from 'lib/TopBar';
import { Modal } from 'lib/Modal';
import Button from 'lib/Button';
import routes from 'config/routes';

const HelpModal = ({ onClose }: { onClose(): void }) => {
	return (
		<Modal width={400} mobileBottomsheet onBackgroundClick={onClose}>
			<TopBar title="Help" onClose={onClose} />
			<ContentContainer>
				<Button link={routes.contact_us} openLinkInNewTab isOutline>
					Contact support
				</Button>
			</ContentContainer>
		</Modal>
	);
};

const ContentContainer = styled.div`
	padding: 16px;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 16px;
`;

export default HelpModal;
