import styled from 'styled-components';

const StyledTitle = styled.h2`
	@media (max-width: 1080px) {
		font-size: 24px;
		margin-bottom: 15px;
	}

	color: #041f47;
	font-size: 32px;
	font-weight: 700;
	margin-bottom: 20px;
`;
export default StyledTitle;
