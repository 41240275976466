import React from 'react';

import styled from 'styled-components';

import { Modal } from 'lib/Modal';
import theme from 'config/theme';
import { TopBar } from 'lib/TopBar';
import ModalImage from '../../../assets/images/landlord-dashboard/TrustedLandlordModal.jpg';

const { colors, fontWeights } = theme;

interface TrustedLandlordProps {
	onClose(): void;
}
const TrustedLandlordModal = ({ onClose }: TrustedLandlordProps) => {
	return (
		<Modal mobileBottomsheet slideUp>
			<TopBar
				title={'Trusted Landlord'}
				subTitle={'What is a Trusted Landlord?'}
				onClose={onClose}
			/>
			<Container>
				<ContentContainer>
					<QuestionContainer>
						A Trusted landlord is user on DigsConnect which meets the following criteria:
					</QuestionContainer>
					<InfoContainer>
						<Ul>
							<Li>Has at least 1 listed property</Li>
							<Li>
								Verified themselves as a landlord, by providing proof of ID, and proof of listing
								ownership.
							</Li>
							<Li>Placed and paid for at least 1 tenant through the platform</Li>
						</Ul>
					</InfoContainer>
					<QuestionContainer>
						So why would you want to be a DigsConnect trusted landlord? By being a trusted landlord,
						your listing receives the following benefits:
					</QuestionContainer>
					<InfoContainer>
						<Ul>
							<Li>
								Tenants are more likely to enquire to your listings if you have a Trusted Landlord
								badge on your listing previews.
							</Li>
							<Li>
								Your properties are recommended to tenants, and appear first in search results.
							</Li>
						</Ul>
					</InfoContainer>
				</ContentContainer>
				<ImageContainer>
					<img src={ModalImage} />
				</ImageContainer>
			</Container>
		</Modal>
	);
};

export default TrustedLandlordModal;

const ImageContainer = styled.div`
	background-color: ${colors.darkTurquoiseFaded};
	border-radius: 16px;
	padding: 16px 32px;
`;

const Container = styled.div`
	background: ${colors.white};
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	overflow-y: auto;
	padding: 24px;
`;

const QuestionContainer = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 16px;
	font-weight: ${fontWeights.bold};
`;

const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 16px;
	width: 100%;
`;
const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 16px;
	width: 100%;
`;

const Ul = styled.ul`
	list-style-type: disc;
	margin-left: 24px;
	margin-bottom: 16px;
`;

const Li = styled.li`
	list-style-type: disc;
	margin-bottom: 8px;
`;
