import { Container } from 'unstated';

import { SearchItem } from 'models/search';
import searchService from 'services/searchService';

interface State {
	areaTitle: string;
	areaTitleFull: string;
	selectedItems: SearchItem[];
}

const INITIAL_STATE: State = {
	areaTitle: '',
	areaTitleFull: '',
	selectedItems: [],
};

const MULTI_TITLES_DISPLAY_LIMIT = 3;

const getMultiLocationTitle = (items: SearchItem[], isShortTitle: boolean): string => {
	const concatenatedTitles = items
		.slice(0, MULTI_TITLES_DISPLAY_LIMIT)
		.map(item => (isShortTitle ? searchService.getShortTitle(item) : item.label))
		.join(', ');

	if (items.length > MULTI_TITLES_DISPLAY_LIMIT) {
		return `${concatenatedTitles} & ${items.length - MULTI_TITLES_DISPLAY_LIMIT} more`;
	}

	return concatenatedTitles;
};

class SearchContainer extends Container<State> {
	state = INITIAL_STATE;

	setSelectedItem(item: SearchItem): void {
		if (!item) {
			this.clear();
			return;
		}
		this.setState(state => ({
			...state,
			areaTitle: searchService.getShortTitle(item),
			areaTitleFull: item.label,
			selectedItems: [item],
		}));
	}

	setSelectedItems(items: SearchItem[]): void {
		if (!items || !items.length) {
			this.clear();
			return;
		}
		this.setState(state => ({
			...state,
			areaTitle: getMultiLocationTitle(items, true),
			areaTitleFull: getMultiLocationTitle(items, false),
			selectedItems: items,
		}));
	}

	clear(): void {
		this.setState(state => ({
			...state,
			...INITIAL_STATE,
		}));
	}
}

export const searchContainer = new SearchContainer();

export default searchContainer;
