import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import countryCodes from 'constants/countryCodes';
import theme from 'config/theme';
import ReactCountryFlag from './CountryCodeSelect/ReactCountryFlag';
import { ReactComponent as DropdownIcon } from 'assets/icons/caret-down.svg';
import { validationErrors } from 'config/validationErrors';

const { colors } = theme;

interface MobileNumberInputProps {
	country: string;
	mobileNumber: string;
	onCountrySelect: (option: string) => void;
	onMobileNumberChange: (newValue: string) => void;
	noBorder?: boolean;
	disabled?: boolean;
	isErrorState?: boolean;
	errorMessage?: string;
}

const MobileNumberInput = ({
	country = 'ZA',
	mobileNumber,
	noBorder,
	disabled,
	isErrorState = false,
	errorMessage = validationErrors.InvalidField,
	onCountrySelect,
	onMobileNumberChange,
}: MobileNumberInputProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef<any>(null);

	const selectedCountry = countryCodes.find(o => o.code === country) || countryCodes[0];

	const handleSelect = (option: any) => {
		onCountrySelect(option.code);
		onMobileNumberChange('');
		setIsOpen(false);
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
				setIsOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [dropdownRef]);

	return (
		<MainContainer>
			<Container disabled={disabled}>
				<DropdownContainer>
					<DropdownControl ref={dropdownRef} onClick={() => setIsOpen(!isOpen)} noBorder={noBorder}>
						<ReactCountryFlag
							style={{
								width: '1.5em',
								height: '1.5em',
								fontSize: '1.5em',
							}}
							countryCode={selectedCountry.code}
							svg
						/>
						<DropdownIcon style={{ width: 10, height: 10 }} />
					</DropdownControl>
				</DropdownContainer>
				{isOpen && (
					<DropdownList ref={dropdownRef} noBorder={noBorder}>
						{countryCodes.map(countryCode => (
							<DropdownListItem key={countryCode.code} onClick={() => handleSelect(countryCode)}>
								<DropdownListItemLeft>
									<ReactCountryFlag
										style={{
											width: '1.5em',
											height: '1.5em',
											fontSize: '1.5em',
										}}
										countryCode={countryCode.code}
										svg
									/>
									<CountryNameText>{countryCode.country}</CountryNameText>
								</DropdownListItemLeft>
								<CountryCodeText>+{countryCode.dial_code}</CountryCodeText>
							</DropdownListItem>
						))}
					</DropdownList>
				)}
				<TextInputContainer noBorder={noBorder}>
					{selectedCountry && <CountryCodeText>+{selectedCountry.dial_code}</CountryCodeText>}
					<StandardInput
						placeholder="Mobile number"
						value={mobileNumber}
						onChange={e => onMobileNumberChange((e.target.value || '').toString().trim())}
						onKeyPress={event => {
							if (!/[0-9]/.test(event.key)) {
								event.preventDefault();
							}
						}}
					/>
				</TextInputContainer>
			</Container>
			{!!isErrorState && !!errorMessage && <ValidationError>{errorMessage}</ValidationError>}
		</MainContainer>
	);
};

const Container = styled.div<{ disabled?: boolean }>`
	display: flex;
	position: relative;
	width: 100%;
	margin-bottom: 16px;
	disabled: ${({ disabled }) => (disabled ? 'true' : 'false')};
`;

const MainContainer = styled.div`
	width: 100%;
`;

const DropdownContainer = styled.div`
	width: 76px;
`;

const DropdownControl = styled.div<{ noBorder?: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: ${colors.grey05};
	border-top-left-radius: 16px;
	border-bottom-left-radius: 16px;
	padding: 10px;
	cursor: pointer;
	width: 76px;
	height: 56px;
	border: ${({ noBorder }) => (noBorder ? 'none' : `2px solid ${colors.darkBlue}`)};
	border-right: 0;
	padding-right: 8px;
`;

const DropdownList = styled.ul<{ noBorder?: boolean }>`
	position: absolute;
	top: 64px;
	left: 0;
	z-index: 999;
	width: 100%;
	background-color: ${colors.grey05};
	padding: 10px 0;
	margin: 0;
	max-height: 250px;
	overflow-y: auto;
	border-radius: 16px;
	border: ${({ noBorder }) => (noBorder ? 'none' : `2px solid ${colors.darkBlue}`)};
`;

const DropdownListItem = styled.li`
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	padding-top: 4px;
	padding-bottom: 4px;
	cursor: pointer;

	&:hover {
		background-color: ${colors.grey10};
	}
`;

const CountryNameText = styled.div`
	margin-left: 8px;
	font-size: 16px;
`;

const CountryCodeText = styled.div`
	color: ${colors.grey60};
	font-size: 16px;
`;

const DropdownListItemLeft = styled.div`
	display: flex;
	align-items: center;
`;

const StandardInput = styled.input<{ lightBackground?: boolean; fontSize?: number }>`
	color: ${colors.darkBlue};
	background: transparent;
	width: 100%;
	padding-left: 8px;
	outline: none;
	::placeholder {
		text-overflow: ellipsis;
	}
`;

const ValidationError = styled.div`
	font-size: 12px;
	color: ${colors.pink};
	margin-top: -16px;
	padding: 4px 16px;
`;

const TextInputContainer = styled.div<{
	lightBackground?: boolean;
	fontSize?: number;
	noBorder?: boolean;
}>`
	background-color: ${colors.grey05};
	color: ${colors.darkBlue};
	padding-left: 8px;
	width: 100%;
	height: 56px;
	border: ${({ noBorder }) => (noBorder ? 'none' : `2px solid ${colors.darkBlue}`)};
	border-top-right-radius: 16px;
	border-bottom-right-radius: 16px;
	${({ fontSize }) => (!!fontSize ? 'font-size: ${fontSize}px;' : 'font-size: auto;')};
	outline: none;
	::placeholder {
		text-overflow: ellipsis;
	}
	display: flex;
	justify-content: center;
	align-items: center;
`;

export default MobileNumberInput;
