import styled from 'styled-components';

import React from 'react';
import ReactLoading from 'react-loading';

function ResendButton(props) {
	const { loading } = props;

	return (
		<StyledResendButton {...props}>
			{loading ? (
				<LoadingWrapper>
					<ReactLoading type="bubbles" color="#9da7b7" height={50} width={50} />
				</LoadingWrapper>
			) : (
				props.children
			)}
		</StyledResendButton>
	);
}

export default ResendButton;

/**styled components */
const StyledResendButton = styled.button`
	outline: none;
	color: #9da7b7;
	font-weight: 400;
	font-size: 14px;
	background-color: transparent;
`;

const LoadingWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 56px;
`;
