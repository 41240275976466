import React from 'react';

import { InputContainer, InputError, Label, StyledInput, TextArea } from './styles';

const Input = ({ error, id, label, ...props }) => (
	<>
		{label && <Label htmlFor={id}>{label}</Label>}
		<InputContainer>
			<StyledInput id={id} {...props} />
			{error && <InputError>{error}</InputError>}
		</InputContainer>
	</>
);

Input.displayName = 'Input';

Input.Container = InputContainer;

Input.Label = Label;

Input.Error = InputError;

Input.TextArea = TextArea;

export default Input;
