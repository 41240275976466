
export interface PaginatedInbox {
	count: number;
	next: string;
	previous: string;
	results: Conversation[];
}

export interface Conversation {
	archived: boolean;
	date_created: string;
	date_created_epoch: string;
	date_expiry: string;
	date_expiry_epoch: string;
	date_status_change: string;
	date_status_change_epoch: string;
	latest_message: {
		date_created: string;
		date_created_epoch: string;
		extra_data: any;
		file: any;
		message: string;
		message_type: MessageType;
		show: boolean;
		user: any;
	};
	other_users: ConversationUser[];
	pinned: boolean;
	property_title: string;
	read: boolean;
	search_terms: string[];
	status: ConversationStatus;
	status_readable: string;
	unread_messages: number;
	users: ConversationUser[];
	uuid: string;
}

export interface ConversationUser {
	first_name: string;
	is_trusted_landlord: boolean;
	join_date: string;
	last_name: string;
	profile_picture: {
		face_detected: boolean;
		is_default: boolean;
		url: string;
	};
	face_detected: boolean;
	is_default: boolean;
	url: string;
	uuid: string;
}

export interface ConversationMessages {
	uuid: string;
	status: ConversationStatus;
	status_readable: string;
	messages: {
		count: number;
		current_page: number;
		num_pages: number;
		newer_messages: boolean;
		last_uuid: string;
		results: ConversationMessage[];
	};
}

export interface ConversationMessage {
	uuid: string;
	message_type: MessageType;
	user: null | { first_name: string; uuid: string };
	message: string;
	file?: string;
	extra_data: {
		context_text?: string;
		context_action?: string;
		inline_action?: boolean;
		icon_url?: string;
	};
	date_created: string;
	date_created_epoch: string;
	show: boolean;
	read: boolean;
}

export enum MessageType {
	Unknown = '',
	Support = 'support',
	Context = 'context',
	Message = 'message',
	Document = 'document',
	Image = 'image',
}

export enum ConversationStatus {
	Unknown = '',
	Confirmed = 'confirmed',
	Pending = 'pending',
	Connected = 'connected',
	Expired = 'expired',
	Cancelled = 'cancelled',
}

export enum ConversationAction {
	Unknown = '',
	Pin = 'pin',
	Unpin = 'unpin',
	Archive = 'archive',
	Restore = 'unarchive',
	Report = 'report',
}

interface FilterOption {
	label: string;
	value: string;
}

export interface InboxFilters {
	status: FilterOption[];
	gender: FilterOption[];
	occupation: FilterOption[];
	payer: FilterOption[];
}

export interface ConversationBookings {
	lease_information: {
		occupancy_lease_term: string;
		occupancy_start_date: string;
		occupant_rental: number;
		service_fee: number;
		total_lease_value: number;
	};
	booking_information: {
		property_uuid: string;
		room_uuid?: string;
	};
	listings: {
		share_url: string;
		title: string;
	}[];
	other_user: {
		cellphone: string;
		email: string;
		first_name: string;
		join_date: string;
		last_name: string;
		profile_picture: {
			url: string;
		};
		uuid: string;
	};
	personal_information: {
		age: number;
		birth_date: string;
		gender: string;
		institution: { id: number; name: string };
		id: number;
		name: string;
		level_of_study: string;
		nationality: string;
		occupant_type: string;
		occupation: null;
		payment_provider: string;
	};
	status: ConversationStatus;
	status_readable: string;
}

export interface ConversationAttachments {
	documents: {
		date_created: string;
		file: string;
	}[];
	images: {
		date_created: string;
		file: string;
	}[];
}
