const formatForServer = (date: Date): string =>
	`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

const padLeadingZeros = (num: number): string => `00${num}`.slice(-2);

const isToday = (date: Date): boolean => {
	const today = new Date();
	return (
		date.getDate() === today.getDate() &&
		date.getMonth() === today.getMonth() &&
		date.getFullYear() === today.getFullYear()
	);
};

const formatTime = (date: Date): string => {
	let hours = date.getHours();
	const minutes = date
		.getMinutes()
		.toString()
		.padStart(2, '0');
	const period = hours >= 12 ? 'pm' : 'am';

	if (hours > 12) {
		hours -= 12;
	} else if (hours === 0) {
		hours = 12;
	}

	const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes}`;

	return `${formattedTime}${period}`;
};

const formatForDisplay = (
	value: Date | string,
	showTimeIfToday?: boolean,
	alwaysShowTime?: boolean,
): string => {
	if (!value) {
		return '';
	}
	const date = typeof value === 'string' ? new Date(value) : value;

	if (showTimeIfToday && isToday(date)) {
		return formatTime(date);
	}

	const day = padLeadingZeros(date.getDate());
	const month = padLeadingZeros(date.getMonth() + 1);
	const year = padLeadingZeros(date.getFullYear());
	const dateDisplay = `${day}/${month}/${year}`;

	if (alwaysShowTime) {
		return `${dateDisplay} ${formatTime(date)}`;
	}

	return dateDisplay;
};

const isSameDay = (date1: Date, date2: Date): boolean => {
	return (
		date1.getFullYear() === date2.getFullYear() &&
		date1.getMonth() === date2.getMonth() &&
		date1.getDate() === date2.getDate()
	);
};

const formatDateLongDisplay = (date: Date): string => {
	const day = date.getDate();
	const month = date.toLocaleString('default', { month: 'long' });
	const year = date.getFullYear();

	let daySuffix = 'th';
	if (day === 1 || day === 21 || day === 31) {
		daySuffix = 'st';
	} else if (day === 2 || day === 22) {
		daySuffix = 'nd';
	} else if (day === 3 || day === 23) {
		daySuffix = 'rd';
	}

	return `${day}${daySuffix} ${month} ${year}`;
};

const dateService = {
	formatForServer,
	formatForDisplay,
	formatDateLongDisplay,
	formatTimeDisplay: formatTime,
	isSameDay,
};

export default dateService;
