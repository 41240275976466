import React from 'react';

import styled from 'styled-components';

import theme from 'config/theme';
import { PopupBottom, PopupButtonContainer } from './CommonStyles';
import { Modal as ModalContainer } from 'lib/Modal';
import { Title } from 'lib/Title';
import { InfoText } from 'lib/InfoText';
import { Cross } from 'lib/Cross';
import { Button } from 'lib/Button';
import { useScreenWidth } from 'hooks/useScreenWidth';
import { BREAKPOINT_MOBILE_PX } from 'constants/breakPoints';

const { colors } = theme;

export const Modal = ({
	title,
	subTitle,
	actionText,
	secondaryActionText,
	children,
	icon,
	canClose = true,
	onActionClick,
	onSecondaryActionClick,
	onClose,
}) => {
	const width = useScreenWidth();

	const handleBackgroundClick = () => {
		if (!canClose) {
			return;
		}

		onClose();
	};

	const isMobile = width <= BREAKPOINT_MOBILE_PX;

	return (
		<ModalContainer onBackgroundClick={handleBackgroundClick}>
			<ModalTitleContainer>
				{canClose && <Cross onClick={onClose} />}
				<div>
					{!!title && (
						<TitleContainer>
							{icon}
							<Title noMargin={!subTitle} standardMargin={!!subTitle}>
								{title}
							</Title>
						</TitleContainer>
					)}
					{!!subTitle && <InfoText noMargin>{subTitle}</InfoText>}
				</div>
				{canClose && !(isMobile && secondaryActionText) && <Cross visibility={'hidden'} />}
				{isMobile && secondaryActionText && (
					<SecondaryAction onClick={onSecondaryActionClick}>{secondaryActionText}</SecondaryAction>
				)}
			</ModalTitleContainer>
			<ModalInner>{children}</ModalInner>
			{!!actionText && (
				<CustomPopupBottom hasSecondaryAction={Boolean(secondaryActionText)}>
					{!isMobile && secondaryActionText && (
						<SecondaryAction onClick={onSecondaryActionClick}>
							{secondaryActionText}
						</SecondaryAction>
					)}
					<CustomPopupButtonContainer fixedWidth={Boolean(!isMobile && secondaryActionText)}>
						<Button noMargin isOutline onClick={onActionClick}>
							{actionText}
						</Button>
					</CustomPopupButtonContainer>
				</CustomPopupBottom>
			)}
		</ModalContainer>
	);
};

const ModalInner = styled.div`
	overflow-y: auto;
	padding: 16px 40px;

	@media (max-width: 768px) {
		margin-bottom: 88px;
		padding: 16px;
	}
`;

const ModalTitleContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px 24px;
	padding-top: 20px;
	border-bottom: 1px solid ${colors.grey10};
`;

const CustomPopupButtonContainer = styled(PopupButtonContainer)`
	${({ fixedWidth }) => (fixedWidth ? 'width: 200px;' : '')}
`;

const CustomPopupBottom = styled(PopupBottom)`
	flex-shrink: 0;
	border-top: 1px solid ${colors.grey10};
	display: flex;
	justify-content: ${({ hasSecondaryAction }) => (hasSecondaryAction ? 'space-between' : 'center')};
	align-items: center;

	@media (max-width: 768px) {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: white;
	}
`;

const TitleContainer = styled.div`
	display: flex;
	align-items: center;
`;

const SecondaryAction = styled.div`
	white-space: nowrap;
	font-weight: 500;
	cursor: pointer;

	@media (max-width: ${BREAKPOINT_MOBILE_PX}px) {
		margin-left: -16px;
	}
`;
