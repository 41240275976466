import * as React from 'react';

import styled, { keyframes } from 'styled-components';

import theme from 'config/theme';

const { zIndices, colors } = theme;

interface ModalProps {
	children: any;
	slideUp?: boolean;
	width?: number;
	height?: number;
	mobileBottomsheet?: boolean;
	bottomOffset?: number;
	onBackgroundClick?(): void;
}

export const Modal = ({
	children,
	slideUp,
	height,
	width,
	mobileBottomsheet,
	onBackgroundClick,
}: ModalProps) => {
	const handleBackgroundClick = () => {
		onBackgroundClick && onBackgroundClick();
	};

	return (
		<ModalBackground
			slideUp={slideUp}
			onClick={handleBackgroundClick}
			onTouchEnd={onBackgroundClick}
			mobileBottomsheet={mobileBottomsheet}
		>
			<ModalContainer
				slideUp={slideUp}
				height={height}
				width={width}
				mobileBottomsheet={mobileBottomsheet}
				onClick={e => e.stopPropagation()}
				onTouchEnd={e => e.stopPropagation()}
			>
				{children}
			</ModalContainer>
		</ModalBackground>
	);
};

const ModalBackground = styled.div<{
	slideUp?: boolean;
	mobileBottomsheet?: boolean;
}>`
	width: 100%;
	margin: auto;
	background-color: rgba(0, 0, 0, 0.4);
	position: fixed;
	left: 0;
	top: 0;
	z-index: ${zIndices.modal};
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	@media (max-width: 768px) {
		align-items: center;

		${({ mobileBottomsheet }) =>
			!mobileBottomsheet
				? ''
				: `
			border: 1px solid ${colors.grey10};
			box-shadow: 0 -16px 64px rgba(0,0,0,0.2);
			overflow: hidden;
			height: 100%;
    `}
`;

const slideInKeyframes = keyframes`
  from {
    opacity: 0;
    margin-top: 80px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
`;

const ModalContainer = styled.div<{
	height?: number;
	width?: number;
	slideUp?: boolean;
	mobileBottomsheet?: boolean;
}>`
	background-color: ${colors.white};
	width: ${({ width }) => `${width || 640}px`};
	max-height: 90vh;
	margin: 0 auto;
	border-radius: 16px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	${({ height }) => height && `height: ${height}px;`}
	animation: ${slideInKeyframes} 300ms ease-out;

  @media (max-width: 768px) {
    padding: 0;
    max-height: 100vh;
    height:  ${({ slideUp }) => (slideUp ? 'auto' : '100%')};
    width: 100%;
    border-radius: ${({ slideUp }) => (slideUp ? '16px' : '0')};
	${({ mobileBottomsheet }) =>
		!mobileBottomsheet
			? ''
			: `
		border-radius: 0px;
		border-top-left-radius: 15px;
		position: absolute;
		border-top-right-radius: 15px;
		border: 1px solid ${colors.grey10};
		box-shadow: 0 -16px 64px rgba(0,0,0,0.2);
		overflow: hidden;
		height: auto;
		bottom: 0px;
    `}
  }
`;
