import client from '../utils/fetch-client';

const path = '/search';

export default {
	getLocations() {
		return client.get(`${path}/searchlist`);
	},

	reportListing(listingId, body) {
		return client.post(`${path}/digs/${listingId}/report`, { body });
	},

	createApplication(listingId = '', config = {}) {
		return client.post(`${path}/digs/${listingId}/application`, config);
	},
};
