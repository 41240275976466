import React from 'react';

import styled from 'styled-components';
import { components } from 'react-select';

import theme from 'config/theme';

const { colors } = theme;

const LinkText = styled.span`
	color: ${colors.pink};
	text-decoration: underline;
	cursor: pointer;
`;

export const NoOptionsMessage = (props: any) => {
	const handleButtonClick = () => {
		props.selectProps.onButtonClick();
	};

	return (
		<components.NoOptionsMessage {...props}>
			<p>
				{!!props.selectProps.searchValue && (
					<>
						{props.selectProps.title}{' '}
						<LinkText onClick={handleButtonClick}>{props.selectProps.buttonText}</LinkText>
					</>
				)}
				{!props.selectProps.searchValue && <>Start typing...</>}
			</p>
		</components.NoOptionsMessage>
	);
};
